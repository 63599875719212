import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useMedia } from '../../utils/customHooks';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rootDiv: {
    width: '100%',
    background: theme.palette.primary.blueZodiac,
  },
  contentDiv: {
    color: 'white',
    padding: '1.5em',
    minWidth: '90%',
    width: 'unset',
    maxWidth: 'unset',
    fontSize: 'unset',
  },
  headerRoot: {
    position: 'relative',
    textAlign: 'left',
    marginBottom: '1em',
    fontSize: '2rem',
    display: 'flex',
  },
}));

export default function HeadlineBox(props) {
  const { height, children, headChildren, headLineText, textTransform } = props;

  const classes = useStyles();

  const textTransformStyle = 'none';

  const { isMobile, fontSize, marginBottom } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: false,
        fontSize: '2rem',
        marginBottom: '1em',
      },
    ],
    {
      isMobile: true,
      fontSize: '1.5rem',
      marginBottom: 'unset',
    }
  );

  const heightSize = isMobile ? 'auto' : height;

  return (
    <div className={classes.rootDiv} style={{ height: heightSize }}>
      <div className={`MuiTab-root ${classes.contentDiv}`}>
        <div
          className={classes.headerRoot}
          style={{ marginBottom: marginBottom }}
        >
          <div
            style={{
              textTransform: textTransformStyle,
              marginRight: 'auto',
              fontSize: fontSize,
            }}
          >
            {headLineText && <FormattedMessage id={headLineText} />}
          </div>
          {headChildren}
        </div>
        {children}
      </div>
    </div>
  );
}

HeadlineBox.defaultProps = {
  height: '90px',
};

HeadlineBox.propTypes = {
  height: PropTypes.string.isRequired,
  headLineText: PropTypes.string.isRequired,
  headChildren: PropTypes.node,
  children: PropTypes.node,
};
