import React, { useEffect } from 'react';
import LostAndFoundTableOverview from './lostAndFoundTableOverview';
import { getLostUlds } from '../../actions/lostAndFound';
import { connect } from 'react-redux';

export function LostUlds({ getLostUlds, token, filterString, lostUlds }) {
  useEffect(() => {
    if (token) getLostUlds(token);
  }, token);

  const headRows = (formatMessage) => [
    {
      id: 'companyName',
      numeric: false,
      disablePadding: true,
      label: formatMessage({ id: 'TABLE_HEADER.COMPANY_NAME' }),
      sortable: true,
    },
    {
      id: 'companyCode',
      numeric: true,
      disablePadding: true,
      label: formatMessage({ id: 'TABLE_HEADER.COMPANY_CODE' }),
      sortable: true,
    },
    {
      id: 'uldIds',
      sortable: false,
      numeric: false,
      disablePadding: true,
      label: formatMessage({ id: 'ADD_NEW_LOST_AND_FOUND.ULD_ENTRY' }),
    },
    {
      id: 'creationDate',
      numeric: false,
      disablePadding: true,
      label: formatMessage({ id: 'BASE.CREATION_DATE' }),
      sortable: true,
    },
    {
      id: 'comment',
      numeric: false,
      disablePadding: true,
      label: formatMessage({ id: 'BASE.LOST_FOUND_CHAT' }),
      sortable: false,
    },
  ];

  const filterData = (rows) => {
    return rows.filter((row) =>
      Object.values([
        row.companyCode,
        row.companyName,
        row.uldIds,
        row.comment,
      ]).find((values) =>
        String(values)
          .toLocaleLowerCase()
          .includes(filterString.toLocaleLowerCase())
      )
    );
  };

  return (
    <LostAndFoundTableOverview
      rows={filterData(lostUlds)}
      headRows={headRows}
    />
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  lostUlds: state.lostAndFound.lostUlds,
  filterString: state.lostAndFound.filterString,
});

const mapDispatchToProps = (dispatch) => ({
  getLostUlds: (token) => dispatch(getLostUlds(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LostUlds);
