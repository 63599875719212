import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getReminders,
  getAvailableAudience,
  getAvailableIntervals,
  addReminder,
  deleteReminder,
  editReminder,
} from '../../actions/reminder';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ReminderForm from '../../components/reminder/reminderForm';
import UldShapedFooterWithButtons from '../../components/uldShapedFooterWithButtons';
import { withSnackbar } from 'notistack';
import ReminderComponent from '../../components/reminder/reminderComponent';
import * as ReminderModel from '../../model/reminder';

const styles = (theme) => ({
  titleText: {
    fontSize: '1.5rem',
    fontWeight: '700',
    padding: '0',
    marginBottom: '2rem',
    marginTop: '1.5rem',
    textAlign: 'left',
    color: theme.palette.primary.chambray,
  },
  form: {
    display: 'flex',
    width: '100%',
  },
  withMargin: {
    marginTop: '1rem',
  },
  center: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
  },
});

export class Reminder extends PureComponent {
  initialState = {
    title: '',
    message: '',
    firstExecutionDate: new Date().toISOString().slice(0, 10),
    firstExecutionTime: new Date().toISOString().slice(11, 16),
    interval: 7,
    intervalTimeUnit: '',
    audience: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedReminder: undefined,
      ...this.initialState,
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { token, enqueueSnackbar } = this.props;

    this.props.getAvailableAudience(token, enqueueSnackbar);
    this.props.getAvailableIntervals(token, enqueueSnackbar);
    this.props.getAllReminders(token, enqueueSnackbar);
  }

  changeTitle = (newValue) => this.setState({ title: newValue });
  changeMessage = (newValue) => this.setState({ message: newValue });
  changeFirstExecutionTime = (newValue) =>
    this.setState({ firstExecutionTime: newValue });
  changeFirstExecutionDate = (newValue) =>
    this.setState({ firstExecutionDate: newValue });
  changeInterval = (newValue) => this.setState({ interval: newValue });
  changeIntervalTimeUnit = (newValue) =>
    this.setState({ intervalTimeUnit: newValue });
  changeAudience = (newValue) => this.setState({ audience: newValue });

  clearAllFields = () =>
    this.setState({
      ...this.state,
      ...this.initialState,
    });

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.selectedReminder) {
      this.props.editReminder(
        {
          id: this.state.selectedReminder,
          title: this.state.title,
          message: this.state.message,
          firstExecutionTime: new Date(
            this.state.firstExecutionDate +
              'T' +
              this.state.firstExecutionTime +
              ':00.000Z'
          ),
          interval: this.state.interval,
          intervalTimeUnit: this.state.intervalTimeUnit,
          audience: this.state.audience,
        },
        this.props.token,
        this.props.enqueueSnackbar,
        () => this.setState({ selectedReminder: null, ...this.initialState })
      );
    } else {
      this.props.addReminder(
        {
          title: this.state.title,
          message: this.state.message,
          firstExecutionTime: new Date(
            this.state.firstExecutionDate +
              'T' +
              this.state.firstExecutionTime +
              ':00.000Z'
          ),
          interval: this.state.interval,
          intervalTimeUnit: this.state.intervalTimeUnit,
          audience: this.state.audience,
        },
        this.props.token,
        this.props.enqueueSnackbar,
        this.clearAllFields
      );
    }
  };

  deleteReminder = (id) => {
    this.props.deleteReminder(
      id,
      this.props.token,
      this.props.enqueueSnackbar,
      () => {
        if (this.state.selectedReminder === id) {
          this.setState({ selectedReminder: null, ...this.initialState });
        }
      }
    );
  };

  selectReminder = (reminder) => {
    this.setState({
      selectedReminder: reminder.id,
      title: reminder.title,
      message: reminder.message,
      firstExecutionDate: reminder.firstExecutionTime.slice(0, 10),
      firstExecutionTime: reminder.firstExecutionTime.slice(11, 16),
      interval: reminder.interval,
      intervalTimeUnit: reminder.intervalTimeUnit,
      audience: reminder.audience,
    });
    requestAnimationFrame(() => {
      this.formRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    });
  };

  render() {
    const { classes, reminders } = this.props;
    const { selectedReminder } = this.state;

    return (
      <Grid container justify="center">
        <Grid item xs={12} md={4}>
          <Typography className={classes.titleText}>
            <FormattedMessage id="REMINDER.AVAILABLE_REMINDERS" />
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <div id="reminder-container" className={classes.center}>
            <div>
              {reminders.map((reminder) => (
                <ReminderComponent
                  key={reminder.id}
                  reminder={reminder}
                  selectReminder={this.selectReminder}
                  deleteReminder={this.deleteReminder}
                />
              ))}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <hr />
        </Grid>
        <Grid item xs={12} className={classes.push} />
        <Grid item xs={12} md={12}>
          <form
            onSubmit={this.handleSubmit}
            className={classes.form}
            ref={this.formRef}
          >
            <Grid container justify="center">
              <Grid item xs={12} md={4}>
                <Typography className={classes.titleText}>
                  <FormattedMessage
                    id={
                      selectedReminder
                        ? 'REMINDER.EDIT_REMINDER'
                        : 'REMINDER.NEW_REMINDER'
                    }
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <ReminderForm
                  title={{
                    value: this.state.title,
                    onChange: this.changeTitle,
                  }}
                  message={{
                    value: this.state.message,
                    onChange: this.changeMessage,
                  }}
                  firstExecutionDate={{
                    value: this.state.firstExecutionDate,
                    onChange: this.changeFirstExecutionDate,
                  }}
                  firstExecutionTime={{
                    value: this.state.firstExecutionTime,
                    onChange: this.changeFirstExecutionTime,
                  }}
                  interval={{
                    value: this.state.interval,
                    onChange: this.changeInterval,
                  }}
                  intervalTimeUnit={{
                    value: this.state.intervalTimeUnit,
                    onChange: this.changeIntervalTimeUnit,
                    values: this.props.interval,
                  }}
                  audience={{
                    value: this.state.audience,
                    onChange: this.changeAudience,
                    values: this.props.audience,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.withMargin}>
                <UldShapedFooterWithButtons
                  confirmButtonLabel={
                    <FormattedMessage
                      id={
                        selectedReminder
                          ? 'REMINDER.SUBMIT_EDIT'
                          : 'REMINDER.SUBMIT_NEW'
                      }
                    />
                  }
                  confirmButtonType={'submit'}
                  cancelButtonLabel={'REMINDER.RESET'}
                  cancelButtonOnClick={this.clearAllFields}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

Reminder.propTypes = {
  token: PropTypes.string.isRequired,
  reminders: PropTypes.arrayOf(PropTypes.instanceOf(ReminderModel)).isRequired,
  audience: PropTypes.arrayOf(PropTypes.string).isRequired,
  interval: PropTypes.arrayOf(PropTypes.string).isRequired,
  getAllReminders: PropTypes.func.isRequired,
  getAvailableAudience: PropTypes.func.isRequired,
  getAvailableIntervals: PropTypes.func.isRequired,
  addReminder: PropTypes.func.isRequired,
  deleteReminder: PropTypes.func.isRequired,
  editReminder: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  reminders: state.admin.reminder.list,
  audience: state.admin.reminder.audience,
  interval: state.admin.reminder.interval,
});

const mapDispatchToProps = (dispatch) => ({
  getAllReminders: (token, enqueueSnackbar) =>
    dispatch(getReminders(token, enqueueSnackbar)),
  getAvailableAudience: (token, enqueueSnackbar) =>
    dispatch(getAvailableAudience(token, enqueueSnackbar)),
  getAvailableIntervals: (token, enqueueSnackbar) =>
    dispatch(getAvailableIntervals(token, enqueueSnackbar)),
  addReminder: (reminder, token, enqueueSnackbar, successAction) =>
    dispatch(addReminder(reminder, token, enqueueSnackbar, successAction)),
  deleteReminder: (reminderId, token, enqueueSnackbar, successAction) =>
    dispatch(deleteReminder(reminderId, token, enqueueSnackbar, successAction)),
  editReminder: (reminder, token, enqueueSnackbar, successAction) =>
    dispatch(editReminder(reminder, token, enqueueSnackbar, successAction)),
});

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Reminder))
);
