import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core';

import ChevronDown from '../icons/chevron_down';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightRegular,
  },
  box: {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    minHeight: '6rem',
  },
  body: {
    color: theme.palette.primary.dark,
    fontSize: '1.125rem',
  },
  panelDetails: {
    flexDirection: 'column',
    padding: '0 0 1rem 0',
  },
  expansionPanelSummary: {
    padding: '0 1.5rem 0 0',
  },
}));

export default function SimpleExpansionPanel(props) {
  const classes = useStyles();

  const { headlineMessageId, bodyMessageId } = props;

  return (
    <ExpansionPanel className={classes.box} style={{ borderRadius: '0px' }}>
      <ExpansionPanelSummary
        className={classes.expansionPanelSummary}
        expandIcon={<ChevronDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" className={classes.heading}>
          <FormattedMessage id={headlineMessageId} />
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panelDetails}>
        <Typography variant="subtitle1" className={classes.body}>
          <FormattedMessage id={bodyMessageId} />
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

SimpleExpansionPanel.propTypes = {
  headlineMessageId: PropTypes.string.isRequired,
  bodyMessageId: PropTypes.string.isRequired,
};
