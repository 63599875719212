import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Link, Container } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { Routes } from '../constants';
import ContactSkypooling from './contactSkypooling';
import Cookie from './cookie';

const useStyles = makeStyles((theme) => ({
  blueBox: {
    backgroundColor: theme.palette.primary.linkWater25,
    padding: '2rem 0 4rem 0',
  },
  flexWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  linkButtons: {
    justifyContent: 'unset',
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontSize: '1rem',
    margin: '0px',
    paddingLeft: '3rem',
    padding: '0px',
    '&:hover': {
      background: 'none',
    },
  },
  imprintTitle: {
    color: theme.palette.primary.dark,
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: '600',
  },
  gridContainer: {
    width: '94%',
    marginBottom: '0.5rem',
  },
  textStyle: {
    paddingLeft: '3rem',
  },
  linkButtonTerms: {
    justifyContent: 'unset',
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontSize: '1rem',
    margin: '0px',
    padding: '0px',
    '&:hover': {
      background: 'none',
    },
  },
}));

export default function Footer({ token, user }) {
  const classes = useStyles();

  const [margin, setMargin] = React.useState(0);

  return (
    <footer>
      {token ? (
        <ContactSkypooling user={user} />
      ) : (
        <div style={{ margin: '2.5em' }} />
      )}
      <div className={classes.blueBox} style={{ marginBottom: `${margin}px` }}>
        <Container>
          <div style={{ width: '72%' }}>
            <Grid container justify="center" className={classes.gridContainer}>
              <Grid item xs={12}>
                <span className={classes.imprintTitle}>
                  <FormattedMessage id="FOOTER.LEGAL" />
                </span>
              </Grid>
            </Grid>
            <Link
              className={classes.linkButtonTerms}
              href={Routes.PUBLIC.TERMS_OF_USE}
            >
              <FormattedMessage id="FOOTER.TERMS_OF_USE" />
            </Link>
            <Link className={classes.linkButtons} href={Routes.PUBLIC.IMPRINT}>
              <FormattedMessage id="FOOTER.IMPRINT" />
            </Link>
            <Link
              className={classes.linkButtons}
              href={Routes.PUBLIC.DATA_PRIVACY}
            >
              <FormattedMessage id="FOOTER.DATA_PRIVACY" />
            </Link>
            <Link
              className={classes.linkButtons}
              href="https://www.uldcare.com/uld-care-code-conduct/"
            >
              <FormattedMessage id="FOOTER.ULD_CODE_OF_CONDUCT" />
            </Link>
          </div>
        </Container>
      </div>
      <Cookie setMargin={setMargin} />
    </footer>
  );
}

Footer.propTypes = {
  token: PropTypes.string,
  user: PropTypes.object,
};
