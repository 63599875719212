import { getFromServer } from '../api';
import { Actions, Api } from '../constants';
import { createAction } from './utils';

export const getInfoSlider = () => (dispatch) => {
  getFromServer(Api.PUBLIC_API.GET_PATH_FOR_IMAGES.INFO_SLIDER).then(
    (response) => {
      if (response.ok) {
        response.json().then((json) => {
          dispatch(
            createAction(Actions.GET_PATH_FOR_IMAGES.GET_INFO_SLIDER, json)
          );
        });
      }
    }
  );
};

export const getCompaniesImages = () => (dispatch) => {
  getFromServer(Api.PUBLIC_API.GET_PATH_FOR_IMAGES.COMPANIES_IMAGES).then(
    (response) => {
      if (response.ok) {
        response.json().then((json) => {
          dispatch(
            createAction(Actions.GET_PATH_FOR_IMAGES.GET_COMPANIES_IMAGES, json)
          );
        });
      }
    }
  );
};

export const getBenefitsSlide = () => (dispatch) => {
  getFromServer(Api.PUBLIC_API.GET_PATH_FOR_IMAGES.BENEFIT_SLIDER).then(
    (response) => {
      if (response.ok) {
        response.json().then((json) => {
          dispatch(
            createAction(Actions.GET_PATH_FOR_IMAGES.GET_BENEFITS_SLIDER, json)
          );
        });
      }
    }
  );
};

export const getAdAirportStocks = () =>
  getImagesBySlider(
    'adAirportStocks',
    Actions.GET_PATH_FOR_IMAGES.GET_AD_AIRPORT_STOCKS
  );

export const getAdLandingPageBottom = () =>
  getImagesBySlider(
    'adLandingPageBottom',
    Actions.GET_PATH_FOR_IMAGES.GET_AD_LANDING_PAGE_BOTTOM
  );

export const getAdLandingPageTop = () =>
  getImagesBySlider(
    'adLandingPageTop',
    Actions.GET_PATH_FOR_IMAGES.GET_AD_LANDING_PAGE_TOP
  );

export const getAdMessages = () =>
  getImagesBySlider('adMessages', Actions.GET_PATH_FOR_IMAGES.GET_AD_MESSAGES);

export const getAdMyCompany = () =>
  getImagesBySlider(
    'adMyCompany',
    Actions.GET_PATH_FOR_IMAGES.GET_AD_MY_COMPANY
  );

export const getAdMyInventory = () =>
  getImagesBySlider(
    'adMyInventory',
    Actions.GET_PATH_FOR_IMAGES.GET_AD_MY_INVENTORY
  );

const getImagesBySlider = (path, action) => (dispatch) => {
  return getFromServer(
    Api.PUBLIC_API.GET_PATH_FOR_IMAGES.AD_LANDSCAPE + path
  ).then((response) => {
    if (response.ok) {
      response.json().then((json) => {
        dispatch(createAction(action, json));
      });
    }
  });
};
