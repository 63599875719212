import React from 'react';
import { getFromServer } from '../api';
import { Api } from '../constants';
import { withStyles } from '@material-ui/core';
import DialogPopup from './dialog/dialogPopup';

const styles = (theme) => ({
  textStyle: {
    color: theme.palette.primary.dark,
  },
});

export class NewsPopup extends React.Component {
  state = {
    open: false,
    title: '',
    message: '',
    creationDate: '',
  };

  componentDidMount() {
    if (!this.props.isNewsRead) {
      getFromServer(Api.PUBLIC_API.NEWS, this.props.token).then((resp) => {
        if (resp.ok)
          resp
            .json()
            .then(({ data: { title, message, creationDate } }) =>
              this.setState({ title, message, creationDate, open: true })
            );
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isNewsRead && !this.props.isNewsRead) {
      getFromServer(Api.PUBLIC_API.NEWS, this.props.token).then((resp) => {
        if (resp.ok)
          resp
            .json()
            .then(({ data: { title, message, creationDate } }) =>
              this.setState({ title, message, creationDate, open: true })
            );
      });
    }
  }

  render() {
    return (
      <DialogPopup
        open={this.state.open}
        title={this.state.title}
        message={this.state.message}
        closeFunction={() => this.setState({ open: false })}
      />
    );
  }
}

export default withStyles(styles)(NewsPopup);
