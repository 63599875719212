import Actions from '../constants/actions';

import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '../i18n';

const initialState = {
  language: DEFAULT_LANGUAGE,
  locale: window.navigator.language,
};

export default function language(state = initialState, actions) {
  switch (actions.type) {
    case Actions.SET_LANGUAGE:
      return {
        ...state,
        language: setLanguage(actions.payload),
      };
    case Actions.SET_LOCALE:
      return {
        ...state,
        locale: actions.payload,
      };
    default:
      return state;
  }
}

export function setLanguage(language) {
  if (SUPPORTED_LANGUAGES.indexOf(language) > -1) {
    return language;
  } else {
    return setLanguage(DEFAULT_LANGUAGE);
  }
}
