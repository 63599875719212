import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import BagDollar from '../icons/bagDollar';
import StarUldShapedBox from '../icons/starUldShapedBox';

const triangleStyle = (color) => ({
  textDecoration: 'none',
  color: 'white',
  width: '0px',
  borderRight: '2rem solid transparent',
  borderTop: `1.6rem solid ${color}`,
  height: 0,
  lineHeight: '0.3rem',
});

const squareOverTriangleStyle = (color) => ({
  width: '2rem',
  backgroundColor: color,
});

const boxStyle = (color) => ({
  height: '10%',
  width: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: color,
});

const useStyles = makeStyles((theme) => ({
  styleTriangleUrgent: triangleStyle(theme.palette.secondary.orangePeel),
  styleTriangleSpecial: triangleStyle(theme.palette.primary.linkWater),
  styleSquareTriangleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  styleSquareOverTriangleUrgent: squareOverTriangleStyle(
    theme.palette.secondary.orangePeel
  ),
  styleSquareOverTriangleSpecial: squareOverTriangleStyle(
    theme.palette.primary.linkWater
  ),
  styleBoxUrgent: boxStyle(theme.palette.secondary.orangePeel),
  styleBoxSpecial: boxStyle(theme.palette.primary.linkWater),
  styleWrapper: {
    display: 'inline-flex',
    justifyContent: 'center',
  },
}));

export const LeasingStockUldShapedBox = ({
  height,
  width,
  marginLeft,
  marginTop,
}) => (
  <IconUldShapedBox
    height={height}
    width={width}
    marginLeft={marginLeft}
    marginTop={marginTop}
    img={<BagDollar />}
  />
);

LeasingStockUldShapedBox.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  marginLeft: PropTypes.string,
  marginTop: PropTypes.string,
};

export const ImportantStockUldShapedBox = ({ height, width }) => (
  <IconUldShapedBox
    height={height}
    width={width}
    isImportantStock
    img={<StarUldShapedBox />}
  />
);

ImportantStockUldShapedBox.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

const IconUldShapedBox = ({
  height,
  width,
  marginLeft = 0,
  marginTop = 0,
  isImportantStock,
  img,
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        style={{
          height: height,
          width: width,
          marginTop: marginTop,
          marginLeft: marginLeft,
        }}
        className={classes.styleWrapper}
      >
        <div
          style={{ height: height }}
          className={
            isImportantStock ? classes.styleBoxUrgent : classes.styleBoxSpecial
          }
        >
          <span>{img && img}</span>
        </div>
        <span className={classes.styleSquareTriangleWrapper}>
          <div
            style={{ height: height }}
            className={
              isImportantStock
                ? classes.styleSquareOverTriangleUrgent
                : classes.styleSquareOverTriangleSpecial
            }
          />
          <div
            className={
              isImportantStock
                ? classes.styleTriangleUrgent
                : classes.styleTriangleSpecial
            }
          />
        </span>
      </div>
    </>
  );
};
