import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Routes } from '../constants';
import { Container, Typography, withStyles, Link } from '@material-ui/core';

const styles = () => ({
  errorRoot: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, routeWithError: '' };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, routeWithError: window.location.pathname };
  }

  componentDidUpdate() {
    if (
      this.state.hasError &&
      this.state.routeWithError !== window.location.pathname
    ) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    // TODO: look into if we should log this error to a service or similar
    // this.setState({ routeWithError: this.props.history.location.pathname });
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container className={classes.errorRoot}>
          <Typography variant="h2">
            <FormattedMessage id="BASE.UNRECOVERABLE_ERROR.SORRY" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage
              id="BASE.UNRECOVERABLE_ERROR.MESSAGE"
              values={{
                link: (
                  <Link href={Routes.APP.HOME.INDEX}>
                    <FormattedMessage id="BASE.HERE" />
                  </Link>
                ),
              }}
            />
          </Typography>
        </Container>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default withStyles(styles)(ErrorBoundary);
