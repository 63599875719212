import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import UldShapedBox from './uldShapedBox';

const useStyles = makeStyles((theme) => ({
  alignment: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  content: {
    width: '80%',
    color: theme.palette.primary.dark,
  },
}));

export function UldShapedErrorBox({ children }) {
  const { alignment, content } = useStyles();
  return (
    <UldShapedBox height="100%">
      <div className={alignment}>
        <div className={content}>{children}</div>
      </div>
    </UldShapedBox>
  );
}

UldShapedErrorBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default UldShapedErrorBox;
