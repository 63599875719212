export default class Stock {
  constructor({
    id,
    validUntil,
    requestCounter,
    amount,
    important,
    userIsOwner,
    hasAlreadyBeenBookedByUsersCompany,
    creationDate,
    availableFrom,
    uldType,
    iataCode,
    email,
    phone,
    companyCode,
    companyName,
    contactPerson,
    comment,
    location,
    offerInformation,
    offerType,
    destinationIataCode,
    leasing,
  }) {
    this.id = id;
    this.validUntil = validUntil;
    this.requestCounter = requestCounter;
    this.amount = amount;
    this.important = important;
    this.userIsOwner = userIsOwner;
    this.hasAlreadyBeenBookedByUsersCompany =
      hasAlreadyBeenBookedByUsersCompany;
    this.creationDate = creationDate;
    this.availableFrom = availableFrom;
    this.uldType = uldType;
    this.iataCode = iataCode;
    this.email = email;
    this.phone = phone;
    this.companyCode = companyCode;
    this.companyName = companyName;
    this.contactPerson = contactPerson;
    this.comment = comment;
    this.location = location;
    this.offerInformation = offerInformation;
    this.offerType = offerType;
    this.destinationIataCode = destinationIataCode;
    this.leasing = leasing;
  }
}
