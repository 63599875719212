import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import StockTile from './stockTile';

const useStyles = makeStyles((theme) => ({
  stockTile: {
    backgroundColor: theme.palette.primary.linkWater25,
    padding: '0',
    maxWidth: 'unset',
    width: '95%',
    fontWeight: '600',
    color: theme.palette.primary.chambray,
  },
  whiteLine: {
    backgroundColor: theme.palette.primary.contrastText,
    height: '3px',
    display: 'flex',
    width: '100%',
    margin: '0',
  },
  blueLine: {
    backgroundColor: theme.palette.primary.linkWater25,
    height: '2px',
    display: 'flex',
    width: '100%',
    margin: '0',
  },
}));

export function StockTableTile({
  row,
  isAirportStocks,
  handleClick,
  token,
  deleteFunc,
  editFunc,
  detailsFunc,
}) {
  const classes = useStyles();

  return (
    <div>
      <ListItem style={{ padding: '0' }}>
        <div
          className={classes.stockTile}
          style={{ display: 'flex', width: '100%' }}
          onClick={() => handleClick(row)}
        >
          <StockTile
            row={row}
            isAirportStocks={isAirportStocks}
            token={token}
            deleteFunc={deleteFunc}
            editFunc={editFunc}
            detailsFunc={detailsFunc}
          />
        </div>
      </ListItem>
      <div className={classes.whiteLine} />
    </div>
  );
}

StockTableTile.propTypes = {
  row: PropTypes.object.isRequired,
  isAirportStocks: PropTypes.bool,
  token: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

export default StockTableTile;
