import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
  buttonStyle: {
    fontSize: '0.85rem',
    background: 'none',
    color: theme.palette.primary.main,
    border: 'none',
    padding: '0 29px',
    cursor: 'pointer',
    outline: 'inherit',
    textTransform: 'uppercase',
    minWidth: 'unset',
    fontWeight: '600',
  },
});

export class CancelButton extends Component {
  render() {
    const { padding, classes, messageId, onClick } = this.props;
    const paddingStyle = padding ? padding : '0 29px';

    return (
      <button
        className={`MuiTab-root ${classes.buttonStyle}`}
        type="button"
        onClick={onClick}
        style={{ padding: paddingStyle }}
      >
        <FormattedMessage id={messageId} />
      </button>
    );
  }
}

CancelButton.defaultProps = {
  messageId: 'BUTTON.CANCEL',
  onClick: () => {},
};

CancelButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  messageId: PropTypes.string,
};

export default withStyles(styles)(CancelButton);
