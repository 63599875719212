import { getFromServer } from '../api';
import { Actions, Api } from '../constants';
import { createAction } from './utils';

export const getUldTypes = (token) => (dispatch) => {
  getFromServer(Api.PUBLIC_API.ULD_CONTROLLER, token).then((response) => {
    if (response.ok) {
      response.json().then((json) => {
        dispatch(createAction(Actions.ULD_TYPES.ADD_ALL_ULD_TYPES, json));
      });
    }
  });
};
