import React from 'react';

import { FormattedMessage } from 'react-intl';
import ULDShapedBox from '../uldShapedBox';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const styles = makeStyles((theme) => ({
  outerDiv: {
    display: 'flex',
    padding: '40px',
    flexFlow: 'column',
  },
  headLineDiv: {
    fontSize: '1.625rem',
    fontWeight: '600',
    color: theme.palette.primary.dark,
  },
  innerTextDiv: {
    margin: '.5rem 0',
    fontSize: '1.125rem',
    fontWeight: '500',
    color: theme.palette.primary.dark,
  },
  largeMargin: {
    margin: '.5rem 0 2em 0',
  },
  flexContainer: {
    display: 'flex',
  },
}));

const AboutTextContainer = () => {
  const classes = styles();

  return (
    <div className={classes.flexContainer}>
      <ULDShapedBox margin={'50px 0 10px'} height="100%">
        <div className={classes.outerDiv}>
          <div className={classes.headLineDiv}>
            <FormattedMessage id="HOME.ABOUT_HEADLINE" />
          </div>
          <div className={clsx(classes.innerTextDiv, classes.largeMargin)}>
            <FormattedMessage id="HOME.ABOUT_TEASER_TEXT" />
          </div>
          <div className={classes.innerTextDiv}>
            <FormattedMessage
              id="HOME.ABOUT_INNER_HEADLINE"
              values={{
                right: <b>{'right'}</b>,
              }}
            />
          </div>
          <div className={classes.innerTextDiv}>
            <FormattedMessage id="HOME.ABOUT_INNER_TEXT" />
          </div>
          <div className={clsx(classes.innerTextDiv, classes.largeMargin)}>
            <FormattedMessage id="HOME.ABOUT_INNER_TEXT_2" />
          </div>
          <div className={classes.innerTextDiv}>
            <FormattedMessage id="HOME.ABOUT_INNER_TEXT_3" />
          </div>
        </div>
      </ULDShapedBox>
    </div>
  );
};
export default AboutTextContainer;
