import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    borderBottom: `0.4rem solid ${theme.palette.primary.linkWater}`,
  },
  sortLabel: {
    transition: '200ms background-color cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: theme.palette.primary.chambray,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.linkWater25,
    },
    padding: '0px',
    textAlign: 'right',
  },
  tableCell: {
    align: 'center',
  },
  tableStyle: {
    padding: '2.5rem 0rem 1.5rem 0rem',
    textAlign: 'right',
  },
  notClickable: {
    color: theme.palette.primary.chambray,
    fontSize: '1rem',
    textAlign: 'right',
    paddingRight: '20%',
  },
  commentLabel: {
    padding: '2.5rem 0rem 1.5rem 0rem',
    textAlign: 'left',
    paddingLeft: '16px',
  },
  uldIdsLabel: {
    padding: '2.5rem 0rem 1.5rem 0rem',
    textAlign: 'center',
    paddingLeft: '16px',
  },
}));

export function LostAndFoundTableHeader({
  isDescending,
  orderBy,
  onRequestSort,
  headRows,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const direction = isDescending ? 'desc' : 'asc';
  const classes = useStyles();
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            sortDirection={orderBy === row.id ? direction : false}
            className={
              row.id == 'comment'
                ? classes.commentLabel
                : row.id == 'uldIds'
                ? classes.uldIdsLabel
                : classes.tableStyle
            }
          >
            {row.sortable ? (
              <TableSortLabel
                className={classes.sortLabel}
                active={orderBy === row.id}
                direction={direction}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            ) : (
              <span className={classes.notClickable}>{row.label}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

LostAndFoundTableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  isDescending: PropTypes.bool.isRequired,
  headRows: PropTypes.array.isRequired,
};

export default LostAndFoundTableHeader;
