import { Actions } from '../constants';
import Stock from '../model/stock';

const initialState = {
  myInventory: [],
  company: [],
  matches: [],
  airport: [],
  specialStock: new Stock({}),
  hasMatches: false,
  lastMatchingRequest: undefined,
  lastStockRequest: undefined,
  lastHasMatchingRequest: undefined,
  matchPopUp: false,
};

export default function stock(state = initialState, action) {
  switch (action.type) {
    case Actions.STOCK.MY_INVENTORY.ADD: {
      return {
        ...state,
        myInventory: [...action.payload.map((stock) => new Stock(stock))],
        lastStockRequest: new Date(),
      };
    }
    case Actions.STOCK.DELETE: {
      const myInventory = state.myInventory.filter(
        (element) => element.id !== action.payload
      );
      const company = state.company.filter(
        (element) => element.id !== action.payload
      );
      return {
        ...state,
        myInventory: [...myInventory],
        company: [...company],
      };
    }
    case Actions.STOCK.USER_COMPANY.ADD: {
      return {
        ...state,
        company: [...action.payload.map((stock) => new Stock(stock))],
      };
    }
    case Actions.STOCK.MATCHES.ADD: {
      if (action.payload.message === 'STOCKS_DID_NOT_CHANGE') return state;
      else
        return {
          ...state,
          matches: [...action.payload.data.map((stock) => new Stock(stock))],
          lastMatchingRequest: new Date(),
        };
    }
    case Actions.STOCK.MATCHES.OPEN_POP_UP: {
      if (action.payload.message === 'NO_NEW_MATCHES_FOUND') return state;
      else if (action.payload.message === 'NEW_MATCHES_FOUND')
        return {
          ...state,
          matchPopUp: true,
        };
      else
        return {
          ...state,
          matchPopUp: false,
        };
    }
    case Actions.STOCK.AIRPORT.ADD: {
      return {
        ...state,
        airport: [...action.payload.map((stock) => new Stock(stock))],
      };
    }
    case Actions.STOCK.SPECIAL_STOCK: {
      return {
        ...state,
        specialStock: new Stock({ ...action.payload }),
      };
    }
    case Actions.STOCK.MATCHES.ADD_HAS_MATCHES: {
      if (action.payload.message === 'STOCKS_DID_NOT_CHANGE') return state;
      else
        return {
          ...state,
          hasMatches: action.payload.data,
          lastHasMatchingRequest: new Date(),
        };
    }
    default: {
      return state;
    }
  }
}
