import React from 'react';
import PropTypes from 'prop-types';

import { Link, Container, Paper, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import UldShapedButton from '../components/uldShapedButton';
import { useDispatch, useSelector } from 'react-redux';
import { setCookie } from '../actions/user';
import { COOKIE_KEYS } from '../utils/cookieUtils';
import { useMedia } from '../utils/customHooks';

const styles = makeStyles({
  cookieStyle: {
    display: 'flex',
    backgroundColor: 'white',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '1rem',
    zIndex: 1000,
  },
  textStyle: {
    margin: '1em',
  },
  button: {
    padding: '0 1rem',
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
  },
  selfCenter: {
    alignSelf: 'center',
    justifySelf: 'center',
  },
});

export function Cookie({ setMargin }) {
  const accepted = useSelector((state) => state.user.consent);

  const dispatch = useDispatch();
  const classes = styles();
  const theme = useTheme();
  const cookieRef = React.createRef();

  React.useEffect(() => {
    setMargin(cookieRef.current ? cookieRef.current.offsetHeight : 0);
  }, [cookieRef, setMargin]);

  const { whiteSpace, height } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        whiteSpace: 'nowrap',
      },
    ],
    {
      whiteSpace: 'unset',
      height: '4em',
    }
  );

  if (accepted) return null;
  return (
    <Paper elevation={4} className={classes.cookieStyle} ref={cookieRef}>
      <Container>
        <div className={classes.box}>
          <Grid container justify="center">
            <Grid item xs={12} md={8}>
              <div className={classes.textStyle}>
                <FormattedMessage
                  id="COOKIE.TEXT"
                  values={{
                    link: (
                      <Link href="https://skypooling.com/dataPrivacy">
                        <FormattedMessage id="FOOTER.DATA_PRIVACY_TITLE" />
                      </Link>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4} className={classes.flex}>
              <Grid container spacing={1} justify="space-between">
                <Grid item xs={12} md={12} className={classes.selfCenter}>
                  <UldShapedButton
                    type="button"
                    label={
                      <div className={classes.button}>
                        <FormattedMessage id="COOKIE.ACCEPT_ALL" />
                      </div>
                    }
                    buttonHeight={height}
                    whiteSpace={whiteSpace}
                    onClick={() => {
                      dispatch(setCookie(COOKIE_KEYS.ACCEPT_ALL, 20));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.selfCenter}>
                  <UldShapedButton
                    type="button"
                    label={
                      <div className={classes.button}>
                        <FormattedMessage id="COOKIE.ACCEPT_ONLY_BASE" />
                      </div>
                    }
                    buttonHeight={height}
                    whiteSpace={whiteSpace}
                    onClick={() => {
                      dispatch(setCookie(COOKIE_KEYS.ONLY_NECESSARY));
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Paper>
  );
}

Cookie.propTypes = {
  classes: PropTypes.object.isRequired,
  setMargin: PropTypes.func.isRequired,
};

export default Cookie;
