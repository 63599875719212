import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '50em',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export function LoadingComponent({ formattedMessageId }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.loadingInfo}>
        <CircularProgress />
        <FormattedMessage id={formattedMessageId} />
      </div>
    </div>
  );
}

LoadingComponent.propTypes = {
  formattedMessageId: PropTypes.string.isRequired,
};

export default LoadingComponent;
