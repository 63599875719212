import { Actions } from '../constants';

const initialState = {
  specialBooking: {},
  incoming: {
    open: [],
    confirmed: [],
    denied: [],
  },
  outgoing: {
    open: [],
    confirmed: [],
    denied: [],
  },
  allBookings: [],
};

export default function bookings(state = initialState, action) {
  switch (action.type) {
    case Actions.BOOKINGS.ADD_ALL_BOOKINGS:
      return {
        ...state,
        allBookings: [...action.payload],
      };
    case Actions.BOOKINGS.INCOMING.ADD_OPEN_BOOKINGS:
      return {
        ...state,
        incoming: {
          ...state.incoming,
          open: [...action.payload],
        },
      };
    case Actions.BOOKINGS.INCOMING.ADD_CONFIRMED_BOOKINGS:
      return {
        ...state,
        incoming: {
          ...state.incoming,
          confirmed: [...action.payload],
        },
      };
    case Actions.BOOKINGS.INCOMING.ADD_DENIED_BOOKINGS:
      return {
        ...state,
        incoming: {
          ...state.incoming,
          denied: [...action.payload],
        },
      };
    case Actions.BOOKINGS.OUTGOING.ADD_OPEN_BOOKINGS:
      return {
        ...state,
        outgoing: {
          ...state.outgoing,
          open: [...action.payload],
        },
      };
    case Actions.BOOKINGS.OUTGOING.ADD_CONFIRMED_BOOKINGS:
      return {
        ...state,
        outgoing: {
          ...state.outgoing,
          confirmed: [...action.payload],
        },
      };
    case Actions.BOOKINGS.OUTGOING.ADD_DENIED_BOOKINGS:
      return {
        ...state,
        outgoing: {
          ...state.outgoing,
          denied: [...action.payload],
        },
      };
    case Actions.BOOKINGS.ADD_SPECIAL_BOOKING:
      return {
        ...state,
        specialBooking: { ...action.payload },
      };
    default: {
      return state;
    }
  }
}
