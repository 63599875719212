import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import MultipleSelect from '../../searchSelect/multipleSelect';
import {
  grid,
  gridItemHeadline,
  grid_margin,
} from '../../addNewStock/cssStyles';
import Airport from '../../../model/airport';

const useStyles = makeStyles((theme) => ({
  grid,
  grid_margin,
  gridItemHeadline: gridItemHeadline(theme),
  checked: {
    color: `${theme.palette.primary.blueZodiac}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.lightBlue,
    },
  },
  root: {
    '& > span': {
      fontSize: '1em',
    },
    color: {
      backgroundColor: theme.palette.primary.blueZodiac,
    },
  },
}));

export const BookingRequestForm = ({
  amountDefault,
  amount,
  setAmount,
  uldType,
  iataCode,
  availableFrom,
  setAvailableFrom,
  validUntil,
  setValidUntil,
  airports,
  requiresAirportInput,
  destinationIataCodeDefault,
  destinationIataCode,
  setDestinationIataCode,
  intl,
}) => {
  const classes = useStyles();

  return (
    <span>
      <Grid container justify="center">
        <Grid item xs={12} md={4}>
          <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
            <FormattedMessage id="BOOKINGS.DETAILS.MY_BOOKING_REQUEST" />
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <RadioGroup
                aria-label={<FormattedMessage id="BASE.STOCKTYPE" />}
                name="stockType"
                style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
                value={amountDefault <= 0 ? '1' : '-1'}
                disabled
              >
                <FormControlLabel
                  classes={{ root: classes.root }}
                  value={'-1'}
                  style={{ marginRight: '15%' }}
                  control={
                    <Radio
                      classes={{ checked: classes.checked }}
                      required
                      color="default"
                    />
                  }
                  label={<FormattedMessage id="BASE.UNDERSTOCK" />}
                  disabled
                />
                <FormControlLabel
                  classes={{ root: classes.root }}
                  value={'1'}
                  style={{ marginRight: '15%' }}
                  control={
                    <Radio
                      classes={{ checked: classes.checked }}
                      required
                      color="default"
                    />
                  }
                  label={<FormattedMessage id="BASE.OVERSTOCK" />}
                  disabled
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  id="standard-amount"
                  label={<FormattedMessage id="BASE.AMOUNT" />}
                  type="number"
                  margin="normal"
                  InputProps={{
                    inputProps: { min: 1, max: Math.abs(amount) },
                  }}
                  value={amount}
                  onChange={(event) => setAmount(event.target.value)}
                  required
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid_margin}>
              <TextField
                fullWidth
                name="uldTypes"
                value={uldType}
                label={<FormattedMessage id="BASE.ULD_TYPE" />}
                required={true}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid_margin}>
              <TextField
                fullWidth
                name="airports"
                value={iataCode}
                label={<FormattedMessage id="BASE.AIRPORT" />}
                required={true}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid_margin}>
              <TextField
                fullWidth
                id="availableFrom"
                label={<FormattedMessage id="BASE.AVAILABLE_FROM" />}
                type="date"
                value={availableFrom}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => setAvailableFrom(event.target.value)}
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid_margin}>
              <TextField
                fullWidth
                id="validTill"
                label={<FormattedMessage id="BASE.VALID_UNTIL" />}
                type="date"
                value={validUntil}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => setValidUntil(event.target.value)}
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid_margin}>
              <MultipleSelect
                id="destinationAirports"
                data={airports.map(({ iataCode, name }) => ({
                  value: iataCode,
                  label: `${iataCode} - ${name}`,
                }))}
                label={<FormattedMessage id="BOOK_NOW.RETURN_STATION" />}
                placeholder={
                  requiresAirportInput
                    ? destinationIataCodeDefault
                    : intl.formatMessage({ id: 'ADD_NEW_STOCK.ANY_AIRPORT' })
                }
                limit={1}
                selected={destinationIataCode.map((value) => ({ value }))}
                setSelected={setDestinationIataCode}
                required={
                  requiresAirportInput && !(destinationIataCode.length > 0)
                }
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </span>
  );
};

BookingRequestForm.propTypes = {
  amountDefault: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  setAmount: PropTypes.func.isRequired,
  uldType: PropTypes.string.isRequired,
  iataCode: PropTypes.string.isRequired,
  availableFrom: PropTypes.string.isRequired,
  setAvailableFrom: PropTypes.func.isRequired,
  validUntil: PropTypes.string.isRequired,
  setValidUntil: PropTypes.func.isRequired,
  airports: PropTypes.arrayOf(PropTypes.instanceOf(Airport)).isRequired,
  requiresAirportInput: PropTypes.bool.isRequired,
  destinationIataCodeDefault: PropTypes.string.isRequired,
  destinationIataCode: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDestinationIataCode: PropTypes.func.isRequired,
  intl: intlShape,
};

export default injectIntl(BookingRequestForm);
