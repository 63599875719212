import React from 'react';

import UldShapedBox from '../components/uldShapedBox';
import SearchTextField from '../components/searchTextField';

import { useMedia } from '../utils/customHooks';

export function SearchFieldBox(props) {
  const { isMobile } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: true,
      },
    ],
    {
      isMobile: false,
    }
  );

  const { handleChange } = props;

  return isMobile ? (
    <UldShapedBox height="100px">
      <SearchTextField onChange={handleChange} />
    </UldShapedBox>
  ) : null;
}

export default SearchFieldBox;
