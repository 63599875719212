import React from 'react';
import PropTypes from 'prop-types';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import Keycloak from 'keycloak-js';

import { createAction } from '../actions/utils';
import { Actions, Api } from '../constants';
import {
  getUser,
  setAcceptTermsAndPolicyDate,
  getUnread,
  updateLastLogin,
} from '../actions/user';
import { getFromServer } from '../api';
import { getUsersAsAdmin, getRolesAsAdmin } from '../actions/admin';
import { getNotifications } from '../actions/notifications';

const keycloak = new Keycloak({
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

const KeycloakProviderWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const [notificationsInterval, setNotificationsInterval] = React.useState(-1);
  const { token } = useSelector((state) => state.auth);

  const onTokens = async (resp) => {
    dispatch(createAction(Actions.AUTH.TOKEN.ADD, resp));
    dispatch(getUser(resp.token));
    getFromServer(Api.ADMIN_API.AUTHORIZED, resp.token).then((response) => {
      if (response.ok) {
        response.json().then(({ isAdmin }) => {
          if (isAdmin) {
            dispatch(getUsersAsAdmin(resp.token));
            dispatch(getRolesAsAdmin(resp.token));
          }
        });
      }
    });
    dispatch(getUnread(resp.token));
    setAcceptTermsAndPolicyDate(resp.token);
    clearInterval(notificationsInterval);
    dispatch(getNotifications(resp.token));
    setNotificationsInterval(
      setInterval(() => dispatch(getNotifications(resp.token)), 20000)
    );
    await updateLastLogin(resp.token);
  };

  const handleEvents = (event) => {
    switch (event) {
      case 'onAuthLogout':
      case 'onAuthRefreshError': {
        clearInterval(notificationsInterval);
        dispatch(createAction(Actions.AUTH.TOKEN.REMOVE));
        keycloak.logout();
        break;
      }
      case 'onReady': {
        console.log(keycloak.isTokenExpired());
        if (!token || keycloak.isTokenExpired()) {
          clearInterval(notificationsInterval);
          dispatch(createAction(Actions.AUTH.TOKEN.REMOVE));
          keycloak.logout();
        } else dispatch(createAction(Actions.AUTH.TOKEN.ADD, keycloak.token));
        break;
      }
      default:
        break;
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'check-sso',
        timeSkew: 10,
        checkLoginIframe: false,
      }}
      onTokens={onTokens}
      onReady={onTokens}
      onEvent={handleEvents}
      onError={() => dispatch(createAction(Actions.AUTH.TOKEN.ADD_ERROR))}
    >
      {children}
    </ReactKeycloakProvider>
  );
};

KeycloakProviderWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default KeycloakProviderWrapper;
