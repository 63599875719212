import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { Routes, Api } from '../../constants';
import { postToServer } from '../../api/index';

import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core';
import PaperPlane from '../../icons/paperplane';
import Bullhorn from '../../icons/bullhorn';
import Bulb from '../../icons/bulb';
import Pencil5 from '../../icons/pencil5';
import { UldShapedButton } from '../uldShapedButton.jsx';
import ModalWithContactForm from '../modalWithContactForm';
import TextSlideshow from './textSlideshow';

import { useMedia } from '../../utils/customHooks';
import { withSnackbar } from 'notistack';

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  headLine: {
    color: theme.palette.primary.dark,
    fontSize: '1.625rem',
    textTransform: 'uppercase',
    marginTop: '1.25rem',
  },
  text: {
    fontSize: '1.125rem',
    color: theme.palette.primary.dark,
    margin: '10px 0',
  },
  gridRootItem: {
    display: 'flex',
  },
  itemButton: {
    marginTop: '20px',
    display: 'flex',
    alignSelf: 'flex-end',
  },
  textAskAndJoin: {
    fontSize: '1.125rem',
    color: theme.palette.primary.dark,
    margin: '10px 0',
    marginTop: 0,
  },
  gridContainer: {
    marginTop: '20px',
    justifyContent: 'space-around',
  },
}));

export function ContactContainer(props) {
  const classes = styles();
  const [open, setOpen] = React.useState(false);
  const { history, onClick, enqueueSnackbar } = props;

  const { textAlign, justifyContent, buttonWidth } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        textAlign: 'left',
        justifyContent: '',
        buttonWidth: 'calc(100% - 0.75em)',
      },
    ],
    {
      textAlign: 'center',
      justifyContent: 'center',
      buttonWidth: '75%',
    }
  );

  function handleRegisterClick() {
    history.push(Routes.PUBLIC.REGISTRATION);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const sendMail = (sender, senderName, subject, text) => {
    const mail = {
      from: sender,
      name: senderName,
      subject: subject,
      text: text,
    };

    postToServer(Api.USERS_API.SEND_MAIL_TO_SKYPOOLING, mail).then(
      (response) => {
        if (response.ok) {
          enqueueSnackbar(<FormattedMessage id="EMAIL.SEND_SUCCESS" />, {
            variant: 'success',
          });
        } else if (response.status === 403) {
          enqueueSnackbar(<FormattedMessage id="BASE.UNAUTHORIZED" />, {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar(<FormattedMessage id="EMAIL.SEND_FAIL" />, {
            variant: 'warning',
          });
        }
      }
    );
  };

  const submit = (sender, senderName, subject, text) => {
    sendMail(sender, senderName, subject, text);
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1} className={classes.gridContainer}>
        <Grid item xs={12} md={4} className={classes.gridRootItem}>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: textAlign }}>
              <Pencil5 />
              <Typography
                className={classes.headLine}
                variant="h6"
                id="tableTitle"
              >
                <FormattedMessage id="HOME.INFO_CONTAINER_JOIN_US" />
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: textAlign }}>
              <div className={classes.textAskAndJoin}>
                <FormattedMessage id="HOME.INFO_CONTAINER_HANDSHAKE_INNER_TEXT" />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.itemButton}
              style={{ justifyContent }}
            >
              <a style={{ textDecoration: 'none', width: '100%' }}>
                <UldShapedButton
                  buttonWidth={buttonWidth}
                  color="#FFFFFF"
                  onClick={handleRegisterClick}
                  label={
                    <FormattedMessage id="HOME.INFO_CONTAINER_REGISTER_NOW" />
                  }
                />
              </a>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} className={classes.gridRootItem}>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: textAlign }}>
              <PaperPlane />
              <Typography
                className={classes.headLine}
                variant="h6"
                id="tableTitle"
              >
                <FormattedMessage id="HOME.INFO_CONTAINER_CONTACT_US" />
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: textAlign }}>
              <div className={classes.text}>
                <FormattedMessage id="HOME.INFO_CONTAINER_PAPERPLANE_INNER_TEXT" />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.itemButton}
              style={{ justifyContent }}
            >
              <a
                href={'mailto:info@skypooling.com?subject=skypooling'}
                style={{ textDecoration: 'none', width: '100%' }}
              >
                <UldShapedButton
                  buttonWidth={buttonWidth}
                  color="#FFFFFF"
                  label={<FormattedMessage id="HOME.INFO_CONTAINER_EMAIL_US" />}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ModalWithContactForm
        open={open}
        headline={<FormattedMessage id={'EMAIL.MODAL_HEADLINE.NAME_UNSET'} />}
        receiver={'email of receiver'}
        textId="EMAIL.MODAL_TEXT_HOME_NOT_LOGGED_IN"
        labelSingleRowId="EMAIL.SUBJECT"
        labelMultiRowId="EMAIL.TEXT"
        labelSingleRowUserNameId="EMAIL.USER_NAME"
        labelSingleRowUserEmailId="EMAIL.USER_EMAIL"
        actionId="EMAIL.SEND_NOW"
        submit={submit}
        handleClose={() => setOpen(false)}
      />
    </div>
  );
}

ContactContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(ContactContainer);
