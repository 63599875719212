import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../assets/skypooling_logo_neu.png';
import { Link } from 'react-router-dom';
import { Routes } from '../constants';

const useStyles = makeStyles((theme) => ({
  link: {
    width: '200px',
    padding: '0px',
    margin: '0px',
    display: 'flex',
  },
}));

export function MenuSectionLeft({ isLoggedIn }) {
  const classes = useStyles();

  return (
    <Link
      to={isLoggedIn ? Routes.APP.HOME.INDEX : Routes.PUBLIC.HOME}
      className={classes.link}
    >
      <img
        style={{ width: '100%', height: '100%' }}
        src={logo}
        alt="skypooling"
      />
    </Link>
  );
}

MenuSectionLeft.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default MenuSectionLeft;
