import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, makeStyles, FormControl } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { gridItemHeadline } from '../../addNewStock/cssStyles';

const useStyles = makeStyles((theme) => ({
  gridItemHeadline: gridItemHeadline(theme),
  marginTop: {
    marginTop: '25px',
  },
}));

const BookingFurtherInformationForm = ({
  requestComment,
  setRequestComment,
  requestAmount,
  setRequestAmount,
}) => {
  const classes = useStyles();
  return (
    <span>
      <Grid container justify="center" className={classes.marginTop}>
        <Grid item xs={12} md={4}>
          <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
            <FormattedMessage id="BOOKNOW.FURTHER_INFORMATION" />
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  id="standard-amount"
                  label={<FormattedMessage id="BASE.AMOUNT_TO_REQUEST" />}
                  type="number"
                  margin="normal"
                  InputProps={{
                    inputProps: { min: 1, max: Math.abs(requestAmount) },
                  }}
                  value={requestAmount}
                  onChange={(event) => setRequestAmount(event.target.value)}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                id="requestComment"
                label={<FormattedMessage id="BOOKNOW.COMMENT" />}
                type="text"
                value={requestComment}
                multiline
                onChange={(event) => setRequestComment(event.target.value)}
                helperText={
                  <FormattedMessage id="AIRPORT_STOCKS.BOOKING_REQUEST_COMMENT_HELPER" />
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </span>
  );
};

BookingFurtherInformationForm.propTypes = {
  requestComment: PropTypes.string,
  setRequestComment: PropTypes.func.isRequired,
};

export default BookingFurtherInformationForm;
