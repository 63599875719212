import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  getSpecificBooking,
  denyStockBooking,
  acceptStockBooking,
  acceptLeasingStockBooking,
  denyLeasingStockBooking,
} from '../../actions/booking';
import { withStyles, CircularProgress } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import BookingContainer from '../../components/booking/bookingContainer';
import MenuButton from '../../components/menuButton';
import BackIcon from '../../icons/back';
import { Routes } from '../../constants';
import LoadingComponent from '../../components/loadingComponent';
import BookingDetailsHeader from '../../components/booking/bookingDetailsComponets/bookingDetailsHeader';
import HeadlineBox from '../../components/headlineBox';

const styles = (theme) => ({
  subtitle: {
    fontSize: '1.5rem',
    fontWeight: '700',
    marginBottom: '2em',
    marginTop: '1.5em',
    padding: '0',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'unset',
    textTransform: 'none',
    color: theme.palette.primary.chambray,
  },
});

export class BookingDetails extends Component {
  state = {
    loading: true,
    booking: {},
  };

  componentDidMount = async () => {
    const { token, match } = this.props;

    const booking = await getSpecificBooking(
      match.params.id,
      token,
      this.errorFunctionForGettingBooking
    );
    this.setState({ loading: false, booking });
  };

  errorFunctionForGettingBooking = (errorCode) => {
    const { enqueueSnackbar, history } = this.props;
    enqueueSnackbar(
      <FormattedMessage
        id="BOOKINGS.ERROR_IN_REQUEST"
        values={{ errorCode }}
      />,
      {
        variant: 'error',
      }
    );
    history.push(Routes.APP.BOOKINGS.INDEX);
  };

  goToBookings = () => this.props.history.push(Routes.APP.BOOKINGS.INDEX);

  confirmRequest = (message) => {
    const { token, enqueueSnackbar } = this.props;
    const { booking } = this.state;
    booking.leasing
      ? acceptLeasingStockBooking(
          booking.id,
          message,
          token,
          this.goToBookings,
          enqueueSnackbar
        )
      : acceptStockBooking(
          booking.id,
          message,
          token,
          this.goToBookings,
          enqueueSnackbar
        );
  };

  denyRequest = (message) => {
    const { token, enqueueSnackbar } = this.props;
    const { booking } = this.state;

    booking.leasing
      ? denyLeasingStockBooking(
          booking.id,
          message,
          token,
          this.goToBookings,
          enqueueSnackbar
        )
      : denyStockBooking(
          booking.id,
          message,
          token,
          this.goToBookings,
          enqueueSnackbar
        );
  };

  render() {
    const { classes, history, user } = this.props;
    return (
      <Fragment>
        {this.state.loading ? (
          <LoadingComponent formattedMessageId={'BOOKING.DETAILS.LOADING'} />
        ) : (
          <Fragment>
            <HeadlineBox headLineText="BOOKINGS.HEADLINE.DETAILS" />
            <div style={{ marginTop: '1em' }}>
              <MenuButton
                text={<FormattedMessage id="BASE.BACK" />}
                onClick={history.goBack}
                img={<BackIcon />}
              />
            </div>
            <BookingDetailsHeader booking={this.state.booking} user={user} />
            <BookingContainer
              booking={this.state.booking}
              onConfirm={this.confirmRequest}
              onDeny={this.denyRequest}
              token={this.props.token}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

BookingDetails.propTypes = {
  token: PropTypes.string.isRequired,
  getSpecificBooking: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.user,
});

export default withSnackbar(
  withRouter(withStyles(styles)(connect(mapStateToProps)(BookingDetails)))
);
