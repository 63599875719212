import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  styleTriangle: {
    textDecoration: 'none',
    color: 'white',
    width: '0px',
    borderRight: '30px solid transparent',
    borderTop: `30px solid ${theme.palette.primary.linkWater25}`,
    height: 0,
    lineHeight: '10px',
  },
  styleSquareTriangleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  styleSquareOverTriangle: {
    width: '30px',
    height: '100%',
    backgroundColor: theme.palette.primary.linkWater25,
  },
  styleBox: {
    width: '100%',
    backgroundColor: theme.palette.primary.linkWater25,
    overflow: 'hidden',
  },
  styleWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export class UldShapedBox extends Component {
  render() {
    const { height, classes, justifyContent, margin } = this.props;

    const marginStyle = margin ? margin : 'unset';

    return (
      <React.Fragment>
        <div
          style={{
            height: height,
            justifyContent: justifyContent,
            margin: marginStyle,
            width: '100%',
          }}
          className={classes.styleWrapper}
        >
          <div style={{ height: height }} className={classes.styleBox}>
            {this.props.children}
          </div>
          <span className={classes.styleSquareTriangleWrapper}>
            <div
              style={{ height: height }}
              className={classes.styleSquareOverTriangle}
            />
            <div className={classes.styleTriangle} />
          </span>
        </div>
      </React.Fragment>
    );
  }
}

UldShapedBox.propTypes = {
  classes: PropTypes.object.isRequired,
  height: PropTypes.string,
};

export default withStyles(styles)(UldShapedBox);
