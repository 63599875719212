import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import CloseIcon from '../icons/cross';

import {
  getFromServer,
  postToServer,
  patchFromServer,
  uploadToServer,
} from '../api';
import { Actions, Api, Routes } from '../constants';
import { createAction } from './utils';

export const getAirportStocks = (token) =>
  getStocks(token, Actions.STOCK.AIRPORT.ADD);

export const getMyInventoryStocks = (token) =>
  getStocks(token, Actions.STOCK.MY_INVENTORY.ADD, 'myInventory');

export const getCompanyStocks = (token) =>
  getStocks(token, Actions.STOCK.USER_COMPANY.ADD, 'company');

export const getMatchingStocks = (token) =>
  getStocks(token, Actions.STOCK.MATCHES.ADD, 'matches');

const getStocks = (token, action, additionalRoute) => (dispatch) => {
  const add = additionalRoute ? '/' + additionalRoute : '';
  return getFromServer(Api.BOOKER_API.BASE + add, token).then((response) => {
    if (response.ok) {
      response.json().then((json) => {
        dispatch(createAction(action, json));
      });
    }
  });
};

export const getMatchingStocksWithLastRequestTimeStamp =
  (token, lastMatchingRequest) => (dispatch) => {
    return postToServer(
      Api.BOOKER_API.MATCHES,
      { lastRequest: lastMatchingRequest },
      token
    ).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          dispatch(createAction(Actions.STOCK.MATCHES.ADD, json));
        });
      }
    });
  };

export const hasMatchingStocks = (token, lastMatchingRequest) => (dispatch) => {
  return postToServer(
    Api.BOOKER_API.HASMATCHES,
    { lastRequest: lastMatchingRequest },
    token
  ).then((response) => {
    if (response.ok) {
      response.json().then((json) => {
        dispatch(createAction(Actions.STOCK.MATCHES.ADD_HAS_MATCHES, json));
      });
    }
  });
};

export const putNewStock = (newStock, token) => (dispatch) => {
  const stockRequestDto = {
    amount: newStock.amount * newStock.factor,
    contactPerson: newStock.contactPerson,
    creationDate: new Date(),
    email: newStock.email,
    iataCode: newStock.iataCode,
    phone: newStock.phone,
    uldType: newStock.uldType,
    validUntil: newStock.validUntil,
    destinationIataCode: newStock.destinationIataCode,
    availableFrom: newStock.availableFrom,
    comment: newStock.comment,
    location: newStock.location,
  };

  return postToServer(Api.BOOKER_API.BASE, stockRequestDto, token).then(
    async (response) => {
      if (response.ok) {
        const resultJson = await response.json();
        dispatch(createAction(Actions.STOCK.MATCHES.OPEN_POP_UP, resultJson));
        return resultJson;
      } else {
        throw response;
      }
    }
  );
};

export const putNewLeasingStock = (newLeasingStock, token) => (dispatch) => {
  const stockRequestDto = {
    amount: newLeasingStock.amount * newLeasingStock.factor,
    contactPerson: newLeasingStock.contactPerson,
    creationDate: new Date(),
    email: newLeasingStock.email,
    iataCode: newLeasingStock.iataCode,
    phone: newLeasingStock.phone,
    uldType: newLeasingStock.uldType,
    validUntil: newLeasingStock.validUntil,
    destinationIataCode: newLeasingStock.destinationIataCode,
    availableFrom: newLeasingStock.availableFrom,
    comment: newLeasingStock.comment,
    location: newLeasingStock.location,
    offerInformation: newLeasingStock.commercialInformation,
    offerType: newLeasingStock.leasingType,
  };

  return postToServer(
    Api.BOOKER_API.LEASING_STOCK.BASE,
    stockRequestDto,
    token
  ).then(async (response) => {
    if (response.ok) {
      const resultJson = await response.json();
      dispatch(createAction(Actions.STOCK.MATCHES.OPEN_POP_UP, resultJson));
      return resultJson;
    } else {
      throw response;
    }
  });
};

export const patchStock = (specialStock, token) => (dispatch) => {
  const stockRequestDto = {
    id: specialStock.id,
    amount: specialStock.amount * specialStock.factor,
    contactPerson: specialStock.contactPerson,
    email: specialStock.email,
    iataCode: specialStock.iataCode,
    phone: specialStock.phone,
    uldType: specialStock.uldType,
    validUntil: specialStock.validUntil,
    destinationIataCode: specialStock.destinationIataCode,
    availableFrom: specialStock.availableFrom,
    comment: specialStock.comment,
    location: specialStock.location,
  };

  return patchFromServer(Api.BOOKER_API.BASE, stockRequestDto, token).then(
    async (response) => {
      if (response.ok) {
        const resultJson = await response.json();
        dispatch(createAction(Actions.STOCK.MATCHES.OPEN_POP_UP, resultJson));
        return resultJson;
      } else {
        throw response;
      }
    }
  );
};

export const importExcel =
  (file, token, enqueueSnackbar, closeSnackbar) => (dispatch) => {
    if (file) {
      let data = new FormData();
      data.append('file', file);
      data.append('filename', file.name);

      return uploadToServer(Api.BOOKER_API.EXCEL.IMPORT, data, token)
        .then((response) => {
          if (response.ok) {
            enqueueSnackbar(<FormattedMessage id="EXCEL.UPLOAD.SUCCESS" />, {
              variant: 'success',
            });
            response.json().then((json) => {
              dispatch(createAction(Actions.STOCK.MATCHES.OPEN_POP_UP, json));
            });
          } else if (response.status === 409) {
            enqueueSnackbar(
              <FormattedMessage id="EXCEL.UPLOAD.INCORRECT_DATA" />,
              {
                variant: 'warning',
              }
            );
            // Then print all errors
            response.json().then((body) => {
              body.data.forEach((error) => {
                enqueueSnackbar(error, {
                  variant: 'warning',
                  persist: true,
                  action: (key) => (
                    <Button
                      onClick={() => {
                        closeSnackbar(key);
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  ),
                });
              });
            });
          } else {
            enqueueSnackbar(<FormattedMessage id="EXCEL.UPLOAD.FAIL" />, {
              variant: 'warning',
            });
          }
        })
        .then(() => dispatch(getMyInventoryStocks(token)))
        .then(() => dispatch(getMatchingStocksWithLastRequestTimeStamp(token)));
    }
  };

export const getSpecialStockFromPropsOrFromBackend = async (
  specialStock,
  id,
  token,
  enqueueSnackbar,
  push
) => {
  if (specialStock.id === Number.parseInt(id)) {
    return specialStock;
  } else if (id) {
    const result = await getFromServer(`${Api.BOOKER_API.BASE}/${id}`, token)
      .then(async (response) => {
        if (response.ok) {
          const stockToReturn = await response.json().then((stock) => stock);
          return stockToReturn;
        } else {
          throw response;
        }
      })
      .catch((resp) => {
        enqueueSnackbar(<FormattedMessage id="STOCK_DETAILS.ERROR_LOAD" />, {
          variant: 'error',
        });
        push(Routes.APP.AIRPORT_STOCKS.INDEX);
        return {};
      });
    return result;
  } else {
    enqueueSnackbar(<FormattedMessage id="STOCK_DETAILS.NO_ID" />, {
      variant: 'error',
    });
    push(Routes.APP.AIRPORT_STOCKS.INDEX);
    return {};
  }
};
