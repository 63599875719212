import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { SUPPORTED_LANGUAGES } from '../i18n';

const styles = {
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export class IntlProviderWrapper extends Component {
  state = {
    switching: true,
    initialLoad: true,
    messages: {},
    locale: null,
    error: false,
  };

  componentDidMount() {
    this.setState(
      {
        switching: true,
      },
      () => this.changeLanguageAndLocale(null, null)
    );
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.switching &&
      (prevProps.language !== this.props.language ||
        prevProps.locale !== this.props.locale)
    )
      this.setState(
        {
          switching: true,
        },
        () => this.changeLanguageAndLocale(prevProps.locale, prevProps.language)
      );
  }

  changeLanguageAndLocale = async (prevLocale, prevLanguage) => {
    let errorLanguage = false;
    let errorLocale = false;
    if (prevLanguage !== this.props.language)
      errorLanguage = await this.selectLanguage(this.props.language);
    if (prevLocale !== this.props.locale)
      errorLocale = await this.selectLocale(this.props.locale);

    if (!errorLanguage && !errorLocale) {
      this.setState({
        switching: false,
        initialLoad: false,
        error: false,
      });
    } else {
      this.setState({
        switching: false,
        initialLoad: false,
        error: true,
      });
    }
  };

  selectLanguage = async (lang) => {
    let error = false;
    const messages = await import(
      /* webpackChunkName: "i18n-messages-[request]" */ `../i18n/messages/${lang}.json`
    )
      .then((messages) => messages.default)
      .catch(() => {
        error = true;
      });

    if (!error)
      this.setState({
        messages: { ...messages },
      });
    return error;
  };

  selectLocale = async (locale) => {
    const dashPosition = locale.indexOf('-');
    const shortLangName =
      dashPosition > -1 ? locale.substring(0, dashPosition) : locale;
    let error = false;

    await import(
      /* webpackChunkName: "i18n-locale" */ `react-intl/locale-data/${shortLangName}`
    )
      .then((localeDate) => addLocaleData(localeDate.default))
      .then(() => this.setState({ locale: locale }))
      .catch(() => {
        error = true;
      });

    return error;
  };

  render() {
    const { children, classes } = this.props;
    const { messages, initialLoad, locale } = this.state;
    if (initialLoad) {
      return (
        <div className={classes.root}>
          <div className={classes.loadingInfo}>
            <CircularProgress />
            Page Loading in Progress
          </div>
        </div>
      );
    } else {
      return (
        <IntlProvider
          textComponent={Fragment}
          locale={locale}
          messages={messages}
          timeZone="UTC"
        >
          {children}
        </IntlProvider>
      );
    }
  }
}

IntlProviderWrapper.propTypes = {
  language: PropTypes.oneOf(SUPPORTED_LANGUAGES).isRequired,
  children: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { language, locale } = state.language;
  return {
    language,
    locale,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(IntlProviderWrapper)
);
