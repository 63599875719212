import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: '1.5rem',
    fontWeight: '700',
    marginBottom: '2em',
    marginTop: '1.5em',
    padding: '0',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'unset',
    textTransform: 'none',
    color: theme.palette.primary.chambray,
  },
}));

export function BookingDetailsHeader({ booking, user }) {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={`MuiTab-root ${classes.subtitle}`}>
        {user?.company.name === booking.stockCreatorUser.company.name &&
          booking.open && (
            <FormattedMessage id="BOOKINGS.DETAILS.HEADLINE.PLEASE_TAKE_ACTION" />
          )}
        {user?.company.name !== booking.stockCreatorUser.company.name &&
          booking.open && (
            <FormattedMessage id="BOOKING.DETAILS.HEADLINE.WAITING_FOR_FEEDBACK" />
          )}
        {!booking.open && booking.denied && (
          <FormattedMessage id="BOOKING.DETAILS.HEADLINE.BOOKING_DENIED" />
        )}
        {!booking.open && !booking.denied && (
          <FormattedMessage id="BOOKING.DETAILS.HEADLINE.BOOKING_CONFIRMED" />
        )}
      </div>
    </Fragment>
  );
}

BookingDetailsHeader.propTypes = {
  booking: PropTypes.shape({
    bookingUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    bookedStock: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      uldType: PropTypes.string.isRequired,
      iataCode: PropTypes.string.isRequired,
      availableFrom: PropTypes.string.isRequired,
      validUntil: PropTypes.string.isRequired,
      destinationIataCode: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    returnAirport: PropTypes.shape({
      iataCode: PropTypes.string.isRequired,
    }),
    stockCreatorUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    creationDate: PropTypes.string.isRequired,
    requestComment: PropTypes.string,
    replyComment: PropTypes.string,
    fromWhen: PropTypes.string.isRequired,
    tillWhen: PropTypes.string.isRequired,
    amountBooked: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    incoming: PropTypes.bool.isRequired,
    denied: PropTypes.bool.isRequired,
    additionalInformation: PropTypes.string,
    id: PropTypes.number.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDeny: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default BookingDetailsHeader;
