import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import { useMedia } from '../utils/customHooks';
import SearchFieldBox from '../components/searchFieldBox';
import HeadlineBox from '../components/headlineBox';
import Routes from '../constants/routes';
import UldShapedButton from '../components/uldShapedButton';
import LostAndFoundTab from '../components/lostAndFound/lostAndFoundTab';
import { Actions } from '../constants';
import { createAction } from '../actions/utils';
import Plus from '../icons/plus';
import MenuButton from '../components/menuButton';
import SearchButton from '../components/headlineBox/searchButton';

const useStyle = makeStyles((theme) => ({
  span: {
    display: 'flex',
  },
}));

export function LostAndFound({ history, dispatch }) {
  const classes = useStyle();

  const handleFilterChange = (string) => {
    dispatch(createAction(Actions.LOST_AND_FOUND.ADD_FILTER_STRING, string));
  };

  const { isMobile } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: false,
      },
    ],
    {
      isMobile: true,
    }
  );

  return (
    <React.Fragment>
      <HeadlineBox
        headChildren={
          isMobile && (
            <MenuButton
              id="add-new-entry-mobile"
              img={<Plus />}
              paddingLeft="15px"
              minWidth="fit-content"
              onClick={() => history.push(Routes.APP.LOST_AND_FOUND.ADD_ENTRY)}
            />
          )
        }
        height="180px"
        headLineText="TAB_NAVBAR.LOST_AND_FOUND"
      >
        {!isMobile && (
          <div style={{ width: '15%', fontSize: '1em' }}>
            <UldShapedButton
              onClick={() => history.push(Routes.APP.LOST_AND_FOUND.ADD_ENTRY)}
              label={<FormattedMessage id="ADD_NEW_ENTRY" />}
            />
          </div>
        )}
        <span className={classes.span}>
          {isMobile && <SearchButton handleChange={handleFilterChange} />}
        </span>
      </HeadlineBox>
      {!isMobile && <SearchFieldBox handleChange={handleFilterChange} />}
      <LostAndFoundTab />
    </React.Fragment>
  );
}

LostAndFound.defaultProps = {};

LostAndFound.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  intl: intlShape,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default injectIntl(
  withRouter(connect(mapDispatchToProps)(LostAndFound))
);
