import React from 'react';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '../icons/cross';
import { Button } from '@material-ui/core';

export function validatePassword(pw, enqueueSnackbar, closeSnackbar) {
  const errors = [];
  if (pw.length < 8) {
    errors.push(
      <FormattedMessage
        key={'REGISTRATION.PASSWORD_CHARACTERS'}
        id="REGISTRATION.PASSWORD_CHARACTERS"
      />
    );
  }
  if (pw.search(/[a-z]/i) < 0) {
    errors.push(
      <FormattedMessage
        key={'REGISTRATION.PASSWORD_LEAST_ONE'}
        id="REGISTRATION.PASSWORD_LEAST_ONE"
      />
    );
  }
  if (pw.search(/[0-9]/) < 0) {
    errors.push(
      <FormattedMessage
        key={'REGISTRATION.PASSWORD_DIGIT'}
        id="REGISTRATION.PASSWORD_DIGIT"
      />
    );
  }
  if (pw.search(/[a-z]/) < 0) {
    errors.push(
      <FormattedMessage
        key={'REGISTRATION.PASSWORD_LOWERCASE'}
        id="REGISTRATION.PASSWORD_LOWERCASE"
      />
    );
  }
  if (pw.search(/[A-Z]/) < 0) {
    errors.push(
      <FormattedMessage
        key={'REGISTRATION.PASSWORD_UPPERCASE'}
        id="REGISTRATION.PASSWORD_UPPERCASE"
      />
    );
  }

  if (errors.length > 0) {
    const finalMessage = [].concat(
      ...errors.map((error, idx) => [error, <br key={idx} />])
    );

    const closeButton = (key) => (
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        <CloseIcon />
      </Button>
    );

    enqueueSnackbar(<>{finalMessage.slice(0, finalMessage.length - 1)}</>, {
      variant: 'warning',
      persist: true,
      action: closeButton,
    });

    return false;
  }
  return true;
}
