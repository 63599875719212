import { patchFromServer } from '../api';
import { createAction } from './utils';
import { Actions, Api } from '../constants';
export const patchLeasingStock = (specialStock, token) => (dispatch) => {
  const stockRequestDto = {
    id: specialStock.id,
    amount: specialStock.amount * specialStock.factor,
    contactPerson: specialStock.contactPerson,
    email: specialStock.email,
    iataCode: specialStock.iataCode,
    phone: specialStock.phone,
    uldType: specialStock.uldType,
    validUntil: specialStock.validUntil,
    destinationIataCode: specialStock.destinationIataCode,
    availableFrom: specialStock.availableFrom,
    comment: specialStock.comment,
    location: specialStock.location,
    offerInformation: specialStock.offerInformation,
    offerType: specialStock.offerType,
  };

  return patchFromServer(
    Api.BOOKER_API.LEASING_STOCK.BASE,
    stockRequestDto,
    token
  ).then(async (response) => {
    if (response.ok) {
      const resultJson = await response.json();
      dispatch(createAction(Actions.STOCK.MATCHES.OPEN_POP_UP, resultJson));
      return resultJson;
    } else {
      throw response;
    }
  });
};
