import React, { Fragment, Component } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import { withStyles } from '@material-ui/core';

const styles = {
  widthAndHeight: {
    width: '100%',
  },
  sliderIe11Compat: {
    display: 'flex',
  },
};

class InfoSlideshow extends Component {
  render() {
    const { classes, infoSlider, onClick } = this.props;

    const images = [];
    for (var i = 0; i < infoSlider.length; i++) {
      images.push(
        <div className={classes.widthAndHeight} key={infoSlider[i]}>
          <img
            className={classes.widthAndHeight}
            alt={infoSlider[i]}
            src={infoSlider[i]}
          />
        </div>
      );
    }

    return (
      <Fragment>
        <Carousel
          className={classes.sliderIe11Compat}
          autoPlay
          interval={5000}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          infiniteLoop={true}
        >
          {images}
        </Carousel>
      </Fragment>
    );
  }
}

export default withStyles(styles)(InfoSlideshow);
