import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, FormattedDate, intlShape } from 'react-intl';
import BlueInfoContainer from '../blueInfoContainer';
import UldRight from '../../icons/uldRight';
import UldLeft from '../../icons/uldLeft';
import BookingOverviewIcon from './bookingOverviewIcon';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import MenuButton from '../menuButton';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles((theme) => ({
  stockTile: {
    backgroundColor: theme.palette.primary.linkWater25,
    padding: '0',
    maxWidth: 'unset',
    width: '95%',
    fontWeight: '600',
    color: theme.palette.primary.chambray,
  },

  stockText: {
    color: theme.palette.secondary.white,
    fontWeight: '600',
    width: '35px',
    padding: '8px 0',
    paddingLeft: '10px',
    display: 'flex',
  },
  stock: {
    display: 'inline-flex',
    alignContent: 'flex-start',
    width: '20px',
    height: '100%',
    backgroundColor: theme.palette.secondary.white,
  },
  importantStock: {
    display: 'inline-flex',
    alignContent: 'flex-start',
    width: '20px',
    height: '101%',
    margin: '-2px 0 -2px 0',
    backgroundColor: theme.palette.secondary.orangePeel,
  },
  leasingStock: {
    display: 'inline-flex',
    alignContent: 'flex-start',
    width: '20px',
    height: '101%',
    margin: '-2px 0 -2px 0',
    backgroundColor: theme.palette.primary.light,
  },
  emergency: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '0.25rem',
  },
  container: {
    justify: 'center',
  },
  containerWithPadding: {
    paddingBottom: '2rem',
  },
  gridItem: {
    padding: '8px',
  },
  cellPadding: {
    paddingRight: '5px',
    paddingTop: '10px',
    textTransform: 'none',
  },
  topPadding: {
    padding: '2rem 0.5rem 0 0.5rem',
  },
  textEnd: {
    textAlign: 'end',
  },
  textCenter: {
    textAlign: 'center',
  },
  textSize: {
    fontSize: '0.8rem',
  },
  whiteLine: {
    backgroundColor: theme.palette.primary.contrastText,
    height: '3px',
    display: 'flex',
    width: '100%',
    margin: '0',
  },
}));

export default function OverviewTableMobile({ data, onRowClick = () => {} }) {
  const classes = useStyles();

  function handleClick(dataId) {
    onRowClick(dataId);
  }

  return (
    <List>
      {data.map((row) => (
        <>
          <ListItem style={{ padding: '0' }} key={row.id}>
            <div
              className={classes.stockTile}
              style={{ display: 'flex', width: '100%' }}
              onClick={() => handleClick(row.id)}
            >
              <Grid container justify="center">
                <Grid item xs={1}>
                  <div
                    className={
                      row.leasing ? classes.leasingStock : classes.stockTile
                    }
                  ></div>
                </Grid>
                <Grid item xs={11} className={classes.topPadding}>
                  <Grid container spacing={2} className={classes.textSize}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={4}>
                          <FormattedMessage id="BOOKINGS.OVERVIEWTABLE.HEADLINE.OWN" />
                        </Grid>
                        <Grid item xs={4} className={classes.textCenter}>
                          <FormattedMessage id="BOOKINGS.OVERVIEWTABLE.HEADLINE.DIRECTION" />
                        </Grid>
                        <Grid item xs={4} className={classes.textEnd}>
                          <FormattedMessage id="BOOKINGS.OVERVIEWTABLE.HEADLINE.PARTNER" />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={4}>
                          {row.ownCompanyCode}
                        </Grid>
                        <Grid item xs={4} className={classes.textCenter}>
                          {row.isMovingToPartner ? <UldRight /> : <UldLeft />}
                        </Grid>
                        <Grid item xs={4} className={classes.textEnd}>
                          {row.partnerCompanyCode}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={6}>
                          <FormattedMessage id="BOOKINGS.OVERVIEWTABLE.HEADLINE.TYPE" />
                          :
                        </Grid>
                        <Grid item xs={6} className={classes.textEnd}>
                          {row.type}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={6}>
                          <FormattedMessage id="BOOKINGS.OVERVIEWTABLE.HEADLINE.STATE" />
                          :
                        </Grid>
                        <Grid item xs={6} className={classes.textEnd}>
                          <BookingOverviewIcon bookingState={row.state} />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={6}>
                          <FormattedMessage id="BOOKINGS.OVERVIEWTABLE.HEADLINE.AMOUNT" />
                          :
                        </Grid>
                        <Grid item xs={6} className={classes.textEnd}>
                          {row.amount}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={6}>
                          <FormattedMessage id="BOOKINGS.OVERVIEWTABLE.HEADLINE.AIRPORT.CODE" />
                          :
                        </Grid>
                        <Grid item xs={6} className={classes.textEnd}>
                          {row.stationCode}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.containerWithPadding}>
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={6} className={classes.gridItem}>
                            <FormattedMessage id="BOOKINGS.OVERVIEWTABLE.HEADLINE.RETURNAIRPORT.CODE" />
                            :
                          </Grid>
                          <Grid item xs={6} className={classes.textEnd}>
                            {row.returnStationCode}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={6} className={classes.gridItem}>
                            <FormattedMessage id="BOOKINGS.OVERVIEWTABLE.HEADLINE.BOOKINGDATE" />
                            :
                          </Grid>
                          <Grid item xs={6} className={classes.textEnd}>
                            <FormattedDate
                              year="numeric"
                              month="2-digit"
                              day="2-digit"
                              value={String(row.bookingDate)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </ListItem>
          <div className={classes.whiteLine} />
        </>
      ))}
    </List>
  );
}

OverviewTableMobile.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      ownCompanyCode: PropTypes.string.isRequired,
      partnerCompanyCode: PropTypes.string.isRequired,
      isMovingToPartner: PropTypes.bool.isRequired,
      leasing: PropTypes.bool.isRequired,
      amount: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      stationCode: PropTypes.string.isRequired,
      returnStationCode: PropTypes.string.isRequired,
      bookingDate: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    })
  ),
  intl: intlShape,
};
