import { Actions } from '../constants';
import ImageObject from '../model/imageObject';

const initialState = {
  infoSlider: [],
  benefitsSlider: [],
  companiesImages: [],
  adSlider: {
    airportStocks: [],
    landingPageBottom: [],
    landingPageTop: [],
    messages: [],
    myCompany: [],
    myInventory: [],
  },
};

export default function getPathForImages(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PATH_FOR_IMAGES.GET_INFO_SLIDER:
      return { ...state, infoSlider: [...action.payload] };
    case Actions.GET_PATH_FOR_IMAGES.GET_BENEFITS_SLIDER:
      return { ...state, benefitsSlider: [...action.payload] };
    case Actions.GET_PATH_FOR_IMAGES.GET_COMPANIES_IMAGES:
      return { ...state, companiesImages: [...action.payload] };
    case Actions.GET_PATH_FOR_IMAGES.GET_AD_AIRPORT_STOCKS:
      return {
        ...state,
        adSlider: {
          ...state.adSlider,
          airportStocks: [
            ...action.payload.map(
              (item) =>
                new ImageObject({ ...item, pathAddition: 'adAirportStocks' })
            ),
          ],
        },
      };
    case Actions.GET_PATH_FOR_IMAGES.GET_AD_LANDING_PAGE_BOTTOM:
      return {
        ...state,
        adSlider: {
          ...state.adSlider,
          landingPageBottom: [
            ...action.payload.map(
              (item) =>
                new ImageObject({
                  ...item,
                  pathAddition: 'adLandingPageBottom',
                })
            ),
          ],
        },
      };
    case Actions.GET_PATH_FOR_IMAGES.GET_AD_LANDING_PAGE_TOP:
      return {
        ...state,
        adSlider: {
          ...state.adSlider,
          landingPageTop: [
            ...action.payload.map(
              (item) =>
                new ImageObject({ ...item, pathAddition: 'adLandingPageTop' })
            ),
          ],
        },
      };
    case Actions.GET_PATH_FOR_IMAGES.GET_AD_MESSAGES:
      return {
        ...state,
        adSlider: {
          ...state.adSlider,
          messages: [
            ...action.payload.map(
              (item) => new ImageObject({ ...item, pathAddition: 'adMessages' })
            ),
          ],
        },
      };
    case Actions.GET_PATH_FOR_IMAGES.GET_AD_MY_COMPANY:
      return {
        ...state,
        adSlider: {
          ...state.adSlider,
          myCompany: [
            ...action.payload.map(
              (item) =>
                new ImageObject({ ...item, pathAddition: 'adMyCompany' })
            ),
          ],
        },
      };
    case Actions.GET_PATH_FOR_IMAGES.GET_AD_MY_INVENTORY:
      return {
        ...state,
        adSlider: {
          ...state.adSlider,
          myInventory: [
            ...action.payload.map(
              (item) =>
                new ImageObject({ ...item, pathAddition: 'adMyInventory' })
            ),
          ],
        },
      };
    default: {
      return state;
    }
  }
}
