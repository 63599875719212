import React from 'react';
import PropTypes from 'prop-types';
import Chat from '../../icons/chat';
import FileEmpty from '../../icons/fileEmpty';
import Rocket from '../../icons/rocket';
import LostFound from '../../icons/lostFound';
import NOTIFICATIONTYPES from '../../constants/notificationTypes';

export default function notificationTypeIcon({ type }) {
  switch (type) {
    case NOTIFICATIONTYPES.BOOKING.ANSWER.CONFIRM:
    case NOTIFICATIONTYPES.BOOKING.ANSWER.DENY:
    case NOTIFICATIONTYPES.BOOKING.REQUEST:
      return <FileEmpty />;
    case NOTIFICATIONTYPES.SKYPOOLING_NEWS:
      return <Rocket />;
    case NOTIFICATIONTYPES.STOCK_CHAT:
      return <Chat />;
    case NOTIFICATIONTYPES.LOST_AND_FOUND:
      return <LostFound />;
    default:
      return <div>No Icon</div>;
  }
}

notificationTypeIcon.propTypes = {
  type: PropTypes.string.isRequired,
};
