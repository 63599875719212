import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { gridItemHeadline, grid, grid_margin } from '../addNewStock/cssStyles';

const useStyles = makeStyles((theme) => ({
  gridItemHeadline: gridItemHeadline(theme),
  grid: grid,
  grid_margin: grid_margin,
}));

export default function LostAndFoundDetailInformations({
  report,
  stockIds,
  comment,
  iataCode,
}) {
  const classes = useStyles();

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={4}>
        <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
          <FormattedMessage id="BASE.ULD" />
        </div>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <RadioGroup
              aria-label={<FormattedMessage id="BASE.REPORT" />}
              name="report"
              style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
              value={report}
              disabled
            >
              <FormControlLabel
                classes={{ root: classes.root }}
                value={'LOST'}
                style={{ marginRight: '15%' }}
                control={
                  <Radio
                    className={`MuiRadio-root ${classes.radio}`}
                    classes={{ checked: classes.checked }}
                    required
                    color="default"
                  />
                }
                disabled
                label={<FormattedMessage id="BASE.REPORT_LOST_ULD" />}
              />
              <FormControlLabel
                classes={{ root: classes.root }}
                value={'FOUND'}
                style={{ marginRight: '15%' }}
                control={
                  <Radio
                    classes={{ checked: classes.checked }}
                    required
                    color="default"
                  />
                }
                disabled
                label={<FormattedMessage id="BASE.REPORT_FOUND_ULD" />}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="comment"
              label={<FormattedMessage id="ADD_NEW_LOST_AND_FOUND.COMMENT" />}
              type="text"
              value={comment}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="uldids"
              label={<FormattedMessage id="ADD_NEW_LOST_AND_FOUND.ULD_ENTRY" />}
              type="text"
              multiline
              value={stockIds.join(', ')}
              InputLabelProps={{
                shrink: true,
              }}
              required
              disabled
            />
          </Grid>
          {report === 'FOUND' && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="airports-select"
                label={<FormattedMessage id="ADD_NEW_STOCK.FROM_STATION" />}
                value={iataCode}
                disabled
                type="text"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

LostAndFoundDetailInformations.propTypes = {
  report: PropTypes.string.isRequired,
  stockIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  comment: PropTypes.string.isRequired,
  iataCode: PropTypes.string,
};
