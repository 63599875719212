import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { gridItemHeadline, grid } from './cssStyles';

const useStyles = makeStyles((theme) => ({
  gridItemHeadline: gridItemHeadline(theme),
  grid: grid,
}));

export function AddNewStockContactSection({
  contactPerson,
  email,
  phone,
  paddingRight,
}) {
  const classes = useStyles();

  return (
    <span>
      <Grid container justify="center">
        <Grid item xs={12} md={4}>
          <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
            <FormattedMessage id="BASE.CONTACT" />
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} className={classes.grid}>
              <FormControl fullWidth>
                <TextField
                  style={{ marginRight: paddingRight }}
                  id="standard-name"
                  label={<FormattedMessage id="BASE.NAME" />}
                  type="text"
                  disabled
                  value={contactPerson}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
              <FormControl fullWidth>
                <TextField
                  style={{ marginRight: paddingRight }}
                  id="standard-email"
                  label={<FormattedMessage id="BASE.EMAIL" />}
                  type="email"
                  disabled
                  value={email}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
              <FormControl fullWidth>
                <TextField
                  style={{ marginRight: paddingRight }}
                  id="standard-phone"
                  label={<FormattedMessage id="BASE.PHONE" />}
                  type="text"
                  disabled
                  value={phone}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </span>
  );
}

AddNewStockContactSection.defaultProps = {
  defaultValues: {
    contactPerson: '',
    email: '',
    phone: '',
  },
};

AddNewStockContactSection.propTypes = {
  defaultValues: PropTypes.shape({
    contactPerson: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  contactPerson: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  paddingRight: PropTypes.string,
};

export default AddNewStockContactSection;
