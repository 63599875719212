import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { getCompanyStocks } from '../actions/stock';
import Table from '../components/table';
import SearchFieldBox from '../components/searchFieldBox';
import { Routes } from '../constants';
import { createAction } from '../actions/utils';
import Actions from '../constants/actions';
import { HeadlineBoxWithSearch } from '../components/headlineBox';
import AdLandscape from '../components/adLandscape';
import { getAdMyCompany } from '../actions/pathsForImages';

const styles = (theme) => ({
  sponsorsText: {
    color: theme.palette.primary.chambray,
    textTransform: 'uppercase',
    padding: '0',
    fontSize: '1rem',
    fontWeight: '600',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'left',
    marginBottom: '15px',
    marginTop: 'inherit',
  },
});

const headRowsForTable = (formatMessage) => [
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TILE_EMERGENCY' }),
  }, // "Mark-as-important"-Row
  {
    id: 'companyCode',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.COMPANY_CODE' }),
  },
  {
    id: 'contact',
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.CONTACT' }),
  },
  {
    id: 'amount',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.AMOUNT' }),
  },
  {
    id: 'uldType',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.ULD_TYPE' }),
  },
  {
    id: 'iataCode',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.STATION' }),
  },
  {
    id: 'availableFrom',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.AVAILABLE_FROM' }),
  },
  {
    id: 'validUntil',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.VALID_UNTIL' }),
  },
  {
    id: 'requests',
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.REQUESTS' }),
  },
  { id: 'deleteEdit', numeric: true, disablePadding: false, label: '' }, // "Delete"-Row
];

const headRowsForTableWithoutEmergency = (formatMessage) => [
  {
    id: 'companyCode',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.COMPANY_CODE' }),
  },
  {
    id: 'contact',
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.CONTACT' }),
  },
  {
    id: 'amount',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.AMOUNT' }),
  },
  {
    id: 'uldType',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.ULD_TYPE' }),
  },
  {
    id: 'iataCode',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.STATION' }),
  },
  {
    id: 'availableFrom',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.AVAILABLE_FROM' }),
  },
  {
    id: 'validUntil',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.VALID_UNTIL' }),
  },
  {
    id: 'requests',
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.REQUESTS' }),
  },
  { id: 'deleteEdit', numeric: true, disablePadding: false, label: '' }, // "Delete"-Row
];

export class MyCompany extends Component {
  componentDidMount = () => {
    this.props.getUserCompanyStocks(this.props.token);
    this.props.getAdSlider();
  };

  state = {
    filterString: '',
  };

  handleChange = (newValue) => {
    this.setState({
      filterString: newValue,
    });
  };

  filterData = (stocks) => {
    return stocks.filter((row) =>
      Object.values([row.iataCode, row.uldType, row.amount]).find((values) =>
        String(values)
          .toLocaleLowerCase()
          .includes(this.state.filterString.toLowerCase())
      )
    );
  };

  editFunc = (element) => {
    this.props.editStock(element);
    this.props.history.push(Routes.APP.MY_COMPANY.EDIT_STOCK);
  };

  detailsFunc = (stock) => {
    this.props.dispatchStockDetails(stock);
    this.props.history.push(`${Routes.APP.MY_COMPANY.DETAILS}/${stock.id}`);
  };

  goToBooking = () => {
    this.props.history.push(`${Routes.APP.BOOKINGS.INCOMING}/all`);
  };

  render() {
    const { companyStocks, intl, dispatch, adImages, roles } = this.props;

    return (
      <React.Fragment>
        <HeadlineBoxWithSearch
          headLineText="MY_COMPANY.HEADLINE"
          handleChange={this.handleChange}
        />
        <SearchFieldBox handleChange={this.handleChange} />
        <Table
          title={<FormattedMessage id="MY_COMPANY.INVENTORY_OVERVIEW" />}
          renderAdditionalButtons={true}
          headRows={
            roles.includes('ULDLeaser')
              ? headRowsForTableWithoutEmergency(intl.formatMessage)
              : headRowsForTable(intl.formatMessage)
          }
          isMyCompanyTable={true}
          data={this.filterData(companyStocks)}
          defaultOrder="companyCode"
          editFunc={this.editFunc}
          detailsFunc={this.detailsFunc}
          getStocksFunction={this.props.getUserCompanyStocks}
          token={this.props.token}
          dispatch={dispatch}
          goToBooking={this.goToBooking}
        />
        {adImages && adImages.length > 0 && <AdLandscape adSlider={adImages} />}
      </React.Fragment>
    );
  }
}

MyCompany.defaultProps = {
  companyStocks: [],
};

MyCompany.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  companyStocks: PropTypes.array,
  token: PropTypes.string,
  adImages: PropTypes.arrayOf(PropTypes.string),
  getUserCompanyStocks: PropTypes.func.isRequired,
  getAdSlider: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  dispatchStockDetails: PropTypes.func.isRequired,
  editStock: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  intl: intlShape,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  companyStocks: state.stocks.company,
  adImages: state.getPathForImages.adSlider.myCompany,
  token: state.auth.token,
  roles: state.user.roles,
});

const mapDispatchToProps = (dispatch) => ({
  getAdSlider: () => dispatch(getAdMyCompany()),
  getUserCompanyStocks: (token) => dispatch(getCompanyStocks(token)),
  editStock: (element) =>
    dispatch(createAction(Actions.STOCK.SPECIAL_STOCK, element)),
  dispatchStockDetails: (element) =>
    dispatch(createAction(Actions.STOCK.SPECIAL_STOCK, element)),
  dispatch,
});

export default injectIntl(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MyCompany))
);
