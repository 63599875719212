import { Actions } from '../constants';
import StockChat from '../model/stockChat';

const initialState = {
  chats: [],
  unreadMessages: [],
};

export default function stockChat(state = initialState, action) {
  switch (action.type) {
    case Actions.STOCK_CHAT.GET_STOCK_CHAT: {
      return {
        ...state,
        chats: [...action.payload.map((chats) => new StockChat(chats))],
      };
    }
    case Actions.STOCK_CHAT.RESET_STOCK_CHAT_MESSAGES: {
      return {
        ...state,
        chats: [],
      };
    }
    default: {
      return state;
    }
  }
}
