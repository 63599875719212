import React from 'react';

export default () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="icons/uld-in"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M10.624,1.527 L10.624,2.526 L2.00075,2.60437481 L2.00075,23.5833748 L4.0785,23.6031248 L14.92475,23.6023748 L24.00075,13.8943748 L24,11.396 L24.999,11.396 L25,14.2630323 L15.387128,24.6031248 L4.07394328,24.6031248 L1,24.5750887 L1,1.61432974 L10.624,1.527 Z M15.9499586,1.51051549 C16.101479,1.66203589 16.101479,1.90835366 15.9499586,2.05987406 L11.9513742,6.05845848 L24.6114862,6.05845848 C24.8259458,6.05845848 25,6.23251268 25,6.44697232 C25,6.66143196 24.8259458,6.83548616 24.6114862,6.83548616 L11.9513742,6.83548616 L15.9499586,10.8340706 C16.101479,10.985591 16.101479,11.2319088 15.9499586,11.3834291 C15.8738099,11.4595779 15.7743504,11.4968752 15.6748909,11.4968752 C15.5754313,11.4968752 15.4759718,11.4588008 15.3998231,11.3834291 L10.737657,6.72126309 C10.5861366,6.56974269 10.5861366,6.32342492 10.737657,6.17190452 L10.738434,6.17268155 L15.4006001,1.51051549 C15.5521205,1.35899509 15.7984383,1.35899509 15.9499586,1.51051549 Z"
        id="Combined-Shape"
        fill="#122236"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);
