import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import MenuButton from '../components/menuButton';
import BackIcon from '../icons/back';
import HeadlineBox from '../components/headlineBox';
import NotificationCenterList from '../components/notificationCenter/notificationCenterList';

export class NotificationCenter extends Component {
  render() {
    return (
      <React.Fragment>
        <HeadlineBox
          textTransform="initial"
          headLineText="NOTIFICATIONCENTER.HEADLINE"
        />
        <div style={{ marginTop: '1em' }}>
          <MenuButton
            text={<FormattedMessage id="BASE.BACK" />}
            onClick={() => this.props.history.goBack()}
            img={<BackIcon />}
          />
          <div>
            <NotificationCenterList />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

NotificationCenter.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }),
  enqueueSnackbar: PropTypes.func.isRequired,
  token: PropTypes.string,
};

export default withSnackbar(withRouter(NotificationCenter));
