import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  flexStyle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  stockText: {
    color: theme.palette.primary.chambray,
    fontWeight: '600',
    backgroundColor: theme.palette.primary.linkWater25,
    fontSize: '0.8rem',
    textAlign: 'start',
    padding: '10px',
  },
  blockText: {
    paddingBottom: '3px',
  },
}));

export function LostAndFoundResponsiveTile({
  row: { id, companyCode, creationDate, iataCode, uldIds },
  onRowClick = () => {},
}) {
  const classes = useStyles();

  return (
    <ListItem
      onClick={() => {
        onRowClick(id);
      }}
    >
      <Grid container className={classes.stockText}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} className={classes.blockText}>
              <FormattedMessage id="TABLE_HEADER.COMPANY_CODE" />
            </Grid>
            <Grid item xs={6}>
              {companyCode}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} className={classes.blockText}>
              <FormattedMessage id="BASE.CREATION_DATE" />
            </Grid>
            <Grid item xs={6}>
              <FormattedDate
                year="numeric"
                month="2-digit"
                day="2-digit"
                value={creationDate}
              />
            </Grid>
          </Grid>
        </Grid>
        {iataCode && (
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="space-between">
              <Grid item xs={6} className={classes.blockText}>
                <FormattedMessage id="TABLE_HEADER.STATION" />
              </Grid>
              <Grid item xs={6}>{`${iataCode}`}</Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormattedMessage id="ADD_NEW_LOST_AND_FOUND.ULD_ENTRY" />
            </Grid>
            <Grid item xs={6}>
              {`${uldIds?.slice(0, 50).join(', ')}${
                uldIds.length > 50 ? ', ...' : ''
              }`}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
}

LostAndFoundResponsiveTile.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    companyCode: PropTypes.string.isRequired,
    creationDate: PropTypes.sutring,
    iataCode: PropTypes.string.isRequired,
    uldIds: PropTypes.array.isRequired,
  }),
  onRowClick: PropTypes.func,
};

export default LostAndFoundResponsiveTile;
