import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage, FormattedDate } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import StockUldShapedBox from '../stockUldShapedBox';
import MenuButton from '../menuButton';
import TrashIcon from '../../icons/trash';
import PencilIcon from '../../icons/pencil';
import ChatIcon from '../../icons/chat';
import { withSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  stockText: {
    color: theme.palette.secondary.white,
    fontWeight: '600',
    width: '35px',
    padding: '8px 0',
    paddingLeft: '10px',
    display: 'flex',
  },
  stockTile: {
    fontWeight: '600',
    color: theme.palette.primary.chambray,
  },
  stock: {
    display: 'inline-flex',
    alignContent: 'flex-start',
    width: '20px',
    height: '100%',
    backgroundColor: theme.palette.secondary.white,
  },
  importantStock: {
    display: 'inline-flex',
    alignContent: 'flex-start',
    width: '20px',
    height: '101%',
    margin: '-2px 0 -2px 0',
    backgroundColor: theme.palette.secondary.orangePeel,
  },
  leasingStock: {
    display: 'inline-flex',
    alignContent: 'flex-start',
    width: '20px',
    height: '101%',
    margin: '-2px 0 -2px 0',
    backgroundColor: theme.palette.primary.light,
  },
  emergency: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '0.25rem',
  },
  container: {
    justify: 'center',
  },
  containerWithPadding: {
    justify: 'center',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  gridItem: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'baseline',
    fontSize: '0.9rem',
  },
  spacerTop: {
    paddingTop: '1.5rem',
  },
  spacerBottom: {
    paddingBottom: '1.5rem',
  },
  cellPadding: {
    paddingRight: '5px',
    paddingTop: '10px',
    textTransform: 'none',
  },
  topPadding: {
    padding: '2rem 0.5rem 0 0.5rem',
  },
  textEnd: {
    textAlign: 'end',
  },
  textSize: {
    fontSize: '0.8rem',
  },
}));

export function StockTile({
  row,
  isAirportStocks,
  deleteFunc,
  editFunc,
  detailsFunc,
}) {
  const classes = useStyles();

  const isImportant = row.important;

  const isLeasing = row.leasing;

  const containerJustify = 'center';

  return (
    <Grid container justify="center">
      <Grid item xs={1}>
        <div
          className={
            isLeasing
              ? classes.leasingStock
              : isImportant
              ? classes.importantStock
              : classes.stockTile
          }
        ></div>
      </Grid>
      <Grid item xs={11} className={classes.topPadding}>
        <Grid container spacing={2} className={classes.textSize}>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6}>
                <FormattedMessage id="TABLE_HEADER.AMOUNT" />:
              </Grid>
              <Grid item xs={6}>
                <StockUldShapedBox width="100px" amount={row.amount} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {isAirportStocks && (
              <Grid container>
                <Grid item xs={6}>
                  <FormattedMessage id="BASE.COMPANY" />:
                </Grid>
                <Grid item xs={6} className={classes.textEnd}>
                  {row.companyCode}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6}>
                <FormattedMessage id="TABLE_HEADER.STATION" />:
              </Grid>
              <Grid item xs={6} className={classes.textEnd}>
                {row.iataCode}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={6}>
                <FormattedMessage id="TABLE_HEADER.ULD_TYPE" />:
              </Grid>
              <Grid item xs={6} className={classes.textEnd}>
                {row.uldType}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <FormattedMessage id="STOCKTILE.AVAILABLE_FROM" />:
              </Grid>
              <Grid item xs={8} className={classes.textEnd}>
                <FormattedDate
                  value={
                    row.availableFrom
                      ? row.availableFrom.slice(0, 10)
                      : row.availableFrom
                  }
                  year="numeric"
                  month="2-digit"
                  day="2-digit"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4}>
                <FormattedMessage id="STOCKTILE.VALID_UNTIL" />:
              </Grid>
              <Grid item xs={8} className={classes.textEnd}>
                <FormattedDate
                  value={
                    row.validUntil
                      ? row.validUntil.slice(0, 10)
                      : row.validUntil
                  }
                  year="numeric"
                  month="2-digit"
                  day="2-digit"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.containerWithPadding}
            justify={containerJustify}
          >
            {isAirportStocks ? (
              <Grid
                container
                className={classes.container}
                justify={containerJustify}
              >
                <Grid item xs={4} className={classes.gridItem}>
                  <MenuButton img={<ChatIcon />} onClick={() => {}} />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                className={classes.containerWithPadding}
                justify={containerJustify}
              >
                <Grid item xs={4} className={classes.gridItem}>
                  <MenuButton
                    classes={{ img: classes.menuButtonImg }}
                    img={<TrashIcon />}
                    onClick={() => deleteFunc(row.id)}
                  />
                </Grid>
                <Grid item xs={4} className={classes.gridItem}>
                  <MenuButton
                    img={<PencilIcon />}
                    onClick={() => editFunc(row)}
                  />
                </Grid>
                <Grid item xs={4} className={classes.gridItem}>
                  <MenuButton
                    img={<ChatIcon />}
                    onClick={() => detailsFunc(row)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

StockTile.propTypes = {
  row: PropTypes.object.isRequired,
  isAirportStocks: PropTypes.bool,
  deleteFunc: PropTypes.func,
  editFunc: PropTypes.func,
  detailsFunc: PropTypes.func,
};

export default withSnackbar(StockTile);
