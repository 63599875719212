import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { FormattedDate } from 'react-intl';
import { useOnScreen } from '../../utils/customHooks';

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: '1rem',
    color: theme.palette.primary.main,
    textAlign: 'left',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    paddingTop: '1rem',
  },
  date: {
    fontSize: '0.75rem',
    color: theme.palette.primary.lightGrey,
    textAlign: 'left',
  },
  company: {
    fontSize: '1rem',
    color: theme.palette.primary.darkGrey,
    textAlign: 'left',
    paddingBottom: '0.5rem',
  },
  body: {
    fontSize: '1rem',
    color: theme.palette.primary.darkGrey,
    textAlign: 'left',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
  messageInfo: {
    display: 'flex',
    alignItems: 'baseline',
  },
  infoWrapper: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  spacer: {
    width: '1rem',
  },
}));

export default function SingleChatMessage({
  message,
  senderName,
  company,
  communicationPartnerEmail,
  markAsRead,
}) {
  const classes = useStyles();
  const divRef = React.createRef();
  useOnScreen(divRef, () => {
    if (message.unread) {
      markAsRead(message.id);
    }
  });

  let backGroundColor;
  if (message.unread) {
    backGroundColor = '#FFF4E5';
  } else {
    backGroundColor = 'white';
  }

  return (
    <div
      className={classes.infoWrapper}
      style={{ backgroundColor: backGroundColor }}
      ref={divRef}
    >
      <span className={classes.messageInfo}>
        <Typography component={'div'} className={classes.name}>
          {senderName}
        </Typography>
        <div className={classes.spacer} />
        <Typography component={'div'} className={classes.date}>
          {message.timestamp ? (
            <FormattedDate
              month="long"
              day="2-digit"
              hour="numeric"
              minute="numeric"
              value={message.timestamp}
            />
          ) : undefined}
        </Typography>
      </span>
      <Typography component={'div'} className={classes.company}>
        {company}
      </Typography>
      <Typography variant="subtitle1" className={classes.body}>
        {message.message}
      </Typography>
    </div>
  );
}

SingleChatMessage.propTypes = {
  message: PropTypes.object,
  senderName: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
};
