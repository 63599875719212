import React from 'react';
import { FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { useMedia } from '../../utils/customHooks';

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  subTitleStyle: {
    fontSize: '3rem',
    color: theme.palette.primary.light,
  },
  save: {
    fontSize: '1.625rem',
    color: theme.palette.primary.dark,
    textAlign: 'left',
    textTransform: 'uppercase',
    fontWeight: '600',
  },
  text: {
    margin: '10px 0',
    fontSize: '1.125rem',
    color: theme.palette.primary.dark,
  },
}));

export default function BenefitsContainer() {
  const classes = styles();

  const { padding, marginTop } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        padding: '0',
        marginTop: '40px',
      },
    ],
    {
      padding: 'unset',
    }
  );

  return (
    <div className={classes.root} style={{ padding: padding, marginTop }}>
      <Grid container spacing={5} justify="center">
        <Grid item xs={12} md={3}>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <div className={classes.save}>
              <FormattedMessage id="HOME.BENEFITS_TITLE" />
            </div>
            <div className={classes.subTitleStyle}>
              <FormattedMessage id="HOME.BENEFITS_SUB_TITLE_WORRIES" />
            </div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <div className={classes.text}>
              <FormattedMessage id="HOME.BENEFITS_WORRIES_TEXT" />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <div className={classes.save}>
              <FormattedMessage id="HOME.BENEFITS_TITLE" />
            </div>
            <div className={classes.subTitleStyle}>
              <FormattedMessage id="HOME.BENEFITS_SUB_TITLE_TIME" />
            </div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <div className={classes.text}>
              <FormattedMessage id="HOME.BENEFITS_TIME_TEXT" />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <div className={classes.save}>
              <FormattedMessage id="HOME.BENEFITS_TITLE" />
            </div>
            <div className={classes.subTitleStyle}>
              {' '}
              CO<sub>2</sub>{' '}
              <FormattedMessage id="HOME.BENEFITS_SUB_TITLE_FUEL" />{' '}
            </div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <div style={{ margin: '0px' }} className={classes.text}>
              <FormattedMessage id="HOME.BENEFITS_FUEL_TEXT" />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <div className={classes.save}>
              <FormattedMessage id="HOME.BENEFITS_TITLE" />
            </div>
            <div className={classes.subTitleStyle}>
              <FormattedMessage id="HOME:BENEFITS_SUB_TITLE_CAPACITIES" />
            </div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <div className={classes.text}>
              <FormattedMessage id="HOME.BENEFITS_CAPACITIES_TEXT" />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
