import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  FormControl,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import SingleSelect from '../searchSelect/singleSelect';

import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const useStyles = makeStyles((theme) => ({
  subheader: {
    lineHeight: 2.6,
    color: theme.palette.primary.chambray,
  },
  saluation: {
    lineHeight: 2.6,
  },
  pushBetweenGridItems: {
    '@media (min-width: 960px)': {
      '& > div:not(:last-child)': {
        paddingRight: '0.5rem',
      },
    },
  },
  editor: {
    zIndex: 1,
  },
}));

export function ReminderForm({
  title,
  message,
  firstExecutionTime,
  firstExecutionDate,
  audience,
  interval,
  intervalTimeUnit,
  intl,
}) {
  const classes = useStyles();

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={12}>
        <FormControl fullWidth>
          <TextField
            required
            id="input-title"
            label={<FormattedMessage id="REMINDER.TITLE" />}
            type="text"
            margin="normal"
            value={title.value}
            onChange={(event) => title.onChange(event.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="h6" className={classes.subheader}>
          <FormattedMessage id="REMINDER.MESSAGE" />
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth className={classes.editor}>
          <Typography className={classes.saluation}>
            <FormattedMessage id="REMINDER.USER_SALUTATION" />
          </Typography>
          <CKEditor
            editor={DecoupledEditor}
            data={message.value}
            required
            config={{
              toolbar: [
                'Heading',
                'Paragraph',
                'bold',
                'italic',
                'Underline',
                'Link',
              ],
              placeholder: intl.formatMessage({ id: 'REMINDER.MESSAGE_BODY' }),
            }}
            onInit={(editor) => {
              //show editor toolbar
              editor.ui
                .getEditableElement()
                .parentElement.insertBefore(
                  editor.ui.view.toolbar.element,
                  editor.ui.getEditableElement()
                );
            }}
            onChange={(event, editor) => message.onChange(editor.getData())}
          />
        </FormControl>
        <br />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="h6" className={classes.subheader}>
          <FormattedMessage id="REMINDER.INTERVAL" />
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid
          container
          justify="center"
          className={classes.pushBetweenGridItems}
        >
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                id="input-interval"
                label={<FormattedMessage id="REMINDER.INTERVAL_INPUT" />}
                type="number"
                autoComplete="off"
                InputProps={{
                  inputProps: { min: 1 },
                }}
                value={interval.value}
                onChange={(event) => interval.onChange(event.target.value)}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <SingleSelect
              id="select-intervalUnit"
              label={<FormattedMessage id="REMINDER.INTERVAL_UNIT" />}
              selected={{ value: intervalTimeUnit.value }}
              setSelected={intervalTimeUnit.onChange}
              showEmpty={true}
              data={intervalTimeUnit.values.map((item) => ({
                value: item,
                label: item,
              }))}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                id="input-firstExecutionDate"
                label={<FormattedMessage id="REMINDER.STARTING_FROM" />}
                type="date"
                value={firstExecutionDate.value}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) =>
                  firstExecutionDate.onChange(event.target.value)
                }
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                id="input-firstExecutionTime"
                label={<FormattedMessage id="REMINDER.STARTING_FROM" />}
                type="time"
                value={firstExecutionTime.value}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) =>
                  firstExecutionTime.onChange(event.target.value)
                }
                required
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <SingleSelect
          id="select-audience"
          label={<FormattedMessage id="REMINDER.AUDIENCE" />}
          selected={{ value: audience.value }}
          setSelected={audience.onChange}
          showEmpty={true}
          data={audience.values.map((item) => ({
            value: item,
            label: item,
          }))}
          required={true}
        />
      </Grid>
      {firstExecutionTime.value &&
        interval.value &&
        intervalTimeUnit.value &&
        audience.value && (
          <Fragment>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" className={classes.subheader}>
                <FormattedMessage id="REMINDER.DESCRIPTION" />
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormattedMessage
                id="REMINDER.INTERVAL_MESSAGE"
                values={{
                  intervalUnit: (
                    <FormattedMessage
                      id={`REMINDER.INTERVAL_UNIT.${intervalTimeUnit.value}`}
                      values={{
                        interval: interval.value,
                      }}
                    />
                  ),
                  firstExecutionTime: new Date(
                    firstExecutionDate.value +
                      'T' +
                      firstExecutionTime.value +
                      ':00.000'
                  ),
                  audience: (
                    <FormattedMessage
                      id={`REMINDER.AUDIENCE.${audience.value}`}
                    />
                  ),
                }}
              />
            </Grid>
          </Fragment>
        )}
    </Grid>
  );
}

ReminderForm.propTypes = {
  title: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }),
  message: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }),
  firstExecutionTime: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }),
  firstExecutionDate: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }),
  audience: PropTypes.shape({
    value: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  interval: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  }),
  intervalTimeUnit: PropTypes.shape({
    value: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  intl: intlShape,
};

export default injectIntl(ReminderForm);
