import React from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    overflow: 'initial',
    boxShadow: 'none',
    '&::before': {
      content: "''",
      position: 'absolute',
      bottom: '-20px',
      right: 'calc(50% + 10px)',
      left: 'calc(50% - 10px)',
      borderBottom: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
      borderTop: `10px solid ${theme.palette.primary.cornflowerBlue}`,
    },
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.primary.cornflowerBlue}`,
    borderRight: `1px solid ${theme.palette.primary.cornflowerBlue}`,
    borderLeft: `1px solid ${theme.palette.primary.cornflowerBlue}`,
    borderTop: `1px solid ${theme.palette.primary.cornflowerBlue}`,
    borderRadius: '0px',
  },
  mainText: {
    textColor: theme.palette.primary.blueZodiac,
    fontWeight: 600,
    margin: '0 1rem',
    fontSize: '1rem',
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center',
  },
  smallInfo: {
    textColor: theme.palette.primary.blueZodiac,
    fontSize: '1rem',
    margin: '1em',
    marginRight: '0.5em',
    fontFamily: theme.typography.fontFamily,
  },
  childMargin: {
    marginTop: '10px',
  },
}));

export default function HoverInfo({
  infoFormattedMessageId,
  infoText,
  mainText,
  children,
  disabled,
  disableMargin,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const childMarginDisabled = disableMargin ? '0px' : '10px';

  function handlePopoverOpen(event) {
    !disabled && setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose() {
    !disabled && setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <div
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup={disabled ? 'false' : 'true'}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <div style={{ marginTop: childMarginDisabled }}>{children}</div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <span>
          {infoFormattedMessageId ? (
            <span className={classes.smallInfo}>
              <FormattedMessage id={infoFormattedMessageId} />
              {infoText}
            </span>
          ) : null}
          <span className={classes.mainText}>{mainText}</span>
        </span>
      </Popover>
    </div>
  );
}

HoverInfo.defaultProps = {
  infoText: ' ',
  mainText: ' ',
};

HoverInfo.propTypes = {
  hoverText: PropTypes.string,
  infoText: PropTypes.string,
};
