import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import Notification from '../icons/notification';
import { useMedia } from '../utils/customHooks';
import { Routes } from '../constants';
import NotificationMessage from '../model/notificationMessage';

const styles = (theme) => ({
  button: {
    padding: 0,
    '&:hover': {
      color: theme.palette.primary.slateGray,
      background: 'none',
    },
    transition: 'color 200ms ease-in-out',
    color: theme.palette.primary.blueZodiac,
    fontSize: '1rem',
    textTransform: 'none',
    paddingLeft: '1.5rem',
  },
  badge: {
    backgroundColor: theme.palette.secondary.orangePeel,
    marginRight: '0.8em',
  },
  labelIcon: {
    paddingRight: '0.5rem',
  },
  mobile: {
    transform: 'none !important',
    top: 'unset !important',
  },
});

export function MessageNotificationButton(props) {
  const handleOnClickNotification = () => {
    props.history.push(Routes.APP.NOTIFICATIONCENTER.INDEX);
  };

  const { classes, notifications } = props;

  const { isMobile } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: false,
      },
    ],
    {
      isMobile: true,
    }
  );
  return (
    <React.Fragment>
      <Button
        className={classes.button}
        onClick={handleOnClickNotification}
        disableFocusRipple
        disableRipple
      >
        <Badge
          badgeContent={
            notifications.filter(
              (notification) =>
                notification.isRead == false &&
                notification.details.stockIsDeleted !== true
            ).length
          }
          color="primary"
          classes={{ badge: classes.badge, root: classes.labelIcon }}
        >
          <Notification />
        </Badge>
        {!isMobile && <FormattedMessage id="BASE.NOTIFICATION" />}
      </Button>
    </React.Fragment>
  );
}

MessageNotificationButton.defaultProps = {
  notifications: [],
};

MessageNotificationButton.propTypes = {
  classes: PropTypes.object.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.instanceOf(NotificationMessage))
    .isRequired,
};

export default withRouter(withStyles(styles)(MessageNotificationButton));
