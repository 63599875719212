import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import LostAndFoundTableHeader from './lostAndFoundTableHeader';
import LostAndFoundTableBody from './lostAndFoundTableBody';
import { injectIntl, intlShape } from 'react-intl';
import LostUlds from '../../model/lostUlds';
import FoundUlds from '../../model/foundUlds';
import { getSortedRows } from '../../utils/utils';
import { useMedia } from '../../utils/customHooks';
import List from '@material-ui/core/List';
import LostAndFoundResponsiveTile from './lostAndFoundResponsiveTile';
import Routes from '../../constants/routes';

export function LostAndFoundTableOverview({
  standardSort = 'creationDate',
  intl,
  rows,
  headRows,
  history,
}) {
  const { isMobile } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: false,
      },
    ],
    {
      isMobile: true,
    }
  );

  const [isDescending, setDescending] = React.useState(true);
  const [orderBy, setOrderBy] = React.useState(standardSort);

  function handleRequestSort(event, property) {
    setDescending(orderBy === property && !isDescending);
    setOrderBy(property);
  }

  const onRowClick = (rowId) => {
    history.push(Routes.APP.LOST_AND_FOUND.DETAILS + '/' + rowId);
  };

  if (!isMobile)
    return (
      <Table>
        <LostAndFoundTableHeader
          headRows={headRows(intl.formatMessage)}
          isDescending={isDescending}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <LostAndFoundTableBody
          rows={getSortedRows([...rows], orderBy, isDescending)}
          onClick={onRowClick}
        />
      </Table>
    );
  else
    return (
      <List>
        {rows.map((row) => (
          <LostAndFoundResponsiveTile
            onRowClick={onRowClick}
            key={row.id}
            row={row}
          />
        ))}
      </List>
    );
}

LostAndFoundTableOverview.propTypes = {
  standardSort: PropTypes.string,
  intl: intlShape,
  rows: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.instanceOf(LostUlds),
      PropTypes.instanceOf(FoundUlds),
    ]).isRequired
  ),
  headRows: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(injectIntl(LostAndFoundTableOverview));
