import React from 'react';
import PropTypes from 'prop-types';
import { useMedia } from '../../utils/customHooks';
import { Routes } from '../../constants';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import LostAndFoundTabRoutes from './lostAndFoundTabRoutes';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: `0.3rem solid ${theme.palette.primary.linkWater25}`,
  },
  tabsIndicator: {
    backgroundColor: theme.palette.secondary.main,
    height: '6px',
  },
  labelIcon: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 600,
    color: theme.palette.primary.dark,
    '& > svg': {
      marginRight: '1em',
    },
  },
  height: {
    marginTop: '3rem',
  },
}));

const getLostAndFoundTabPathname = (pathname) => {
  const tabArray = {
    [Routes.APP.INDEX]: Routes.APP.INDEX,
    [Routes.APP.LOST_AND_FOUND.INDEX]: Routes.APP.LOST_AND_FOUND.FOUND,
    [Routes.APP.LOST_AND_FOUND.DETAILS.FOUND]: Routes.APP.LOST_AND_FOUND.FOUND,
    [Routes.APP.LOST_AND_FOUND.DETAILS.LOST]: Routes.APP.LOST_AND_FOUND.LOST,
    [Routes.APP.LOST_AND_FOUND.FOUND]: Routes.APP.LOST_AND_FOUND.FOUND,
    [Routes.APP.LOST_AND_FOUND.LOST]: Routes.APP.LOST_AND_FOUND.LOST,
  };

  // if path has an id or similar
  if (Object.keys(tabArray).indexOf(pathname) < 0) {
    let pathWithoutLastSlash = pathname.substr(0, pathname.lastIndexOf('/'));
    return tabArray[pathWithoutLastSlash];
  }

  return tabArray[pathname];
};

export function LostAndFoundTab({ location }) {
  const classes = useStyles();

  const { variant, scrollButtons } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        variant: 'standard',
        scrollButtons: 'auto',
      },
    ],
    {
      variant: 'scrollable',
      scrollButtons: 'on',
    }
  );

  const tabStyle = {
    root: classes.tabRoot,
    selected: classes.tabSelected,
    wrapper: classes.labelIcon,
  };
  return (
    <div className={classes.height}>
      <Tabs
        value={getLostAndFoundTabPathname(location.pathname)}
        classes={{
          root: classes.root,
          indicator: classes.tabsIndicator,
          scrollButtons: classes.scrollButtons,
        }}
        variant={variant}
        scrollButtons={scrollButtons}
      >
        <Tab
          classes={tabStyle}
          label={<FormattedMessage id="LOST_AND_FOUND.FOUND" />}
          to={Routes.APP.LOST_AND_FOUND.FOUND}
          value={Routes.APP.LOST_AND_FOUND.FOUND}
          component={Link}
        />
        <Tab
          classes={tabStyle}
          label={<FormattedMessage id="LOST_AND_FOUND.LOST" />}
          to={Routes.APP.LOST_AND_FOUND.LOST}
          value={Routes.APP.LOST_AND_FOUND.LOST}
          component={Link}
        />
      </Tabs>
      <LostAndFoundTabRoutes />
    </div>
  );
}

LostAndFoundTab.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(LostAndFoundTab);
