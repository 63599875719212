import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import { injectIntl, intlShape } from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export function SimpleSelect(props) {
  const menuItems = props.menuItems;

  function handleChange(event) {
    props.setValue(event.target.value);
  }

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={`select-simple-${props.name}`}>
        {props.label}
      </InputLabel>
      <Select
        value={props.selectedValue}
        onChange={handleChange}
        id={`select-simple-${props.name}`}
        native
        required={props.required}
      >
        <option value="" />
        {props.otherItem ? (
          <option key={props.otherItem} value="notListed">
            {' '}
            {props.intl.formatMessage({ id: props.otherItem })}{' '}
          </option>
        ) : null}
        {menuItems.map(props.menuItemsFunction)}
      </Select>
    </FormControl>
  );
}

SimpleSelect.defaultProps = {
  menuItems: [],
  menuItemsFunction: (item) =>
    item === 'notListed' ? null : (
      <option key={item} value={item}>
        {item}
      </option>
    ),
};

SimpleSelect.propTypes = {
  intl: intlShape.isRequired,
  name: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.string),
  selectedValue: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  menuItemsFunction: PropTypes.func,
};

export default injectIntl(SimpleSelect);
