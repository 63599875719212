import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { getSpecialStockFromPropsOrFromBackend } from '../../../actions/stock';
import HeadlineBox from '../../../components/headlineBox';
import BookNowForm from '../../../components/booking/bookNowForm';
import { getAirports } from '../../../actions/airports';
import { addStockBooking } from '../../../actions/booking';
import Airport from '../../../model/airport';
import { Routes } from '../../../constants';

const styles = (theme) => ({
  newStockHeadlineDiv: {
    fontSize: '1.5rem',
    fontWeight: '700',
    marginBottom: '2em',
    marginTop: '1.5em',
    padding: '0',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'unset',
    textTransform: 'none',
    color: theme.palette.primary.chambray,
  },
  root: {
    display: 'flex',
    height: '50em',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerForBooking: {
    display: 'flex',
    height: '100%',
    paddingRight: '2rem',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

export class BookStock extends Component {
  state = {
    stock: {},
    loading: true,
  };

  componentDidMount = async () => {
    const { specialStock, match, token, history, enqueueSnackbar } = this.props;
    this.props.getAirports(token);
    const stock = await getSpecialStockFromPropsOrFromBackend(
      specialStock,
      match.params.id,
      token,
      enqueueSnackbar,
      history
    );
    if (stock.leasing) {
      history.push(`${Routes.APP.AIRPORT_STOCKS.LEASING.BOOK_NOW}/${stock.id}`);
    } else {
      this.setState({ stock, loading: false });
    }
  };

  stockBookingQuery = (stockBookingQuery) => {
    const { token, enqueueSnackbar, history } = this.props;

    const datesValid = stockBookingQuery.fromWhen <= stockBookingQuery.tillWhen;

    const amountValid =
      Math.abs(this.state.stock.amount) -
        Math.abs(stockBookingQuery.amountToBook) >=
      0;

    if (!datesValid)
      return enqueueSnackbar(<FormattedMessage id="BOOKNOW.DATES_INVALID" />, {
        variant: 'error',
      });

    if (!amountValid)
      return enqueueSnackbar(<FormattedMessage id="BOOKNOW.AMOUNT_INVALID" />, {
        variant: 'error',
      });

    if (datesValid && amountValid)
      addStockBooking(token, stockBookingQuery, enqueueSnackbar, history.push);
  };

  render() {
    const { loading, stock } = this.state;
    const { classes, history, airports } = this.props;

    const hasAirports =
      stock.destinationAirports &&
      Object.values(stock.destinationAirports).length > 0;

    return (
      <Fragment>
        <HeadlineBox headLineText="BOOKNOW.HEADLINE" />
        {loading ? (
          <div className={classes.root}>
            <div className={classes.loadingInfo}>
              <CircularProgress />
              <FormattedMessage id="BOOKNOW.LOADING" />
            </div>
          </div>
        ) : (
          <Fragment>
            <div className={`MuiTab-root ${classes.newStockHeadlineDiv}`}>
              <FormattedMessage id="BOOKNOW.SUBTITLE" />
            </div>
            <BookNowForm
              stock={stock}
              airports={hasAirports ? stock.destinationAirports : airports}
              handleSubmit={this.stockBookingQuery}
              cancelButtonFunction={() => history.goBack()}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

BookStock.propTypes = {
  specialStock: PropTypes.shape({
    amount: PropTypes.number,
    phone: PropTypes.string.isRequired,
    contactPerson: PropTypes.string.isRequired,
    iataCode: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    uldType: PropTypes.string.isRequired,
    validUntil: PropTypes.string.isRequired,
    availableFrom: PropTypes.string.isRequired,
    destinationIataCode: PropTypes.arrayOf(PropTypes.string),
  }),
  token: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  getAirports: PropTypes.func.isRequired,
  airports: PropTypes.arrayOf(PropTypes.instanceOf(Airport)).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    specialStock: state.stocks.specialStock,
    token: state.auth.token,
    airports: state.airports,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAirports: (token) => dispatch(getAirports(token)),
});

export default withSnackbar(
  withRouter(
    withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BookStock))
  )
);
