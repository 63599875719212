import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withSnackbar } from 'notistack';

import EditProfileForm from '../components/editProfileForm';
import HeadlineBox from '../components/headlineBox';
import { getAirports } from '../actions/airports';
import { updateUser, getUser } from '../actions/user';
import { withRouter } from 'react-router-dom';
import routes from '../constants/routes';
import { validatePhone /*validateEmail*/ } from '../utils/utils';
import UnchangeableProfileInformation from '../components/unchangeableProfileInformation';
import { validatePassword } from '../utils/validators';

export class EditProfile extends Component {
  componentDidMount() {
    this.props.getAirports(this.props.token);
    this.props.getUser(this.props.token);
  }

  componentWillUnmount() {
    this.props.getUser(this.props.token);
  }

  handleSubmit = ({
    firstName,
    lastName,
    phoneNumber,
    place,
    reachability,
    position,
    groupMail,
    iataCode,
    password,
    confirmPassword,
    notificationStockMatch,
    notificationScheduledReminder,
    notificationRest,
  }) => {
    let validPhone = false;

    if (phoneNumber && phoneNumber.trim()) {
      validPhone = validatePhone(phoneNumber);
    } else {
      validPhone = true;
    }

    let validPassword = false;

    if (password || confirmPassword) {
      validPassword =
        password === confirmPassword &&
        validatePassword(
          password,
          this.props.enqueueSnackbar,
          this.props.closeSnackbar
        );
    } else {
      validPassword = true;
    }

    if (validPhone && validPassword) {
      const userObject = {
        firstName,
        lastName,
        phoneNumber,
        place,
        reachability,
        position,
        groupMail,
        iataCode,
        password,
        notificationRest,
        notificationStockMatch,
        notificationScheduledReminder,
      };

      updateUser(userObject, this.props.token)
        .then(() =>
          this.props.enqueueSnackbar(
            <FormattedMessage id="EDIT_PROFILE.SUCCESS" />,
            { variant: 'success' }
          )
        )
        .then(() => this.props.history.push(routes.APP.HOME.INDEX))
        .catch(() =>
          this.props.enqueueSnackbar(
            <FormattedMessage id="EDIT_PROFILE.FAILED" />,
            { variant: 'warning' }
          )
        );
      this.setState({
        user: {
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          place: place,
          reachability: reachability,
          position: position,
          groupMail: groupMail,
          iataCode: iataCode,
          notificationStockMatch: notificationStockMatch,
          notificationScheduledReminder: notificationScheduledReminder,
          notificationRest: notificationRest,
        },
      });
    } else {
      let id = '';
      if (password !== confirmPassword) id = 'FORM_PASSWORDS_DO_NOT_MATCH';
      else if (!validPhone) id = 'FORM_PLEASE_FILL_IN_A_VALID_PHONE_NUMBER';
      //else if (!validGroupMail) id = "FORM_PLEASE_FILL_IN_A_VALID_EMAIL_ADRESS";
      else id = 'EDIT_PROFILE.FAILED';

      this.props.enqueueSnackbar(<FormattedMessage id={id} />, {
        variant: 'warning',
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <HeadlineBox headLineText="YOUR_PROFILE" />
        <UnchangeableProfileInformation
          userUnchangeableInforations={this.props.userUnchangeableInforations}
        />
        <EditProfileForm
          handleSubmit={this.handleSubmit}
          user={this.props.user}
          airports={this.props.airports}
          cancelButtonFunction={this.props.history.goBack}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: {
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    phoneNumber: state.user.phone,
    place: state.user.place,
    reachability: state.user.reachability,
    position: state.user.position,
    groupMail: state.user.groupMail,
    iataCode: state.user.iataCode,
    notificationStockMatch: state.user.notificationStockMatch,
    notificationScheduledReminder: state.user.notificationScheduledReminder,
    notificationRest: state.user.notificationRest,
  },
  userUnchangeableInforations: {
    email: state.user.email,
    company: {
      name: state.user.company.name,
      code: state.user.company.code,
    },
  },
  airports: state.airports,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  getAirports: (token) => dispatch(getAirports(token)),
  getUser: (token) => {
    dispatch(getUser(token));
  },
  dispatch,
});

EditProfile.propTypes = {
  userUnchangeableInforations: PropTypes.shape({
    email: PropTypes.string.isRequired,
    company: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }).isRequired,
  }),
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    place: PropTypes.string,
    reachability: PropTypes.string,
    groupMail: PropTypes.string,
    iataCode: PropTypes.arrayOf(PropTypes.string),
    notificationStockMatch: PropTypes.bool,
    notificationScheduledReminder: PropTypes.bool,
    notificationRest: PropTypes.bool,
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  getUser: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  getAirports: PropTypes.func.isRequired,
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      iataCode: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  token: PropTypes.string,
};

export default withSnackbar(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProfile))
);
