export const getFromServer = (path, token) => {
  let auth = {};
  if (token)
    auth = {
      Authorization: 'Bearer ' + token,
    };

  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      ...auth,
    },
  };

  return fetch(process.env.REACT_APP_BACKEND_SERVER + path, options);
};

export const postToServer = (path, payload, token) => {
  return postToServerPlaintext(path, JSON.stringify(payload), token);
};

export const postToServerPlaintext = (
  path,
  payload,
  token,
  contentType = 'application/json'
) => {
  let auth = {};
  if (token)
    auth = {
      Authorization: 'Bearer ' + token,
    };

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
      ...auth,
    },
    body: payload,
  };

  return fetch(process.env.REACT_APP_BACKEND_SERVER + path, options);
};

export const putToServerPlaintext = (
  path,
  payload,
  token,
  contentType = 'application/json'
) => {
  let auth = {};

  if (token)
    auth = {
      Authorization: 'Bearer ' + token,
    };

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': contentType,
      Accept: 'application/json',
      ...auth,
    },
    body: payload,
  };
  return fetch(process.env.REACT_APP_BACKEND_SERVER + path, options);
};

export const uploadToServer = (path, payload, token) => {
  let auth = {};
  if (token)
    auth = {
      Authorization: 'Bearer ' + token,
    };

  const options = {
    method: 'POST',
    headers: { Accept: 'application/json', ...auth },
    body: payload,
  };

  return fetch(process.env.REACT_APP_BACKEND_SERVER + path, options);
};

export const patchFromServer = (path, payload, token) => {
  const stringifiedPayload = JSON.stringify(payload);

  let auth = {};
  if (token)
    auth = {
      Authorization: 'Bearer ' + token,
    };

  const options = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...auth },
    body: stringifiedPayload,
  };

  return fetch(process.env.REACT_APP_BACKEND_SERVER + path, options);
};

export const deleteFromServer = (path, id, token) => {
  let auth = {};
  if (token)
    auth = {
      Authorization: 'Bearer ' + token,
    };

  const options = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...auth },
    body: JSON.stringify(id),
  };

  return fetch(process.env.REACT_APP_BACKEND_SERVER + path, options);
};
