import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { FormattedDate } from 'react-intl';
import LostUlds from '../../model/lostUlds';
import FoundUlds from '../../model/foundUlds';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tableCellStyleUldIds: {
    width: '19rem',
  },
  tableCellStyle: {
    textAlign: 'right',
    paddingRight: '26px',
    maxWidth: '19rem',
  },
  tableCellStyleComments: {
    maxWidth: '19rem',
    textAlign: 'left',
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
export function LostAndFoundTableBody({ rows, onClick = () => {} }) {
  const classes = useStyles();
  return (
    <TableBody>
      {rows.map((row, index) => {
        return (
          <TableRow
            hover
            onClick={() => onClick(row.id)}
            key={index}
            classes={{
              hover: classes.hover,
              root: classes.tableCellStyle,
            }}
          >
            <TableCell className={classes.tableCellStyle}>
              {row.companyName}
            </TableCell>
            <TableCell className={classes.tableCellStyle}>
              {row.companyCode}
            </TableCell>
            <TableCell className={classes.tableCellStyleUldIds}>
              {`${row.uldIds?.slice(0, 50).join(', ')}${
                row.uldIds.length > 50 ? ', ...' : ''
              }`}
            </TableCell>
            {row.airport && (
              <TableCell className={classes.tableCellStyle}>
                {row.iataCode}
              </TableCell>
            )}
            <TableCell className={classes.tableCellStyle}>
              <FormattedDate
                year="numeric"
                month="2-digit"
                day="2-digit"
                value={row.creationDate}
              />
            </TableCell>
            <TableCell className={classes.tableCellStyleComments}>
              {row.comment}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}

LostAndFoundTableBody.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.instanceOf(LostUlds) || PropTypes.instanceOf(FoundUlds)
  ).isRequired,
  onClick: PropTypes.func,
};

export default LostAndFoundTableBody;
