import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { useScrollTrigger, Container } from '@material-ui/core';

import { MenuSectionLeft } from './menuSectionLeft';
import { MenuSectionRight } from './menuSectionRight';
import { withRouter } from 'react-router-dom';
import NotificationMessage from '../model/notificationMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'green',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: 'blue',
    },
  },
  sectionMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  appbar: {
    backgroundColor: 'white',
  },
}));

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const Navbar = (props) => {
  const classes = useStyles();
  return (
    <ElevationScroll>
      <AppBar className={classes.appbar}>
        <Toolbar>
          <Container>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-around"
            >
              <MenuSectionLeft isLoggedIn={props.isLoggedIn} />
              <MenuSectionRight
                unread={props.unread}
                isLoggedIn={props.isLoggedIn}
                history={props.history}
                notifications={props.notifications}
              />
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
};

Navbar.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  unread: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.instanceOf(NotificationMessage))
    .isRequired,
};

export default withRouter(Navbar);
