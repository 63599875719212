import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';

import MenuButton from './menuButton';
import Chat from '../icons/chat';
import Account from '../icons/account';
import DialogBox from './dialogBox';
import { Routes } from '../constants';
import { useMedia } from '../utils/customHooks';
import MessageNotificationButton from './messageNotificationButton';
import NotificationMessage from '../model/notificationMessage';

const useStyles = makeStyles((theme) => ({
  link: {
    height: '10px',
    width: '10px',
    margin: '15px 10px',
    backgroundColor: theme.palette.secondary.orangePeel,
    borderRadius: '50%',
    position: 'absolute',
    zIndex: '1000',
  },
}));

export function MenuSectionRight(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [showField, setShowField] = React.useState(true);
  const [selectedValue, setSelectedValue] = React.useState([]);

  function handleClickOpen() {
    setOpen(true);
  }

  const handleOnClickChat = () => {
    props.history.push(Routes.APP.GLOBAL_CHAT.INDEX);
    setShowField(false);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const { showText } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        showText: true,
      },
    ],
    {
      showText: false,
    }
  );

  return (
    <span style={{ alignItems: 'center', marginLeft: 'auto', display: 'flex' }}>
      <Fragment>
        {props.isLoggedIn && (
          <div>
            {props.unread > 0 && showField ? (
              <span className={classes.link} />
            ) : null}
            <MenuButton
              img={<Chat />}
              onClick={handleOnClickChat}
              text={
                showText ? <FormattedMessage id="BASE.GLOBAL_CHAT" /> : null
              }
              minWidth="fit-content"
            />
            <MessageNotificationButton notifications={props.notifications} />
          </div>
        )}
        <MenuButton
          img={<Account />}
          onClick={handleClickOpen}
          text={showText ? <FormattedMessage id="BASE.ACCOUNT" /> : null}
          paddingLeft="1.5rem"
          minWidth="fit-content"
        />
      </Fragment>
      <DialogBox
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </span>
  );
}

MenuSectionRight.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  unread: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.instanceOf(NotificationMessage))
    .isRequired,
};

export default MenuSectionRight;
