export const pruneObjectNullToUndefined = (obj) => {
  let prunedObject = { ...obj };
  Object.getOwnPropertyNames(prunedObject).forEach((property) => {
    if (prunedObject[property] === null) {
      prunedObject[property] = undefined;
    }
  });
  return prunedObject;
};

export const randomString = () =>
  Math.random().toString(36).replace(/^0\./g, '');

export const randomEmail = () => {
  return `${randomString()}@${randomString()}.${randomString().substring(
    0,
    2
  )}`;
};

export const randomIntegerBelow = (max) =>
  typeof max === 'number'
    ? Math.floor(Math.random() * Math.floor(max))
    : Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER));

export const randomInteger = () => randomIntegerBelow(Number.MAX_SAFE_INTEGER);

export const randomFloat = () => randomInteger() + Math.random();

export const randomPLZ = () => {
  let plz = Math.random().toString(10).substring(2, 7);
  let plzRegex = /\d[1-9]\d{3}/;
  let reservedPlz = /(05|43|62)\d{3}/;
  return plzRegex.test(plz) && !reservedPlz.test(plz) ? plz : randomPLZ();
};

export const randomDate = () => new Date(8640000000000000 * Math.random()); //8640000000000000 is the maximum range per spec

export const randomArray = (
  length = randomIntegerBelow(10),
  valueCreator = randomInteger
) => Array.from({ length }, valueCreator);

export const randomBoolean = () => Math.random() >= 0.5;

export const relativeComplementaryArray = (A, B) => {
  if (!(A instanceof Array && B instanceof Array)) {
    return [];
  }
  return A.filter((leftItem) => !B.includes(leftItem));
};

export const reduceValidators = (validators) => (value) =>
  validators
    .map((validation) => validation(value))
    .reduce((accumulator, currentValue) => accumulator && currentValue);

/**
 * returns a random value from a given array
 * @param {Any} array
 */
export const randomValueFromArray = (array) =>
  array[randomIntegerBelow(array.length)];

export const calculateDistance = (
  { latitude: lat1, longitude: lon1 },
  { latitude: lat2, longitude: lon2 }
) => {
  const earthRadius = 6371e3;
  const lat1Radians = toRadians(lat1);
  const lat2Radians = toRadians(lat2);
  const diffLat2Lat1Radians = toRadians(lat2 - lat1);
  const diffLon2Lon1Radians = toRadians(lon2 - lon1);

  const a =
    Math.sin(diffLat2Lat1Radians / 2) * Math.sin(diffLat2Lat1Radians / 2) +
    Math.cos(lat1Radians) *
      Math.cos(lat2Radians) *
      Math.sin(diffLon2Lon1Radians / 2) *
      Math.sin(diffLon2Lon1Radians / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return Math.round(earthRadius * c);
};

/** validates a given email address with rfc5322 standard */
export const validateEmail = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

/** validates a given phone number */
export const validatePhone = (phone) => {
  var re = /^((\+|00)[\d]{1,3}(-| ){0,1}[(]{0,1}[\d- ]{0,40}[)]{0,1}){0,1}$/;
  return re.test(phone);
};

/** Converts numeric degrees to radians */
function toRadians(value) {
  return (value * Math.PI) / 180;
}

export const getSortedRows = (rows, orderBy, isDescending) => {
  const sortedRows = rows.sort((a, b) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  });
  if (!isDescending) return sortedRows.reverse();
  return sortedRows;
};
