import React from 'react';
import { FormattedMessage } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { useMedia } from '../../utils/customHooks';

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '40px',
  },
  headLine: {
    color: theme.palette.primary.dark,
    fontSize: '1.625rem',
    textTransform: 'uppercase',
    fontWeight: '600',
  },
  numberStyle: {
    fontSize: '6.750rem',
    color: theme.palette.primary.light,
  },
  text: {
    margin: '10px 0 10px',
    fontSize: '1.125rem',
    color: theme.palette.primary.dark,
  },
}));

export default function InfoSlideshowContainer() {
  const classes = styles();

  const { textAlign } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        textAlign: 'left',
      },
    ],
    {
      textAlign: 'center',
    }
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={5} justify="center">
        <Grid item xs={12} md={3}>
          <Grid item xs={12} style={{ textAlign: textAlign }}>
            <div className={classes.numberStyle}> 100 </div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: textAlign }}>
            <Typography
              className={classes.headLine}
              variant="h6"
              id="tableTitle"
            >
              <FormattedMessage id="HOME.INFO_SLIDESHOW_CONTAINER_USERS" />
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: textAlign }}>
            <div className={classes.text}>
              <FormattedMessage id="HOME.INFO_SLIDESHOW_CONTAINER_USERS_TEXT" />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid item xs={12} style={{ textAlign: textAlign }}>
            <div className={classes.numberStyle}> 50 </div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: textAlign }}>
            <Typography
              className={classes.headLine}
              variant="h6"
              id="tableTitle"
            >
              <FormattedMessage id="HOME.INFO_SLIDESHOW_CONTAINER_AIRLINES" />
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: textAlign }}>
            <div className={classes.text}>
              <FormattedMessage id="HOME.INFO_SLIDESHOW_CONTAINER_AIRLINES_TEXT" />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid item xs={12} style={{ textAlign: textAlign }}>
            <div className={classes.numberStyle}> 9000 </div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: textAlign }}>
            <Typography
              className={classes.headLine}
              variant="h6"
              id="tableTitle"
            >
              <FormattedMessage id="HOME.INFO_SLIDESHOW_CONTAINER_ULD" />
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: textAlign }}>
            <div className={classes.text}>
              <FormattedMessage id="HOME.INFO_SLIDESHOW_CONTAINER_ULD_TEXT" />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
