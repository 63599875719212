export default class LostAndFoundEntry {
  constructor({
    id,
    report,
    uldIds,
    comment,
    user,
    airport,
    creationDate,
    comments,
  }) {
    this.id = id;
    this.report = report;
    this.uldIds = uldIds;
    this.comment = comment;
    this.companyName = user.company.name;
    this.companyCode = user.company.code;
    this.userName = user.firstName + ' ' + user.lastName;
    this.airport = airport?.name;
    this.iataCode = airport?.iataCode;
    this.creationDate = creationDate;
    this.messages = comments;
  }
}
