import { Actions } from '../constants';
import FoundUlds from '../model/foundUlds';
import LostUlds from '../model/lostUlds';
import LostAndFoundEntry from '../model/lostAndFoundEntry';

const initialState = {
  lostUlds: [],
  foundUlds: [],
  filterString: '',
  lostAndFoundEntry: undefined,
};

export default function lostAndFound(state = initialState, action) {
  switch (action.type) {
    case Actions.LOST_AND_FOUND.ADD_FOUND:
      return {
        ...state,
        foundUlds: action.payload.map((foundUlds) => new FoundUlds(foundUlds)),
      };
    case Actions.LOST_AND_FOUND.ADD_LOST:
      return {
        ...state,
        lostUlds: action.payload.map((lostUlds) => new LostUlds(lostUlds)),
      };
    case Actions.LOST_AND_FOUND.ADD_FILTER_STRING:
      return {
        ...state,
        filterString: action.payload,
      };
    case Actions.LOST_AND_FOUND.GET_LOST_AND_FOUND_ENTRY:
      return {
        ...state,
        lostAndFoundEntry: new LostAndFoundEntry(action.payload),
      };
    default: {
      return state;
    }
  }
}
