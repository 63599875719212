import React, { useEffect } from 'react';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import PropTypes from 'prop-types';
import { Grid, makeStyles, List, ListItem } from '@material-ui/core';
import LostAndFoundMessage from '../../model/lostAndFoundMessage';
import MessageInputContainer from '../messageInputContainer';
import { gridItemHeadline } from '../addNewStock/cssStyles';

const useStyles = makeStyles((theme) => ({
  gridItemHeadline: gridItemHeadline(theme),
  contactCell: {
    color: theme.palette.primary.chambray,
    fontSize: '1rem',
    fontWeight: '700',
    marginRight: '1%',
  },
  companyCell: {
    fontSize: '1rem',
    fontWeight: '700',
  },
  timeCell: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '0.9rem',
  },
  chat: {
    maxHeight: '400px',
    overflowY: 'auto',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  borderStyle: {
    borderBottom: '1px solid #000000',
  },
  messageStyle: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
}));

export default function LostAndFoundChat({
  lostAndFoundMessages,
  sendChatMessage,
  chatContainerRef,
}) {
  const classes = useStyles();

  useEffect(() => {
    scrollLastMessagesIntoView();
  }, [lostAndFoundMessages]);

  const scrollLastMessagesIntoView = () => {
    requestAnimationFrame(() => {
      if (chatContainerRef.current !== null) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    });
  };

  return (
    <>
      <div className={`${classes.gridItemHeadline}`}>
        <FormattedMessage id="BASE.LOST_FOUND_CHAT" />
      </div>
      <div className={classes.chat} ref={chatContainerRef}>
        <List>
          {lostAndFoundMessages.map((item) => (
            <ListItem key={item.creationDate}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <span className={classes.contactCell}>
                    <FormattedDate
                      year="numeric"
                      month="2-digit"
                      day="2-digit"
                      value={item.creationDate}
                    />
                  </span>
                </Grid>
                <Grid item xs={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={11}>
                      <span className={classes.contactCell}>
                        {item.creator.firstName + ' ' + item.creator.lastName}
                      </span>
                      <span className={classes.timeCell}>
                        <FormattedTime
                          hour="2-digit"
                          minute="2-digit"
                          value={item.creationDate}
                        />
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={10}>
                      <span className={classes.companyCell}>
                        {item.creator.company.name}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.messageStyle}>
                      {item.comment}
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.borderStyle} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
        <div />
      </div>
      <MessageInputContainer
        width="100%"
        chatWidth="66%"
        sendChatMessage={(event) => sendChatMessage(event)}
      />
    </>
  );
}

LostAndFoundChat.propTypes = {
  lostAndFoundMessages: PropTypes.arrayOf(
    PropTypes.instanceOf(LostAndFoundMessage)
  ).isRequired,
  sendChatMessage: PropTypes.func.isRequired,
  chatContainerRef: PropTypes.object.isRequired,
};
