import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedTime, FormattedMessage, FormattedDate } from 'react-intl';

import { useMedia } from '../../utils/customHooks';
import NotificationMessage from '../../model/notificationMessage';
import ListItem from '@material-ui/core/ListItem';
import NotificationTypeIcon from './notificationTypeIcon';
import NotificationType from '../../constants/notificationTypes';
import { LeasingStockUldShapedBox } from '../iconUldShapedBox';
import BagDollar from '../../icons/bagDollar';

const useStyle = makeStyles((theme) => ({
  mainContainerDefault: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  mainContainerLeasing: {
    backgroundColor: theme.palette.primary.linkWater25,
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  timeDayCell: {
    color: theme.palette.primary.chambray,
    fontSize: '1rem',
    fontWeight: '700',
    marginRight: '1%',
  },
  time: {
    marginLeft: '1%',
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '0.9rem',
  },
  typeCell: {
    fontSize: '1rem',
    fontWeight: '700',
  },
  isReadCell: {
    [theme.breakpoints.up('xs')]: {
      marginTop: '30%',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '8%',
    },
    height: '8px',
    width: '8px',
    backgroundColor: '#ff9300',
    borderRadius: '50%',
    display: 'inline-block',
  },
  typeHeadline: {
    color: theme.palette.primary.chambray,
    fontSize: '1rem',
    fontWeight: '700',
    marginRight: '1%',
  },
  subject: {
    color: theme.palette.primary.chambray,
    fontSize: '1rem',
    fontWeight: '500',
    marginTop: '1%',
    marginBottom: '1%',
  },
  messageLong: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  messageShort: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    overflow: 'hidden',
    position: 'relative',
    lineHeight: '1.2em',
    maxHeight: '2.4em',
    textAlign: 'justify',
    marginRight: '-1em',
    paddingRight: '1em',
    '&::before': {
      content: '...',
      position: 'absolute',
      right: '0',
      bottom: '0',
    },
    '&::after': {
      content: '',
      position: 'absolute',
      right: '0',
      width: '1em',
      height: '1em',
      marginTop: '0.2em',
      background: 'white',
    },
  },
  line: {
    backgroundColor: '#e0e0e0',
    height: '4px',
    display: 'flex',
    width: '100%',
  },
}));

export function NotificationItem({ notification, onClick }) {
  const classes = useStyle();

  const { isMobile } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: false,
      },
    ],
    {
      isMobile: true,
    }
  );

  return (
    <React.Fragment>
      <ListItem
        button
        onClick={() => onClick(notification)}
        disabled={notification.details.stockIsDeleted}
        className={
          notification.details.leasing
            ? classes.mainContainerLeasing
            : classes.mainContainerDefault
        }
      >
        <Grid container spacing={1}>
          <Grid container item xs={1} sm={1}>
            <span>
              {notification.details.leasing ? (
                isMobile ? (
                  <BagDollar size={18} />
                ) : (
                  <LeasingStockUldShapedBox
                    height={60}
                    marginLeft="-1em"
                    marginTop="-1em"
                  />
                )
              ) : (
                ''
              )}
            </span>
          </Grid>
          <Grid
            container
            justify={isMobile ? 'left' : 'center'}
            item
            xs={4}
            sm={1}
          >
            <span className={classes.timeDayCell}>
              <FormattedDate
                year="numeric"
                month="2-digit"
                day="2-digit"
                value={notification.timestamp}
              />
            </span>
          </Grid>
          <Grid
            container
            className={classes.typeCell}
            justify="flex-end"
            item
            xs={7}
            sm={1}
          >
            <NotificationTypeIcon type={notification.type} />
          </Grid>
          <Grid container justify="flex-end" item xs={1} sm={1}>
            <span
              className={
                !notification.isRead && !notification.details.stockIsDeleted
                  ? classes.isReadCell
                  : ''
              }
            ></span>
          </Grid>
          <Grid container item xs={11} sm={8}>
            <Grid item xs={12}>
              <span className={classes.typeHeadline}>
                <FormattedMessage id={notification.headline} />
              </span>
              <span className={classes.time}>
                <FormattedTime
                  hour="2-digit"
                  minute="2-digit"
                  value={notification.timestamp}
                />
              </span>
            </Grid>
            <Grid className={classes.subject} item xs={12}>
              <span>
                {notification.type === NotificationType.SKYPOOLING_NEWS ? (
                  notification.subject
                ) : (
                  <FormattedMessage
                    id={notification.subject}
                    values={notification.details}
                  />
                )}
              </span>
            </Grid>
            <Grid
              className={
                notification.type === NotificationType.STOCK_CHAT
                  ? classes.messageShort
                  : classes.messageLong
              }
              item
              xs={12}
            >
              {notification.type === NotificationType.STOCK_CHAT
                ? isMobile
                  ? ''
                  : notification.message
                : ''}

              {notification.type === NotificationType.SKYPOOLING_NEWS ||
              notification.type === NotificationType.STOCK_CHAT ? (
                ''
              ) : (
                <FormattedMessage
                  id={notification.message}
                  values={notification.details}
                />
              )}
            </Grid>
          </Grid>
          <Grid container item xs={1}></Grid>
        </Grid>
      </ListItem>
      <div className={classes.line} />
    </React.Fragment>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.instanceOf(NotificationMessage).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NotificationItem;
