import React, { Fragment } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import HoverInfo from '../hoverInfo';
import MenuButton from '../menuButton';
import StockUldShapedBox from '../stockUldShapedBox';

import EmailIcon from '../../icons/email';
import StarIcon from '../../icons/star';
import CallIcon from '../../icons/phone';
import TrashIcon from '../../icons/trash';
import PencilIcon from '../../icons/pencil';
import ChatIcon from '../../icons/chat';
import TaskIcon from '../../icons/task';
import {
  ImportantStockUldShapedBox,
  LeasingStockUldShapedBox,
} from '../iconUldShapedBox';

const useStyles = makeStyles((theme) => ({
  flexLayout: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  menuButtonLabel: {
    flexDirection: 'column',
  },
  menuButtonImg: {
    paddingRight: 0,
  },
  importantMark: {
    paddingRight: 0,
    '& > svg > path': {
      fill: theme.palette.secondary.main,
      stroke: theme.palette.secondary.light,
    },
  },
  notImportantMark: {
    paddingRight: 0,
    '& > svg > path': {
      fill: 'rgba(0,0,0,0)',
      stroke: theme.palette.secondary.contrastText,
    },
  },
  notImportantMarkAndDisabled: {
    opacity: 0.3,
    paddingRight: 0,
    '& > svg > path': {
      fill: 'rgba(0,0,0,0)',
      stroke: theme.palette.secondary.contrastText,
    },
  },
  importantRow: {
    backgroundColor: theme.palette.secondary.light,
  },
  leasingStockRow: {
    backgroundColor: theme.palette.primary.linkWater25,
  },
  hover: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.linkWater50} !important`,
      cursor: 'pointer',
    },
  },
  tableCell: {
    padding: '14px 0px',
  },
  iconCell: {
    padding: '0',
  },
  button: {
    minWidth: 'auto',
  },
  spacer: {
    padding: '1rem',
  },
  requestsCell: {
    paddingRight: 0,
  },
}));

export default function StockTableRow({
  renderAdditionalButtons,
  row,
  handleClick,
  deleteFunc,
  editFunc,
  detailsFunc,
  markImportant,
  goToBooking,
  isAirportStocks,
}) {
  const classes = useStyles();

  const isNonFreeLeasing = row.leasing && !(row.offerType === 'FREE');

  let rowStyle = '';
  if (isNonFreeLeasing && isAirportStocks) {
    rowStyle = classes.leasingStockRow;
  } else if (row.important) {
    rowStyle = classes.importantRow;
  }

  const uldShapedIcon = () => {
    if (isNonFreeLeasing) {
      return <LeasingStockUldShapedBox height={60} />;
    } else if (row.important) {
      return <ImportantStockUldShapedBox height={60} />;
    }
  };
  return (
    <TableRow
      hover
      classes={{
        hover: classes.hover,
        root: rowStyle,
      }}
      onClick={() => handleClick(row)}
    >
      {isAirportStocks && (
        <TableCell className={classes.iconCell} align="left">
          {uldShapedIcon()}
        </TableCell>
      )}
      {renderAdditionalButtons ? (
        !row.leasing ? (
          <TableCell className={classes.tableCell} align="right">
            {new Date(row.validUntil) >= new Date() ? (
              <MenuButton
                classes={{
                  label: classes.menuButtonLabel,
                  img: row.important
                    ? classes.importantMark
                    : classes.notImportantMark,
                }}
                img={<StarIcon />}
                isTableButton={true}
                onClick={() => markImportant(row)}
              />
            ) : (
              <MenuButton
                disabled
                classes={{
                  label: classes.menuButtonLabel,
                  img: row.important
                    ? classes.importantMark
                    : classes.notImportantMarkAndDisabled,
                }}
                img={<StarIcon />}
                isTableButton={true}
                onClick={() => {}}
              />
            )}
          </TableCell>
        ) : null
      ) : null}
      {renderAdditionalButtons ? null : (
        <TableCell className={classes.tableCell} align="right">
          {row.companyName}
        </TableCell>
      )}
      <TableCell className={classes.tableCell} align="right">
        {row.companyCode}
      </TableCell>
      {renderAdditionalButtons ? (
        <TableCell className={classes.tableCell} align="right">
          <div className={classes.flexLayout}>
            <div className={classes.spacer} />
            <HoverInfo
              infoFormattedMessageId="HOVER_INFO.CONTACT"
              infoText={row.contactPerson}
              mainText={row.email}
              disabled={!row.email}
            >
              <MenuButton
                classes={{
                  label: classes.menuButtonLabel,
                  img: classes.menuButtonImg,
                  button: classes.button,
                }}
                img={<EmailIcon />}
                isTableButton={true}
                disabled={!row.email}
              />
            </HoverInfo>
            <div className={classes.spacer} />
            <HoverInfo
              infoFormattedMessageId="HOVER_INFO.CONTACT"
              infoText={row.contactPerson}
              mainText={row.phone}
              disabled={!row.phone}
            >
              <MenuButton
                classes={{
                  label: classes.menuButtonLabel,
                  img: classes.menuButtonImg,
                  button: classes.button,
                }}
                img={<CallIcon />}
                isTableButton={true}
                disabled={!row.phone}
              />
            </HoverInfo>
          </div>
        </TableCell>
      ) : null}
      <TableCell className={classes.tableCell} align="right">
        <StockUldShapedBox amount={row.amount} />
      </TableCell>
      <TableCell className={classes.tableCell} align="right">
        {row.uldType}
      </TableCell>
      <TableCell className={classes.tableCell} align="right">
        {row.iataCode}
      </TableCell>
      <TableCell className={classes.tableCell} align="right">
        <FormattedDate
          year="numeric"
          month="2-digit"
          day="2-digit"
          value={String(row.availableFrom).slice(0, 10)}
        />
      </TableCell>
      <TableCell className={classes.tableCell} align="right">
        {row.validUntil ? (
          <FormattedDate
            year="numeric"
            month="2-digit"
            day="2-digit"
            value={String(row.validUntil).slice(0, 10)}
          />
        ) : (
          <FormattedMessage id="BASE.INFINITE" />
        )}
      </TableCell>
      {renderAdditionalButtons && (
        <Fragment>
          <TableCell className={classes.requestsCell} align="right">
            <MenuButton
              img={<TaskIcon />}
              onClick={goToBooking}
              disabled={!row.requestCounter}
            />
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            <span>
              <MenuButton img={<PencilIcon />} onClick={() => editFunc(row)} />
              <MenuButton
                classes={{ img: classes.menuButtonImg }}
                img={<TrashIcon />}
                onClick={() => deleteFunc(row.id)}
              />
            </span>
          </TableCell>
        </Fragment>
      )}
    </TableRow>
  );
}
