import React from 'react';
import { Api } from '../constants';
import { FormattedMessage } from 'react-intl';
import { getFromServer, putToServerPlaintext } from '../api';
import { createAction } from './utils';
import { Actions } from '../constants';

export const addLostAndFound = (
  token,
  lostFound,
  enqueueSnackbar,
  onSuccess
) => {
  return putToServerPlaintext(
    Api.BOOKER_API.LOST_AND_FOUND_API.BASE,
    JSON.stringify(lostFound),
    token
  ).then((response) => {
    if (response.ok) {
      enqueueSnackbar(
        <FormattedMessage id="LOST_AND_FOUND.SUCCESSFULLY_POSTED" />,
        {
          variant: 'success',
        }
      );
      onSuccess();
    } else {
      enqueueSnackbar(
        <FormattedMessage id="LOST_AND_FOUND.NO_VALID_ULD_ID_FOUND" />,
        {
          variant: 'error',
        }
      );
    }
  });
};

export const getLostUlds = (token) => (dispatch) => {
  return getFromServer(Api.BOOKER_API.LOST_AND_FOUND_API.LOST, token).then(
    (response) => {
      if (response.ok) {
        response.json().then((json) => {
          dispatch(createAction(Actions.LOST_AND_FOUND.ADD_LOST, json.data));
        });
      }
    }
  );
};

export const getFoundUlds = (token) => (dispatch) => {
  return getFromServer(Api.BOOKER_API.LOST_AND_FOUND_API.FOUND, token).then(
    (response) => {
      if (response.ok) {
        response.json().then((json) => {
          dispatch(createAction(Actions.LOST_AND_FOUND.ADD_FOUND, json.data));
        });
      }
    }
  );
};

export const getLostAndFoundEntry = (id, token) => (dispatch) => {
  return getFromServer(
    Api.BOOKER_API.LOST_AND_FOUND_API.GET_LOST_AND_FOUND_ENTRY + '/' + id,
    token
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw response;
      }
    })
    .then((json) =>
      dispatch(
        createAction(Actions.LOST_AND_FOUND.GET_LOST_AND_FOUND_ENTRY, json.data)
      )
    );
};

export const putMessage = (id, messageText, token) => (dispatch) => {
  const messageDto = {
    id: id,
    message: messageText,
  };
  putToServerPlaintext(
    Api.BOOKER_API.LOST_AND_FOUND_API.ADD_MESSAGE_FOR_LOST_AND_FOUND_ENTRY,
    JSON.stringify(messageDto),
    token
  )
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
    })
    .then(() => dispatch(getLostAndFoundEntry(id, token)));
};
