import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import UserList from '../containers/admin/users';
import Companies from '../containers/admin/companies';
import Reminder from '../containers/admin/reminder';
import News from '../containers/admin/news';
import { Routes } from '../constants';

export default function AdminTabRoutes() {
  return (
    <Switch>
      <Redirect
        exact
        from={Routes.APP.ADMIN.INDEX}
        to={Routes.APP.ADMIN.USERS}
      />
      <Route exact path={Routes.APP.ADMIN.USERS} component={UserList} />
      <Route exact path={Routes.APP.ADMIN.COMPANIES} component={Companies} />
      <Route exact path={Routes.APP.ADMIN.NEWS} component={News} />
      <Route exact path={Routes.APP.ADMIN.REMINDER} component={Reminder} />
    </Switch>
  );
}
