import React from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

export const createAction = (type, payload) => {
  return { type, payload };
};

export const bindAllActionCreators = (actionCreators, dispatch) => {
  return Object.keys(actionCreators).reduce((result, key) => {
    return Object.assign({}, result, {
      [key]: bindActionCreators(actionCreators[key], dispatch),
    });
  }, {});
};

const errorMessage = (errorCode, messageId, enqueueSnackbar) =>
  enqueueSnackbar(<FormattedMessage id={messageId} values={{ errorCode }} />, {
    variant: 'error',
  });

export const errorHandeling =
  (messageId, enqueueSnackbar) =>
  (resp = {}) => {
    if (resp.json) {
      return resp
        .json()
        .then(({ errorCode = 'GENERAL_ERROR' }) =>
          errorMessage(errorCode, messageId, enqueueSnackbar)
        )
        .catch(() => errorMessage('GENERAL_ERROR', messageId, enqueueSnackbar));
    } else return errorMessage('GENERAL_ERROR', messageId, enqueueSnackbar);
  };
