import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ULDShapedBox from '../uldShapedBox';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  outerDiv: {
    display: 'flex',
    padding: '40px',
    flexFlow: 'column',
  },
  headLineDiv: {
    fontSize: '1.625rem',
    fontWeight: '600',
    color: theme.palette.primary.dark,
  },
  innerTextDiv: {
    margin: '10px 0',
    fontSize: '1.125rem',
    fontWeight: '500',
    color: theme.palette.primary.dark,
    marginTop: '20px',
  },
  flexContainer: {
    display: 'flex',
  },
}));

const InfoBoxSkypoolingBenefits = ({ headline, children }) => {
  const classes = styles();
  return (
    <ULDShapedBox margin={'50px 0 10px'}>
      <div className={classes.outerDiv}>
        <div className={classes.headLineDiv}>
          <FormattedMessage id={headline} />
        </div>
        {children}
      </div>
    </ULDShapedBox>
  );
};

InfoBoxSkypoolingBenefits.propTypes = {
  headline: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
};

export const InfoBoxSkypoolingBenefitsFreeToUse = () => {
  const { innerTextDiv } = styles();
  const bullet_points = [
    'HOME.INFO_BOX_SKYPOOLING_BULLET_POINTS_1',
    'HOME.INFO_BOX_SKYPOOLING_BULLET_POINTS_2',
    'HOME.INFO_BOX_SKYPOOLING_BULLET_POINTS_3',
    'HOME.INFO_BOX_SKYPOOLING_BULLET_POINTS_4',
    'HOME.INFO_BOX_SKYPOOLING_BULLET_POINTS_5',
    'HOME.INFO_BOX_SKYPOOLING_BULLET_POINTS_6',
    'HOME.INFO_BOX_SKYPOOLING_BULLET_POINTS_7',
    'HOME.INFO_BOX_SKYPOOLING_BULLET_POINTS_8',
    'HOME.INFO_BOX_SKYPOOLING_BULLET_POINTS_9',
  ];

  return (
    <InfoBoxSkypoolingBenefits headline="HOME.INFO_BOX_SKYPOOLING_BULLET_POINTS_HEADLINE">
      <div className={innerTextDiv}>
        <ul>
          {bullet_points.map((point, index) => {
            return (
              <li key={index}>
                <FormattedMessage id={point} />
              </li>
            );
          })}
        </ul>
      </div>
      <span className={innerTextDiv}>
        <FormattedMessage id="HOME.INFO_BOX_SKYPOOLING_JOIN" />
      </span>
    </InfoBoxSkypoolingBenefits>
  );
};

export const InfoBoxSkypoolingBenefitsSharingEconomy = () => {
  const { innerTextDiv } = styles();

  return (
    <InfoBoxSkypoolingBenefits headline="HOME.INFO_BOX_SKYPOOLING_BENEFITS_HEADLINE">
      <div className={innerTextDiv}>
        <FormattedMessage
          id="HOME.INFO_BOX_SKYPOOLING_BENEFITS_TEXT"
          values={{
            br: (
              <React.Fragment>
                <div style={{ marginTop: '0.5rem' }} />
              </React.Fragment>
            ),
            skypoolingIsAfreeToUse: (
              <b>{'skypooling is a free-to-use sharing platform'}</b>
            ),
          }}
        />
      </div>
    </InfoBoxSkypoolingBenefits>
  );
};
