import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Container } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Api } from '../constants';

import { postToServer } from '../api';
import Chat from '../icons/chat';
import ModalWithContactForm from './modalWithContactForm';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  blueBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  flexWrapper: {
    display: 'flex',
    marginTop: '2.5em',
    padding: '10px 20px',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.linkWater,
    '&:hover': {
      backgroundColor: theme.palette.primary.cornflowerBlue,
    },
  },
  headerStyle: {
    fontWeight: '600',
    paddingLeft: '10px',
    fontSize: '1rem',
    color: theme.palette.primary.main,
  },
}));

export default function ContactSkypooling({ user }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClosed = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const sendMail = (subject, text) => {
    const userEmail = user.email;
    const userName = user.firstName + ' ' + user.lastName;
    const mail = {
      from: userEmail,
      name: userName,
      subject: subject,
      text: text,
    };

    postToServer(Api.USERS_API.SEND_MAIL_TO_SKYPOOLING, mail)
      .then((response) => {
        if (response.ok) {
          enqueueSnackbar(<FormattedMessage id="EMAIL.SEND_SUCCESS" />, {
            variant: 'success',
          });
        } else {
          throw response;
        }
      })
      .catch(() =>
        enqueueSnackbar(<FormattedMessage id="EMAIL.SEND_FAIL" />, {
          variant: 'warning',
        })
      );
  };

  const submit = (_, __, subject, text) => {
    sendMail(subject, text);
    handleClosed();
  };

  return (
    <Fragment>
      <Container>
        <div className={classes.blueBox}>
          <a
            id="open-contact-modal"
            className={classes.flexWrapper}
            href={'mailto:info@skypooling.com?subject=contact skypooling'}
          >
            <Chat />
            <div className={classes.headerStyle}>
              <FormattedMessage id="FOOTER.CONTACT_US" />
            </div>
          </a>
        </div>
        <ModalWithContactForm
          open={open}
          headline={<FormattedMessage id={'EMAIL.MODAL_HEADLINE.NAME_UNSET'} />}
          textId="EMAIL.MODAL_TEXT_HOME"
          labelSingleRowId="EMAIL.SUBJECT"
          labelMultiRowId="EMAIL.TEXT"
          actionId="EMAIL.SEND_NOW"
          submit={submit}
          handleClose={handleClosed}
        />
      </Container>
    </Fragment>
  );
}

ContactSkypooling.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
};
