import React from 'react';
import PropTypes from 'prop-types';
import { useMedia } from '../../utils/customHooks';
import HeadlineBox from './headlineBox';
import SearchButton from './searchButton';

export function HeadlineBoxWithSearchAndAddStock(props) {
  const { height, handleChange, headLineText } = props;

  const { isMobile } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: false,
      },
    ],
    {
      isMobile: true,
    }
  );

  return (
    <HeadlineBox
      headChildren={isMobile && <SearchButton handleChange={handleChange} />}
      height={height}
      headLineText={headLineText}
    />
  );
}

HeadlineBoxWithSearchAndAddStock.propTypes = {
  height: PropTypes.string,
  headLineText: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default HeadlineBoxWithSearchAndAddStock;
