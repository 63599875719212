import React from 'react';

export default () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 26 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="icons/lost-founds"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M25,1.39687519 L25,14.2630323 L15.387128,24.6031248 L4.07394328,24.6031248 L1,24.5750887 L1,1.61432974 L25,1.39687519 Z M24.00075,2.40637481 L2.00075,2.60437481 L2.00075,23.5833748 L4.0785,23.6031248 L14.92475,23.6023748 L24.00075,13.8943748 L24.00075,2.40637481 Z M11.4867498,16.7890625 C11.7888331,16.7890625 12.0219061,16.8802083 12.1859686,17.0625 C12.3500311,17.2447917 12.4320623,17.5078125 12.4320623,17.8515625 C12.4320623,18.1848958 12.348729,18.4440104 12.1820623,18.6289062 C12.0153956,18.8138021 11.7836248,18.90625 11.4867498,18.90625 C11.2159165,18.90625 10.9919581,18.8242187 10.8148748,18.6601562 C10.6377915,18.4960937 10.5492498,18.2265625 10.5492498,17.8515625 C10.5492498,17.1432292 10.8617498,16.7890625 11.4867498,16.7890625 Z M11.9711248,7.09375 C12.9659165,7.09375 13.739354,7.33854167 14.2914373,7.828125 C14.8435206,8.31770833 15.1195623,9.0078125 15.1195623,9.8984375 C15.1195623,10.2786458 15.0687811,10.6132812 14.9672186,10.9023437 C14.8656561,11.1914062 14.7159165,11.4648437 14.5179998,11.7226562 C14.3200831,11.9804687 13.8929998,12.3958333 13.2367498,12.96875 C12.7107081,13.4166667 12.3630519,13.7890625 12.1937811,14.0859375 C12.0245102,14.3828125 11.9398748,14.7786458 11.9398748,15.2734375 L11.9398748,15.2734375 L11.9398748,15.53125 L10.9320623,15.53125 L10.9320623,15.109375 C10.9320623,14.5 11.0258123,13.9986979 11.2133123,13.6054687 C11.4008123,13.2122396 11.7497706,12.796875 12.2601873,12.359375 C12.9685206,11.7604167 13.4151352,11.3098958 13.6000311,11.0078125 C13.7849269,10.7057292 13.8773748,10.3411458 13.8773748,9.9140625 C13.8773748,9.3828125 13.7068019,8.97265625 13.3656561,8.68359375 C13.0245102,8.39453125 12.5336248,8.25 11.8929998,8.25 C11.4815415,8.25 11.0804998,8.29817708 10.6898748,8.39453125 C10.2992498,8.49088542 9.85133314,8.66666667 9.34612481,8.921875 L9.34612481,8.921875 L8.88518731,7.8671875 C9.86956231,7.3515625 10.8982081,7.09375 11.9711248,7.09375 Z"
          id="Combined-Shape"
          fill="#122236"
          fillWule="nonzero"
        ></path>
      </g>
    </svg>
  );
};
