import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import { Routes } from '../constants';
import { withSnackbar } from 'notistack';
import { getAirports } from '../actions/airports';
import { getUldTypes } from '../actions/uldTypes';
import HeadlineBox from '../components/headlineBox';
import { addLostAndFound } from '../actions/lostAndFound';
import AddNewStockContactSection from '../components/addNewStock/addNewStockContactSection';
import AddNewLostAndFoundUldEntry from '../components/lostAndFound/addNewLostAndFoundUldEntry';
import UldShapedFooterWithButtons from '../components/uldShapedFooterWithButtons';

const styles = (theme) => ({
  newStockHeadlineDiv: {
    fontSize: '1.5rem',
    fontWeight: '700',
    marginBottom: '2em',
    marginTop: '1.5em',
    padding: '0',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'left',
    color: theme.palette.primary.chambray,
  },
  line: {
    backgroundColor: theme.palette.primary.linkWater25,
    height: '4px',
    display: 'flex',
    width: '100%',
    marginTop: '2em',
    marginBottom: '2em',
  },
});

export function AddNewLostAndFound({
  getAirports,
  token,
  classes,
  airports,
  user,
  history,
  enqueueSnackbar,
}) {
  useEffect(() => {
    getAirports(token);
  }, []);

  const [iataCode, setIataCode] = useState('');
  const [stockIds, setStockIds] = useState('');
  const [comment, setComment] = useState('');
  const [report, setReport] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    addLostAndFound(
      token,
      {
        iataCode,
        uldIds: stockIds,
        comment,
        report,
      },
      enqueueSnackbar,
      () => {
        if (report === 'lost') {
          history.push(Routes.APP.LOST_AND_FOUND.LOST);
        } else {
          history.push(Routes.APP.LOST_AND_FOUND.FOUND);
        }
      }
    );
  };

  const { firstName, lastName, email, phone } = user;

  return (
    <React.Fragment>
      <HeadlineBox headLineText="TAB_NAVBAR.LOST_AND_FOUND" />
      <div className={`MuiTab-root ${classes.newStockHeadlineDiv}`}>
        <FormattedMessage id="ADD_NEW_ENTRY" />
      </div>
      <form onSubmit={handleSubmit}>
        <AddNewStockContactSection
          contactPerson={`${firstName} ${lastName}`}
          email={email}
          phone={phone}
        />
        <div className={classes.line} />
        <AddNewLostAndFoundUldEntry
          handleSubmit={handleSubmit}
          airports={airports}
          iataCode={iataCode}
          setIataCode={setIataCode}
          stockIds={stockIds}
          setStockIds={setStockIds}
          comment={comment}
          setComment={setComment}
          report={report}
          setReport={setReport}
        />
        <UldShapedFooterWithButtons
          confirmButtonLabel={<FormattedMessage id="ADD_NEW_ENTRY" />}
          confirmButtonType={'submit'}
          cancelButtonOnClick={() => history.goBack()}
        />
      </form>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    airports: state.airports,
    uldTypes: state.uldTypes,
    user: state.user,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAirports: (token) => dispatch(getAirports(token)),
});

AddNewLostAndFound.defaultProps = {
  airports: [],
  uldTypes: [],
  user: {},
};

AddNewLostAndFound.propTypes = {
  classes: PropTypes.object.isRequired,
  getAirports: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      iataCode: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  user: PropTypes.object,
  token: PropTypes.string,
};

export default withSnackbar(
  withRouter(
    withStyles(styles)(
      connect(mapStateToProps, mapDispatchToProps)(AddNewLostAndFound)
    )
  )
);
