import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import reducer from '../reducers/index';
import { Defaults } from '../constants';

export default function configureStore(initialState = {}) {
  const middlewares = [thunkMiddleware];

  let composeEnhancers = compose;

  if (process.env.NODE_ENV === `development`) {
    const { createLogger } = require('redux-logger');
    const loggerMiddleware = createLogger({ diff: true });
    middlewares.push(loggerMiddleware);
    composeEnhancers =
      (global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})) ||
      compose;
  }

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  const store = createStore(reducer, initialState, enhancer);

  return store;
}

// load state from localStorage
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(Defaults.AUTH_TOKEN_STORAGE);
    if (serializedState === null) {
      return undefined; // reducer will return Redux state, as localstorage is null.
    }
    return { auth: JSON.parse(serializedState) };
  } catch (err) {
    return undefined;
  }
};

const saveToLocalStorage = ({ auth }) => {
  try {
    const serializedState = JSON.stringify(auth);
    localStorage.setItem(Defaults.AUTH_TOKEN_STORAGE, serializedState);
  } catch (err) {
    // ignore error
  }
};
