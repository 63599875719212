import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { FormattedMessage, injectIntl } from 'react-intl';
import MenuButton from './menuButton';
import HoverInfo from './hoverInfo';
import CallIcon from '../icons/phone';
import { useMedia } from '../utils/customHooks';
import StockChatContainer from '../components/stockChat/stockChatContainer';
import ChatIcon from '../icons/chat';
import StockChat from '../model/stockChat';
import { gridItemHeadline } from './addNewStock/cssStyles';
import Stock from '../model/stock';
import AddNewStockLeasingSection from './addNewStock/addNewStockLeasingSection';

const useStyles = makeStyles((theme) => ({
  checked: {
    color: `${theme.palette.primary.blueZodiac}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.lightBlue,
    },
  },
  root: {
    '& > span': {
      fontSize: '1em',
    },
    color: {
      backgroundColor: theme.palette.primary.blueZodiac,
    },
  },
  line: {
    backgroundColor: theme.palette.primary.linkWater25,
    height: '4px',
    display: 'flex',
    width: '100%',
    marginTop: '2em',
    marginBottom: '2em',
  },
  buttonEmail: {
    display: 'flex',
    marginBottom: '1em',
    '&:hover': {
      background: 'none',
    },
  },
  buttonSingle: {
    display: 'flex',
    '&:hover': {
      background: 'none',
    },
  },
  gridItemHeadline: gridItemHeadline(theme),
  grid: {
    display: 'flex',
    alignItems: 'center',
  },
  gridMessages: {
    marginTop: '2rem',
    display: 'block',
  },
  contactGrid: {
    marginBottom: '2rem',
    justifyContent: 'flex-end',
    display: 'flex',
    maxWidth: '100%',
  },
  push: {
    paddingRight: '2rem',
    paddingTop: '1rem',
    content: "''",
  },
}));

export function Details({
  specialStock,
  createChat,
  intl,
  chats,
  sendMessage,
  token,
  chatIdToOpen,
  requestedAmount,
  requestedComment,
  setRequestedAmount,
  setRequestedComment,
  hasAlreadyBeenBookedByUsersCompany,
}) {
  const messageContainerRef = React.createRef();

  const [hasScrolled, setHasScrolled] = React.useState(false);

  const chatRefs = chats.reduce((chat, value) => {
    chat[value.chatId] = React.createRef();
    return chat;
  }, {});

  const scrollMessageContainerIntoView = () => {
    messageContainerRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  useEffect(() => {
    if (chats.some((chat) => chat.chatId == chatIdToOpen) && !hasScrolled) {
      chatRefs[chatIdToOpen].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setHasScrolled(true);
    }
  });

  const { paddingRight } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        paddingRight: '30px',
      },
    ],
    {
      paddingRight: '0px',
    }
  );

  const {
    id,
    contactPerson,
    phone,
    amount,
    uldType,
    iataCode,
    validUntil,
    availableFrom,
    destinationIataCode,
    comment,
    companyName,
    location,
  } = specialStock;

  const hasMessages = chats
    .map((chat) => chat.stockChatMessages.length > 0)
    .reduce((a, b) => a || b, false);

  const canCreateNewChat = !specialStock.userIsOwner && chats.length === 0;

  const classes = useStyles();

  return (
    <div>
      <span>
        <Grid container>
          <Grid item xs={12} md={4}>
            <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
              <FormattedMessage id="BASE.CONTACT" />
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid
                item
                xs={8}
                md={6}
                className={classes.grid}
                style={{ paddingRight: paddingRight }}
              >
                <FormControl fullWidth>
                  <TextField
                    style={{ marginRight: paddingRight }}
                    id="standard-name"
                    label={<FormattedMessage id="BASE.NAME" />}
                    type="text"
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MenuButton
                            id="openEmailButton"
                            classes={{ label: classes.buttonEmail }}
                            img={<ChatIcon />}
                            text={<FormattedMessage id="BASE.CONTACT" />}
                            isTableButton={true}
                            disabled={!hasMessages && specialStock.userIsOwner}
                            onClick={scrollMessageContainerIntoView}
                          />
                        </InputAdornment>
                      ),
                    }}
                    value={contactPerson}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={4}
                md={6}
                className={classes.grid}
                style={{ paddingRight: paddingRight }}
              >
                <HoverInfo
                  infoFormattedMessageId="HOVER_INFO.CONTACT"
                  infoText={contactPerson}
                  mainText={phone}
                  disabled={!phone}
                ></HoverInfo>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className={classes.grid}
                style={{ paddingRight: paddingRight }}
              >
                <FormControl fullWidth>
                  <TextField
                    style={{ marginRight: paddingRight }}
                    id="company-name"
                    label={<FormattedMessage id="BASE.COMPANY_NAME" />}
                    type="text"
                    margin="normal"
                    value={companyName}
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </span>
      <div className={classes.line} />
      <span>
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            md={4}
            className={classes.grid}
            style={{ paddingRight: paddingRight }}
          >
            <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
              <FormattedMessage id="STOCKDETAILS.STOCK_ENTRY_OTHER_PARTY" />
            </div>
          </Grid>
          <Grid item xs={12} md={4} style={{ paddingRight: paddingRight }}>
            <RadioGroup
              aria-label={<FormattedMessage id="BASE.STOCKTYPE" />}
              name="stockType"
              style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
              value={amount <= 0 ? '-1' : '1'}
              disabled
            >
              <FormControlLabel
                classes={{ root: classes.root }}
                value={'-1'}
                style={{ marginRight: '15%' }}
                control={
                  <Radio
                    classes={{ checked: classes.checked }}
                    required
                    color="default"
                  />
                }
                label={<FormattedMessage id="BASE.UNDERSTOCK" />}
                disabled
              />
              <FormControlLabel
                classes={{ root: classes.root }}
                value={'1'}
                style={{ marginRight: '15%' }}
                control={
                  <Radio
                    classes={{ checked: classes.checked }}
                    required
                    color="default"
                  />
                }
                label={<FormattedMessage id="BASE.OVERSTOCK" />}
                disabled
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} md={4} style={{ paddingRight: paddingRight }}>
            <FormControl fullWidth>
              <TextField
                id="standard-amount"
                label={<FormattedMessage id="BASE.AMOUNT" />}
                type="number"
                margin="normal"
                value={amount >= 0 ? amount : amount * -1}
                required
                disabled
              />
            </FormControl>
            <div
              className={classes.push}
              style={{ paddingRight: paddingRight }}
            />
          </Grid>
          <Grid item xs={false} md={4} />
          <Grid item xs={12} md={4} style={{ paddingRight: paddingRight }}>
            <TextField
              fullWidth
              name="uldTypes"
              value={uldType}
              label={<FormattedMessage id="BASE.ULD_TYPE" />}
              required={true}
              disabled
            />
            <div
              className={classes.push}
              style={{ paddingRight: paddingRight }}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ paddingRight: paddingRight }}>
            <TextField
              fullWidth
              name="airports"
              value={iataCode}
              label={<FormattedMessage id="BASE.AIRPORT" />}
              required={true}
              disabled
            />
            <div
              className={classes.push}
              style={{ paddingRight: paddingRight }}
            />
          </Grid>
          <Grid item xs={false} md={4} />
          <Grid item xs={12} md={4} style={{ paddingRight: paddingRight }}>
            <TextField
              fullWidth
              id="validFrom"
              label={<FormattedMessage id="BASE.AVAILABLE_FROM" />}
              type="date"
              value={availableFrom.slice(0, 10)}
              required
              disabled
            />
            <div
              className={classes.push}
              style={{ paddingRight: paddingRight }}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ paddingRight: paddingRight }}>
            <TextField
              fullWidth
              id="validTill"
              label={<FormattedMessage id="BASE.VALID_UNTIL" />}
              type="date"
              value={validUntil.slice(0, 10)}
              required
              disabled
            />
            <div
              className={classes.push}
              style={{ paddingRight: paddingRight }}
            />
          </Grid>
          <Grid item xs={false} md={4} />
          <Grid item xs={12} md={4} style={{ paddingRight: paddingRight }}>
            <TextField
              fullWidth
              name="destinationAirports"
              value={
                destinationIataCode && destinationIataCode.length > 0
                  ? destinationIataCode.map((item) => item).join(', ')
                  : intl.formatMessage({ id: 'ADD_NEW_STOCK.ANY_AIRPORT' })
              }
              label={
                <FormattedMessage id="ADD_NEW_STOCK.DESTINATION_AIRPORT" />
              }
              required={true}
              disabled
            />
            <div
              className={classes.push}
              style={{ paddingRight: paddingRight }}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ paddingRight: paddingRight }}>
            <TextField
              fullWidth
              name="comment"
              multiline
              value={
                comment
                  ? comment
                  : intl.formatMessage({ id: 'STOCKDETAILS.NO_COMMENT' })
              }
              label={<FormattedMessage id="ADD_NEW_STOCK.COMMENT" />}
              disabled
            />
            <div
              className={classes.push}
              style={{ paddingRight: paddingRight }}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ paddingRight: paddingRight }}>
            <TextField
              fullWidth
              name="location"
              multiline
              value={
                location
                  ? location
                  : intl.formatMessage({ id: 'ADD_NEW_STOCK.NO_LOCATION' })
              }
              label={<FormattedMessage id="ADD_NEW_STOCK.LOCATION" />}
              disabled
            />
            <div
              className={classes.push}
              style={{ paddingRight: paddingRight }}
            />
          </Grid>
        </Grid>

        <div className={classes.line} />
        <span>
          <Grid container>
            <Grid item xs={12} md={4}>
              <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
                <FormattedMessage id="AIRPORT_STOCKS.BOOKING_REQUEST" />
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid
                  item
                  xs={8}
                  md={6}
                  className={classes.grid}
                  style={{ paddingRight: paddingRight }}
                >
                  <FormControl fullWidth>
                    <TextField
                      id="standard-amount"
                      label={<FormattedMessage id="BASE.AMOUNT_TO_REQUEST" />}
                      type="number"
                      margin="normal"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: Math.abs(specialStock.amount),
                        },
                      }}
                      value={Math.abs(requestedAmount)}
                      onChange={(event) =>
                        setRequestedAmount(event.target.value)
                      }
                      required
                      disabled={hasAlreadyBeenBookedByUsersCompany}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={6}
                  className={classes.grid}
                  style={{ paddingRight: paddingRight }}
                ></Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.grid}
                  style={{ paddingRight: paddingRight }}
                >
                  <FormControl fullWidth>
                    <TextField
                      style={{ marginRight: paddingRight }}
                      id="stock-request-comment"
                      label={
                        <FormattedMessage id="AIRPORT_STOCKS.BOOKING_REQUEST.COMMENT" />
                      }
                      type="text"
                      margin="normal"
                      value={requestedComment}
                      helperText={
                        <FormattedMessage id="AIRPORT_STOCKS.BOOKING_REQUEST_COMMENT_HELPER" />
                      }
                      onChange={(event) =>
                        setRequestedComment(event.target.value)
                      }
                      disabled={hasAlreadyBeenBookedByUsersCompany}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </span>
        {specialStock.leasing && (
          <Fragment>
            <div className={classes.line} />
            <AddNewStockLeasingSection
              leasingType={specialStock.offerType}
              commercialInformation={specialStock.offerInformation}
              paddingRight={paddingRight}
              disableAllInputfields={true}
            />
          </Fragment>
        )}
      </span>
    </div>
  );
}

Details.propTypes = {
  specialStock: PropTypes.instanceOf(Stock).isRequired,
  chats: PropTypes.arrayOf(PropTypes.instanceOf(StockChat)).isRequired,
  createChat: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  token: PropTypes.string,
  chatIdToOpen: PropTypes.string,
};

export default injectIntl(Details);
