import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  TextField,
} from '@material-ui/core';
import SingleSelect from '../searchSelect/singleSelect';
import {
  gridItemHeadline,
  grid,
  checked,
  grid_margin,
  radio,
} from '../addNewStock/cssStyles';

const useStyles = makeStyles((theme) => ({
  gridItemHeadline: gridItemHeadline(theme),
  grid: grid,
  grid_margin: grid_margin,
  checked: checked(theme),
  radio: radio(theme),
}));

export function AddNewLostAndFoundUldEntry({
  report,
  setReport,
  intl,
  stockIds,
  setStockIds,
  comment,
  setComment,
  iataCode,
  setIataCode,
  airports,
}) {
  const classes = useStyles();

  return (
    <span>
      <Grid container justify="center">
        <Grid item xs={12} md={4}>
          <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
            <FormattedMessage id="BASE.ULD" />
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <RadioGroup
                aria-label={<FormattedMessage id="BASE.REPORT" />}
                name="report"
                style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
                onChange={(event) => setReport(event.target.value)}
                value={report}
              >
                <FormControlLabel
                  classes={{ root: classes.root }}
                  value={'lost'}
                  style={{ marginRight: '15%' }}
                  control={
                    <Radio
                      className={`MuiRadio-root ${classes.radio}`}
                      classes={{ checked: classes.checked }}
                      required
                      color="default"
                    />
                  }
                  label={<FormattedMessage id="BASE.REPORT_LOST_ULD" />}
                />
                <FormControlLabel
                  classes={{ root: classes.root }}
                  value={'found'}
                  style={{ marginRight: '15%' }}
                  control={
                    <Radio
                      classes={{ checked: classes.checked }}
                      required
                      color="default"
                    />
                  }
                  label={<FormattedMessage id="BASE.REPORT_FOUND_ULD" />}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="comment"
                label={<FormattedMessage id="ADD_NEW_LOST_AND_FOUND.COMMENT" />}
                placeholder={intl.formatMessage({
                  id: 'ADD_NEW_LOST_AND_FOUND.COMMENT',
                })}
                type="text"
                value={comment}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => setComment(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="uldids"
                label={
                  <FormattedMessage id="ADD_NEW_LOST_AND_FOUND.ULD_ENTRY" />
                }
                placeholder={intl.formatMessage({
                  id: 'ADD_NEW_LOST_AND_FOUND.ULD_ENTRY.PLACEHOLDER',
                })}
                type="text"
                multiline
                value={stockIds}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                onChange={(event) => setStockIds(event.target.value)}
              />
            </Grid>
            {report === 'found' && (
              <Grid item xs={12} md={6}>
                <SingleSelect
                  id="airports-select"
                  label={<FormattedMessage id="ADD_NEW_STOCK.FROM_STATION" />}
                  selected={{ value: iataCode }}
                  setSelected={setIataCode}
                  data={airports.map(({ iataCode, name }) => ({
                    value: iataCode,
                    label: `${iataCode} - ${name}`,
                  }))}
                  required={true}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </span>
  );
}

AddNewLostAndFoundUldEntry.propTypes = {
  report: PropTypes.string.isRequired,
  setReport: PropTypes.func.isRequired,
  intl: intlShape,
  stockIds: PropTypes.string.isRequired,
  setStockIds: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired,
  iataCode: PropTypes.string.isRequired,
  setIataCode: PropTypes.func.isRequired,
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      iataCode: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  paddingRight: PropTypes.string.isRequired,
};

export default injectIntl(AddNewLostAndFoundUldEntry);
