import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import InformationContainer from '../informationContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '50em',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridItemHeadline: {
    padding: '0px',
    fontWeight: '700',
    fontSize: '12pt',
    width: 'fit-content',
    minWidth: 'unset',
    minHeight: 'unset',
    maxHeight: 'unset',
    maxWidth: 'unset',
    textAlign: 'unset',
    textTransform: 'uppercase',
    color: theme.palette.primary.chambray,
  },

  grid: {
    marginBottom: '1rem',
  },
}));

export function BookingContactSection({ booking }) {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid container justify="center">
        <Grid item xs={12} sm={4} md={4}>
          <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
            <FormattedMessage id="BOOKINGS.DETAILS.BOOKER" />
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={8}>
          <Grid container justify="center">
            <Grid item xs={12} sm={7} md={7} className={classes.grid}>
              <InformationContainer
                headline={
                  <FormattedMessage id="BOOKINGS.DETAILS.BOOKER_NAME" />
                }
                subtitle={
                  <Fragment>
                    {booking.stockCreatorUser.firstName +
                      ' ' +
                      booking.stockCreatorUser.lastName}
                    <br />
                    {`${booking.stockCreatorUser.company.name} (${booking.stockCreatorUser.company.code})`}
                  </Fragment>
                }
              />
            </Grid>

            <Grid item xs={12} sm={5} md={5} className={classes.grid}>
              <InformationContainer
                headline={
                  <FormattedMessage id="BOOKINGS.DETAILS.BOOKER_COMPANY" />
                }
                subtitle={
                  <Fragment>
                    {booking.bookingUser.firstName +
                      ' ' +
                      booking.bookingUser.lastName}
                    <br />
                    {`${booking.bookingUser.company.name} (${booking.bookingUser.company.code})`}
                  </Fragment>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

BookingContactSection.propTypes = {
  booking: PropTypes.shape({
    bookingUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    stockCreatorUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    bookedStock: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      uldType: PropTypes.string.isRequired,
      iataCode: PropTypes.string.isRequired,
      availableFrom: PropTypes.string.isRequired,
      validUntil: PropTypes.string.isRequired,
      destinationIataCode: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    returnAirport: PropTypes.shape({
      iataCode: PropTypes.string.isRequired,
    }),
    requestComment: PropTypes.string,
    replyComment: PropTypes.string,
    fromWhen: PropTypes.string.isRequired,
    tillWhen: PropTypes.string.isRequired,
    amountBooked: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    incoming: PropTypes.bool.isRequired,
    denied: PropTypes.bool.isRequired,
    additionalInformation: PropTypes.string,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default BookingContactSection;
