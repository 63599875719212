import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Grid,
  Button,
} from '@material-ui/core';
import Account from '../../icons/account';
import { FormattedMessage } from 'react-intl';
import InformationItem from './informationItem';
import SimpleSelect from '../simpleSelect';
import TrashIcon from '../../icons/trash';
import MenuButton from '../menuButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useMedia } from '../../utils/customHooks';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: '#FF0000',
  },
  deleteSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonNormal: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.linkWater50,
    color: theme.palette.primary.blueZodiac,
    '&:hover': {
      background: theme.palette.primary.cornflowerBlue,
    },
  },
  buttonAction: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.orangePeel,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      background: theme.palette.secondary.tangerine,
    },
  },
}));

export default function UserComponent({
  user,
  enableUserFunction,
  disableUserFunction,
  deleteUser,
  roles,
  roleFromBackend,
  setRole,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { whiteSpace, height } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        whiteSpace: 'nowrap',
      },
    ],
    {
      whiteSpace: 'unset',
      height: '4em',
    }
  );
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Account />
      </ListItemAvatar>
      <ListItemText
        primary={user.firstName + ' ' + user.lastName}
        secondary={
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={8}>
              <div className={classes.informationContainer}>
                <InformationItem
                  headline={<FormattedMessage id="USER_COMPONENT.EMAIL" />}
                  message={user.email}
                />
                <InformationItem
                  headline={<FormattedMessage id="USER_COMPONENT.PHONE" />}
                  message={
                    user.phone ? (
                      user.phone
                    ) : (
                      <FormattedMessage id="USER_COMPONENT.NOT_SET" />
                    )
                  }
                />
                <InformationItem
                  color={user.company.code === 'notListed' ? '#FF0000' : null}
                  headline={<FormattedMessage id="USER_COMPONENT.COMPANY" />}
                  message={
                    user.company.code === 'notListed' ? (
                      <FormattedMessage id="REGISTRATION.NO_COMPANY" />
                    ) : (
                      `${user.company.name} (${user.company.code})`
                    )
                  }
                />
                <div className={classes.deleteSection}>
                  <MenuButton img={<TrashIcon />} onClick={handleClickOpen} />
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    PaperProps={{
                      style: {
                        backgroundColor: '#EFF3FA',
                        boxShadow: 'none',
                      },
                    }}
                  >
                    <DialogTitle id="alert-dialog-title">
                      <h5>
                        <FormattedMessage
                          id="REGISTRATION.DELETE_USER_CONFIRMATION_MESSAGE"
                          values={{
                            firstName: user.firstName,
                            lastName: user.lastName,
                          }}
                        />
                      </h5>
                    </DialogTitle>
                    <DialogActions>
                      <Button
                        className={classes.buttonAction}
                        onClick={deleteUser}
                      >
                        <FormattedMessage id="REGISTRATION.DELETE_USER_CONFIRMATION_AGREEMENT" />
                      </Button>
                      <Button
                        className={classes.buttonNormal}
                        onClick={handleClose}
                      >
                        <FormattedMessage id="REGISTRATION.DELETE_USER_CONFIRMATION_CANCEL" />
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              {user.enabled ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={disableUserFunction}
                >
                  <FormattedMessage id="USER_COMPONENT.DISABLE_USER" />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={enableUserFunction}
                >
                  <FormattedMessage id="USER_COMPONENT.ENABLE_USER" />
                </Button>
              )}
            </Grid>
            <Grid item xs={8}>
              <SimpleSelect
                name={<FormattedMessage id="USER_COMPONENT.ROLE" />}
                menuItems={roles}
                selectedValue={roleFromBackend}
                setValue={setRole}
              />
            </Grid>
          </Grid>
        }
      />
    </ListItem>
  );
}
