import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '../../node_modules/@material-ui/styles';

import HeadlineBox from '../components/headlineBox';
import MenuButton from '../components/menuButton';
import BackIcon from '../icons/back';

import { Routes } from '../constants';
const styles = (theme) => ({
  title: {
    color: theme.palette.primary.dark,
    fontSize: '2.25rem',
    marginTop: '1.5em',
    marginBottom: '0.7em',
  },
  textStyle: {
    fontSize: '1.125rem',
    color: 'black',
  },
  subtitle: {
    color: theme.palette.primary.dark,
    fontSize: '1.625rem',
    marginTop: '1em',
  },
  jettainerText: {
    color: theme.palette.primary.dark,
    fontSize: '1.625rem',
  },
});

class Imprint extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <HeadlineBox headLineText="FOOTER.LEGAL_INFORMATION" />
        <div style={{ marginTop: '1em' }} />
        <MenuButton
          text={<FormattedMessage id="BASE.BACK" />}
          onClick={() => this.props.history.push(Routes.APP.INDEX)}
          img={<BackIcon />}
        />

        <div className={classes.title}>
          <FormattedMessage id="FOOTER.IMPRINT" />
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.IMPRINT_SKYPOOLING_IS_OPERATED" />
        </div>
        <br />
        <div className={classes.jettainerText}>
          <FormattedMessage id="FOOTER.IMPRINT_JETTAINER_GMBH" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.IMPRINT_AM_PRIME_PARC" />
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.IMPRINT_RAUNHEIM" />
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.GERMANY" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.IMPRINT_TELEFON" />
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.IMPRINT_TELEFAX" />
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.IMPRINT_EMAIL" />
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.IMPRINT_INTERNET" />
        </div>
        <br />
        <div className={classes.subtitle}>
          <FormattedMessage id="FOOTER.IMPRINT_REPRESENTED" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.IMPRINT_DIRECTOR_NAME" />
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.IMPRINT_DIRECTOR" />
        </div>
        <br />
        <div className={classes.subtitle}>
          <FormattedMessage id="FOOTER.IMPRINT_REGISTRATION" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.IMPRINT_REGISTRATION_1" />
        </div>
        <br />
        <div className={classes.subtitle}>
          <FormattedMessage id="FOOTER.IMPRINT_VAT_ID" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.IMPRINT_NUMBER" />
        </div>
      </Fragment>
    );
  }
}

Imprint.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(withStyles(styles)(Imprint));
