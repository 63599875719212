import React from 'react';
import { FormattedMessage } from 'react-intl';

import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core';
import { useMedia } from '../../utils/customHooks';

const styles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.dark,
    textTransform: 'uppercase',
    fontSize: '1rem',
    margin: '10px 0',
    fontWeight: '600',
  },
}));

export default function FAQsHeadLine(props) {
  const classes = styles();

  const { textAlign } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        textAlign: 'left',
      },
    ],
    {
      textAlign: 'center',
    }
  );

  return (
    <div className={classes.title}>
      <Typography
        style={{
          fontSize: '2.25rem',
          textTransform: 'none',
          textAlign: textAlign,
          marginTop: '50px',
        }}
        variant="h6"
        id="tableTitle"
      >
        <FormattedMessage id="HOME.FAQS_HEADLINE" />
      </Typography>
    </div>
  );
}
