import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import UldShapedButton from '../uldShapedButton';
import MenuButton from '../menuButton';
import Plus from '../../icons/plus';

import { useMedia } from '../../utils/customHooks';
import HeadlineBox from './headlineBox';
import ExcelUpload from './excelUpload';
import { makeStyles } from '@material-ui/core';
import SearchButton from './searchButton';

const useStyles = makeStyles((theme) => ({
  spanStyles: {
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: '0.85rem',
    textAlign: 'center',
  },
}));

export function HeadlineBoxWithSearchAndAddStock(props) {
  const {
    height,
    headLineText,
    addNewStockFunc,
    handleChange,
    importExcelFunction,
    disableExcelUpload,
  } = props;

  const classes = useStyles();

  const { isMobile } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: false,
      },
    ],
    {
      isMobile: true,
    }
  );

  return (
    <HeadlineBox
      headChildren={
        isMobile && (
          <SearchButton handleChange={handleChange}>
            <MenuButton
              component="span"
              id="add-stock-button-mobile"
              img={<Plus />}
              paddingLeft="15px"
              minWidth="fit-content"
              onClick={addNewStockFunc}
            />
          </SearchButton>
        )
      }
      headLineText={headLineText}
      height={height}
    >
      {!isMobile && (
        <div style={{ marginBottom: '50px' }}>
          <span className={classes.spanStyles}>
            <UldShapedButton
              id="add-stock-button"
              label={<FormattedMessage id="MY_INVENTORY.ADD_NEW_STOCK" />}
              onClick={addNewStockFunc}
            />
            {!disableExcelUpload && (
              <ExcelUpload importExcelFunction={importExcelFunction} />
            )}
          </span>
        </div>
      )}
    </HeadlineBox>
  );
}

HeadlineBoxWithSearchAndAddStock.propTypes = {
  height: PropTypes.string.isRequired,
  headLineText: PropTypes.string.isRequired,
  addNewStockFunc: PropTypes.func.isRequired,
  importExcelFunction: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  disableExcelUpload: PropTypes.bool,
};

export default HeadlineBoxWithSearchAndAddStock;
