import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { useMedia } from '../utils/customHooks';
import CancelButton from '../components/cancelButton';
import UldShapedButton from '../components/uldShapedButton';
import UldShapedFooterWithButtons from '../components/uldShapedFooterWithButtons';

const useStyles = makeStyles((theme) => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    backgroundColor: theme.palette.secondary.white,
    border: `1px solid ${theme.palette.primary.blueZodiac}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    outline: 'none',
    color: theme.palette.primary.blueZodiac,
    fontFamily: theme.typography.fontFamily,
  },
  buttonNormal: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.linkWater50,
    color: theme.palette.primary.blueZodiac,
    '&:hover': {
      background: theme.palette.primary.cornflowerBlue,
    },
  },
  buttonAction: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.orangePeel,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      background: theme.palette.secondary.tangerine,
    },
  },
  input: {
    display: 'none',
  },
}));

export default function ModalWithContactForm(props) {
  const classes = useStyles();
  const [emailSubject, setEmailSubject] = React.useState('');
  const [emailText, setEmailText] = React.useState('');
  const [userNameText, setUserNameText] = React.useState('');
  const [userEmailAdress, setUserEmailAdress] = React.useState('');

  const { width } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        width: '400px',
      },
    ],
    {
      width: '60%',
    }
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    props.submit(userEmailAdress, userNameText, emailSubject, emailText);
    setEmailSubject('');
    setEmailText('');
    setUserNameText('');
    setUserEmailAdress('');
  };

  return (
    <Modal
      aria-labelledby="emailForm"
      aria-describedby="fill in your request"
      open={props.open}
    >
      <form
        style={{ width: width }}
        className={classes.paper}
        onSubmit={handleSubmit}
      >
        <h2 id="modal-title">{props.headline}</h2>
        <p id="simple-modal-description">
          <FormattedMessage id={props.textId} />
        </p>
        <TextField
          id="subject-input"
          fullWidth
          label={<FormattedMessage id={props.labelSingleRowId} />}
          value={emailSubject}
          required
          onChange={(event) => setEmailSubject(event.target.value)}
          margin="normal"
        />
        <TextField
          id="message-input"
          fullWidth
          label={<FormattedMessage id={props.labelMultiRowId} />}
          multiline
          rowsMax="5"
          value={emailText}
          required
          onChange={(event) => setEmailText(event.target.value)}
          margin="normal"
        />
        {props.labelSingleRowUserEmailId && props.labelSingleRowUserNameId && (
          <div>
            <TextField
              id="username-input"
              fullWidth
              label={<FormattedMessage id={props.labelSingleRowUserNameId} />}
              value={userNameText}
              required
              onChange={(event) => setUserNameText(event.target.value)}
              margin="normal"
            />
            <TextField
              id="useremail-input"
              fullWidth
              label={<FormattedMessage id={props.labelSingleRowUserEmailId} />}
              value={userEmailAdress}
              required
              inputProps={{
                type: 'email',
              }}
              onChange={(event) => setUserEmailAdress(event.target.value)}
              margin="normal"
            />
          </div>
        )}
        <UldShapedFooterWithButtons
          confirmButtonLabel={<FormattedMessage id={props.actionId} />}
          confirmButtonType={'submit'}
          cancelButtonOnClick={props.handleClose}
        />
      </form>
    </Modal>
  );
}

ModalWithContactForm.propTypes = {
  submit: PropTypes.func.isRequired,
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  open: PropTypes.bool.isRequired,
  textId: PropTypes.string.isRequired,
  labelSingleRowId: PropTypes.string.isRequired,
  labelMultiRowId: PropTypes.string.isRequired,
  labelSingleRowUserEmailId: PropTypes.string,
  labelSingleRowUserNameId: PropTypes.string,
  actionId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};
