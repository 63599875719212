import { getFromServer, patchFromServer } from '../api';
import { Actions, Api } from '../constants';
import { createAction } from './utils';

export const getNotifications = (token) => (dispatch) => {
  getFromServer(Api.USERS_API.GET_NOTIFICATIONS, token).then((response) => {
    if (response.ok) {
      response.json().then((json) => {
        dispatch(
          createAction(Actions.NOTIFICATIONS.GET_NOTIFICATIONS, json.data)
        );
      });
    }
  });
};

export const markNotificationAsRead = (notificationId, token) => (dispatch) => {
  return patchFromServer(
    Api.USERS_API.NOTIFICATION_READ(notificationId),
    notificationId,
    token
  ).then((response) => {
    if (response.ok) {
      dispatch(getNotifications(token));
      return;
    } else {
      throw response;
    }
  });
};
