import { Actions } from '../constants';
import GlobalChatMessage from '../model/globalChatMessage';

const initialState = [];

export default function globalChat(state = initialState, action) {
  switch (action.type) {
    case Actions.GLOBAL_CHAT.GET_GLOBAL_CHAT:
      return [...action.payload.map((item) => new GlobalChatMessage(item))];
    default: {
      return state;
    }
  }
}
