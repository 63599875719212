import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  Checkbox,
  Link,
  makeStyles,
} from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Routes } from '../constants';
import SimpleSelect from './simpleSelect';
import UldShapedFooterWithButtons from '../components/uldShapedFooterWithButtons';
import ClearButton from './clearButton';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > span': {
      fontSize: '1em',
    },
    color: {
      backgroundColor: theme.palette.primary.blueZodiac,
    },
  },
  link: {
    fontSize: '1rem',
    textAlign: 'left',
    verticalAlign: 'baseline',
  },
  spacer: {
    marginTop: '3em',
  },
  checkbox: {
    padding: '0 0.5rem 0 0',
    alignItems: 'unset',
  },
  grid: {
    margin: 'auto 0',
  },
  form: {
    flexDirection: 'row',
    marginBottom: '2px',
    marginTop: '1rem',
  },
  checkboxWrapper: {
    flexDirection: 'row',
    fontSize: '1rem',
    marginTop: '1rem',
  },
  buttonHover: {
    '&:hover': {
      background: theme.palette.secondary.dark,
    },
  },
}));

export function RegistrationForm({
  handleTerms,
  handlePolicy,
  handleSubmit,
  cancelButtonFunction,
  companies,
  intl,
}) {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [confirmEmail, setConfirmEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [companyCode, setCompanyCode] = React.useState('');
  const [termsAndPrivacyAgreement, setTermsAndPrivacyAgreement] =
    React.useState(false);
  const [contactAgreement, setContactAgreement] = React.useState(false);
  const classes = useStyles();

  const submitForm = (event) => {
    event.preventDefault();
    handleSubmit({
      firstName,
      lastName,
      email,
      confirmEmail,
      phoneNumber,
      password,
      confirmPassword,
      companyCode,
      termsAndPrivacyAgreement,
      contactAgreement,
    });
  };

  const setCompanyWithName = (companyName) => {
    setCompanyName(companyName);

    if (companyName === 'notListed') {
      setCompanyCode('notListed');
    } else {
      const company = companies.find((com) => com.name === companyName);
      company !== undefined ? setCompanyCode(company.code) : setCompanyCode('');
    }
  };

  const setCompanyWithCode = (companyCode) => {
    setCompanyCode(companyCode);

    if (companyCode === 'notListed') {
      setCompanyName('notListed');
    } else {
      const company = companies.find((com) => com.code === companyCode);
      company !== undefined ? setCompanyName(company.name) : setCompanyName('');
    }
  };

  const setWithContactAgreement = (event) => {
    setContactAgreement(event);
  };

  const setWithTermsAndPrivacyAgreement = (event) => {
    setTermsAndPrivacyAgreement(event);
  };

  return (
    <form onSubmit={submitForm}>
      <Grid container spacing={4} justify="center">
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="standard-firstName"
              label={<FormattedMessage id="BASE.FIRSTNAME" />}
              type="text"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setFirstName('')} />
                  </InputAdornment>
                ),
              }}
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="standard-lastName"
              label={<FormattedMessage id="BASE.LASTNAME" />}
              type="text"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setLastName('')} />
                  </InputAdornment>
                ),
              }}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* 2 Zeile */}
      <Grid container spacing={4} justify="center">
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="standard-email"
              label={<FormattedMessage id="BASE.EMAIL" />}
              type="email"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setEmail('')} />
                  </InputAdornment>
                ),
              }}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="standard-confirmEmail"
              label={<FormattedMessage id="REGISTRATION.CONFIRM_EMAIL" />}
              type="email"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setConfirmEmail('')} />
                  </InputAdornment>
                ),
              }}
              value={confirmEmail}
              onChange={(event) => setConfirmEmail(event.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* 3 Zeile */}
      <Grid container spacing={4} justify="center">
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="standard-password"
              label={<FormattedMessage id="REGISTRATION.PASSWORD" />}
              type="password"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setPassword('')} />
                  </InputAdornment>
                ),
              }}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="standard-confirmPassword"
              label={<FormattedMessage id="REGISTRATION.CONFIRM_PASSWORD" />}
              type="password"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setConfirmPassword('')} />
                  </InputAdornment>
                ),
              }}
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* 4 Zeile */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              placeholder={intl.formatMessage({
                id: 'FORM_PLACEHOLDER_PHONE_NUMBER',
              })}
              id="standard-phoneNumber"
              label={<FormattedMessage id="BASE.PHONE" />}
              type="tel"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setPhoneNumber('')} />
                  </InputAdornment>
                ),
              }}
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* 5 Zeile */}
      <Grid container spacing={4} justify="center">
        <Grid item xs={12} sm={6}>
          <SimpleSelect
            name="company"
            otherItem="REGISTRATION.NO_COMPANY"
            menuItems={companies.map((item) => item.name)}
            selectedValue={companyName}
            setValue={setCompanyWithName}
            label={<FormattedMessage id="BASE.COMPANY_NAME" />}
            required={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SimpleSelect
            name="companies"
            otherItem="REGISTRATION.NO_COMPANY"
            menuItems={companies.map((item) => item.code)}
            selectedValue={companyCode}
            setValue={setCompanyWithCode}
            label={<FormattedMessage id="BASE.COMPANY_LETTER_CODE" />}
            required={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} justify="center">
        <Grid item className={classes.grid} xs={12} sm={6}>
          <FormControl className={classes.form} fullWidth>
            <Checkbox
              required={true}
              className={classes.checkbox}
              checked={termsAndPrivacyAgreement}
              value="termsAndPrivacyAgreement"
              onChange={(event) =>
                setWithTermsAndPrivacyAgreement(event.target.checked)
              }
            />
            <FormattedMessage
              id="REGISTRATION.AGREE_TOC_AND_DATA_PRIVACY"
              tagName="span"
              values={{
                toc: (
                  <Link
                    href={`${window.location.origin}${Routes.PUBLIC.TERMS_OF_USE}`}
                  >
                    <FormattedMessage id="REGISTRATION.TOC" />
                  </Link>
                ),
                skypoolingDomain: (
                  <FormattedMessage id="REGISTRATION.SKYPOOLING_DOMAIN">
                    {(link) => <Link href={`https://${link}`}>{link}</Link>}
                  </FormattedMessage>
                ),
                dataPrivacyPolicy: (
                  <Link
                    href={`${window.location.origin}${Routes.PUBLIC.DATA_PRIVACY}`}
                  >
                    <FormattedMessage id="REGISTRATION.DATA_PRIVACY_POLICY" />
                  </Link>
                ),
              }}
            />
          </FormControl>
          <FormControl className={classes.checkboxWrapper} fullWidth>
            <Checkbox
              className={classes.checkbox}
              checked={contactAgreement}
              value="contactAgreement"
              onChange={(event) =>
                setWithContactAgreement(event.target.checked)
              }
            />
            <FormattedMessage
              id="REGISTRATION.AGREE_OPTINS"
              values={{
                email: (
                  <Link href="mailto:info@skypooling.com">
                    info@skypooling.com
                  </Link>
                ),
              }}
              tagName="span"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
      <div className={classes.spacer}>
        <UldShapedFooterWithButtons
          className={classes.buttonHover}
          confirmButtonLabel={'submit'}
          confirmButtonType={'submit'}
          cancelButtonOnClick={cancelButtonFunction}
        />
      </div>
    </form>
  );
}

RegistrationForm.propTypes = {
  handlePolicy: PropTypes.func.isRequired,
  handleTerms: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cancelButtonFunction: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ),
};

export default injectIntl(RegistrationForm);
