import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AdminTab from '../../components/tabNavbar/adminTab';
import HeadlineBox from '../../components/headlineBox';

export default class AdminPanel extends Component {
  render() {
    const { children } = this.props;
    return (
      <Fragment>
        <HeadlineBox headLineText="ADMIN.USER_ADMINISTRATION" />
        <AdminTab>{children}</AdminTab>
      </Fragment>
    );
  }
}

AdminPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
