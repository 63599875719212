import { getFromServer, postToServer } from '../api';
import { Actions, Api } from '../constants';
import { createAction } from './utils';

export const getGlobalChat = (token) => (dispatch) => {
  getFromServer(Api.USERS_API.GET_GLOBAL_CHAT, token).then((response) => {
    if (response.ok) {
      response.json().then((json) => {
        dispatch(createAction(Actions.GLOBAL_CHAT.GET_GLOBAL_CHAT, json));
      });
    }
  });
};

export const postGlobalChat = (messageText, token) => (dispatch) => {
  const globalChatDto = {
    message: messageText,
  };
  postToServer(Api.USERS_API.GET_GLOBAL_CHAT, globalChatDto, token)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw response;
      }
    })
    .then(() => dispatch(getGlobalChat(token)));
};
