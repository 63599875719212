import Company from './company';

export default class User {
  constructor({ company, email, firstName, lastName }) {
    this.company = new Company(company);
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
  }
}
