import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { Routes, Defaults } from '../constants';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (rest.token) {
        return <Component {...props} />;
      } else {
        localStorage.setItem(
          Defaults.UNAUTHORIZED_ROUTE_KEY,
          props.location.pathname
        );
        return (
          <Redirect
            to={{
              pathname: Routes.PUBLIC.HOME,
              state: { from: props.location },
            }}
          />
        );
      }
    }}
  />
);

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps, null)(PrivateRoute);
