import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ChevronDown from '../../icons/chevron_down';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import MessageInputContainer from '../stockChat/messageInputContainer';
import { useMedia } from '../../utils/customHooks';
import SingleChatMessage from './singleChatMessage';
import StockChat from '../../model/stockChat';
import { markMessageAndNotificationAsRead } from '../../actions/stockChat';

const useStyles = makeStyles((theme) => ({
  alignWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  heading: {
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightRegular,
  },
  box: {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    minHeight: '6rem',
    borderRadius: 'unset',
    width: '100%',
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  boxMobile: {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    minHeight: '6rem',
    width: '100%',
    paddingTop: '2rem',
    marginLeft: 'auto',
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  panelDetails: {
    flexDirection: 'column',
    padding: '0 0 1rem 0',
  },
  expansionPanelSummary: {
    padding: '0 1.5rem 0 0',
  },
  messageFrame: {
    borderTopStyle: 'solid',
    borderRightStyle: 'solid',
    borderLeftStyle: 'solid',
    borderWidth: '1px',
    paddingBottom: '2rem',
    paddingTop: '2rem',
    borderColor: theme.palette.primary.linkWater25,
  },
  spacer: {
    padding: '2rem',
  },
  newMessages: {
    color: theme.palette.secondary.main,
  },
  noNewMessages: {
    color: theme.palette.primary.dark,
  },
}));

export function StockChatContainer({
  defaultExpanded,
  chat,
  sendMessage,
  token,
  dispatch,
}) {
  const classes = useStyles();

  const { isMobile } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: false,
      },
    ],
    {
      isMobile: true,
    }
  );

  const communicationPartner = chat.enquiring
    ? chat.stockCompany
    : chat.enquiringCompany;

  const updateWasReadState = (messageId) => {
    dispatch(markMessageAndNotificationAsRead(messageId, token));
  };

  let newMessages;

  if (
    chat.stockChatMessages &&
    chat.stockChatMessages.find((m) => m.unread) !== undefined
  )
    newMessages = true;
  else newMessages = false;

  return (
    <div className={classes.alignWrapper}>
      <ExpansionPanel
        className={isMobile ? classes.boxMobile : classes.box}
        square
        defaultExpanded={defaultExpanded}
        // onChange={(event, expanded) => updateWasReadState(expanded)}
      >
        <ExpansionPanelSummary
          className={classes.expansionPanelSummary}
          expandIcon={<ChevronDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            variant="h6"
            className={classes.heading}
            id="communicationPartnerHeader"
          >
            <div
              className={
                newMessages ? classes.newMessages : classes.noNewMessages
              }
            >
              <FormattedMessage
                id={'STOCK_CHAT.NEW_MESSAGE_REQUIRED_STOCK'}
                values={{
                  name: `${communicationPartner.name} (${communicationPartner.code})`,
                }}
              />
            </div>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelDetails}>
          <div className={classes.messageFrame}>
            {chat.stockChatMessages ? (
              chat.stockChatMessages.map((message) => {
                const senderName = `${message.sender.firstName} ${message.sender.lastName}`;
                const company = message.sender.company.name;
                return (
                  <SingleChatMessage
                    key={message.timestamp}
                    senderName={senderName}
                    company={company}
                    message={message}
                    markAsRead={updateWasReadState}
                    communicationPartnerEmail={communicationPartner.email}
                  />
                );
              })
            ) : (
              <div className={classes.spacer} />
            )}
          </div>
          <MessageInputContainer sendFunc={sendMessage} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

StockChatContainer.propTypes = {
  defaultExpanded: PropTypes.bool,
  chat: PropTypes.instanceOf(StockChat).isRequired,
  sendMessage: PropTypes.func.isRequired,
  token: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(StockChatContainer);
