import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Grid,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { gridItemHeadline } from '../../addNewStock/cssStyles';

const useStyles = makeStyles((theme) => ({
  gridItemHeadline: gridItemHeadline(theme),
  marginTop: {
    marginTop: '25px',
  },
}));

const BookingLeasingInformationForm = ({ acceptTerms, setAcceptTerms }) => {
  const classes = useStyles();

  return (
    <span>
      <Grid container justify="center" className={classes.marginTop}>
        <Grid item xs={12} md={4}>
          <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
            <FormattedMessage id="BOOKNOW.LEASING.INFORMATION" />
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={acceptTerms}
                onChange={(event) => setAcceptTerms(event.target.checked)}
                value="acceptLeasingTerms"
              />
            }
            label={
              <FormattedMessage id="BOOKNOW.LEASING.ACCEPT_TERMS_BUTTON" />
            }
          />
        </Grid>
      </Grid>
    </span>
  );
};

BookingLeasingInformationForm.propTypes = {
  acceptTerms: PropTypes.bool.isRequired,
  setAcceptTerms: PropTypes.func.isRequired,
};

export default BookingLeasingInformationForm;
