import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { withStyles } from '@material-ui/core';

import Table from '../components/table';
import {
  getAirportStocks,
  getMatchingStocksWithLastRequestTimeStamp,
} from '../actions/stock';
import { createAction } from '../actions/utils';
import { Actions, Routes } from '../constants';
import SearchFieldBox from '../components/searchFieldBox';
import { HeadlineBoxWithSearch } from '../components/headlineBox';
import AdLandscape from '../components/adLandscape';
import { getAdAirportStocks } from '../actions/pathsForImages';

const styles = (theme) => ({
  sponsorsText: {
    color: theme.palette.primary.chambray,
    textTransform: 'uppercase',
    padding: '0',
    fontSize: '1rem',
    fontWeight: '600',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'left',
    marginBottom: '15px',
    marginTop: 'inherit',
  },
});

const headRowsForTable = (formatMessage) => [
  { id: 'type', sortable: false, numeric: false, disablePadding: false },
  {
    id: 'companyName',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.COMPANY_NAME' }),
  },
  {
    id: 'companyCode',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.COMPANY_CODE' }),
  },
  {
    id: 'amount',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.AMOUNT' }),
  },
  {
    id: 'uldType',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.ULD_TYPE' }),
  },
  {
    id: 'iataCode',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.STATION' }),
  },
  {
    id: 'availableFrom',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.AVAILABLE_FROM' }),
  },
  {
    id: 'validUntil',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.VALID_UNTIL' }),
  },
];

export class AirportStocks extends Component {
  componentDidMount = () => {
    this.props.getAirportStocks(this.props.token);
    this.props.getMatchingStocksWithLastRequestTimeStamp(
      this.props.token,
      this.props.lastMatchingRequest
    );
    this.props.getAdSlider();
  };

  goToDetails = (stock) => {
    this.props.dispatchStockDetails(stock);
    stock.leasing
      ? this.props.history.push(
          `${Routes.APP.AIRPORT_STOCKS.LEASING.BOOK_NOW}/${stock.id}`
        )
      : this.props.history.push(
          `${Routes.APP.AIRPORT_STOCKS.BOOK_NOW}/${stock.id}`
        );
  };

  state = {
    filterString: '',
  };

  handleChange = (newValue) => {
    this.setState({
      filterString: newValue,
    });
  };

  filterData = (stocks) => {
    return stocks.filter((row) =>
      Object.values([row.iataCode, row.uldType, row.amount]).find((values) =>
        String(values)
          .toLocaleLowerCase()
          .includes(this.state.filterString.toLowerCase())
      )
    );
  };

  render() {
    const { airportStocks, matches, intl, dispatch, adImages } = this.props;

    return (
      <React.Fragment>
        <HeadlineBoxWithSearch
          headLineText="AIRPORT_STOCKS.HEADLINE"
          handleChange={this.handleChange}
        />
        <SearchFieldBox handleChange={this.handleChange} />
        <Table
          title={<FormattedMessage id="AIRPORT_STOCKS.FOUND_MATCHES" />}
          headRows={headRowsForTable(intl.formatMessage)}
          data={this.filterData(matches)}
          defaultOrder="companyCode"
          handleClick={this.goToDetails}
          isAirportStocks={true}
          dispatch={dispatch}
        />
        <Table
          title={<FormattedMessage id="AIRPORT_STOCKS.MORE_STOCKS" />}
          headRows={headRowsForTable(intl.formatMessage)}
          data={this.filterData(airportStocks)}
          defaultOrder="companyCode"
          handleClick={this.goToDetails}
          isAirportStocks={true}
          dispatch={dispatch}
        />
        {adImages && adImages.length > 0 && <AdLandscape adSlider={adImages} />}
      </React.Fragment>
    );
  }
}

AirportStocks.defaultProps = {
  airportStocks: [],
  matches: [],
};

AirportStocks.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getAirportStocks: PropTypes.func.isRequired,
  getMatchingStocksWithLastRequestTimeStamp: PropTypes.func.isRequired,
  dispatchStockDetails: PropTypes.func.isRequired,
  adImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  getAdSlider: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  airportStocks: PropTypes.array,
  matches: PropTypes.array,
  token: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  intl: intlShape,
  lastMatchingRequest: PropTypes.instanceOf(Date),
};

const mapStateToProps = (state) => ({
  airportStocks: state.stocks.airport,
  matches: state.stocks.matches,
  adImages: state.getPathForImages.adSlider.airportStocks,
  token: state.auth.token,
  lastMatchingRequest: state.stocks.lastMatchingRequest,
});

const mapDispatchToProps = (dispatch) => ({
  getAdSlider: () => dispatch(getAdAirportStocks()),
  getAirportStocks: (token) => dispatch(getAirportStocks(token)),
  getMatchingStocksWithLastRequestTimeStamp: (token, lastMatchingRequest) =>
    dispatch(
      getMatchingStocksWithLastRequestTimeStamp(token, lastMatchingRequest)
    ),
  dispatchStockDetails: (element) =>
    dispatch(createAction(Actions.STOCK.SPECIAL_STOCK, element)),
  dispatch,
});

export default injectIntl(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(AirportStocks)
  )
);
