import User from './user';

export default class StockChatMessage {
  constructor({ id, message, sender, timestamp, unread }) {
    this.id = id;
    this.message = message;
    this.sender = new User(sender);
    this.timestamp = timestamp;
    this.unread = unread;
  }
}
