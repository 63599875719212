import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  textStyle: {
    color: theme.palette.primary.dark,
  },
}));

export default function DialogPopup({ open, title, message, closeFunction }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.textStyle}
        >
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeFunction} color="primary">
          <FormattedMessage id="NEWSPOPUP.BUTTON" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  closeFunction: PropTypes.func.isRequired,
};
