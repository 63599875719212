import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import UldShapedButton from '../components/uldShapedButton';
import { useDispatch } from 'react-redux';
import { setCookie } from '../actions/user';
import { FormattedMessage } from 'react-intl';
import { COOKIE_KEYS } from '../utils/cookieUtils';
import UldShapedErrorBox from './uldShapedErrorBox';

const useStyles = makeStyles(() => ({
  button: {
    padding: '0 1rem',
    textTransform: 'uppercase',
  },
  buttonLocation: {
    paddingTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonSize: {
    minWidth: '60%',
    maxWidth: '100%',
  },
}));

export function CookieNotAllowed({ messageId = 'COOKIES.CONSENT_NOT_GIVEN' }) {
  const { button, buttonSize, buttonLocation } = useStyles();
  const dispatch = useDispatch();
  return (
    <UldShapedErrorBox>
      <>
        <FormattedMessage id={messageId} />
        <div className={buttonLocation}>
          <div className={buttonSize}>
            <UldShapedButton
              type="button"
              label={
                <div className={button}>
                  <FormattedMessage id="COOKIE.ACCEPT_ALL" />
                </div>
              }
              onClick={() => {
                dispatch(setCookie(COOKIE_KEYS.ACCEPT_ALL, 20));
              }}
            />
          </div>
        </div>
      </>
    </UldShapedErrorBox>
  );
}

CookieNotAllowed.propTypes = {
  messageId: PropTypes.string,
};

export default CookieNotAllowed;
