import React, { Component } from 'react';

import FAQsHeadLine from './faqsHeadLine.jsx';
import FAQsForm from './faqsForm.jsx';

class FAQs extends Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.divElement = React.createRef();
  }

  render() {
    return (
      <div ref={this.divElement} style={{ width: '100%' }}>
        <FAQsHeadLine></FAQsHeadLine>
        <FAQsForm></FAQsForm>
      </div>
    );
  }
}

export default FAQs;
