import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import UldShapedFooterWithButtons from './uldShapedFooterWithButtons';
import { useMedia } from '../utils/customHooks';
import AddNewStockContactSection from './addNewStock/addNewStockContactSection';
import AddNewStockUldSection from './addNewStock/addNewStockUldSection';
import AddNewStockLeasingSection from './addNewStock/addNewStockLeasingSection';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > span': {
      fontSize: '1em',
    },
    color: {
      backgroundColor: theme.palette.primary.blueZodiac,
    },
  },
  line: {
    backgroundColor: theme.palette.primary.linkWater25,
    height: '4px',
    display: 'flex',
    width: '100%',
    marginTop: '2em',
    marginBottom: '2em',
  },
  push: {
    paddingRight: '30px',
    paddingTop: '15px',
    content: "''",
  },
}));

export function StockForm({
  defaultValues,
  handleSubmit,
  uldTypes,
  airports,
  user,
  onShowError,
  showErrors,
  confirmButtonLabel,
  cancelButtonFunction,
}) {
  const { paddingRight } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        paddingRight: '30px',
      },
    ],
    {
      paddingRight: '0px',
    }
  );

  const {
    contactPerson: contactPersonDefault,
    email: emailDefault,
    phone: phoneDefault,
    amount: amountDefault,
    uldType: uldTypeDefault,
    iataCode: iataCodeDefault,
    validUntil: validUntilDefault,
    destinationIataCode: destinationIataCodeDefault,
    availableFrom: availableFromDefault,
    comment: commentDefault,
    location: locationDefault,
    offerInformation: offerInformationDefault,
    offerType: offerTypeDefault,
  } = defaultValues;

  const getDefaultValidUntilDate = () => {
    let date = new Date();
    date = date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    return new Date(date);
  };

  const contactPerson =
    user !== undefined && user.firstName && user.lastName
      ? user.firstName + ' ' + user.lastName
      : contactPersonDefault;

  const email = user !== undefined ? user.email : emailDefault;

  const phone = user !== undefined ? user.phone : phoneDefault;

  const [factor, setFactor] = React.useState(amountDefault > 0 ? '1' : '-1');
  const [amount, setAmount] = React.useState(
    amountDefault ? amountDefault * factor : 1
  );
  const [uldType, setUldType] = React.useState(uldTypeDefault);
  const [iataCode, setIataCode] = React.useState(iataCodeDefault);
  const [validUntil, setValidUntil] = React.useState(
    validUntilDefault || getDefaultValidUntilDate().toISOString().slice(0, 10)
  );
  const [destinationIataCode, setDestinationIataCode] = React.useState(
    destinationIataCodeDefault
  );
  const [availableFrom, setAvailableFrom] = React.useState(
    availableFromDefault || new Date().toISOString().slice(0, 10)
  );
  const [comment, setComment] = React.useState(commentDefault);
  const [location, setLocation] = React.useState(locationDefault);

  const [leasingType, setLeasingType] = React.useState(offerTypeDefault);
  const [commercialInformation, setCommercialInformation] = React.useState(
    offerInformationDefault
  );

  const classes = useStyles();

  const submitForm = (event) => {
    event.preventDefault();
    handleSubmit({
      contactPerson,
      email,
      phone,
      factor: parseInt(factor),
      amount: parseInt(amount),
      uldType,
      iataCode,
      validUntil,
      destinationIataCode,
      availableFrom,
      comment,
      location,
      leasingType,
      commercialInformation,
      isLeasing: user.roles.includes('ULDLeaser'),
      offerInformation: commercialInformation,
      offerType: leasingType,
    });
  };

  return (
    <form onSubmit={submitForm}>
      <AddNewStockContactSection
        contactPerson={contactPerson}
        email={email}
        phone={phone}
      />
      <div className={classes.line} />
      <AddNewStockUldSection
        factor={factor}
        setFactor={setFactor}
        amount={amount}
        setAmount={setAmount}
        iataCode={iataCode}
        setIataCode={setIataCode}
        uldType={uldType}
        setUldType={setUldType}
        validUntil={validUntil}
        setValidUntil={setValidUntil}
        destinationIataCode={destinationIataCode}
        setDestinationIataCode={setDestinationIataCode}
        availableFrom={availableFrom}
        setAvailableFrom={setAvailableFrom}
        comment={comment}
        setComment={setComment}
        location={location}
        setLocation={setLocation}
        onShowError={onShowError}
        uldTypes={uldTypes}
        airports={airports}
        paddingRight={paddingRight}
        showErrors={showErrors}
      />
      {user.roles.includes('ULDLeaser') && (
        <>
          <div className={classes.line} />
          <AddNewStockLeasingSection
            leasingType={leasingType}
            setLeasingType={setLeasingType}
            commercialInformation={commercialInformation}
            setCommercialInformation={setCommercialInformation}
            paddingRight={paddingRight}
          />
        </>
      )}
      <div>
        <UldShapedFooterWithButtons
          confirmButtonLabel={confirmButtonLabel}
          confirmButtonType={'submit'}
          cancelButtonOnClick={cancelButtonFunction}
        />
      </div>
    </form>
  );
}

StockForm.defaultProps = {
  defaultValues: {
    amount: '',
    contactPerson: '',
    email: '',
    factor: '0',
    iataCode: '',
    phone: '',
    uldType: '',
    destinationIataCode: [],
    location: '',
    comment: '',
    leasingType: '',
    commercialInformation: '',
  },
};

StockForm.propTypes = {
  defaultValues: PropTypes.shape({
    contactPerson: PropTypes.string,
    email: PropTypes.string,
    factor: PropTypes.string,
    iataCode: PropTypes.string,
    phone: PropTypes.string,
    uldType: PropTypes.string,
    validUntil: PropTypes.string,
    destinationIataCode: PropTypes.arrayOf(PropTypes.string),
    availableFrom: PropTypes.string,
    comment: PropTypes.string,
    location: PropTypes.string,
    leasingType: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  confirmButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  cancelButtonFunction: PropTypes.func.isRequired,
  uldTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      iataCode: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  user: PropTypes.object,
};

export default injectIntl(StockForm);
