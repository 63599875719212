import React, { Fragment, Component, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Details from '../components/details';
import { Routes } from '../constants';
import HeadlineBox from '../components/headlineBox';
import MenuButton from '../components/menuButton';
import BackIcon from '../icons/back';

import { CircularProgress } from '@material-ui/core';
import { getStockChat, resetStockChatMessages } from '../actions/stockChat';
import UldShapedBox from '../components/uldShapedBox';
import { getSpecialStockFromPropsOrFromBackend } from '../actions/stock';
import UldShapedButtonBig from '../components/uldShapedButtonBig';
import { createNewStockChat } from '../actions/stockChat';
import { putNewStockChatMessage } from '../actions/stockChat';
import Stock from '../model/stock';
import StockChat from '../model/stockChat';
import StockBookingQuery from '../model/stockBookingQuery';
import { BookStock } from './bookings/form/bookStock';
import {
  acceptLeasingStockBooking,
  acceptStockBooking,
  addLeasingStockBooking,
  addStockBooking,
  getSpecificBooking,
} from '../actions/booking';
import details from './bookings/details';
import { BookingRequestForm } from '../components/booking/form/request';
import { stock } from '../actions';
import routes from '../constants/routes';

const styles = (theme) => ({
  newStockHeadlineDiv: {
    fontSize: '1.5rem',
    fontWeight: '700',
    marginBottom: '2em',
    marginTop: '1.5em',
    padding: '0',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'left',
    textTransform: 'none',
    color: theme.palette.primary.chambray,
  },
  root: {
    display: 'flex',
    height: '50em',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  newMessageButton: {
    color: theme.palette.primary.main,
    hoverColor: theme.palette.primary.light,
  },
  containerForBooking: {
    display: 'flex',
    height: '100%',
    paddingRight: '2rem',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  uldButtonContainer: {
    width: '75%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bookingCustomizedText: {
    textAlign: 'center',
    marginBottom: '2em',
    paddingRight: '10%',
    paddingLeft: '10%',
  },
});

export class StockDetails extends Component {
  state = {
    open: false,
    loading: true,
    stock: new Stock({}),
    chatTimer: -1,
    chatIdToOpen: -1,
    bookingStarted: false,
    requestedAmount: undefined,
    requestedComment: undefined,
  };
  setRequestedAmount = (rAmount) => {
    this.setState({ ...this.state, requestedAmount: rAmount });
  };

  setRequestedComment = (rComment) => {
    this.setState({ ...this.state, requestedComment: rComment });
  };

  componentDidMount = async () => {
    const { specialStock, match, token, history, enqueueSnackbar } = this.props;
    const stock = await getSpecialStockFromPropsOrFromBackend(
      specialStock,
      match.params.id,
      token,
      enqueueSnackbar,
      history.push
    );

    if (stock.id) {
      this.getStockChatMessages();
      this.setState({
        stock: new Stock({ ...stock }),
        loading: false,
        chatTimer: setInterval(() => this.getStockChatMessages(), 10000),
        requestedAmount: stock.amount,
      });
    }

    // const booking = await getSpecificBooking(
    //     match.params.id,
    //     token,
    //     this.errorFunctionForGettingBooking
    // );
  };

  componentWillUnmount = () => {
    clearInterval(this.state.chatTimer);
    this.props.resetStockChatMessages();
  };

  getStockChatMessages = () => {
    this.props.match &&
      this.props.match.params &&
      this.props.getStockChat(this.props.match.params.id, this.props.token);
  };

  createChat = () => {
    const {
      match: {
        params: { id },
      },
      createNewStockChat,
    } = this.props;
    createNewStockChat(id, this.props.enqueueSnackbar, this.props.token);
  };

  sendMessage = (chatId) => (event, messageText, setMessageText) => {
    event.preventDefault();
    if (
      messageText !== '' &&
      messageText !== ' ' &&
      messageText !== undefined
    ) {
      putNewStockChatMessage(messageText, chatId, this.props.token).then(() => {
        this.getStockChatMessages();
      });
    }
    setMessageText('');
  };

  sendRequestedStock = () => {
    const { enqueueSnackbar, history } = this.props;

    const amountToBook =
      this.state.stock.amount > 0
        ? this.state.requestedAmount * -1
        : Math.abs(this.state.requestedAmount);
    const requestDuration = new Date(this.props.specialStock.availableFrom);
    requestDuration.setDate(requestDuration.getDate() + 7);

    const stockBookingQuery = new StockBookingQuery({
      stockId: this.props.specialStock.id,
      fromWhen: this.props.specialStock.availableFrom,
      tillWhen: requestDuration.toISOString(),
      amountToBook: amountToBook,
      requestComment: this.state.requestedComment,
      returnAirport:
        this.state.stock.destinationIataCode.length > 0
          ? this.state.stock.destinationIataCode[0]
          : undefined,
      uldType: this.props.specialStock.uldType,
      iataCode: this.props.specialStock.iataCode,
    });

    this.bookingStarted = true;
    const datesValid = stockBookingQuery.fromWhen <= stockBookingQuery.tillWhen;
    const amountValid =
      Math.abs(this.state.stock.amount) -
        Math.abs(stockBookingQuery.amountToBook) >=
      0;

    if (!datesValid)
      return enqueueSnackbar(<FormattedMessage id="BOOKNOW.DATES_INVALID" />, {
        variant: 'error',
      });

    if (!amountValid)
      return enqueueSnackbar(<FormattedMessage id="BOOKNOW.AMOUNT_INVALID" />, {
        variant: 'error',
      });
    if (datesValid && amountValid) {
      addStockBooking(
        this.props.token,
        stockBookingQuery,
        this.props.enqueueSnackbar,
        history.push(routes.APP.AIRPORT_STOCKS.INDEX)
      ).then(() => this.confirmRequest(''));
    }
  };

  confirmRequest = (message) => {
    const { token, enqueueSnackbar } = this.props;
    this.props.specialStock.leasing
      ? acceptLeasingStockBooking(
          this.props.specialStock.id,
          message,
          token,
          this.goToBookings,
          enqueueSnackbar
        )
      : acceptStockBooking(
          this.props.specialStock.id,
          message,
          token,
          this.goToBookings,
          enqueueSnackbar
        );
  };

  render() {
    const { classes, history, userCompany } = this.props;
    const { stock, loading } = this.state;

    return (
      <Fragment>
        <HeadlineBox headLineText="STOCKDETAILS.INVENTORY" />
        {loading ? (
          <div className={classes.root}>
            <div className={classes.loadingInfo}>
              <CircularProgress />
              <FormattedMessage id="STOCKDETAILS.LOADING" />
            </div>
          </div>
        ) : (
          <Fragment>
            <div style={{ marginTop: '1em' }}>
              <MenuButton
                text={<FormattedMessage id="BASE.BACK" />}
                onClick={() => history.goBack()}
                img={<BackIcon />}
              />
              <div className={`MuiTab-root ${classes.newStockHeadlineDiv}`}>
                <FormattedMessage id="STOCKDETAILS.HEADLINE" />
              </div>
              <Details
                specialStock={stock}
                createChat={this.createChat}
                chats={this.props.chats}
                chatIdToOpen={this.props.match.params.chatId}
                getStockChatMessages={this.getStockChatMessages}
                sendMessage={this.sendMessage}
                token={this.props.token}
                requestedAmount={this.state.requestedAmount ?? stock.amount}
                setRequestedAmount={this.setRequestedAmount}
                requestedComment={this.state.requestedComment}
                setRequestedComment={this.setRequestedComment}
                hasAlreadyBeenBookedByUsersCompany={
                  stock.hasAlreadyBeenBookedByUsersCompany
                }
              />

              {userCompany && stock && userCompany.code !== stock.companyCode && (
                <div style={{ marginTop: '3em' }}>
                  <div className={classes.bookingCustomizedText}>
                    {stock.hasAlreadyBeenBookedByUsersCompany ? (
                      <FormattedMessage id="BOOKING.DETAILS.STOCK_ALREADY_BOOKED" />
                    ) : (
                      <FormattedMessage id="BOOKING.DETAILS.YOUR_BOOKING_CUSTOMIZED" />
                    )}
                  </div>
                  <UldShapedBox height="80px">
                    <div className={classes.containerForBooking}>
                      <div className={classes.uldButtonContainer}>
                        <UldShapedButtonBig
                          disabled={stock.hasAlreadyBeenBookedByUsersCompany}
                          onClick={() => this.sendRequestedStock(stock)}
                        >
                          <FormattedMessage id="BOOKNOW.REQUEST_NOW" />
                        </UldShapedButtonBig>
                      </div>
                    </div>
                  </UldShapedBox>
                </div>
              )}
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    specialStock: state.stocks.specialStock,
    userEmail: state.user.email,
    userCompany: state.user.company,
    token: state.auth.token,
    chats: state.stockChat.chats,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStockChat: (stockId, token) => dispatch(getStockChat(stockId, token)),
  createNewStockChat: (stockId, enqueueSnackbar, token) =>
    dispatch(createNewStockChat(stockId, enqueueSnackbar, token)),
  resetStockChatMessages: () => dispatch(resetStockChatMessages()),
});

StockDetails.defaultProps = {
  specialStock: new Stock({}),
  userCompany: {},
};

StockDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  specialStock: PropTypes.instanceOf(Stock).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  userCompany: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }),
  userEmail: PropTypes.string,
  getStockChat: PropTypes.func.isRequired,
  createNewStockChat: PropTypes.func.isRequired,
  resetStockChatMessages: PropTypes.func.isRequired,
  chats: PropTypes.arrayOf(PropTypes.instanceOf(StockChat)).isRequired,
  token: PropTypes.string,
};

export default withSnackbar(
  withRouter(
    withStyles(styles)(
      connect(mapStateToProps, mapDispatchToProps)(StockDetails)
    )
  )
);
