import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, Container } from '@material-ui/core';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { withSnackbar } from 'notistack';
import NotificationMessage from '../model/notificationMessage';
import { withRouter } from 'react-router-dom';
import { getMatchingStocksWithLastRequestTimeStamp } from '../actions/stock';

const style = (theme) => ({
  toolbar: theme.mixins.toolbar,
  webpageStyle: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainStyle: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    flex: '1 0 auto',
  },
});

export class App extends React.Component {
  componentDidMount = () => {
    this.unlisten = this.props.history.listen((location, action) => {
      this.props.getMatchingStocksWithLastRequestTimeStamp(
        this.props.token,
        this.props.lastHasMatchingRequest
      );
    });
  };

  componentWillUnmount = () => {
    this.unlisten();
  };

  render() {
    const { children, classes, token, user, notifications } = this.props;
    return (
      <div className={classes.webpageStyle}>
        <Navbar
          className={classes.navbarStyle}
          unread={user.unread ? user.unread : 0}
          isLoggedIn={!!token}
          notifications={notifications}
        />
        <main className={classes.mainStyle}>
          <div className={classes.toolbar} />
          <Container className={classes.contentContainer}>{children}</Container>
        </main>
        <Footer className={classes.footerStyle} user={user} token={token} />
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.instanceOf(NotificationMessage))
    .isRequired,
  token: PropTypes.string,
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
  }).isRequired,
  getMatchingStocksWithLastRequestTimeStamp: PropTypes.func.isRequired,
  lastHasMatchingRequest: PropTypes.instanceOf(Date),
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.user,
  notifications: state.notifications,
  lastHasMatchingRequest: state.stocks.lastHasMatchingRequest,
});

const mapDispatchToProps = (dispatch) => ({
  getMatchingStocksWithLastRequestTimeStamp: (token, lastHasMatchingRequest) =>
    dispatch(
      getMatchingStocksWithLastRequestTimeStamp(token, lastHasMatchingRequest)
    ),
});

export default withStyles(style)(
  withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)))
);
