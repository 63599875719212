import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import InformationContainer from '../informationContainer';
import UldLeft from '../../../icons/uldLeft';
import UldRight from '../../../icons/uldRight';
import { useMedia } from '../../../utils/customHooks';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '50em',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerForBooking: {
    display: 'flex',
    height: '100%',
    paddingRight: '2rem',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  gridItemHeadline: {
    fontWeight: '700',
    padding: '9px 0',
    fontSize: '12pt',
    width: 'fit-content',
    minWidth: 'unset',
    minHeight: 'unset',
    maxHeight: 'unset',
    maxWidth: 'unset',
    textAlign: 'unset',
    textTransform: 'uppercase',
    color: theme.palette.primary.chambray,
  },
  line: {
    backgroundColor: theme.palette.primary.linkWater25,
    height: '4px',
    display: 'flex',
    width: '100%',
    marginTop: '2em',
    marginBottom: '2em',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  grid: {
    marginBottom: '1rem',
  },
  checked: {
    color: `${theme.palette.primary.blueZodiac}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.lightBlue,
    },
  },
  form: {
    width: '100%',
  },
  gridUldArrow: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export function BookingDetailsRequestDetails({ booking }) {
  const classes = useStyles();
  const { isMobile } = useMedia(
    ['(min-width: 600px)'],
    [
      {
        isMobile: false,
      },
    ],
    {
      isMobile: true,
    }
  );

  return (
    <span>
      <Grid container justify="center">
        <Grid item xs={12} sm={4} md={4}>
          <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
            <FormattedMessage id="BOOKINGS.DETAILS.REQUEST_DETAILS" />
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={8}>
          <Grid container justify="center">
            <Grid item xs={12} sm={5} md={5} className={classes.grid}>
              <InformationContainer
                headline={<FormattedMessage id="BOOKINGS.DETAILS.OWN_AMOUNT" />}
                subtitle={
                  <FormattedMessage
                    id={
                      booking.bookedStock.amount > 0
                        ? 'BOOKINGS.DETAILS.AMOUNT_OFFERING'
                        : 'BOOKINGS.DETAILS.AMOUNT_SEARCHING'
                    }
                    values={{ amount: Math.abs(booking.bookedStock.amount) }}
                  />
                }
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} className={classes.gridUldArrow}>
              {!isMobile &&
                (booking.bookedStock.amount > booking.amountBooked ? (
                  <UldRight />
                ) : (
                  <UldLeft />
                ))}
            </Grid>
            <Grid item xs={12} sm={5} md={5} className={classes.grid}>
              <InformationContainer
                headline={
                  <FormattedMessage id="BOOKINGS.DETAILS.REQUESTOR_AMOUNT" />
                }
                subtitle={
                  <FormattedMessage
                    id={
                      booking.amountBooked > 0
                        ? 'BOOKINGS.DETAILS.AMOUNT_OFFERING'
                        : 'BOOKINGS.DETAILS.AMOUNT_SEARCHING'
                    }
                    values={{ amount: Math.abs(booking.amountBooked) }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={7} className={classes.grid}>
              <InformationContainer
                headline={<FormattedMessage id="BOOKINGS.DETAILS.ULD_TYPE" />}
                subtitle={booking.bookedStock.uldType}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} className={classes.grid}>
              <InformationContainer
                headline={<FormattedMessage id="BOOKINGS.DETAILS.STATION" />}
                subtitle={booking.bookedStock.iataCode}
              />
            </Grid>
            <Grid item xs={12} sm={7} md={7} className={classes.grid}>
              <InformationContainer
                headline={
                  <FormattedMessage id="BOOKINGS.DETAILS.OWN_AVAILABLE_FROM" />
                }
                subtitle={
                  <FormattedDate
                    year="numeric"
                    month="2-digit"
                    day="2-digit"
                    value={booking.bookedStock.availableFrom}
                  />
                }
              />
            </Grid>

            <Grid item xs={12} sm={5} md={5} className={classes.grid}>
              <InformationContainer
                headline={
                  <FormattedMessage id="BOOKINGS.DETAILS.OWN_VALID_UNTIL" />
                }
                subtitle={
                  <FormattedDate
                    year="numeric"
                    month="2-digit"
                    day="2-digit"
                    value={booking.bookedStock.validUntil}
                  />
                }
              />
            </Grid>

            <Grid item xs={12} sm={5} md={7} className={classes.grid}>
              <InformationContainer
                headline={
                  <FormattedMessage id="BOOKINGS.DETAILS.OWN_DESTINATION" />
                }
                subtitle={
                  booking.bookedStock.destinationIataCode &&
                  booking.bookedStock.destinationIataCode.length > 0 ? (
                    booking.bookedStock.destinationIataCode.join(', ')
                  ) : (
                    <FormattedMessage id="BOOKINGS.DETAILS.ANY_AIRPORT" />
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={5} md={5} className={classes.grid}>
              <InformationContainer
                headline={
                  <FormattedMessage id="BOOKINGS.DETAILS.BOOKING_DATE" />
                }
                subtitle={
                  <FormattedDate
                    year="numeric"
                    month="2-digit"
                    day="2-digit"
                    value={booking.creationDate}
                  ></FormattedDate>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </span>
  );
}

BookingDetailsRequestDetails.propTypes = {
  booking: PropTypes.shape({
    bookingUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    creationDate: PropTypes.string.isRequired,
    stockCreatorUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    bookedStock: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      uldType: PropTypes.string.isRequired,
      iataCode: PropTypes.string.isRequired,
      availableFrom: PropTypes.string.isRequired,
      validUntil: PropTypes.string.isRequired,
      destinationIataCode: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    returnAirport: PropTypes.shape({
      iataCode: PropTypes.string.isRequired,
    }),
    requestComment: PropTypes.string,
    replyComment: PropTypes.string,
    fromWhen: PropTypes.string.isRequired,
    tillWhen: PropTypes.string.isRequired,
    amountBooked: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    incoming: PropTypes.bool.isRequired,
    denied: PropTypes.bool.isRequired,
    additionalInformation: PropTypes.string,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default BookingDetailsRequestDetails;
