import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { FormattedDate, intlShape } from 'react-intl';
import BlueInfoContainer from '../blueInfoContainer';
import UldRight from '../../icons/uldRight';
import UldLeft from '../../icons/uldLeft';
import BookingOverviewIcon from './bookingOverviewIcon';
import { LeasingStockUldShapedBox } from '../iconUldShapedBox';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    borderBottom: `0.4rem solid ${theme.palette.primary.linkWater}`,
  },
  sortLabel: {
    padding: '2.5rem 0 1.5rem 0',
    transition: '200ms background-color cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: theme.palette.primary.chambray,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.linkWater25,
    },
  },
  tableCell: {
    padding: '14px 0px',
  },
  tableStyle: {
    padding: '14px 0px',
  },
  iconCell: {
    padding: '0',
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const formattedHeadRows = (formatMessage) => [
  { id: 'type', sortable: false, numeric: false, disablePadding: false },
  {
    id: 'ownCompanyCode',
    sortable: true,
    numeric: true,
    disablePadding: true,
    label: formatMessage({ id: 'BOOKINGS.OVERVIEWTABLE.HEADLINE.OWN' }),
  },
  {
    id: 'isMovingToPartner',
    sortable: true,
    numeric: true,
    disablePadding: true,
    label: formatMessage({ id: 'BOOKINGS.OVERVIEWTABLE.HEADLINE.DIRECTION' }),
  },
  {
    id: 'partnerCompanyCode',
    sortable: true,
    numeric: true,
    disablePadding: true,
    label: formatMessage({ id: 'BOOKINGS.OVERVIEWTABLE.HEADLINE.PARTNER' }),
  },
  {
    id: 'amount',
    sortable: true,
    numeric: true,
    disablePadding: true,
    label: formatMessage({ id: 'BOOKINGS.OVERVIEWTABLE.HEADLINE.AMOUNT' }),
  },
  {
    id: 'type',
    sortable: true,
    numeric: true,
    disablePadding: true,
    label: formatMessage({ id: 'BOOKINGS.OVERVIEWTABLE.HEADLINE.TYPE' }),
  },
  {
    id: 'stationCode',
    sortable: true,
    numeric: true,
    disablePadding: true,
    label: formatMessage({
      id: 'BOOKINGS.OVERVIEWTABLE.HEADLINE.AIRPORT.CODE',
    }),
  },
  {
    id: 'bookingDate',
    sortable: true,
    numeric: true,
    disablePadding: true,
    label: formatMessage({ id: 'BOOKINGS.OVERVIEWTABLE.HEADLINE.BOOKINGDATE' }),
  },
];

function EnhancedTableHead(props) {
  const { isDescending, orderBy, onRequestSort, headRows } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const direction = isDescending ? 'desc' : 'asc';
  const classes = useStyles();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            className={classes.tableCell}
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.bookingDate ? direction : true}
          >
            {row.sortable && (
              <TableSortLabel
                className={classes.sortLabel}
                active={orderBy === row.id}
                direction={direction}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default function OverviewTable({
  data,
  intl,
  onRowClick = () => {},
  standardSort = 'creationDate',
}) {
  const classes = useStyles();
  const [isDescending, setDescending] = React.useState(true);
  const [orderBy, setOrderBy] = React.useState(standardSort);

  function handleRequestSort(event, property) {
    setDescending(orderBy === property && !isDescending);
    setOrderBy(property);
  }

  function handleClick(dataId) {
    onRowClick(dataId);
  }

  const getSortedRows = () => {
    const sortedRows = [...data].sort((a, b) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    });
    if (!isDescending) return sortedRows.reverse();
    return sortedRows;
  };

  const orderedRows = getSortedRows();

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            isDescending={isDescending}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headRows={formattedHeadRows(intl.formatMessage)}
          />
          <TableBody>
            {orderedRows.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={() => handleClick(row.id)}
                  tabIndex={-1}
                  key={index}
                  classes={{
                    hover: classes.hover,
                  }}
                >
                  <TableCell className={classes.iconCell} align="left">
                    {row.leasing && <LeasingStockUldShapedBox height={60} />}
                  </TableCell>
                  <TableCell
                    className={classes.tableStyle}
                    id={labelId}
                    scope="row"
                    align="right"
                    padding="none"
                  >
                    {row.ownCompanyCode}
                  </TableCell>
                  <TableCell
                    className={classes.tableStyle}
                    align="right"
                    padding="none"
                  >
                    {row.isMovingToPartner ? <UldRight /> : <UldLeft />}
                  </TableCell>
                  <TableCell
                    className={classes.tableStyle}
                    align="right"
                    padding="none"
                  >
                    {row.partnerCompanyCode}
                  </TableCell>
                  <TableCell
                    className={classes.tableStyle}
                    align="right"
                    padding="none"
                  >
                    {Math.abs(row.amount)}
                  </TableCell>
                  <TableCell
                    className={classes.tableStyle}
                    align="right"
                    padding="none"
                  >
                    {row.type}
                  </TableCell>
                  <TableCell
                    className={classes.tableStyle}
                    align="right"
                    padding="none"
                  >
                    {row.stationCode}
                  </TableCell>

                  <TableCell
                    className={classes.tableStyle}
                    align="right"
                    padding="none"
                  >
                    <FormattedDate
                      year="numeric"
                      month="2-digit"
                      day="2-digit"
                      value={String(row.bookingDate)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {orderedRows.length < 1 && <BlueInfoContainer />}
      </div>
    </div>
  );
}

OverviewTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      ownCompanyCode: PropTypes.string.isRequired,
      partnerCompanyCode: PropTypes.string.isRequired,
      isMovingToPartner: PropTypes.bool.isRequired,
      leasing: PropTypes.bool.isRequired,
      amount: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      stationCode: PropTypes.string.isRequired,
      returnStationCode: PropTypes.string.isRequired,
      bookingDate: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    })
  ),
  intl: intlShape,
};
