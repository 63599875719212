import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import { gridItemHeadline, grid, grid_margin } from '../addNewStock/cssStyles';
import ChatIcon from '../../icons/chat';
import CallIcon from '../../icons/phone';
import MenuButton from '../menuButton';
import HoverInfo from '../hoverInfo';
import { useMedia } from '../../utils/customHooks';

const useStyles = makeStyles((theme) => ({
  gridItemHeadline: gridItemHeadline(theme),
  grid: grid,
  grid_margin: grid_margin,
  buttonEmail: {
    display: 'flex',
    marginBottom: '1em',
    '&:hover': {
      background: 'none',
    },
  },
  buttonSingle: {
    display: 'flex',
    '&:hover': {
      background: 'none',
    },
  },
}));

export default function LostAndFoundContact({
  contactPerson,
  companyName,
  phone,
  scrollToComments,
}) {
  const classes = useStyles();

  const { paddingRight } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        paddingRight: '30px',
      },
    ],
    {
      paddingRight: '0px',
    }
  );

  return (
    <span>
      <Grid container>
        <Grid item xs={12} md={4}>
          <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
            <FormattedMessage id="BASE.CONTACT" />
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid
              item
              xs={8}
              md={6}
              className={classes.grid}
              style={{ paddingRight: paddingRight }}
            >
              <FormControl fullWidth>
                <TextField
                  id="standard-name"
                  label={<FormattedMessage id="BASE.NAME" />}
                  type="text"
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MenuButton
                          id="openEmailButton"
                          classes={{ label: classes.buttonEmail }}
                          img={<ChatIcon />}
                          text={<FormattedMessage id="BASE.LOST_FOUND_CHAT" />}
                          isTableButton={true}
                          onClick={scrollToComments}
                        />
                      </InputAdornment>
                    ),
                  }}
                  value={contactPerson}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={4}
              md={6}
              className={classes.grid}
              style={{ paddingRight: paddingRight }}
            ></Grid>
            <Grid
              item
              xs={12}
              md={6}
              className={classes.grid}
              style={{ paddingRight: paddingRight }}
            >
              <FormControl fullWidth>
                <TextField
                  id="company-name"
                  label={<FormattedMessage id="BASE.COMPANY_NAME" />}
                  type="text"
                  margin="normal"
                  value={companyName}
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </span>
  );
}

LostAndFoundContact.propTypes = {
  contactPerson: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  scrollToComments: PropTypes.func.isRequired,
};
