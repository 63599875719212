import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import CancelButton from './cancelButton';
import UldShapedButtonBig from './uldShapedButtonBig';
import { useMedia } from '../utils/customHooks';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    height: '5em',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.linkWater25,
  },
  buttonDiv: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginRight: '2.5em',
    position: 'relative',
    justifyContent: 'flex-end',
  },
  borderSpacer: {
    backgroundColor: theme.palette.primary.linkWater25,
    width: '2.5em',
    height: '2.5em',
    color: 'white',
  },
  border: {
    borderTop: `2.5em solid ${theme.palette.primary.linkWater25}`,
    paddingRight: '0px',
    textDecoration: 'none',
    color: 'white',
    width: '0px',
    borderRight: '2.5em solid transparent',
    height: 0,
    lineHeight: '10px',
  },
  uldShapedButtonStyle: {
    minWidth: '30%',
  },
  rootDiv: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '2.5rem',
  },
}));

export default function UldShapedFooterWithButtons(props) {
  const classes = useStyles();

  const {
    cancelButtonOnClick,
    confirmButtonOnClick,
    confirmButtonLabel,
    confirmButtonType,
    cancelButtonLabel,
    buttonDisabled,
  } = props;

  return (
    <div className={classes.rootDiv}>
      <div className={classes.buttonContainer}>
        <div className={classes.buttonDiv}>
          <CancelButton
            width=""
            onClick={cancelButtonOnClick}
            padding="0 45px"
            buttonType="button"
            messageId={cancelButtonLabel}
          />
          <div className={classes.uldShapedButtonStyle}>
            <UldShapedButtonBig
              onClick={confirmButtonOnClick}
              buttonType={confirmButtonType}
              disabled={buttonDisabled}
            >
              {confirmButtonLabel}
            </UldShapedButtonBig>
          </div>
        </div>
      </div>
      <div>
        <div className={classes.borderSpacer} />
        <div className={classes.border} />
      </div>
    </div>
  );
}

UldShapedFooterWithButtons.defaultProps = {
  buttonDisabled: false,
};

UldShapedFooterWithButtons.propTypes = {
  buttonDisabled: PropTypes.bool,
  cancelButtonOnClick: PropTypes.func.isRequired,
  cancelButtonLabel: PropTypes.string,
  confirmButtonType: PropTypes.oneOf(['button', 'submit', 'reset']).isRequired,
  confirmButtonOnClick: PropTypes.func,
  confirmButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
};
