import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { Paper, makeStyles } from '@material-ui/core';
import InputComponent from './inputComponent';
import SuggestionRenderer from './suggestionRenderer';
import { getSuggestions } from './suggestionService';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
}));

export function SingleSelect({
  label,
  data,
  selected,
  setSelected,
  required,
  id,
  showErrors,
  onShowError,
  showEmpty,
}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState(
    selected ? selected.value : ''
  );

  React.useEffect(() => {
    setInputValue(selected ? selected.value : '');
  }, [selected]);

  const itemToString = (item) => {
    if (item) return item.value;
  };

  const onChange = (item) => {
    setInputValue(item ? item.value : '');
    setSelected(item ? item.value : '');
  };

  function handleInputChange(event) {
    setInputValue(event.target.value);
    if (!event.target.value || event.target.value === '') {
      onChange();
    }
  }

  const handleBlur = () => {
    if (!selected.value) setInputValue('');
  };

  return (
    <Downshift
      id={id}
      onChange={onChange}
      itemToString={itemToString}
      selectedItem={selected}
      inputValue={inputValue}
      defaultHighlightedIndex={0}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        openMenu,
        selectedItem,
      }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          onFocus: openMenu,
        });

        const suggestions = getSuggestions(inputValue, data, 5, {
          showEmpty,
        });

        return (
          <div className={classes.container}>
            {
              <InputComponent
                fullWidth={true}
                label={label}
                required={required}
                onInvalid={onShowError ? () => onShowError() : null}
                error={showErrors ? (inputValue !== '' ? false : true) : false}
                InputLabelProps={getLabelProps()}
                InputProps={{
                  onBlur: (event) => {
                    handleBlur();
                    onBlur(event);
                  },
                  onChange: (event) => {
                    handleInputChange(event);
                    onChange(event);
                  },
                  onFocus,
                }}
                {...inputProps}
              />
            }

            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {suggestions.map((suggestion, index) => (
                    <SuggestionRenderer
                      highlightedIndex={highlightedIndex}
                      suggestion={suggestion}
                      key={suggestion.value}
                      index={index}
                      selectedItem={selectedItem ? selectedItem.value : ''}
                      itemProps={getItemProps({
                        item: suggestion,
                      })}
                    />
                  ))}
                </Paper>
              ) : null}
            </div>
          </div>
        );
      }}
    </Downshift>
  );
}

SingleSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selected: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  setSelected: PropTypes.func.isRequired,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  showEmpty: PropTypes.bool,
};

export default SingleSelect;
