import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';

export default function SuggestionRenderer(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } =
    suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.value) > -1;

  return (
    <MenuItem
      {...itemProps}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

SuggestionRenderer.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};
