import React from 'react';

import { postToServer, getFromServer } from '../api';
import { Api, Routes, Actions } from '../constants';
import { FormattedMessage } from 'react-intl';
import { createAction } from './utils';

const errorMessage = (errorCode, messageId, enqueueSnackbar) =>
  enqueueSnackbar(<FormattedMessage id={messageId} values={{ errorCode }} />, {
    variant: 'error',
  });

const errorHandeling =
  (messageId, enqueueSnackbar) =>
  (resp = {}) => {
    if (resp.json)
      return resp
        .json()
        .then(({ errorCode }) =>
          errorMessage(errorCode, messageId, enqueueSnackbar)
        )
        .catch(() => errorMessage('GENERAL_ERROR', messageId, enqueueSnackbar));
    else return errorMessage('GENERAL_ERROR', messageId, enqueueSnackbar);
  };

export const addStockBooking = (token, stockBooking, enqueueSnackbar, push) => {
  return postToServer(Api.BOOKER_API.BOOKING.BOOK_STOCK, stockBooking, token)
    .then((response) => {
      if (response.ok) {
        enqueueSnackbar(<FormattedMessage id="BOOKNOW.SUCCESSFULLY_POSTED" />, {
          variant: 'success',
        });
        push(Routes.APP.AIRPORT_STOCKS.INDEX);
      } else {
        throw response;
      }
    })
    .catch(errorHandeling('BOOKNOW.ERROR_IN_REQUEST', enqueueSnackbar));
};

export const addLeasingStockBooking = (
  token,
  leasingStockBooking,
  enqueueSnackbar,
  push
) => {
  return postToServer(
    Api.BOOKER_API.BOOKING.LEASING.BOOK_STOCK,
    leasingStockBooking,
    token
  )
    .then((response) => {
      if (response.ok) {
        enqueueSnackbar(<FormattedMessage id="BOOKNOW.SUCCESSFULLY_POSTED" />, {
          variant: 'success',
        });
        push(Routes.APP.AIRPORT_STOCKS.INDEX);
      } else {
        throw response;
      }
    })
    .catch(errorHandeling('BOOKNOW.ERROR_IN_REQUEST', enqueueSnackbar));
};

const getBookings = (
  path,
  action,
  token,
  enqueueSnackbar,
  withCompanyBookings = false,
  dispatch
) => {
  const filterString = withCompanyBookings ? `?withCompanyBookings=1` : '';

  return getFromServer(`${path}${filterString}`, token)
    .then((response) => {
      if (response.ok)
        response
          .json()
          .then(({ data }) => dispatch(createAction(action, data)));
      else throw response;
    })
    .catch(() => {});
  //.catch(errorHandeling("BOOKNOW.ERROR_IN_REQUEST", enqueueSnackbar));
};

export const getIncomingOpenBookings =
  (token, enqueueSnackbar, withCompanyBookings = false) =>
  (dispatch) =>
    getBookings(
      Api.BOOKER_API.BOOKING.INCOMING.GET_OPEN_BOOKINGS,
      Actions.BOOKINGS.INCOMING.ADD_OPEN_BOOKINGS,
      token,
      enqueueSnackbar,
      withCompanyBookings,
      dispatch
    );
export const getIncomingConfirmedBookings =
  (token, enqueueSnackbar, withCompanyBookings = false) =>
  (dispatch) =>
    getBookings(
      Api.BOOKER_API.BOOKING.INCOMING.GET_CONFIRMED_BOOKINGS,
      Actions.BOOKINGS.INCOMING.ADD_CONFIRMED_BOOKINGS,
      token,
      enqueueSnackbar,
      withCompanyBookings,
      dispatch
    );
export const getIncomingDeniedBookings =
  (token, enqueueSnackbar, withCompanyBookings = false) =>
  (dispatch) =>
    getBookings(
      Api.BOOKER_API.BOOKING.INCOMING.GET_DENIED_BOOKINGS,
      Actions.BOOKINGS.INCOMING.ADD_DENIED_BOOKINGS,
      token,
      enqueueSnackbar,
      withCompanyBookings,
      dispatch
    );
export const getOutgoingOpenBookings =
  (token, enqueueSnackbar, withCompanyBookings = false) =>
  (dispatch) =>
    getBookings(
      Api.BOOKER_API.BOOKING.OUTGOING.GET_OPEN_BOOKINGS,
      Actions.BOOKINGS.OUTGOING.ADD_OPEN_BOOKINGS,
      token,
      enqueueSnackbar,
      withCompanyBookings,
      dispatch
    );
export const getOutgoingConfirmedBookings =
  (token, enqueueSnackbar, withCompanyBookings = false) =>
  (dispatch) =>
    getBookings(
      Api.BOOKER_API.BOOKING.OUTGOING.GET_CONFIRMED_BOOKINGS,
      Actions.BOOKINGS.OUTGOING.ADD_CONFIRMED_BOOKINGS,
      token,
      enqueueSnackbar,
      withCompanyBookings,
      dispatch
    );
export const getOutgoingDeniedBookings =
  (token, enqueueSnackbar, withCompanyBookings = false) =>
  (dispatch) =>
    getBookings(
      Api.BOOKER_API.BOOKING.OUTGOING.GET_DENIED_BOOKINGS,
      Actions.BOOKINGS.OUTGOING.ADD_DENIED_BOOKINGS,
      token,
      enqueueSnackbar,
      withCompanyBookings,
      dispatch
    );
export const getAllBookings =
  (token, enqueueSnackbar, withCompanyBookings = false) =>
  (dispatch) =>
    getBookings(
      Api.BOOKER_API.BOOKING.GET_ALL_BOOKINGS,
      Actions.BOOKINGS.ADD_ALL_BOOKINGS,
      token,
      enqueueSnackbar,
      withCompanyBookings,
      dispatch
    );

export const getSpecificBooking = async (bookingId, token, errorFunction) => {
  return await getFromServer(
    Api.BOOKER_API.BOOKING.GET_SPECIFIC_BOOKING + bookingId,
    token
  )
    .then(async (response) => {
      if (response.ok) return await response.json().then(({ data }) => data);
      else throw response;
    })
    .catch((resp = {}) => {
      if (resp.json)
        resp
          .json()
          .then(({ errorCode }) =>
            errorFunction(errorCode ? errorCode : 'GENERAL_ERROR')
          );
      else errorFunction('GENERAL_ERROR');
      return {};
    });
};

export const denyStockBooking = (
  bookingId,
  additionalComment,
  token,
  successCallback,
  enqueueSnackbar
) => {
  return postToServer(
    Api.BOOKER_API.BOOKING.DENY_REQUEST(bookingId),
    additionalComment,
    token
  )
    .then((response) => {
      if (response.ok) {
        enqueueSnackbar(<FormattedMessage id="BOOKINGS.DENY_SUCCESSFULL" />, {
          variant: 'success',
        });
        successCallback();
      } else {
        throw response;
      }
    })
    .catch(errorHandeling('BOOKINGS.DENY_FAILED', enqueueSnackbar));
};

export const acceptStockBooking = (
  bookingId,
  additionalComment,
  token,
  successCallback,
  enqueueSnackbar
) => {
  return postToServer(
    Api.BOOKER_API.BOOKING.ACCEPT_REQUEST(bookingId),
    additionalComment,
    token
  )
    .then((response) => {
      if (response.ok) {
        enqueueSnackbar(<FormattedMessage id="BOOKINGS.ACCEPT_SUCCESSFULL" />, {
          variant: 'success',
        });
        successCallback();
      } else {
        throw response;
      }
    })
    .catch(errorHandeling('BOOKINGS.ACCEPT_FAILED', enqueueSnackbar));
};

export const denyLeasingStockBooking = (
  bookingId,
  additionalComment,
  token,
  successCallback,
  enqueueSnackbar
) => {
  return postToServer(
    Api.BOOKER_API.BOOKING.DENY_LEASING_REQUEST(bookingId),
    additionalComment,
    token
  )
    .then((response) => {
      if (response.ok) {
        enqueueSnackbar(<FormattedMessage id="BOOKINGS.DENY_SUCCESSFULL" />, {
          variant: 'success',
        });
        successCallback();
      } else {
        throw response;
      }
    })
    .catch(errorHandeling('BOOKINGS.DENY_FAILED', enqueueSnackbar));
};

export const acceptLeasingStockBooking = (
  bookingId,
  additionalComment,
  token,
  successCallback,
  enqueueSnackbar
) => {
  return postToServer(
    Api.BOOKER_API.BOOKING.ACCEPT_LEASING_REQUEST(bookingId),
    additionalComment,
    token
  )
    .then((response) => {
      if (response.ok) {
        enqueueSnackbar(<FormattedMessage id="BOOKINGS.ACCEPT_SUCCESSFULL" />, {
          variant: 'success',
        });
        successCallback();
      } else {
        throw response;
      }
    })
    .catch(errorHandeling('BOOKINGS.ACCEPT_FAILED', enqueueSnackbar));
};

export const saveAdditionalInformationForBooking = (
  bookingId,
  additionalInformation,
  token,
  successCallback,
  enqueueSnackbar
) => {
  return postToServer(
    Api.BOOKER_API.BOOKING.SAVE_ADDITIONAL(bookingId),
    additionalInformation,
    token
  )
    .then((response) => {
      if (response.ok) {
        enqueueSnackbar(
          <FormattedMessage id="BOOKING.DETAILS.SAVE_ADDITIONAL_SUCCESSFULL" />,
          {
            variant: 'success',
          }
        );
        successCallback();
      } else {
        throw response;
      }
    })
    .catch(errorHandeling('BOOKINGS.ACCEPT_FAILED', enqueueSnackbar));
};
