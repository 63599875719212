import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NotificationMessage from '../../model/notificationMessage';
import NotificationItem from './notificationItem';
import List from '@material-ui/core/List';
import {
  getNotifications,
  markNotificationAsRead,
} from '../../actions/notifications';
import DialogPopup from '../dialog/dialogPopup';
import NotificationType from '../../constants/notificationTypes';

const styles = (theme) => ({
  headline: {
    fontSize: '1.5rem',
    fontWeight: '700',
    marginTop: '1.5em',
    padding: '0',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'unset',
    textTransform: 'none',
    color: theme.palette.primary.chambray,
  },
  line: {
    backgroundColor: theme.palette.primary.linkWater25,
    height: '8px',
    display: 'flex',
    width: '100%',
    marginTop: '2em',
  },
});

let timer = null;
let interval = 12000;
export class NotificationCenterList extends Component {
  state = {
    openDialog: false,
    dialogTitle: '',
    dialogMessage: '',
  };

  componentDidMount() {
    this.props.getNotifications(this.props.token);
    timer = setInterval(
      () => this.props.getNotifications(this.props.token),
      interval
    );
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  handleClick = (notification) => {
    this.markNotificationAsRead(notification);
    this.openNotificationLink(notification);
  };

  markNotificationAsRead = (notification) => {
    this.props.markNotificationAsRead(notification.id, this.props.token);
  };

  openNotificationLink = (notification) => {
    if (notification.type === NotificationType.SKYPOOLING_NEWS) {
      this.setState({
        openDialog: true,
        dialogTitle: notification.details.title,
        dialogMessage: notification.details.message,
      });
    } else {
      this.props.history.push(notification.targetUrl);
    }
  };

  closeDialog = () => {
    this.setState({
      openDialog: false,
      dialogTitle: '',
      dialogMessage: '',
    });
  };

  render() {
    const { notifications, classes } = this.props;

    return (
      <React.Fragment>
        <div className={`${classes.headline}`}>
          <FormattedMessage id="NOTIFICATIONCENTER.NOTIFICATION_OVERVIEW" />
        </div>
        <div className={classes.line} />
        <div className={classes.notificationList}>
          <List>
            {notifications.map((note, index) => {
              return (
                <NotificationItem
                  key={index}
                  notification={note}
                  onClick={this.handleClick}
                />
              );
            })}
          </List>
          <DialogPopup
            open={this.state.openDialog}
            title={this.state.dialogTitle}
            message={this.state.dialogMessage}
            closeFunction={this.closeDialog}
          />
        </div>
      </React.Fragment>
    );
  }
}

NotificationCenterList.propTypes = {
  classes: PropTypes.object.isRequired,
  getNotifications: PropTypes.func.isRequired,
  markNotificationAsRead: PropTypes.func.isRequired,
  token: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.instanceOf(NotificationMessage))
    .isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  getNotifications: (token) => {
    dispatch(getNotifications(token));
  },
  markNotificationAsRead: (notificationId, token) => {
    dispatch(markNotificationAsRead(notificationId, token));
  },
});

export default withRouter(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(NotificationCenterList)
  )
);
