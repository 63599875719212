import React, { Fragment } from 'react';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Grid,
  TextField,
  makeStyles,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import InformationContainer from './informationContainer';
import UldShapedFooterWithButtons from '../uldShapedFooterWithButtons';
import { saveAdditionalInformationForBooking } from '../../actions/booking';
import { withSnackbar } from 'notistack';
import { Routes } from '../../constants';
import AddNewStockLeasingSection from '../addNewStock/addNewStockLeasingSection';
import { useMedia } from '../../utils/customHooks';
import BookingDetailsRequestDetails from './bookingDetailsComponets/bookingDetailsRequestDetails';
import BookingContactSection from './bookingDetailsComponets/bookingContactSection';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '50em',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerForBooking: {
    display: 'flex',
    height: '100%',
    paddingRight: '2rem',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  gridItemHeadline: {
    fontWeight: '700',
    padding: '9px 0',
    fontSize: '12pt',
    width: 'fit-content',
    minWidth: 'unset',
    minHeight: 'unset',
    maxHeight: 'unset',
    maxWidth: 'unset',
    textAlign: 'unset',
    textTransform: 'uppercase',
    color: theme.palette.primary.chambray,
  },
  line: {
    backgroundColor: theme.palette.primary.linkWater25,
    height: '4px',
    display: 'flex',
    width: '100%',
    marginTop: '2em',
    marginBottom: '2em',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  grid: {
    marginBottom: '1rem',
  },
  checked: {
    color: `${theme.palette.primary.blueZodiac}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.lightBlue,
    },
  },
  form: {
    width: '100%',
  },
}));

export function BookingContainer({
  booking,
  onConfirm,
  onDeny,
  token,
  enqueueSnackbar,
  history,
}) {
  const { paddingRight } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        paddingRight: '30px',
      },
    ],
    {
      paddingRight: '0px',
    }
  );

  const classes = useStyles();

  const [message, setMessage] = React.useState();
  const [additionalValue, setAdditionalValue] = React.useState(
    booking.additionalInformation
  );
  const [stockStatus, setStockStatus] = React.useState(booking.stockStatus);
  const [leasingType, setLeasingType] = React.useState(
    booking.bookedStock.offerType
  );
  const [commercialInformation, setCommercialInformation] = React.useState(
    booking.bookedStock.offerInformation
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    saveAdditionalInformationForBooking(
      booking.id,
      {
        additionalInformation: additionalValue,
        stockStatus: stockStatus,
      },
      token,
      () =>
        history.push(
          booking.incoming
            ? Routes.APP.BOOKINGS.DETAILS.INCOMING
            : Routes.APP.BOOKINGS.DETAILS.OUTGOING
        ),
      enqueueSnackbar
    );
  };

  return (
    <Fragment>
      <BookingContactSection booking={booking} />
      <div className={classes.line} />
      <BookingDetailsRequestDetails booking={booking} />
      <div className={classes.line} />
      {booking.leasing && (
        <>
          <AddNewStockLeasingSection
            leasingType={leasingType}
            setLeasingType={setLeasingType}
            paddingRight={paddingRight}
            commercialInformation={commercialInformation}
            setCommercialInformation={setCommercialInformation}
            disableAllInputfields
          />
          {(booking.requestComment ||
            booking.replyComment ||
            !booking.denied) && <div className={classes.line} />}
        </>
      )}
      {(booking.requestComment || booking.replyComment) && (
        <Fragment>
          <span>
            <Grid container justify="center">
              <Grid item xs={12} sm={4} md={4}>
                <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
                  <FormattedMessage id="BOOKINGS.DETAILS.FURTHER_INFORMATION" />
                </div>
              </Grid>
              {booking.requestComment && (
                <Grid item xs={12} sm={8} md={8} className={classes.grid}>
                  <InformationContainer
                    headline={
                      <FormattedMessage id="BOOKINGS.DETAILS.REQUESTOR_COMMENT" />
                    }
                    subtitle={booking.requestComment}
                  />
                </Grid>
              )}
              {booking.requestComment && booking.replyComment && (
                <Grid item xs={false} sm={4} md={4} />
              )}
              {booking.replyComment && (
                <Grid item xs={12} sm={8} md={8} className={classes.grid}>
                  <InformationContainer
                    headline={
                      <FormattedMessage id="BOOKINGS.DETAILS.BOOKER_COMMENT" />
                    }
                    subtitle={booking.replyComment}
                  />
                </Grid>
              )}
            </Grid>
          </span>
          {!booking.denied && <div className={classes.line} />}
        </Fragment>
      )}
      {booking.open && booking.incoming && !booking.leasing && (
        <div style={{ marginTop: '3em' }}>
          <UldShapedFooterWithButtons
            confirmButtonLabel={
              <FormattedMessage id="BOOKINGS.DETAILS.CONFIRM_REQUEST" />
            }
            confirmButtonType="button"
            confirmButtonOnClick={() => onConfirm(message)}
            cancelButtonLabel={'BOOKINGS.DETAILS.DENY_REQUEST'}
            cancelButtonOnClick={() => onDeny(message)}
          />
        </div>
      )}
      {booking.open && booking.incoming && booking.leasing && (
        <div style={{ marginTop: '3em' }}>
          <UldShapedFooterWithButtons
            confirmButtonLabel={
              <FormattedMessage id="BOOKING.DETAILS.ACKNOWLEDGE_REQUEST" />
            }
            confirmButtonType="button"
            confirmButtonOnClick={() => onConfirm(message)}
            cancelButtonLabel={'BOOKING.DETAILS.DENY_REQUEST'}
            cancelButtonOnClick={() => onDeny(message)}
          />
        </div>
      )}
      {!booking.open && !booking.denied && (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container justify="center">
            <Grid item xs={12} sm={4} md={4}>
              <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
                <FormattedMessage id="BOOKING.DETAILS.ADDITIONAL_INFO" />
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <RadioGroup
                name="additionalInformation"
                style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
                onChange={(event) => setStockStatus(event.target.value)}
                value={stockStatus}
              >
                <FormControlLabel
                  value={'WITH_OWNER'}
                  control={
                    <Radio
                      classes={{ checked: classes.checked }}
                      color="default"
                    />
                  }
                  label={<FormattedMessage id="BOOKINGS.DETAILS.WITH_OWNER" />}
                />
                <FormControlLabel
                  value={'WITH_PARTY'}
                  control={
                    <Radio
                      classes={{ checked: classes.checked }}
                      color="default"
                    />
                  }
                  label={
                    <FormattedMessage id="BOOKINGS.DETAILS.WITH_BORROWING_PARTY" />
                  }
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={4} md={4}></Grid>
          </Grid>

          <Grid container justify="center">
            <Grid item xs={12} sm={4} md={4}></Grid>
            <Grid item xs={12} sm={8} md={8} className={classes.grid}>
              <TextField
                fullWidth
                id="additionalInformation"
                label={
                  <FormattedMessage id="BOOKIING.DETAILS.ADDITIONAL_PLACEHOLDER" />
                }
                type="text"
                value={additionalValue}
                multiline
                onChange={(event) => setAdditionalValue(event.target.value)}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: '3em' }}>
            <UldShapedFooterWithButtons
              confirmButtonLabel={
                <FormattedMessage id="BOOKING.DETAILS.SAVE_ADDITIONAL_BUTTON" />
              }
              confirmButtonType={'submit'}
              cancelButtonOnClick={history.goBack}
            />
          </div>
        </form>
      )}
    </Fragment>
  );
}

BookingContainer.propTypes = {
  booking: PropTypes.shape({
    bookingUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    bookedStock: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      uldType: PropTypes.string.isRequired,
      iataCode: PropTypes.string.isRequired,
      availableFrom: PropTypes.string.isRequired,
      validUntil: PropTypes.string.isRequired,
      destinationIataCode: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    returnAirport: PropTypes.shape({
      iataCode: PropTypes.string.isRequired,
    }),
    stockCreatorUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      company: PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    creationDate: PropTypes.string.isRequired,
    requestComment: PropTypes.string,
    replyComment: PropTypes.string,
    fromWhen: PropTypes.string.isRequired,
    tillWhen: PropTypes.string.isRequired,
    amountBooked: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    incoming: PropTypes.bool.isRequired,
    denied: PropTypes.bool.isRequired,
    additionalInformation: PropTypes.string,
    id: PropTypes.number.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDeny: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default withSnackbar(withRouter(injectIntl(BookingContainer)));
