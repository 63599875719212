import StockBookingQuery from './stockBookingQuery';

export default class LeasingStockBookingQuery extends StockBookingQuery {
  constructor({
    stockId,
    fromWhen,
    tillWhen,
    amountToBook,
    returnAirport,
    requestComment,
    replyComment,
    acceptTerms,
  }) {
    super({
      stockId,
      fromWhen,
      tillWhen,
      amountToBook,
      returnAirport,
      requestComment,
      replyComment,
    });
    this.acceptTerms = acceptTerms;
  }
}
