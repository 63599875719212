import React from 'react';

import Grid from '@material-ui/core/Grid';

import SimpleExpansionPanel from '../simpleExpansionPanel.jsx';

const array = [
  { question: 'HOME.FAQ.QUESTION_1', answer: 'HOME.FAQ.ANSWER_1' },
  { question: 'HOME.FAQ.QUESTION_5', answer: 'HOME.FAQ.ANSWER_5' },
  { question: 'HOME.FAQ.QUESTION_2', answer: 'HOME.FAQ.ANSWER_2' },
  { question: 'HOME.FAQ.QUESTION_6', answer: 'HOME.FAQ.ANSWER_6' },
  { question: 'HOME.FAQ.QUESTION_3', answer: 'HOME.FAQ.ANSWER_3' },
  { question: 'HOME.FAQ.QUESTION_7', answer: 'HOME.FAQ.ANSWER_7' },
  { question: 'HOME.FAQ.QUESTION_4', answer: 'HOME.FAQ.ANSWER_4' },
  { question: 'HOME.FAQ.QUESTION_8', answer: 'HOME.FAQ.ANSWER_8' },
];

export default function FAQsForm(props) {
  return (
    <Grid container spacing={2} justify="flex-start">
      {array.map((item) => (
        <Grid item xs={12} sm={6} key={item.question}>
          <SimpleExpansionPanel
            headlineMessageId={item.question}
            bodyMessageId={item.answer}
          />
        </Grid>
      ))}
    </Grid>
  );
}
