import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  styleTriangleUnderstock: {
    textDecoration: 'none',
    color: 'white',
    width: '0px',
    borderRight: '0.3rem solid transparent',
    borderTop: `0.3rem solid ${theme.palette.secondary.orangePeel}`,
    height: 0,
    lineHeight: '0.3rem',
  },
  styleTriangleOverstock: {
    textDecoration: 'none',
    color: 'white',
    width: '0px',
    borderRight: '0.3rem solid transparent',
    borderTop: `0.3rem solid ${theme.palette.secondary.green}`,
    height: 0,
    lineHeight: '0.3rem',
  },
  styleSquareTriangleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  styleSquareOverTriangleUnderStock: {
    width: '0.3rem',
    height: '1.45rem',
    backgroundColor: theme.palette.secondary.orangePeel,
  },
  styleBoxUnderStock: {
    height: '10%',
    backgroundColor: theme.palette.secondary.orangePeel,
  },
  styleSquareOverTriangleOverStock: {
    width: '0.3rem',
    height: '1.45rem',
    backgroundColor: theme.palette.secondary.green,
  },
  styleBoxOverStock: {
    height: '10%',
    backgroundColor: theme.palette.secondary.green,
  },
  styleWrapper: {
    display: 'inline-flex',
    justifyContent: 'center',
  },
  stockText: {
    color: theme.palette.secondary.white,
    fontWeight: '600',
    width: '2rem',
    padding: '0.3rem 0.3rem 0.3rem 0.3rem',
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '1.15rem',
  },
});

export class StockUldShapedBox extends Component {
  render() {
    const { height, width, classes, amount } = this.props;

    const isUnderstock = amount < 0 ? true : false;
    return (
      <React.Fragment>
        <div
          style={{ height: height, width: width }}
          className={classes.styleWrapper}
        >
          <div
            style={{ height: height }}
            className={
              isUnderstock
                ? classes.styleBoxUnderStock
                : classes.styleBoxOverStock
            }
          >
            <span className={classes.stockText}>
              {amount > 0 ? '+' + amount : amount}
            </span>
          </div>
          <span className={classes.styleSquareTriangleWrapper}>
            <div
              style={{ height: height }}
              className={
                isUnderstock
                  ? classes.styleSquareOverTriangleUnderStock
                  : classes.styleSquareOverTriangleOverStock
              }
            />
            <div
              className={
                isUnderstock
                  ? classes.styleTriangleUnderstock
                  : classes.styleTriangleOverstock
              }
            />
          </span>
        </div>
      </React.Fragment>
    );
  }
}

StockUldShapedBox.propTypes = {
  classes: PropTypes.object.isRequired,
  height: PropTypes.number,
};

export default withStyles(styles)(StockUldShapedBox);
