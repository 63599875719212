import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import IntlProviderWrapper from '../providers/IntlProviderWrapper';

import Routes from '../routes';
import SnackbarProviderWrapper from '../providers/SnackbarProviderWrapper';
import KeycloakProviderWrapper from '../providers/KeycloakProviderWrapper';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
    allVariants: {
      color: '#17375E',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 128,
    },
  },
  palette: {
    primary: {
      light: '#B4C6DC',
      main: '#376092',
      dark: '#17375E',
      contrastText: '#FFFFFF',
      blueZodiac: '#17375E',
      chambray: '#376092',
      cornflowerBlue: '#B4C6DC',
      linkWater: '#C6D9F1',
      linkWater50: '#DEE9F7',
      linkWater25: '#EFF3FA',
      lightGrey: '#A6A6A6',
      darkGrey: '#7F7F7F',
      slateGray: '#6B7F98', // Button-hover-color
    },
    secondary: {
      light: '#FFF4E5',
      main: '#FD9900',
      dark: '#FFA217',
      white: '#FFFFFF',
      contrastText: '#000',
      orangePeel: '#FD9900',
      green: '#00CC00',
      tangerine: '#FFA217',
      fulvous: '#E68C00', // Button-hover-color
    },
  },
});

const Root = ({ store }) => (
  <DocumentTitle title="skypooling">
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <KeycloakProviderWrapper>
          <IntlProviderWrapper>
            <SnackbarProviderWrapper>
              <Routes />
            </SnackbarProviderWrapper>
          </IntlProviderWrapper>
        </KeycloakProviderWrapper>
      </MuiThemeProvider>
    </Provider>
  </DocumentTitle>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
