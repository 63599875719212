import Company from './company';
import StockChatMessage from './stockChatMessage';

export default class StockChat {
  constructor({
    chatId,
    enquiring,
    enquiringCompany,
    stockChatMessages,
    stockCompany,
  }) {
    this.chatId = chatId;
    this.enquiring = enquiring;
    this.enquiringCompany = new Company(enquiringCompany);
    this.stockCompany = new Company(stockCompany);
    this.stockChatMessages = stockChatMessages.map(
      (message) => new StockChatMessage(message)
    );
  }
}
