import React from 'react';
import PropTypes from 'prop-types';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  Grid,
  ExpansionPanelSummary,
  Typography,
  Paper,
  Button,
  makeStyles,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Reminder from '../../model/reminder';
import ChevronDown from '../../icons/chevron_down';

const useStyle = makeStyles((theme) => ({
  subheader: {
    lineHeight: 2.6,
    color: theme.palette.primary.chambray,
  },
  informationPaper: {
    padding: '1rem',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
  flexEnd: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function ReminderComponent({
  reminder,
  deleteReminder,
  selectReminder,
}) {
  const classes = useStyle();

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ChevronDown />}>
        {reminder.title}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.subheader}>
              <FormattedMessage id="REMINDER.MESSAGE" />
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper className={classes.informationPaper}>
              <div dangerouslySetInnerHTML={{ __html: reminder.message }} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.subheader}>
              <FormattedMessage id="REMINDER.NEXT_EXECUTION" />
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper className={classes.informationPaper}>
              <FormattedMessage
                id="REMINDER.NEXT_EXECUTION_MESSAGE"
                values={{
                  nextExecutionTime: new Date(
                    reminder.nextExecutionTime.slice(0, 16)
                  ),
                  audience: (
                    <FormattedMessage
                      id={`REMINDER.AUDIENCE.${reminder.audience}`}
                    />
                  ),
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.subheader}>
              <FormattedMessage id="REMINDER.DESCRIPTION" />
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper className={classes.informationPaper}>
              <FormattedMessage
                id="REMINDER.INTERVAL_MESSAGE"
                values={{
                  intervalUnit: (
                    <FormattedMessage
                      id={`REMINDER.INTERVAL_UNIT.${reminder.intervalTimeUnit}`}
                      values={{
                        interval: reminder.interval,
                      }}
                    />
                  ),
                  firstExecutionTime: new Date(
                    reminder.firstExecutionTime.slice(0, 16)
                  ),
                  audience: (
                    <FormattedMessage
                      id={`REMINDER.AUDIENCE.${reminder.audience}`}
                    />
                  ),
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.flexEnd}>
              <Button
                color="secondary"
                id={`delete-reminder-${reminder.id}`}
                className="delete-reminder"
                onClick={() => deleteReminder(reminder.id)}
              >
                <FormattedMessage id="REMINDER.DELETE_REMINDER" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                id={`select-reminder-${reminder.id}`}
                className="select-reminder"
                onClick={() => selectReminder(reminder)}
              >
                <FormattedMessage id="REMINDER.EDIT" />
              </Button>
            </div>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

ReminderComponent.propTypes = {
  reminder: PropTypes.instanceOf(Reminder).isRequired,
  deleteReminder: PropTypes.func.isRequired,
  selectReminder: PropTypes.func.isRequired,
};
