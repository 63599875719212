import React from 'react';
import { FormattedMessage } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { useMedia } from '../../utils/customHooks';
import { useSelector } from 'react-redux';
import { COOKIE_KEYS } from '../../utils/cookieUtils';
import CookieNotAllowed from '../cookieNotAllowed';

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '50px',
  },
  title: {
    color: theme.palette.primary.dark,
    textTransform: 'uppercase',
    fontSize: '1rem',
    margin: '10px 0',
    fontWeight: '600',
  },
}));

export default function VideoContainer() {
  const classes = styles();

  const accepted = useSelector((state) => state.user.consent);

  const { textAlign, height } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        textAlign: 'left',
        height: '720px',
      },
    ],
    {
      textAlign: 'center',
      height: '360px',
    }
  );

  return (
    <div className={classes.root}>
      <div style={{ height }}>
        {accepted === COOKIE_KEYS.ACCEPT_ALL ? (
          <iframe
            title="videoContainerFrame"
            width="100%"
            height={height}
            src="https://www.youtube.com/embed/kmp9ECg7CCU?rel=0"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <CookieNotAllowed />
        )}
      </div>
    </div>
  );
}
