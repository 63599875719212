import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { ListItem, ListItemText, Grid } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  textStyle: {
    fontSize: '1.125rem',
    color: theme.palette.primary.dark,
    width: '100%',
    padding: '40px',
    paddingBottom: '40px',
  },
  subtitle: {
    color: theme.palette.primary.dark,
    fontSize: '1.625rem',
    fontWeight: '600',
  },
  linkButtons: {
    color: theme.palette.primary.main,
    margin: '0px',
    textDecoration: 'none',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
}));

export function PressRelease() {
  const classes = styles();

  return (
    <div className={classes.textStyle}>
      <ListItem style={{ paddingBottom: '20px' }}>
        <div className={classes.subtitle}>
          <FormattedMessage id="EVENTS_CALENDER.INNER_TITLE_2" />
        </div>
      </ListItem>
      <ListItem>
        <Grid container>
          <Grid item xs={3}>
            <FormattedMessage id="EVENTS_CALENDER.SUSTAINABILITY.DATE" />
          </Grid>
          <Grid item xs={9} className={classes.linkButtons}>
            <a
              href={`/2021_10_07_JT_PR_skypooling_sustainability_EN.pdf`}
              className={classes.linkButtons}
              download
            >
              <FormattedMessage id="EVENTS_CALENDER.SUSTAINABILITY.TEXT" />
            </a>
          </Grid>
        </Grid>
      </ListItem>

      <ListItem>
        <Grid container>
          <Grid item xs={3}>
            <FormattedMessage id="EVENTS_CALENDER.INNER_TEXT_4" />
          </Grid>
          <Grid item xs={9} className={classes.linkButtons}>
            <a
              href={`/20200428_JT_PR_skypooling_corona.pdf`}
              className={classes.linkButtons}
              download
            >
              <FormattedMessage id="EVENTS_CALENDER.LINK_TEXT_7" />
            </a>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem className={classes.paddingStyle}>
        <Grid container>
          <Grid item xs={3}>
            <FormattedMessage id="EVENTS_CALENDER.INNER_TEXT_3" />
          </Grid>
          <Grid item xs={9}>
            <a href="/PM02.pdf" className={classes.linkButtons} download>
              <FormattedMessage id="EVENTS_CALENDER.LINK_TEXT_3" />
            </a>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem className={classes.paddingStyle}>
        <Grid container>
          <Grid item xs={3}>
            <FormattedMessage
              classes={{ primary: classes.textStyle }}
              id="EVENTS_CALENDER.INNER_TEXT_2"
            />
          </Grid>
          <Grid item xs={9}>
            <a
              href={`/2019_06_03_JT_PM_skypooling_eng_V3.pdf`}
              className={classes.linkButtons}
              download
            >
              <FormattedMessage
                classes={{ primary: classes.textStyle }}
                id="EVENTS_CALENDER.LINK_TEXT_2"
              />
            </a>
          </Grid>
        </Grid>
      </ListItem>
    </div>
  );
}

PressRelease.propTypes = {};

export default PressRelease;
