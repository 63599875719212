import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { patchStock } from '../actions/stock';

import StockForm from '../components/stockForm';
import { Routes } from '../constants';
import { getAirports } from '../actions/airports';
import { getUldTypes } from '../actions/uldTypes';
import HeadlineBox from '../components/headlineBox';
import { patchLeasingStock } from '../actions/leasingStock';
import { errorHandeling } from '../actions/utils';

const styles = (theme) => ({
  newStockHeadlineDiv: {
    fontSize: '1.5rem',
    fontWeight: '700',
    marginBottom: '2em',
    marginTop: '1.5em',
    padding: '0',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'left',
    textTransform: 'none',
    color: theme.palette.primary.chambray,
  },
});

export class EditStock extends Component {
  componentDidMount = () => {
    if (!this.props.specialStock.id) {
      this.props.history.push(Routes.APP.MY_INVENTORY.INDEX);
    } else {
      this.props.getAirports(this.props.token);
      this.props.getUldTypes(this.props.token);
    }
  };

  handleSubmit = ({
    contactPerson,
    email,
    phone,
    factor,
    amount,
    uldType,
    iataCode,
    validUntil,
    destinationIataCode,
    availableFrom,
    comment,
    location,
    isLeasing,
    offerInformation,
    offerType,
  }) => {
    const validMail =
      email !== undefined && email !== null && email !== '' && email !== ' ';
    const validPhone =
      phone !== undefined && phone !== null && phone !== '' && phone !== ' ';

    const editFunction = isLeasing
      ? this.props.patchLeasingStock
      : this.props.patchStock;

    validMail || validPhone
      ? editFunction(
          {
            id: this.props.specialStock.id,
            contactPerson,
            email,
            phone,
            factor,
            amount,
            uldType,
            iataCode,
            validUntil,
            destinationIataCode,
            availableFrom,
            comment,
            location,
            offerInformation,
            offerType,
          },
          this.props.token
        )
          .then(() =>
            this.props.enqueueSnackbar(<FormattedMessage id="EDIT_SUCCESS" />, {
              variant: 'success',
            })
          )
          .then(() => this.props.history.push(this.props.redirectTo))
          .catch(errorHandeling('EDIT_FAIL', this.props.enqueueSnackbar))
      : this.props.enqueueSnackbar(
          <FormattedMessage id="FORM_EMAIL_OR_PHONE_REQUIRED" />,
          { variant: 'warning' }
        );
  };

  render() {
    const { classes, airports, uldTypes, specialStock, history, user } =
      this.props;
    if (specialStock.id)
      return (
        <React.Fragment>
          <HeadlineBox headLineText="EDIT_STOCK.HEADLINE" />
          <div className={`MuiTab-root ${classes.newStockHeadlineDiv}`}>
            <FormattedMessage id="EDIT_STOCK" />
          </div>
          <StockForm
            handleSubmit={this.handleSubmit}
            uldTypes={uldTypes}
            airports={airports}
            defaultValues={this.props.specialStock}
            cancelButtonFunction={history.goBack}
            user={user}
            confirmButtonLabel={
              <FormattedMessage id="EDIT_STOCK.PATCH_REQUEST" />
            }
          />
        </React.Fragment>
      );
    else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    airports: state.airports,
    uldTypes: state.uldTypes,
    specialStock: state.stocks.specialStock,
    token: state.auth.token,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAirports: (token) => dispatch(getAirports(token)),
  getUldTypes: (token) => dispatch(getUldTypes(token)),
  patchStock: (payload, token) => dispatch(patchStock(payload, token)),
  patchLeasingStock: (payload, token) =>
    dispatch(patchLeasingStock(payload, token)),
});

EditStock.defaultProps = {
  airports: [],
  uldTypes: [],
  specialStock: {},
};

EditStock.propTypes = {
  classes: PropTypes.object.isRequired,
  specialStock: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    validUntil: PropTypes.string,
    iataCode: PropTypes.string,
    destinationIataCode: PropTypes.arrayOf(PropTypes.string),
    uldType: PropTypes.string,
    contactPerson: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    amount: PropTypes.number,
    comment: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  getAirports: PropTypes.func.isRequired,
  getUldTypes: PropTypes.func.isRequired,
  redirectTo: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  uldTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      iataCode: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  patchLeasingStock: PropTypes.func.isRequired,
  patchStock: PropTypes.func.isRequired,
  token: PropTypes.string,
};

export default withSnackbar(
  withRouter(
    withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EditStock))
  )
);
