import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField } from '@material-ui/core';

import MenuButton from '../menuButton';
import Search from '../../icons/search_white';

const useStyle = makeStyles((theme) => ({
  searchField: {
    backgroundColor: theme.palette.primary.linkWater25,
    transition: 'width .25s ease-in',
    width: '0%',
    height: 'auto',
    margin: 'auto 0px auto auto',
    '& > click': {
      width: '100%',
    },
  },
}));

export default function SearchButton({ children, handleChange }) {
  const classes = useStyle();
  const [showSearch, setShowSearch] = React.useState(false);

  const searchWidth = showSearch ? '100px' : '0%';

  return (
    <Fragment>
      <TextField
        className={classes.searchField}
        style={{ width: searchWidth }}
        id="input-with-icon-grid"
        type="text"
        onChange={(event) => handleChange(event.target.value)}
      />
      <div
        style={{
          margin: 'auto 0',
          display: 'flex',
          maxHeight: 'fit-content',
        }}
      >
        <MenuButton
          img={<Search />}
          id="search-button-mobile"
          paddingLeft="15px"
          minWidth="fit-content"
          onClick={() => {
            setShowSearch(!showSearch);
          }}
        />
        {children}
      </div>
    </Fragment>
  );
}

SearchButton.propTypes = {
  children: PropTypes.node,
  handleChange: PropTypes.func.isRequired,
};
