import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import InputComponent from './inputComponent';
import { Chip, Paper, makeStyles } from '@material-ui/core';
import { getSuggestions } from './suggestionService';
import SuggestionRenderer from './suggestionRenderer';
import { withSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}));

export function MultipleSelect({
  id,
  data,
  label,
  margin,
  setSelected,
  selected,
  placeholder,
  enqueueSnackbar,
  showEmpty,
  required,
  limit = 5,
}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');

  function handleKeyDown(event) {
    if (selected.length && !inputValue.length && event.key === 'Backspace') {
      setSelection(selected.slice(0, selected.length - 1));
    }
  }

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function handleChange(item) {
    let newSelectedItem = [...selected];
    const found = newSelectedItem.filter(({ value }) => value === item.value);
    if (Object.values(found).length < 1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue('');
    if (newSelectedItem.length > limit) {
      enqueueSnackbar(
        <FormattedMessage id="MULTIPLE_SELECT.LIMIT" values={{ limit }} />
      );
    } else {
      setSelection(newSelectedItem);
    }
  }

  const handleDelete = (item) => () => {
    const newSelectedItem = [...selected].filter(
      ({ value }) => item.value !== value
    );
    setSelection(newSelectedItem);
  };

  const setSelection = (items) => {
    if (items && Array.isArray(items)) {
      const iataCodeMap = items.map(({ value }) => value);
      setSelected(iataCodeMap);
    }
  };

  const itemToString = (item) => {
    if (item) return item.value;
  };

  const handleBlur = () => {
    setInputValue('');
  };

  const focusMethod = (callback) => {
    if (selected.length === limit) {
      enqueueSnackbar(
        <FormattedMessage id="MULTIPLE_SELECT.LIMIT" values={{ limit }} />
      );
    } else {
      callback();
    }
  };

  const getDataForSuggestions = () => {
    const selectedValues = selected.map(({ value }) => value);
    const filtered = data.filter(
      ({ value }) => selectedValues.indexOf(value) < 0
    );
    return filtered;
  };

  return (
    <Downshift
      id={id}
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selected}
      itemToString={itemToString}
      defaultHighlightedIndex={0}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        isOpen,
        inputValue: inputValue2,
        selectedItem: selectedItem2,
        highlightedIndex,
        openMenu,
      }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder: selected.length > 0 ? '' : placeholder,
          onBlur: handleBlur,
          onFocus: () => focusMethod(openMenu),
        });

        const suggestions = getSuggestions(
          inputValue2,
          getDataForSuggestions(),
          5,
          {
            showEmpty,
            selectedItems: selected,
          }
        );

        return (
          <div className={classes.container}>
            {
              <InputComponent
                fullWidth={true}
                label={label}
                InputLabelProps={getLabelProps()}
                InputProps={{
                  startAdornment: selected.map((item) => (
                    <Chip
                      style={{ margin: margin }}
                      key={item.value}
                      tabIndex={-1}
                      label={item.value}
                      className={classes.chip}
                      onDelete={handleDelete(item)}
                    />
                  )),
                  onBlur,
                  onChange: (event) => {
                    handleInputChange(event);
                    onChange(event);
                  },
                  onFocus,
                }}
                required={required}
                {...inputProps}
              />
            }

            {isOpen ? (
              <Paper className={classes.paper} square>
                {suggestions.map((suggestion, index) => (
                  <SuggestionRenderer
                    highlightedIndex={highlightedIndex}
                    suggestion={suggestion}
                    key={suggestion.value}
                    index={index}
                    selectedItem={selectedItem2 ? selectedItem2.value : ''}
                    itemProps={getItemProps({
                      item: suggestion,
                    })}
                  />
                ))}
              </Paper>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
}

MultipleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  limit: PropTypes.number,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setSelected: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(MultipleSelect);
