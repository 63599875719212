import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';

import TabNavbarRoutes from '../../routes/tabNavbarRoutes';
import { Routes } from '../../constants';
import HomeIcon from '../../icons/home';
import { FormattedMessage } from 'react-intl';
import { useMedia } from '../../utils/customHooks';
import NewsPopup from '../newsPopup';
import PopupInfo from '../popupInfo';
import { createAction } from '../../actions/utils';
import { Actions } from '../../constants';
import { getMatchingStocksWithLastRequestTimeStamp } from '../../actions/stock';

const useStyles = makeStyles((theme) => ({
  tabsIndicator: {
    backgroundColor: 'transparent',
  },
  tabRoot: {
    backgroundColor: theme.palette.primary.linkWater25,
    transition: '300ms all ease-in-out',
    opacity: 'unset',
    minWidth: '0',
    fontSize: '1.056em',
    '& path': {
      fill: theme.palette.primary.blueZodiac,
    },
  },
  scrollButtons: {
    backgroundColor: theme.palette.primary.linkWater25,
  },
  tabSelected: {
    backgroundColor: theme.palette.primary.blueZodiac,
    minWidth: '0',
    '& span': {
      color: 'white',
    },
    '& path': {
      fill: 'white',
    },
  },
  rootDiv: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  scrollable: {
    backgroundColor: theme.palette.primary.linkWater25,
  },
  tabText: {
    fontWeight: 600,
    padding: '15px 35px',
    color: theme.palette.primary.dark,
  },
  badge: {
    backgroundColor: theme.palette.secondary.orangePeel,
    marginRight: '-0.8em',
  },
}));

const getTabNavPathname = (pathname) => {
  const tabArray = {
    [Routes.APP.INDEX]: Routes.APP.INDEX,
    [Routes.APP.EDIT_PROFILE.INDEX]: Routes.APP.EDIT_PROFILE.INDEX,
    [Routes.APP.GLOBAL_CHAT.INDEX]: Routes.APP.GLOBAL_CHAT.INDEX,
    [Routes.APP.ADMIN.INDEX]: Routes.APP.ADMIN.INDEX,
    [Routes.APP.AIRPORT_STOCKS.DETAILS]: Routes.APP.AIRPORT_STOCKS.INDEX,
    [Routes.APP.AIRPORT_STOCKS.INDEX]: Routes.APP.AIRPORT_STOCKS.INDEX,
    [Routes.APP.AIRPORT_STOCKS.LEASING.BOOK_NOW]:
      Routes.APP.AIRPORT_STOCKS.INDEX,
    [Routes.APP.AIRPORT_STOCKS.BOOK_NOW]: Routes.APP.AIRPORT_STOCKS.INDEX,
    [Routes.APP.MY_COMPANY.INDEX]: Routes.APP.MY_COMPANY.INDEX,
    [Routes.APP.MY_COMPANY.EDIT_STOCK]: Routes.APP.MY_COMPANY.INDEX,
    [Routes.APP.MY_INVENTORY.ADD_STOCK]: Routes.APP.MY_INVENTORY.INDEX,
    [Routes.APP.MY_INVENTORY.INDEX]: Routes.APP.MY_INVENTORY.INDEX,
    [Routes.APP.MY_INVENTORY.EDIT_STOCK]: Routes.APP.MY_INVENTORY.INDEX,
    [Routes.APP.MY_INVENTORY.DETAILS]: Routes.APP.MY_INVENTORY.INDEX,
    [Routes.APP.MY_COMPANY.DETAILS]: Routes.APP.MY_COMPANY.INDEX,
    [Routes.APP.BOOKINGS.INDEX]: Routes.APP.BOOKINGS.INDEX,
    [Routes.APP.BOOKINGS.INCOMING]: Routes.APP.BOOKINGS.INDEX,
    [Routes.APP.BOOKINGS.OUTGOING]: Routes.APP.BOOKINGS.INDEX,
    [Routes.APP.BOOKINGS.DETAILS]: Routes.APP.BOOKINGS.INDEX,
    [Routes.APP.BOOKINGS.DETAILS.INDEX]: Routes.APP.BOOKINGS.INDEX,
    [Routes.APP.BOOKINGS.DETAILS.INCOMING]: Routes.APP.BOOKINGS.INDEX,
    [Routes.APP.BOOKINGS.DETAILS.OUTGOING]: Routes.APP.BOOKINGS.INDEX,
    [Routes.APP.HOME.INDEX]: Routes.APP.HOME.INDEX,
    [Routes.APP.LOST_AND_FOUND.INDEX]: Routes.APP.LOST_AND_FOUND.INDEX,
    [Routes.APP.LOST_AND_FOUND.ADD_ENTRY]: Routes.APP.LOST_AND_FOUND.INDEX,
    [Routes.APP.LOST_AND_FOUND.LOST]: Routes.APP.LOST_AND_FOUND.INDEX,
    [Routes.APP.LOST_AND_FOUND.FOUND]: Routes.APP.LOST_AND_FOUND.INDEX,
    [Routes.APP.LOST_AND_FOUND.DETAILS]: Routes.APP.LOST_AND_FOUND.INDEX,
  };

  // if path has an id or similar
  if (Object.keys(tabArray).indexOf(pathname) < 0) {
    let pathWithoutLastSlash = pathname.substr(0, pathname.lastIndexOf('/'));
    return tabArray[pathWithoutLastSlash];
  }

  return tabArray[pathname];
};

export function TabNavbar({
  location,
  roles,
  token,
  isNewsRead,
  matches,
  openMatchPopUp,
  getMatchingStocksWithLastRequestTimeStamp,
  lastMatchingRequest,
  dispatch,
}) {
  const classes = useStyles();

  const airportStocksRef = React.useRef();

  const handleCloseDialog = () => {
    dispatch(createAction(Actions.STOCK.MATCHES.OPEN_POP_UP, {}));
  };

  const { variant, centered, scrollButtons } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        variant: 'standard',
        centered: true,
        scrollButtons: 'auto',
      },
    ],
    {
      variant: 'scrollable',
      centered: false,
      scrollButtons: 'on',
    }
  );

  useEffect(() => {
    getMatchingStocksWithLastRequestTimeStamp(token, lastMatchingRequest);
  }, []);

  const tabStyle = { root: classes.tabRoot, selected: classes.tabSelected };

  return (
    <div className={classes.rootDiv}>
      <Tabs
        value={getTabNavPathname(location.pathname)}
        classes={{
          indicator: classes.tabsIndicator,
          scrollButtons: classes.scrollButtons,
          scrollable: classes.scrollable,
        }}
        variant={variant}
        scrollButtons={scrollButtons}
        centered={centered}
      >
        <Tab
          classes={tabStyle}
          className={classes.tabText}
          label={<HomeIcon />}
          to={Routes.APP.HOME.INDEX}
          value={Routes.APP.HOME.INDEX}
          component={Link}
        />
        <Tab
          classes={tabStyle}
          className={classes.tabText}
          label={<FormattedMessage id="TAB_NAVBAR.MY_INVENTORY" />}
          to={Routes.APP.MY_INVENTORY.INDEX}
          value={Routes.APP.MY_INVENTORY.INDEX}
          component={Link}
        />
        <Tab
          classes={tabStyle}
          className={classes.tabText}
          label={<FormattedMessage id="TAB_NAVBAR.MY_COMPANY" />}
          to={Routes.APP.MY_COMPANY.INDEX}
          value={Routes.APP.MY_COMPANY.INDEX}
          component={Link}
        />
        <Tab
          classes={tabStyle}
          className={classes.tabText}
          label={<FormattedMessage id="TAB_NAVBAR.BOOKINGS" />}
          to={Routes.APP.BOOKINGS.INDEX}
          value={Routes.APP.BOOKINGS.INDEX}
          component={Link}
        />
        <PopupInfo
          open={openMatchPopUp}
          mainText="AIRPORTSTOCK.POPUP.NEWMATCHES.TEXT"
          titleText="AIRPORTSTOCK.POPUP.NEWMATCHES.TITEL"
          elementRef={airportStocksRef}
          handleCloseDialog={handleCloseDialog}
        />
        <Tab
          classes={tabStyle}
          ref={airportStocksRef}
          className={classes.tabText}
          label={
            <React.Fragment>
              <Badge
                badgeContent={matches.length}
                color="primary"
                classes={{ badge: classes.badge }}
              >
                <FormattedMessage id="TAB_NAVBAR.AIRPORT_STOCKS" />
              </Badge>
            </React.Fragment>
          }
          to={Routes.APP.AIRPORT_STOCKS.INDEX}
          value={Routes.APP.AIRPORT_STOCKS.INDEX}
          component={Link}
          onClick={handleCloseDialog}
        />
        {roles.includes('Admin') && (
          <Tab
            classes={tabStyle}
            className={classes.tabText}
            label={<FormattedMessage id="TAB_NAVBAR.ADMIN_USERS" />}
            to={Routes.APP.ADMIN.INDEX}
            value={Routes.APP.ADMIN.INDEX}
            component={Link}
          />
        )}
        <Tab
          classes={tabStyle}
          className={classes.tabText}
          label={<FormattedMessage id="TAB_NAVBAR.LOST_AND_FOUND" />}
          to={Routes.APP.LOST_AND_FOUND.INDEX}
          value={Routes.APP.LOST_AND_FOUND.INDEX}
          component={Link}
        />
      </Tabs>
      <NewsPopup token={token} isNewsRead={isNewsRead} />
      <TabNavbarRoutes />
    </div>
  );
}

TabNavbar.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  token: PropTypes.string.isRequired,
  isNewsRead: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  matches: PropTypes.array,
  getMatchingStocksWithLastRequestTimeStamp: PropTypes.func.isRequired,
  lastMatchingRequest: PropTypes.instanceOf(Date),
  openMatchPopUp: PropTypes.boolean,
  dispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getMatchingStocksWithLastRequestTimeStamp: (token, lastMatchingRequest) =>
    dispatch(
      getMatchingStocksWithLastRequestTimeStamp(token, lastMatchingRequest)
    ),
});

const mapStateToProps = (state) => ({
  roles: state.user.roles,
  token: state.auth.token,
  isNewsRead: state.user.hasSeenNews,
  matches: state.stocks.matches,
  openMatchPopUp: state.stocks.matchPopUp,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TabNavbar)
);
