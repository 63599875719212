import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/styles';
import { useMedia } from '../../utils/customHooks';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    borderBottom: `0.4rem solid ${theme.palette.secondary.main}`,
  },
  sortLabel: {
    padding: '0.5rem 0 0.5rem 0',
    transition: '200ms background-color cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: theme.palette.primary.chambray,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.linkWater25,
    },
  },
  tableCell: {
    padding: '14px 0px 14px 0px',
  },
  contactCell: {
    color: theme.palette.primary.chambray,
    fontSize: '1rem',
  },
}));

export default function EnhancedTableHead({
  order,
  orderBy,
  onRequestSort,
  headRows,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const { isMobile } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: false,
      },
    ],
    {
      isMobile: true,
    }
  );

  const classes = useStyles();

  return isMobile ? null : (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headRows.map((row, index) => (
          <TableCell
            className={classes.tableCell}
            key={index}
            align={'right'}
            padding={'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.sortable ? (
              <TableSortLabel
                className={classes.sortLabel}
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            ) : (
              <span className={classes.contactCell}>{row.label}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  headRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    })
  ).isRequired,
};
