import { Actions } from '../constants';

const initialState = {
  isAdmin: false,
  users: [],
  roles: [],
  reminder: {
    list: [],
    audience: [],
    interval: [],
  },
};

export default function admin(state = initialState, action) {
  switch (action.type) {
    case Actions.ADMIN.SET_AS_ADMIN: {
      return {
        ...state,
        isAdmin: true,
      };
    }
    case Actions.ADMIN.ADD_USERS: {
      return {
        ...state,
        users: [...action.payload],
      };
    }
    case Actions.ADMIN.ADD_ROLES: {
      return {
        ...state,
        roles: [...action.payload],
      };
    }
    case Actions.ADMIN.REMINDER.ADD_AUDIENCE: {
      return {
        ...state,
        reminder: {
          ...state.reminder,
          audience: [...action.payload],
        },
      };
    }
    case Actions.ADMIN.REMINDER.ADD_INTERVAL: {
      return {
        ...state,
        reminder: {
          ...state.reminder,
          interval: [...action.payload],
        },
      };
    }
    case Actions.ADMIN.REMINDER.ADD_REMINDERS: {
      return {
        ...state,
        reminder: {
          ...state.reminder,
          list: [...action.payload],
        },
      };
    }
    default:
      return state;
  }
}
