import { getFromServer, postToServer, patchFromServer } from '../api';
import { Actions, Api } from '../constants';
import { createAction } from './utils';

export const getUser = (token) => (dispatch) => {
  getFromServer(Api.PUBLIC_API.USER.USER, token).then((response) => {
    if (response.ok) {
      response.json().then((json) => {
        dispatch(createAction(Actions.USER.GET_USER, json));
      });
    }
  });
};

export const updateLastRead = (token) => (dispatch) => {
  return patchFromServer(Api.PUBLIC_API.USER.LAST_READ, {}, token).then(
    (response) => {
      if (response.ok) {
        return;
      } else {
        throw response;
      }
    }
  );
};

export const getUnread = (token) => (dispatch) => {
  getFromServer(Api.USERS_API.GET_GLOBAL_CHAT + '/unreadCount', token).then(
    (response) => {
      if (response.ok) {
        response.json().then((json) => {
          dispatch(createAction(Actions.USER.GET_UNREAD, json));
        });
      }
    }
  );
};

export const updateLastLogin = (token) => {
  return postToServer(Api.PUBLIC_API.USER.UPDATE_LAST_LOGIN, {}, token).then(
    (response) => {
      if (!response.ok) throw response;
    }
  );
};

export const putNewUser = (newUser) => {
  const newUserRequestDto = {
    firstName: newUser.firstName,
    lastName: newUser.lastName,
    email: newUser.email,
    phoneNumber: newUser.phoneNumber,
    password: newUser.password,
    companyCode: newUser.companyCode,
    termsAndPrivacyAgreement: newUser.termsAndPrivacyAgreement,
    contactAgreement: newUser.contactAgreement,
  };

  return postToServer(Api.PUBLIC_API.USER.REGISTER, newUserRequestDto).then(
    (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw response;
      }
    }
  );
};

export const updateUser = (updatedUser, token) => {
  const updateRequestDto = {
    firstName: updatedUser.firstName,
    lastName: updatedUser.lastName,
    phone: updatedUser.phoneNumber,
    place: updatedUser.place,
    reachability: updatedUser.reachability,
    position: updatedUser.position,
    groupMail: updatedUser.groupMail,
    iataCode: updatedUser.iataCode,
    password: updatedUser.password,
    notificationRest: updatedUser.notificationRest,
    notificationScheduledReminder: updatedUser.notificationScheduledReminder,
    notificationStockMatch: updatedUser.notificationStockMatch,
  };

  return patchFromServer(
    Api.PUBLIC_API.USER.USER,
    updateRequestDto,
    token
  ).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  });
};

export const setAcceptTermsAndPolicyDate = (token) => {
  postToServer(Api.PUBLIC_API.SET_ACCEPT_TERMS_AND_POLICY_DATE, {}, token).then(
    (response) => {
      if (!response.ok) throw response;
    }
  );
};

export const setCookie = (value, lifetime) => (dispatch) => {
  return dispatch(createAction(Actions.COOKIE.SET_COOKIE, { value, lifetime }));
};
