export default class Reminder {
  constructor({
    id,
    title,
    message,
    intervalTimeUnit,
    interval,
    firstExecutionTime,
    nextExecutionTime,
    audience,
  }) {
    this.id = id;
    this.title = title;
    this.message = message;
    this.intervalTimeUnit = intervalTimeUnit;
    this.interval = interval;
    this.firstExecutionTime = firstExecutionTime;
    this.nextExecutionTime = nextExecutionTime;
    this.audience = audience;
  }
}
