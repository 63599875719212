import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    paddingLeft: '10%',
    height: '3em',
    width: '100%',
    backgroundColor: theme.palette.secondary.orangePeel,
    border: 'none',
    padding: 0,
    alignItems: 'center',
    fontSize: 'unset',
    '&:hover': {
      backgroundColor: theme.palette.secondary.fulvous,
      '&:before': {
        backgroundColor: theme.palette.secondary.fulvous,
      },
      '&:after': {
        borderTopColor: theme.palette.secondary.fulvous,
        boxShadow: `0 -3px 0px 0px ${theme.palette.secondary.fulvous}`,
      },
    },
    '&:before': {
      width: '1.5em',
      height: '1.5em',
      backgroundColor: theme.palette.secondary.orangePeel,
      content: "' '",
      position: 'absolute',
      top: 0,
      left: '100%',
    },
    '&:after': {
      boxShadow: `0 -3px 0px 0px ${theme.palette.secondary.orangePeel}`,
      width: '0px',
      borderRight: '1.5em solid transparent',
      borderTop: `1.5em solid ${theme.palette.secondary.orangePeel}`,
      height: 0,
      content: "' '",
      position: 'absolute',
      left: '100%',
      bottom: 0,
    },
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
  divStyle: {
    position: 'relative',
  },
  spanStyle: {
    color: 'white',
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
});

export class UldShapedButtonBig extends Component {
  render() {
    const { children, onClick, classes, buttonType, disabled } = this.props;

    return (
      <React.Fragment>
        <div className={classes.divStyle}>
          <button
            className={classes.buttonWrapper}
            type={buttonType}
            disabled={disabled}
            onClick={onClick}
          >
            <span className={classes.spanStyle}>{children}</span>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

UldShapedButtonBig.defaultProps = {
  buttonType: 'button',
  disabled: false,
};

UldShapedButtonBig.propTypes = {
  buttonType: PropTypes.oneOf([
    PropTypes.objectOf(PropTypes.string),
    'button',
    'submit',
    'reset',
  ]).isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(UldShapedButtonBig);
