export function setCookie(cname, value, exyears) {
  let cookieString = cname + '=' + value + ';';
  if (exyears) {
    var d = new Date();
    d.setTime(d.getTime() + exyears * 365 * 24 * 60 * 60 * 1000);
    cookieString += 'expires=' + d.toUTCString();
  }
  document.cookie = cookieString + ';path=/';
}

export function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1);
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
  }
  return undefined;
}

export const COOKIE_KEYS = {
  KEY: 'COOKIE_CONSENT',
  ACCEPT_ALL: 'ACCEPT_ALL',
  ONLY_NECESSARY: 'ONLY_NECESSARY',
};
