import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import UldShapedFooterWithButtons from '../uldShapedFooterWithButtons';
import StockBookingQuery from '../../model/stockBookingQuery';
import BookingContactForm from './form/contact';
import BookingRequestForm from './form/request';
import BookingFurtherInformationForm from './form/furtherInformation';

const useStyles = makeStyles((theme) => ({
  line: {
    backgroundColor: theme.palette.primary.linkWater25,
    height: '4px',
    display: 'flex',
    width: '100%',
    marginTop: '2em',
    marginBottom: '2em',
  },
}));

export function BookNowForm({
  stock,
  handleSubmit,
  airports,
  cancelButtonFunction,
}) {
  const {
    contactPerson,
    companyCode,
    companyName,
    amount: amountDefault,
    uldType,
    iataCode,
    validUntil: validUntilDefault,
    destinationIataCode: destinationIataCodeDefault,
    availableFrom: availableFromDefault,
    hasAlreadyBeenBookedByUsersCompany,
    id,
  } = stock;

  const [amount, setAmount] = React.useState(Math.abs(amountDefault));

  const [requestAmount, setRequestAmount] = useState(amount);

  const [validUntil, setValidUntil] = React.useState(
    validUntilDefault.slice(0, 10)
  );
  const [availableFrom, setAvailableFrom] = React.useState(
    availableFromDefault.slice(0, 10)
  );

  const [destinationIataCode, setDestinationIataCode] = React.useState([]);

  const [requestComment, setRequestComment] = React.useState('');

  const [bookingStarted, setBookingStarted] = React.useState(false);

  const classes = useStyles();

  function getFromTill() {
    let today = new Date();
    let todayInUnixFormat = Math.round(+new Date() / 1000);
    let weekAfterNow = (todayInUnixFormat + 604800).toString().slice(0, 10);
    let validUntilDateInUnixFormat = Math.round(new Date(validUntil) / 1000);
    let availableBookingDays = Math.round(
      validUntilDateInUnixFormat - todayInUnixFormat
    );

    let defaultTillWhenInUnixFormat =
      weekAfterNow <= validUntilDateInUnixFormat
        ? weekAfterNow
        : Math.round(todayInUnixFormat + availableBookingDays);

    let defaultTillWhen = new Date(defaultTillWhenInUnixFormat * 1000)
      .toISOString()
      .slice(0, 10);

    return {
      currentDate: today.toISOString().slice(0, 10),
      defaultTillWhen: defaultTillWhen,
    };
  }

  const submitForm = (event) => {
    event.preventDefault();
    const amountToBook = amountDefault > 0 ? requestAmount * -1 : requestAmount;
    const { currentDate, defaultTillWhen } = getFromTill();

    if (!bookingStarted) {
      const stockBookingQuery = new StockBookingQuery({
        stockId: id,
        fromWhen: currentDate,
        tillWhen: defaultTillWhen,
        amountToBook,
        requestComment: requestComment,
        returnAirport: undefined,
      });
      setBookingStarted(true);
      handleSubmit(stockBookingQuery);
    }
  };

  return (
    <form onSubmit={submitForm}>
      <BookingContactForm
        contactPerson={contactPerson}
        company={`${companyName} (${companyCode})`}
      />
      <div className={classes.line} />
      <BookingRequestForm
        amountDefault={amountDefault}
        amount={amount}
        setAmount={setAmount}
        uldType={uldType}
        iataCode={iataCode}
        availableFrom={availableFrom}
        setAvailableFrom={setAvailableFrom}
        validUntil={validUntil}
        setValidUntil={setValidUntil}
        airports={airports}
        requiresAirportInput={
          destinationIataCodeDefault && destinationIataCodeDefault.length > 0
        }
        destinationIataCodeDefault={destinationIataCodeDefault}
        destinationIataCode={destinationIataCode}
        setDestinationIataCode={setDestinationIataCode}
      />
      {!stock.userIsOwner && (
        <span>
          <div className={classes.line} />
          <BookingFurtherInformationForm
            requestAmount={requestAmount}
            setRequestAmount={setRequestAmount}
            requestComment={requestComment}
            setRequestComment={setRequestComment}
          />
          <div style={{ marginTop: '3em' }}>
            <UldShapedFooterWithButtons
              confirmButtonLabel={<FormattedMessage id="BOOKNOW.REQUEST_NOW" />}
              confirmButtonType={'submit'}
              cancelButtonOnClick={cancelButtonFunction}
              buttonDisabled={hasAlreadyBeenBookedByUsersCompany}
            />
          </div>
        </span>
      )}
    </form>
  );
}

BookNowForm.propTypes = {
  stock: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    phone: PropTypes.string.isRequired,
    contactPerson: PropTypes.string.isRequired,
    iataCode: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    uldType: PropTypes.string.isRequired,
    validUntil: PropTypes.string.isRequired,
    availableFrom: PropTypes.string.isRequired,
    destinationIataCode: PropTypes.arrayOf(PropTypes.string),
    hasAlreadyBeenBookedByUsersCompany: PropTypes.bool,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cancelButtonFunction: PropTypes.func.isRequired,
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      iataCode: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default BookNowForm;
