import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core';

import { deleteFromServer, patchFromServer, postToServer } from '../../api';
import { Api } from '../../constants';
import { Actions } from '../../constants';
import { createAction } from '../../actions/utils';
import ModalInfo from '../modalInfo';
import StockTableRow from './stockTableRow';
import StockTableTile from './stockTableTile';
import EnhancedTableHead from './enhancedTableHead';
import EnhancedTableToolbar from './enhancedTableToolbar';
import { useMedia } from '../../utils/customHooks';
import BlueInfoContainer from '../blueInfoContainer';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    marginTop: '36px',
  },
  table: {
    minWidth: 750,
    color: theme.palette.primary.chambray,
  },
  tableWrapper: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  flexLayout: {
    display: 'flex',
  },
}));

export default function EnhancedTable({
  data,
  title,
  headRows,
  renderAdditionalButtons,
  isMyCompanyTable,
  isAirportStocks,
  defaultOrder,
  editFunc,
  detailsFunc,
  handleClick,
  getStocksFunction,
  goToBooking,
  token,
  dispatch,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(defaultOrder);
  const [open, setOpen] = React.useState(false);
  const [idToDelete, setIdToDelete] = React.useState();
  const [stock, setStock] = React.useState(undefined);
  const [openImportant, setOpenImportant] = React.useState(false);

  const { isMobile, marginTop } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: false,
        marginTop: classes.root.marginTop,
      },
    ],
    {
      isMobile: true,
      marginTop: '0',
    }
  );

  const handleOpenDelete = (id) => {
    setIdToDelete(id);
    setOpen(true);
  };

  const handleCloseDelete = () => {
    setIdToDelete();
    setOpen(false);
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const deleteStock = (dispatch, id) => {
    dispatch(createAction(Actions.STOCK.DELETE, id));
    deleteFromServer(Api.BOOKER_API.BASE, id, token);
    handleCloseDelete();
  };

  const handleOpenImportant = (stock) => {
    if (stock.id) {
      setStock(stock);
      setOpenImportant(true);
    }
  };

  const handleCloseImportant = () => {
    setStock(undefined);
    setOpenImportant(false);
  };

  const markImportant = (stock) => {
    postToServer(Api.USERS_API.SEND_MAIL_ALL_USERS, stock, token).then(
      patchFromServer(Api.BOOKER_API.MARK_AS_IMPORTANT, stock.id, token)
        .then(() => getStocksFunction(token))
        .then(() => handleCloseImportant())
    );
  };

  const sortedData = stableSort(data, getSorting(order, orderBy));

  return (
    <div className={classes.root} style={{ marginTop: marginTop }}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          onRequestSort={handleRequestSort}
          isMyCompanyTable={isMyCompanyTable}
          orderBy={orderBy}
          title={title}
          headRows={headRows}
          order={order}
        />
        {isMobile ? (
          <div>
            <List>
              {sortedData.map((row) => (
                <StockTableTile
                  key={row.id}
                  row={row}
                  renderAdditionalButtons={renderAdditionalButtons}
                  handleClick={handleClick}
                  deleteFunc={handleOpenDelete}
                  editFunc={editFunc}
                  detailsFunc={detailsFunc}
                  markImportant={handleOpenImportant}
                  isAirportStocks={isAirportStocks}
                  token={token}
                />
              ))}
            </List>
            <ModalInfo
              isMobile
              open={open}
              handleClose={handleCloseDelete}
              headlineId="MODAL.HEADLINE"
              textId="MODAL.TEXT"
              action={() => deleteStock(dispatch, idToDelete)}
              actionId="BUTTON.DELETE"
            />
          </div>
        ) : (
          <div>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                renderAdditionalButtons={renderAdditionalButtons}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headRows={headRows}
              />
              <TableBody>
                {sortedData.map((row) => (
                  <StockTableRow
                    key={row.id}
                    row={row}
                    isAirportStocks={isAirportStocks}
                    renderAdditionalButtons={renderAdditionalButtons}
                    handleClick={handleClick}
                    deleteFunc={handleOpenDelete}
                    editFunc={editFunc}
                    detailsFunc={detailsFunc}
                    markImportant={handleOpenImportant}
                    goToBooking={goToBooking}
                  />
                ))}
              </TableBody>
            </Table>
            <ModalInfo
              open={open}
              handleClose={handleCloseDelete}
              headlineId="MODAL.HEADLINE"
              textId="MODAL.TEXT"
              action={() => deleteStock(dispatch, idToDelete)}
              actionId="BUTTON.DELETE"
            />
            <ModalInfo
              open={openImportant}
              handleClose={handleCloseImportant}
              headlineId="MODAL.MARK_IMPORTANT_HEADLINE"
              textId="MODAL.MARK_IMPORTANT_TEXT"
              action={() => markImportant(stock)}
              actionId="BUTTON.MARK_IMPORTANT"
            />
          </div>
        )}
        {sortedData.length < 1 && <BlueInfoContainer />}
      </Paper>
    </div>
  );
}

EnhancedTable.defaultProps = {
  renderAdditionalButtons: false,
  data: [],
  handleClick: () => {},
};

EnhancedTable.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headRows: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  renderAdditionalButtons: PropTypes.bool.isRequired,
  handleClick: PropTypes.func,
  isMyCompanyTable: PropTypes.bool,
  isAirportStocks: PropTypes.bool,
  defaultOrder: PropTypes.string.isRequired,
  editFunc: PropTypes.func,
  detailsFunc: PropTypes.func,
  getStocksFunction: PropTypes.func,
  token: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  goToBooking: PropTypes.func.isRequired,
};
