import { Actions } from '../constants';
import NotificationMessage from '../model/notificationMessage';
import NotificationTypes from '../constants/notificationTypes';
import { Routes } from '../constants';

const initialState = [];

export function createNotificationMessageFromJsonObject(notificationData) {
  let { headline, subject, message, targetUrl, notificationType } = '';
  let details = undefined;

  switch (notificationData.notificationType) {
    case 'BOOKING_REQUEST_NOTIFICATION':
      headline = 'NOTIFICATION.HEADLINE.BOOKING';
      if (notificationData.details.isLeasing) {
        subject = 'NOTIFICATION.SUBJECT.BOOKING.LEASING.REQUEST';
      } else {
        subject = 'NOTIFICATION.SUBJECT.BOOKING.REQUEST';
      }
      notificationType = NotificationTypes.BOOKING.REQUEST;
      message = 'NOTIFICATION.MESSAGE.BOOKING.REQUEST';
      targetUrl =
        Routes.APP.BOOKINGS.DETAILS.INDEX +
        '/' +
        notificationData.details.bookingRequestId;
      break;
    case 'BOOKING_ANSWER_NOTIFICATION':
      headline = 'NOTIFICATION.HEADLINE.BOOKING';
      if (notificationData.details.confirmed) {
        notificationType = NotificationTypes.BOOKING.ANSWER.CONFIRM;
      } else {
        notificationType = NotificationTypes.BOOKING.ANSWER.DENY;
      }
      if (notificationData.details.isLeasing) {
        subject = notificationData.details.confirmed
          ? 'NOTIFICATION.SUBJECT.BOOKING.LEASING.ANSWER.CONFIRM'
          : 'NOTIFICATION.SUBJECT.BOOKING.LEASING.ANSWER.DENY';
      } else {
        subject = notificationData.details.confirmed
          ? 'NOTIFICATION.SUBJECT.BOOKING.ANSWER.CONFIRM'
          : 'NOTIFICATION.SUBJECT.BOOKING.ANSWER.DENY';
      }
      message = notificationData.details.confirmed
        ? 'NOTIFICATION.MESSAGE.BOOKING.ANSWER.CONFIRM'
        : 'NOTIFICATION.MESSAGE.BOOKING.ANSWER.DENY';
      targetUrl =
        Routes.APP.BOOKINGS.DETAILS.INDEX +
        '/' +
        notificationData.details.bookingRequestId;
      break;
    case 'SKYPOOLING_NEWS_NOTIFICATION':
      headline = 'NOTIFICATION.HEADLINE.NEWS';
      notificationType = NotificationTypes.SKYPOOLING_NEWS;
      subject = notificationData.details.title;
      break;
    case 'STOCK_CHAT_NOTIFICATION':
      headline = 'NOTIFICATION.HEADLINE.STOCKCHAT';
      notificationType = NotificationTypes.STOCK_CHAT;
      subject = 'NOTIFICATION.SUBJECT.STOCKCHAT';
      message = notificationData.details.message;

      targetUrl =
        Routes.APP.AIRPORT_STOCKS.DETAILS +
        '/' +
        notificationData.details.stockId +
        '/' +
        notificationData.details.chatId;
      break;
    case 'LOST_AND_FOUND':
      notificationType = NotificationTypes.LOST_AND_FOUND;
      if (notificationData.details.lostAndFoundCreator) {
        if (notificationData.details.report === 'LOST') {
          subject = 'NOTIFICATION.SUBJECT.LOST_AND_FOUND.LOST';
          message = 'NOTIFICATION.MESSAGE.LOST_AND_FOUND.LOST';
        } else {
          subject = 'NOTIFICATION.SUBJECT.LOST_AND_FOUND.FOUND';
          message = 'NOTIFICATION.MESSAGE.LOST_AND_FOUND.FOUND';
        }
      } else {
        subject = 'NOTIFICATION.SUBJECT.LOST_AND_FOUND_COMMENT_FROM_CREATOR';
        message =
          'NOTIFICATION.MESSAGE.LOST_AND_FOUND.LOST_AND_FOUND_COMMENT_FROM_CREATOR';
      }
      headline = 'NOTIFICATION.HEADLINE.LOST_AND_FOUND';

      targetUrl =
        Routes.APP.LOST_AND_FOUND.DETAILS +
        '/' +
        notificationData.details.lostAndFoundId;
      details = {
        firstName: notificationData.details.user.firstName,
        lastName: notificationData.details.user.lastName,
        companyName: notificationData.details.user.company.name,
        airportCode: notificationData.details.airport?.iataCode,
        airportName: notificationData.details.airport?.name,
        date: new Date(notificationData.details.date),
      };
      break;
    default:
  }

  return new NotificationMessage({
    id: notificationData.id,
    timestamp: new Date(notificationData.timeStamp),
    type: notificationType,
    headline: headline,
    subject: subject,
    message: message,
    isRead: notificationData.hasBeenRead,
    targetUrl: targetUrl,
    details: details === undefined ? notificationData.details : details,
  });
}

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case Actions.NOTIFICATIONS.GET_NOTIFICATIONS:
      return [
        ...action.payload.map((notification) =>
          createNotificationMessageFromJsonObject(notification)
        ),
      ];
    default: {
      return state;
    }
  }
}
