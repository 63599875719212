import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.primary.linkWater25,
    border: `1px solid ${theme.palette.primary.blueZodiac}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    outline: 'none',
    color: theme.palette.primary.blueZodiac,
    fontFamily: theme.typography.fontFamily,
  },
  paperMobile: {
    position: 'absolute',
    width: '70%',
    backgroundColor: theme.palette.primary.linkWater25,
    border: `1px solid ${theme.palette.primary.blueZodiac}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    outline: 'none',
    color: theme.palette.primary.blueZodiac,
    fontFamily: theme.typography.fontFamily,
  },
  buttonNormal: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.linkWater50,
    color: theme.palette.primary.blueZodiac,
    '&:hover': {
      background: theme.palette.primary.cornflowerBlue,
    },
  },
  buttonAction: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.orangePeel,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      background: theme.palette.secondary.tangerine,
    },
  },
  input: {
    display: 'none',
  },
}));

export default function ModalInfo(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.open}
    >
      <div
        style={modalStyle}
        className={props.isMobile ? classes.paperMobile : classes.paper}
      >
        <h2 id="modal-title">
          <FormattedMessage id={props.headlineId} />
        </h2>
        <p id="simple-modal-description">
          <FormattedMessage id={props.textId} />
        </p>
        <Button className={classes.buttonAction} onClick={props.action}>
          {<FormattedMessage id={props.actionId} />}
        </Button>
        <Button className={classes.buttonNormal} onClick={props.handleClose}>
          {<FormattedMessage id="BUTTON.CANCEL" />}
        </Button>
      </div>
    </Modal>
  );
}

ModalInfo.defaultProps = {
  open: false,
};
