import React from 'react';

export default () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    viewBox="0 0 20 20"
  >
    <path d="M14.5,20h-3c-0.3,0-0.5-0.2-0.5-0.5V16H9v3.5C9,19.8,8.8,20,8.5,20h-3C4.7,20,4,19.3,4,18.5v-6.1l-1.1,1.2c-0.2,0.2-0.5,0.2-0.7,0l-2-1.8C0.1,11.8,0,11.7,0,11.5s0-0.3,0.1-0.4L9,1.4c0.3-0.3,0.6-0.5,1-0.5s0.8,0.2,1,0.5l8.8,9.8c0.1,0.1,0.1,0.2,0.1,0.4s-0.1,0.3-0.2,0.3l-2,1.8c-0.2,0.2-0.5,0.2-0.7,0L16,12.4v6.1C16,19.3,15.3,20,14.5,20L14.5,20z" />
  </svg>
);
