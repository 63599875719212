export default {
  INCOMING: {
    OPEN: 4,
  },
  OUTGOING: {
    OPEN: 3,
  },
  CONFIRMED: 2,
  DENIED: 1,
};
