import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Routes, Actions } from '../constants';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import { createAction } from '../actions/utils';
import { useKeycloak } from '@react-keycloak/web';

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 600,
  },
  listItemStyle: {
    textAlign: 'center',
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  accountText: {
    padding: '15px 75px',
    fontWeight: 600,
    color: theme.palette.primary.dark,
    fontSize: '1.5rem',
  },
}));

export function DialogBox({ onClose, open, history, logout, token }) {
  const classes = useStyles();
  const { keycloak } = useKeycloak();

  function handleRegisterClick() {
    history.push(Routes.PUBLIC.REGISTRATION);
    onClose();
  }

  function handleEditClick() {
    history.push(Routes.APP.EDIT_PROFILE.INDEX);
    onClose();
  }

  function handleLogoutClick() {
    logout();
    keycloak.logout();
  }

  function handleLoginClick() {
    keycloak.login({
      redirectUri: `${window.location.origin}${Routes.PUBLIC.AUTH}`,
    });
  }

  return (
    <Dialog onClose={onClose} aria-labelledby="AccountDialog" open={open}>
      <DialogTitle
        className={classes.accountText}
        disableTypography
        id="AccountDialog"
      >
        <FormattedMessage id="BASE.ACCOUNT" />
      </DialogTitle>
      <List>
        {!token ? (
          <Fragment>
            <ListItem
              id="login-button"
              button
              onClick={handleLoginClick}
              key={'LoginKey'}
            >
              <ListItemText
                disableTypography
                className={classes.listItemStyle}
                primary={<FormattedMessage id="BASE.LOGIN" />}
              />
            </ListItem>
            <ListItem
              id="register-button"
              button
              onClick={handleRegisterClick}
              key={'RegisterKey'}
            >
              <ListItemText
                disableTypography
                className={classes.listItemStyle}
                primary={<FormattedMessage id="BASE.REGISTER" />}
              />
            </ListItem>
          </Fragment>
        ) : (
          <Fragment>
            <ListItem
              id="edit-profile-button"
              button
              onClick={handleEditClick}
              key={'EditProfileKey'}
            >
              <ListItemText
                disableTypography
                className={classes.listItemStyle}
                primary={<FormattedMessage id="BASE.YOUR_PROFILE" />}
              />
            </ListItem>
            <ListItem
              id="logout-button"
              button
              onClick={handleLogoutClick}
              key={'LogoutKey'}
            >
              <ListItemText
                disableTypography
                className={classes.listItemStyle}
                primary={<FormattedMessage id="BASE.LOGOUT" />}
              />
            </ListItem>
          </Fragment>
        )}
      </List>
    </Dialog>
  );
}

DialogBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  token: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
  keycloak: PropTypes.shape({
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(createAction(Actions.AUTH.TOKEN.REMOVE)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DialogBox)
);
