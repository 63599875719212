import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
} from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  gridItemHeadline,
  grid,
  checked,
  grid_margin,
  radio,
} from './cssStyles';
import SingleSelect from '../searchSelect/singleSelect';
import MultipleSelect from '../searchSelect/multipleSelect';

const useStyles = makeStyles((theme) => ({
  gridItemHeadline: gridItemHeadline(theme),
  grid: grid,
  grid_margin: grid_margin,
  checked: checked(theme),
  radio: radio(theme),
}));

export function AddNewStockUldSection({
  factor,
  setFactor,
  amount,
  setAmount,
  iataCode,
  setIataCode,
  uldType,
  setUldType,
  validUntil,
  setValidUntil,
  destinationIataCode,
  setDestinationIataCode,
  availableFrom,
  setAvailableFrom,
  comment,
  setComment,
  location,
  setLocation,
  onShowError,
  showErrors,
  uldTypes,
  airports,
  intl,
  paddingRight,
}) {
  const classes = useStyles();

  return (
    <span>
      <Grid container justify="center">
        <Grid item xs={12} md={4}>
          <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
            <FormattedMessage id="BASE.ULD" />
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <RadioGroup
                aria-label={<FormattedMessage id="BASE.STOCKTYPE" />}
                name="stockType"
                style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
                onChange={(event) => setFactor(event.target.value)}
                value={factor}
              >
                <FormControlLabel
                  classes={{ root: classes.root }}
                  value={'-1'}
                  style={{ marginRight: '15%' }}
                  control={
                    <Radio
                      className={`MuiRadio-root ${classes.radio}`}
                      classes={{ checked: classes.checked }}
                      required
                      color="default"
                    />
                  }
                  label={<FormattedMessage id="BASE.UNDERSTOCK" />}
                />
                <FormControlLabel
                  classes={{ root: classes.root }}
                  value={'1'}
                  style={{ marginRight: '15%' }}
                  control={
                    <Radio
                      classes={{ checked: classes.checked }}
                      required
                      color="default"
                    />
                  }
                  label={<FormattedMessage id="BASE.OVERSTOCK" />}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  id="standard-amount"
                  label={<FormattedMessage id="BASE.AMOUNT" />}
                  type="number"
                  min="1"
                  autoComplete="off"
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                  value={amount}
                  onChange={(event) => setAmount(event.target.value)}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <SingleSelect
                id="uldType-select"
                label={<FormattedMessage id="BASE.ULD_TYPE" />}
                selected={{ value: uldType }}
                setSelected={setUldType}
                onShowError={onShowError}
                showErrors={showErrors}
                data={uldTypes.map((item) => ({ value: item, label: item }))}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SingleSelect
                id="airports-select"
                label={<FormattedMessage id="ADD_NEW_STOCK.FROM_STATION" />}
                selected={{ value: iataCode }}
                setSelected={setIataCode}
                showErrors={showErrors}
                data={airports.map(({ iataCode, name }) => ({
                  value: iataCode,
                  label: `${iataCode} - ${name}`,
                }))}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="availableFrom"
                label={<FormattedMessage id="BASE.AVAILABLE_FROM" />}
                type="date"
                value={availableFrom.slice(0, 10)}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => setAvailableFrom(event.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="validTill"
                label={<FormattedMessage id="BASE.VALID_UNTIL" />}
                type="date"
                value={validUntil.slice(0, 10)}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => setValidUntil(event.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MultipleSelect
                id="destinationAirports"
                data={airports.map(({ iataCode, name }) => ({
                  value: iataCode,
                  label: `${iataCode} - ${name}`,
                }))}
                label={<FormattedMessage id="ADD_NEW_STOCK.TO_DESTINATION" />}
                placeholder={intl.formatMessage({
                  id: 'ADD_NEW_STOCK.ANY_AIRPORT',
                })}
                limit={5}
                selected={destinationIataCode.map((value) => ({ value }))}
                setSelected={setDestinationIataCode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="comment"
                label={<FormattedMessage id="ADD_NEW_STOCK.COMMENT" />}
                placeholder={intl.formatMessage({
                  id: 'ADD_NEW_STOCK.COMMENT',
                })}
                type="text"
                value={comment}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => setComment(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="location"
                label={<FormattedMessage id="ADD_NEW_STOCK.LOCATION" />}
                placeholder={intl.formatMessage({
                  id: 'ADD_NEW_STOCK.TERMINAL_WAREHOUSE',
                })}
                type="text"
                value={location}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => setLocation(event.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </span>
  );
}

AddNewStockUldSection.propTypes = {
  factor: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  setAmount: PropTypes.func.isRequired,
  setFactor: PropTypes.func.isRequired,
  iataCode: PropTypes.string.isRequired,
  setIataCode: PropTypes.func.isRequired,
  uldType: PropTypes.string.isRequired,
  setUldType: PropTypes.func.isRequired,
  validUntil: PropTypes.string.isRequired,
  setValidUntil: PropTypes.func.isRequired,
  destinationIataCode: PropTypes.arrayOf(PropTypes.string).isRequired,
  setDestinationIataCode: PropTypes.func.isRequired,
  availableFrom: PropTypes.string.isRequired,
  setAvailableFrom: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  setLocation: PropTypes.func.isRequired,
  onShowError: PropTypes.func.isRequired,
  showErrors: PropTypes.bool,
  uldTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      iataCode: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  paddingRight: PropTypes.string.isRequired,
};

export default injectIntl(AddNewStockUldSection);
