import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  headline: {
    color: theme.palette.primary.lightGrey,
  },
  information: {
    color: theme.palette.primary.dark,
  },
}));

export default function InformationContainer({ headline, subtitle }) {
  const classes = useStyle();
  return (
    <Fragment>
      <Typography variant="h6" className={classes.headline}>
        {headline}
      </Typography>
      <Typography variant="subtitle1" className={classes.information}>
        {subtitle}
      </Typography>
    </Fragment>
  );
}

InformationContainer.propTypes = {
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
