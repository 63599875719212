import React, { Suspense } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import SocialBlockIcons from './socialBlockIcons';
import { ContactContainer } from '../homePage/contactContainer';

const useStyles = makeStyles({
  gridContainer: {
    marginTop: '1rem',
    justifyContent: 'space-around',
  },
  cookieNotAllowedHeight: {
    height: '19rem',
  },
});

export function SocialBlock(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.gridContainer}>
      <Grid item xs={12} md={4}>
        <SocialBlockIcons />
      </Grid>
      <Grid item xs={12} md={6}>
        <ContactContainer
          onClick={props.scrollToMyRef}
          history={props.history}
        />
      </Grid>
    </Grid>
  );
}

export default SocialBlock;
