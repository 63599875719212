import React from 'react';
import PropTypes from 'prop-types';
import Check from '../../icons/check';
import Cross from '../../icons/cross';
import Question from '../../icons/question';
import HourGlass from '../../icons/hourGlass';
import BOOKINGSTATE from '../../constants/bookingState';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export default function BookingOverviewIcon({ bookingState }) {
  switch (bookingState) {
    case BOOKINGSTATE.INCOMING.OPEN:
      return (
        <Tooltip
          title={<FormattedMessage id="BOOKINGS.BOOKINGSATE.QUESTION" />}
          placement="right"
        >
          <div>
            <Question />
          </div>
        </Tooltip>
      );
    case BOOKINGSTATE.OUTGOING.OPEN:
      return (
        <Tooltip
          title={<FormattedMessage id="BOOKINGS.BOOKINGSATE.HOURGLASS" />}
          placement="right"
        >
          <div>
            <HourGlass />
          </div>
        </Tooltip>
      );
    case BOOKINGSTATE.CONFIRMED:
      return (
        <Tooltip
          title={<FormattedMessage id="BOOKINGS.BOOKINGSATE.CHECK" />}
          placement="right"
        >
          <div>
            <Check />
          </div>
        </Tooltip>
      );
    case BOOKINGSTATE.DENIED:
      return (
        <Tooltip
          title={<FormattedMessage id="BOOKINGS.BOOKINGSATE.CROSS" />}
          placement="right"
        >
          <div>
            <Cross />
          </div>
        </Tooltip>
      );
    default:
      return <></>;
  }
}

BookingOverviewIcon.propTypes = {
  bookingState: PropTypes.string.isRequired,
};
