export default class AdministratedUser {
  constructor({
    company: { code, name },
    contactAgreement,
    deleted,
    email,
    enabled,
    firstName,
    lastName,
    groupMail,
    iataCode,
    keycloakId,
    position,
    lastRead,
    phone,
    place,
    reachability,
    role,
  }) {
    this.company = {
      code,
      name,
    };
    this.contactAgreement = contactAgreement;
    this.deleted = deleted;
    this.email = email;
    this.enabled = enabled;
    this.firstName = firstName;
    this.lastName = lastName;
    this.groupMail = groupMail;
    this.iataCode = iataCode;
    this.keycloakId = keycloakId;
    this.lastRead = lastRead;
    this.phone = phone;
    this.place = place;
    this.position = position;
    this.reachability = reachability;
    this.role = role;
  }
}
