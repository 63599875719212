import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withSnackbar } from 'notistack';

import RegistrationForm from '../components/registrationForm';
import { getCompanies } from '../actions/companies';
import HeadlineBox from '../components/headlineBox';
import { putNewUser } from '../actions/user';
import { withRouter } from 'react-router-dom';
import { validateEmail, validatePhone } from '../utils/utils';
import routes from '../constants/routes';
import { validatePassword } from '../utils/validators';

export class Registration extends Component {
  componentDidMount = () => {
    this.props.getCompanies();
  };

  handleTerms = () => {
    window.open(routes.PUBLIC.TERMS_OF_USE, '_blank');
    //this.props.history.push(routes.PUBLIC.TERMS_OF_USE);
  };

  handlePolicy = () => {
    this.props.history.push(routes.PUBLIC.DATA_PRIVACY);
  };

  handleSubmit = ({
    firstName,
    lastName,
    email,
    confirmEmail,
    phoneNumber,
    password,
    confirmPassword,
    companyCode,
    termsAndPrivacyAgreement,
    contactAgreement,
  }) => {
    const validMail =
      email !== undefined &&
      email !== null &&
      email !== '' &&
      email !== ' ' &&
      email === confirmEmail &&
      validateEmail(email);

    let validPhone = false;

    if (phoneNumber && phoneNumber.trim()) {
      validPhone = validatePhone(phoneNumber);
    } else {
      validPhone = true;
    }

    const validPassword =
      password !== undefined &&
      password !== null &&
      password !== '' &&
      password !== ' ' &&
      password === confirmPassword &&
      validatePassword(
        password,
        this.props.enqueueSnackbar,
        this.props.closeSnackbar
      );

    if (validMail && validPhone && validPassword) {
      putNewUser({
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        companyCode,
        termsAndPrivacyAgreement,
        contactAgreement,
      })
        .then(() =>
          this.props.enqueueSnackbar(
            <FormattedMessage id="REGISTRATION.SUCCESS" />,
            { variant: 'success' }
          )
        )
        .then(() => this.props.history.push(routes.APP.INDEX))
        .catch(() =>
          this.props.enqueueSnackbar(
            <FormattedMessage id="REGISTRATION.FAILED" />,
            { variant: 'warning' }
          )
        );
    } else {
      let id = '';
      if (email !== confirmEmail) id = 'FORM_EMAIL_ADDRESSES_DO_NOT_MATCH';
      else if (password !== confirmPassword) id = 'FORM_PASSWORDS_DO_NOT_MATCH';
      else if (!validMail) id = 'FORM_INVALID_EMAIL_ADDRESS';
      else if (!validPhone) id = 'FORM_INVALID_PHONE_NUMBER';
      else id = 'REGISTRATION.FAILED';

      this.props.enqueueSnackbar(<FormattedMessage id={id} />, {
        variant: 'warning',
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <HeadlineBox headLineText="REGISTRATION" />
        <RegistrationForm
          handleTerms={this.handleTerms}
          handlePolicy={this.handlePolicy}
          handleSubmit={this.handleSubmit}
          companies={this.props.companies}
          cancelButtonFunction={this.props.history.goBack}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  companies: state.companies,
  dataPolicy: state.dataPolicy,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanies: () => dispatch(getCompanies()),
});

Registration.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ),
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  getCompanies: PropTypes.func.isRequired,
};

export default withSnackbar(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Registration))
);
