export default class NotificationMessage {
  constructor({
    id,
    timestamp,
    type,
    headline,
    subject,
    message,
    isRead,
    targetUrl,
    details,
  }) {
    this.id = id;
    this.timestamp = timestamp;
    this.type = type;
    this.headline = headline;
    this.subject = subject;
    this.message = message;
    this.isRead = isRead;
    this.targetUrl = targetUrl;
    this.details = details;
  }
}
