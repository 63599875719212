import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Routes } from '../../constants';
import FoundUlds from './foundUlds';
import LostUlds from './lostUlds';

const LostAndFoundTabRoutes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={Routes.APP.LOST_AND_FOUND.INDEX}
        to={Routes.APP.LOST_AND_FOUND.FOUND}
      />
      <Route
        exact
        path={Routes.APP.LOST_AND_FOUND.FOUND}
        component={FoundUlds}
      />
      <Route exact path={Routes.APP.LOST_AND_FOUND.LOST} component={LostUlds} />
    </Switch>
  );
};

export default LostAndFoundTabRoutes;
