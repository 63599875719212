import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { putNewStock, putNewLeasingStock } from '../actions/stock';
import routes from '../constants/routes';
import StockForm from '../components/stockForm';
import { getAirports } from '../actions/airports';
import { getUldTypes } from '../actions/uldTypes';
import HeadlineBox from '../components/headlineBox';
import { validateEmail, validatePhone } from '../utils/utils';

const styles = (theme) => ({
  newStockHeadlineDiv: {
    fontSize: '1.5rem',
    fontWeight: '700',
    marginBottom: '2em',
    marginTop: '1.5em',
    padding: '0',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'left',
    color: theme.palette.primary.chambray,
  },
});

export class AddNewStock extends Component {
  componentDidMount = () => {
    this.props.getAirports(this.props.token);
    this.props.getUldTypes(this.props.token);
  };

  state = {
    showErrors: false,
  };

  onShowError = () => {
    this.setState({
      showErrors: true,
    });
  };

  handleSubmit = ({
    contactPerson,
    email,
    phone,
    factor,
    amount,
    uldType,
    iataCode,
    validUntil,
    destinationIataCode,
    availableFrom,
    comment,
    location,
    leasingType,
    commercialInformation,
    isLeasing,
  }) => {
    const validMail =
      email !== undefined &&
      email !== null &&
      email !== '' &&
      email !== ' ' &&
      validateEmail(email);
    const validPhone =
      phone !== undefined &&
      phone !== null &&
      phone !== '' &&
      phone !== ' ' &&
      validatePhone(phone);

    validMail || validPhone
      ? (isLeasing
          ? this.props.putNewLeasingStock(
              {
                contactPerson,
                email,
                phone,
                factor,
                amount,
                uldType,
                iataCode,
                validUntil,
                destinationIataCode,
                availableFrom,
                comment,
                location,
                leasingType,
                commercialInformation,
              },
              this.props.token
            )
          : this.props.putNewStock(
              {
                contactPerson,
                email,
                phone,
                factor,
                amount,
                uldType,
                iataCode,
                validUntil,
                destinationIataCode,
                availableFrom,
                comment,
                location,
              },
              this.props.token
            )
        )
          .then(() =>
            this.props.enqueueSnackbar(
              <FormattedMessage id="BASE.FETCHING_SUCCESS" />,
              { variant: 'success' }
            )
          )
          .then(() => this.props.history.push(routes.APP.MY_INVENTORY.INDEX))
          .catch(() =>
            this.props.enqueueSnackbar(
              <FormattedMessage id="BASE.FETCHING_WARNING" />,
              { variant: 'warning' }
            )
          )
      : this.props.enqueueSnackbar(
          <FormattedMessage id="FORM_EMAIL_OR_PHONE_REQUIRED" />,
          { variant: 'warning' }
        );
  };

  render() {
    const { classes, airports, uldTypes, history, user } = this.props;

    return (
      <React.Fragment>
        <HeadlineBox headLineText="NEW_STOCK.HEADLINE" />
        <div className={`MuiTab-root ${classes.newStockHeadlineDiv}`}>
          <FormattedMessage id="ADD_NEW_STOCK" />
        </div>
        <StockForm
          handleSubmit={this.handleSubmit}
          uldTypes={uldTypes}
          airports={airports}
          user={user}
          onShowError={this.onShowError}
          showErrors={this.state.showErrors}
          confirmButtonLabel={
            <FormattedMessage id="ADD_NEW_STOCK.NEW_REQUEST" />
          }
          cancelButtonFunction={history.goBack}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    airports: state.airports,
    uldTypes: state.uldTypes,
    user: state.user,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAirports: (token) => dispatch(getAirports(token)),
  getUldTypes: (token) => dispatch(getUldTypes(token)),
  putNewStock: (payload, token) => dispatch(putNewStock(payload, token)),
  putNewLeasingStock: (payload, token) =>
    dispatch(putNewLeasingStock(payload, token)),
});

AddNewStock.defaultProps = {
  airports: [],
  uldTypes: [],
  user: {},
};

AddNewStock.propTypes = {
  classes: PropTypes.object.isRequired,
  getAirports: PropTypes.func.isRequired,
  getUldTypes: PropTypes.func.isRequired,
  putNewStock: PropTypes.func.isRequired,
  putNewLeasingStock: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      iataCode: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  uldTypes: PropTypes.arrayOf(PropTypes.shape).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  user: PropTypes.object,
  token: PropTypes.string,
};

export default withSnackbar(
  withRouter(
    withStyles(styles)(
      connect(mapStateToProps, mapDispatchToProps)(AddNewStock)
    )
  )
);
