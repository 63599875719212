export default {
  AIRPORTS: {
    ADD_ALL_AIRPORTS: 'ADD_ALL_AIRPORTS',
  },

  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_LOCALE: 'SET_LOCALE',

  STOCK: {
    DELETE: 'DELETE_STOCKS',
    MY_INVENTORY: {
      ADD: 'MY_INVENTORY_ADD_STOCKS',
    },
    USER_COMPANY: {
      ADD: 'USER_COMPANY_ADD_STOCKS',
    },
    MATCHES: {
      ADD: 'MATCHES_ADD_STOCKS',
      ADD_HAS_MATCHES: 'ADD_HAS_MATCHES',
      OPEN_POP_UP: 'OPEN_POP_UP',
    },
    AIRPORT: {
      ADD: 'AIRPORT_ADD_STOCKS',
    },
    SPECIAL_STOCK: 'SPECIAL_STOCK',
  },

  LOST_AND_FOUND: {
    ADD_LOST: 'LOST_AND_FOUND_ADD_LOST',
    ADD_FOUND: 'LOST_AND_FOUND_ADD_FOUND',
    ADD_FILTER_STRING: 'ADD_FILTER_STRING',
    GET_LOST_AND_FOUND_ENTRY: 'GET_LOST_AND_FOUND_ENTRY',
    ADD_LOST_AND_FOUND_MESSAGE: 'ADD_LOST_AND_FOUND_MESSAGE',
  },

  AUTH: {
    ADD_ROUTE_FROM_UNAUTHORIZED: 'ADD_ROUTE_FROM_UNAUTHORIZED',
    TOKEN: {
      ADD: 'TOKEN_ADD',
      REMOVE: 'TOKEN_REMOVE',
      ADD_ERROR: 'ADD_ERROR',
    },
  },

  ULD_TYPES: {
    ADD_ALL_ULD_TYPES: 'ADD_ALL_ULD_TYPES',
  },

  USER: {
    GET_USER: 'GET_USER',
    GET_UNREAD: 'GET_UNREAD',
  },

  GLOBAL_CHAT: {
    GET_GLOBAL_CHAT: 'GET_GLOBAL_CHAT',
  },
  NOTIFICATIONS: {
    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  },
  COMPANY: {
    ADD_ALL_COMPANIES: 'ADD_ALL_COMPANIES',
  },

  ADMIN: {
    ADD_USERS: 'ADMIN.ADD_USERS',
    ADD_ROLES: 'ADMIN.ADD_ROLES',
    SET_AS_ADMIN: 'ADMIN.SET_AS_ADMIN',
    REMINDER: {
      ADD_INTERVAL: 'ADMIN.REMINDER.ADD_INTERVAL',
      ADD_AUDIENCE: 'ADMIN.REMINDER.ADD_AUDIENCE',
      ADD_REMINDERS: 'ADMIN.REMINDER.ADD_REMINDERS',
    },
    NEWS: {
      ADD_NEWS: 'ADMIN.NEWS.ADD_NEWS',
    },
  },

  GET_PATH_FOR_IMAGES: {
    GET_INFO_SLIDER: 'GET_INFO_SLIDER',
    GET_BENEFITS_SLIDER: 'GET_BENEFITS_SLIDER',
    GET_COMPANIES_IMAGES: 'GET_COMPANIES_IMAGES',
    GET_AD_AIRPORT_STOCKS: 'GET_AIRPORT_STOCKS',
    GET_AD_LANDING_PAGE_BOTTOM: 'GET_LANDING_PAGE_BOTTOM',
    GET_AD_LANDING_PAGE_TOP: 'GET_LANDING_PAGE_TOP',
    GET_AD_MESSAGES: 'GET_MESSAGES',
    GET_AD_MY_COMPANY: 'GET_MY_COMPANY',
    GET_AD_MY_INVENTORY: 'GET_MY_INVENTORY',
  },

  STOCK_CHAT: {
    GET_STOCK_CHAT: 'STOCK_CHAT.GET_STOCK_CHAT',
    RESET_STOCK_CHAT_MESSAGES: 'STOCK_CHAT.RESET_STOCK_CHAT_MESSAGES',
  },
  BOOKINGS: {
    INCOMING: {
      ADD_OPEN_BOOKINGS: 'BOOKINGS.INCOMING.ADD_OPEN_BOOKINGS',
      ADD_CONFIRMED_BOOKINGS: 'BOOKINGS.INCOMING.ADD_CONFIRMED_BOOKINGS',
      ADD_DENIED_BOOKINGS: 'BOOKINGS.INCOMING.ADD_DENIED_BOOKINGS',
    },
    OUTGOING: {
      ADD_OPEN_BOOKINGS: 'BOOKINGS.OUTGOING.ADD_OPEN_BOOKINGS',
      ADD_CONFIRMED_BOOKINGS: 'BOOKINGS.OUTGOING.ADD_CONFIRMED_BOOKINGS',
      ADD_DENIED_BOOKINGS: 'BOOKINGS.OUTGOING.ADD_DENIED_BOOKINGS',
    },
    ADD_ALL_BOOKINGS: 'BOOKINGS.ADD_ALL_BOOKINGS',
    ADD_SPECIAL_BOOKING: 'BOOKINGS.ADD_SPECIAL_BOOKING',
  },
  COOKIE: {
    SET_COOKIE: 'ACTIONS.COOKIE.SET_COOKIE',
  },
};
