import React from 'react';
import { getFromServer, postToServer, deleteFromServer } from '../api';
import { Api, Actions } from '../constants';
import { createAction } from './utils';
import { FormattedMessage } from 'react-intl';
import Reminder from '../model/reminder';
import { patchFromServer } from '../api';

export const getAvailableIntervals = (token, enqueueSnackbar) => (dispatch) => {
  return getFromServer(Api.ADMIN_API.REMINDER.INTERVAL, token)
    .then((response) => {
      if (response.ok) return response.json();
      else throw response;
    })
    .then(({ data }) =>
      dispatch(createAction(Actions.ADMIN.REMINDER.ADD_INTERVAL, data))
    )
    .catch((err) => {
      enqueueSnackbar(
        <FormattedMessage id="REMINDER.COULD_NOT_GET_INTERVALS" />,
        {
          variant: 'error',
        }
      );
    });
};

export const getAvailableAudience = (token, enqueueSnackbar) => (dispatch) => {
  return getFromServer(Api.ADMIN_API.REMINDER.AUDIENCE, token)
    .then((response) => {
      if (response.ok) return response.json();
      else throw response;
    })
    .then(({ data }) =>
      dispatch(createAction(Actions.ADMIN.REMINDER.ADD_AUDIENCE, data))
    )
    .catch((err) => {
      enqueueSnackbar(
        <FormattedMessage id="REMINDER.COULD_NOT_GET_AUDIENCE" />,
        {
          variant: 'error',
        }
      );
    });
};

export const getReminders = (token, enqueueSnackbar) => (dispatch) => {
  return getFromServer(Api.ADMIN_API.REMINDER.INDEX, token)
    .then((response) => {
      if (response.ok) return response.json();
      else throw response;
    })
    .then(({ data }) =>
      dispatch(
        createAction(
          Actions.ADMIN.REMINDER.ADD_REMINDERS,
          data.map((element) => new Reminder(element))
        )
      )
    )
    .catch((err) => {
      enqueueSnackbar(
        <FormattedMessage id="REMINDER.COULD_NOT_GET_REMINDERS" />,
        {
          variant: 'error',
        }
      );
    });
};

export const addReminder =
  (reminder, token, enqueueSnackbar, successAction) => (dispatch) => {
    return postToServer(Api.ADMIN_API.REMINDER.INDEX, reminder, token)
      .then((response) => {
        if (response.ok) return response.json();
        else throw response;
      })
      .then(({ data }) =>
        enqueueSnackbar(
          <FormattedMessage
            id="REMINDER.SUCCESSFULLY_SET_REMINDER"
            values={{ nextExecution: new Date(data) }}
          />,
          {
            variant: 'success',
          }
        )
      )
      .then(() => successAction())
      .then(() => dispatch(getReminders(token, enqueueSnackbar)))
      .catch((resp = {}) => {
        if (resp.json)
          return resp.json().then(({ errorCode }) =>
            enqueueSnackbar(
              <FormattedMessage
                id="REMINDER.COULD_NOT_SET"
                values={{
                  errorCode,
                }}
              />,
              { variant: 'error' }
            )
          );
        else
          return enqueueSnackbar(
            <FormattedMessage
              id="REMINDER.COULD_NOT_SET"
              values={{
                errorCode: 'GENERAL_ERROR',
              }}
            />,
            { variant: 'error' }
          );
      });
  };

export const deleteReminder =
  (reminderId, token, enqueueSnackbar, successAction) => (dispatch) => {
    return deleteFromServer(Api.ADMIN_API.REMINDER.INDEX, reminderId, token)
      .then((response) => {
        if (response.ok) return response.json();
        else throw response;
      })
      .then(({ data }) =>
        enqueueSnackbar(
          <FormattedMessage id="REMINDER.SUCCESSFULLY_DELETED" />,
          {
            variant: 'success',
          }
        )
      )
      .then(() => successAction())
      .then(() => dispatch(getReminders(token, enqueueSnackbar)))
      .catch((resp = {}) => {
        if (resp.json)
          return resp.json().then(({ errorCode }) =>
            enqueueSnackbar(
              <FormattedMessage
                id="REMINDER.COULD_NOT_DELETE"
                values={{
                  errorCode,
                }}
              />,
              { variant: 'error' }
            )
          );
        else
          return enqueueSnackbar(
            <FormattedMessage
              id="REMINDER.COULD_NOT_DELETE"
              values={{
                errorCode: 'GENERAL_ERROR',
              }}
            />,
            { variant: 'error' }
          );
      });
  };

export const editReminder =
  (reminder, token, enqueueSnackbar, successAction) => (dispatch) => {
    return patchFromServer(Api.ADMIN_API.REMINDER.INDEX, reminder, token)
      .then((response) => {
        if (response.ok) return response.json();
        else throw response;
      })
      .then(({ data }) =>
        enqueueSnackbar(
          <FormattedMessage
            id="REMINDER.SUCCESFULLY_EDITED"
            values={{
              nextExecution: new Date(data),
            }}
          />,
          {
            variant: 'success',
          }
        )
      )
      .then(() => successAction())
      .then(() => dispatch(getReminders(token, enqueueSnackbar)))
      .catch((resp = {}) => {
        if (resp.json)
          return resp.json().then(({ errorCode }) =>
            enqueueSnackbar(
              <FormattedMessage
                id="REMINDER.COULD_NOT_EDIT"
                values={{
                  errorCode,
                }}
              />,
              { variant: 'error' }
            )
          );
        else
          return enqueueSnackbar(
            <FormattedMessage
              id="REMINDER.COULD_NOT_EDIT"
              values={{
                errorCode: 'GENERAL_ERROR',
              }}
            />,
            { variant: 'error' }
          );
      });
  };
