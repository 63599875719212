import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Grid } from '@material-ui/core';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import MessageInputContainer from './messageInputContainer';
import { withStyles } from '@material-ui/core/styles';
import GlobalChatMessage from '../model/globalChatMessage';

const styles = (theme) => ({
  headline: {
    fontSize: '1.5rem',
    fontWeight: '700',
    marginTop: '1.5em',
    padding: '0',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'unset',
    textTransform: 'none',
    color: theme.palette.primary.chambray,
  },
  contactCell: {
    color: theme.palette.primary.chambray,
    fontSize: '1rem',
    fontWeight: '700',
    marginRight: '1%',
  },
  companyCell: {
    fontSize: '1rem',
    fontWeight: '700',
  },
  timeCell: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '0.9rem',
  },
  chat: {
    height: '400px',
    overflowY: 'auto',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  borderStyle: {
    borderBottom: '1px solid #000000',
  },
  messageStyle: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
});

export class GlobalChatForm extends Component {
  chatContainerRef = React.createRef();

  scrollLastMessagesIntoView = () => {
    requestAnimationFrame(() => {
      this.chatContainerRef.current.scrollTop =
        this.chatContainerRef.current.scrollHeight;
    });
  };

  componentDidMount = () => {
    this.scrollLastMessagesIntoView();
  };

  componentDidUpdate = (prevProps) => {
    const { globalChat } = this.props;
    const { globalChat: prevGlobalChat } = prevProps;

    if (globalChat.length !== prevGlobalChat.length)
      this.scrollLastMessagesIntoView();
  };

  render() {
    const { classes, globalChat, sendChatMessage } = this.props;

    return (
      <form>
        <div className={`${classes.headline}`}>
          <FormattedMessage id="GLOBAL_CHAT.CHAT_OVERVIEW" />
        </div>
        <div className={classes.chat} ref={this.chatContainerRef}>
          <List>
            {globalChat.map((item) => (
              <ListItem key={item.timestamp}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <span className={classes.contactCell}>
                      <FormattedDate
                        year="numeric"
                        month="2-digit"
                        day="2-digit"
                        value={item.timestamp}
                      />
                    </span>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container spacing={1}>
                      <Grid item xs={11}>
                        <span className={classes.contactCell}>
                          {item.username}
                        </span>
                        <span className={classes.timeCell}>
                          <FormattedTime
                            hour="2-digit"
                            minute="2-digit"
                            value={item.timestamp}
                          />
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={10}>
                        <span className={classes.companyCell}>
                          {item.companyName}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} className={classes.messageStyle}>
                        {item.message}
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.borderStyle} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
          <div />
        </div>
        <MessageInputContainer
          width="100%"
          chatWidth="66%"
          sendChatMessage={(event) => sendChatMessage(event)}
        />
      </form>
    );
  }
}

GlobalChatForm.propTypes = {
  classes: PropTypes.object.isRequired,
  globalChat: PropTypes.arrayOf(PropTypes.instanceOf(GlobalChatMessage))
    .isRequired,
  sendChatMessage: PropTypes.func.isRequired,
};

export default withStyles(styles)(GlobalChatForm);
