import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import MenuButton from '../menuButton';
import Download from '../../icons/download';
import Upload from '../../icons/upload';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  menuButtonContrastText: {
    color: theme.palette.primary.cornflowerBlue,
  },
  linkStyle: {
    textDecorationColor: theme.palette.primary.dark,
  },
}));

export default function ExcelUpload({ importExcelFunction }) {
  const classes = useStyles();

  return (
    <Fragment>
      <div style={{ marginLeft: '50px' }}>
        <a
          className={classes.linkStyle}
          href={`/skypooling_inventory_template.xlsx`}
          download
        >
          <MenuButton
            img={<Download />}
            text={<FormattedMessage id="MY_INVENTORY.DOWNLOAD_TEMPLATE" />}
            classes={{ text: classes.menuButtonContrastText }}
          />
        </a>
      </div>
      <div style={{ marginLeft: '50px' }}>
        <input
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          style={{ display: 'none' }}
          id="upload-excel"
          type="file"
          onChange={importExcelFunction}
        />
        <label htmlFor="upload-excel">
          <MenuButton
            component="span"
            img={<Upload />}
            text={<FormattedMessage id="MY_INVENTORY.UPLOAD_TEMPLATE" />}
            classes={{ text: classes.menuButtonContrastText }}
          />
        </label>
      </div>
    </Fragment>
  );
}

ExcelUpload.propTypes = {
  importExcelFunction: PropTypes.func.isRequired,
};
