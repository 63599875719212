import React, { Fragment } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { FormattedMessage } from 'react-intl';

import { makeStyles, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useMedia } from '../../utils/customHooks';

const styles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.dark,
    fontSize: '1.625rem',
    margin: '10px 0 0',
    marginTop: '1.25rem',
    textAlign: 'center',
  },
  textStyle: {
    color: theme.palette.primary.light,
    fontStyle: 'italic',
  },
  authorTextStyle: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    float: 'right',
    paddingTop: '1.5rem',
  },
  slider: {
    '& .carousel .slide': {
      background: 'white',
    },
    '& .control-arrow': {
      width: '10rem',
    },
  },
}));

export default function TextSlideshow() {
  const classes = styles();

  const { fontSize, height } = useMedia(
    ['(min-width: 1280px)', '(min-width: 960px)'],
    [
      {
        fontSize: '2rem',
        height: '400px',
      },
      {
        fontSize: '1.5rem',
        height: '200px',
      },
    ],
    {
      fontSize: '1.2rem',
      height: '200px',
    }
  );

  return (
    <Fragment>
      <Grid container spacing={5} justify="center">
        <Grid item xs={12} style={{ padding: '30px 20px 0' }}>
          <div className={classes.title}>
            <Typography className={classes.title} variant="h6" id="tableTitle">
              <FormattedMessage id="HOME.TEXT_SLIDESHOW_HEADLINE" />
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Carousel
            className={classes.slider}
            style={{ height: height }}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            infiniteLoop={true}
            autoPlay
            interval={10000}
          >
            <div className={classes.textStyle}>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_1" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_1_2" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_1_3" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_1_4" />
              </div>
              <div className={classes.authorTextStyle}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_AUTHOR_1" />
              </div>
            </div>
            <div className={classes.textStyle}>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_2" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_2_1" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_2_2" />
              </div>
              <div className={classes.authorTextStyle}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_AUTHOR_2" />
              </div>
            </div>
            <div className={classes.textStyle}>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_3" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_3_1" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_3_2" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_3_3" />
              </div>
              <div className={classes.authorTextStyle}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_AUTHOR_3" />
              </div>
            </div>
            <div className={classes.textStyle}>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_4" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_4_1" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_4_2" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_4_3" />
              </div>
              <div className={classes.authorTextStyle}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_AUTHOR_4" />
              </div>
            </div>
            <div className={classes.textStyle}>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_5" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_5_1" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_5_2" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_5_3" />
              </div>
              <div className={classes.authorTextStyle}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_AUTHOR_5" />
              </div>
            </div>
            <div className={classes.textStyle}>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_6" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_6_1" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_6_2" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_6_3" />
              </div>
              <div className={classes.authorTextStyle}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_AUTHOR_6" />
              </div>
            </div>
            <div className={classes.textStyle}>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_7" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_7_1" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_7_2" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_7_3" />
              </div>
              <div className={classes.authorTextStyle}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_AUTHOR_7" />
              </div>
            </div>
            <div className={classes.textStyle}>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_8" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_8_1" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_8_2" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_8_3" />
              </div>
              <div className={classes.authorTextStyle}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_AUTHOR_8" />
              </div>
            </div>
            <div className={classes.textStyle}>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_99" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_99_1" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_99_2" />
              </div>
              <div style={{ fontSize: fontSize }}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_TEXT_CUSTOMER_99_3" />
              </div>
              <div className={classes.authorTextStyle}>
                <FormattedMessage id="HOME.TEXT_SLIDESHOW_AUTHOR_99" />
              </div>
            </div>
          </Carousel>
        </Grid>
      </Grid>
    </Fragment>
  );
}
