import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Routes } from '../constants';
import App from '../containers/App';
import TabNavbar from '../components/tabNavbar/tabNavbar';
import PrivateRoute from './privateRoute';
import Home from '../containers/home';
import Registration from '../containers/registration';
import Imprint from '../containers/imprint';
import DataPrivacy from '../containers/dataPrivacy';
import TermsOfUse from '../containers/termsOfUse';
import AuthPage from '../containers/auth';
import ResetPasswordPage from '../containers/resetPassword';
import ScrollToTop from './helper/scrollToTop';

const Routing = () => {
  return (
    <Router>
      <App>
        <ScrollToTop />
        <Switch>
          <Redirect exact from={Routes.INDEX} to={Routes.PUBLIC.HOME} />
          <PrivateRoute path={Routes.APP.INDEX} component={TabNavbar} />
          <Route exact path={Routes.PUBLIC.AUTH} component={AuthPage} />
          <Route exact path={Routes.PUBLIC.HOME} component={Home} />
          <Route
            exact
            path={Routes.PUBLIC.REGISTRATION}
            component={Registration}
          />
          <Route exact path={Routes.PUBLIC.IMPRINT} component={Imprint} />
          <Route
            exact
            path={Routes.PUBLIC.DATA_PRIVACY}
            component={DataPrivacy}
          />
          <Route
            exact
            path={Routes.PUBLIC.TERMS_OF_USE}
            component={TermsOfUse}
          />
          <Route
            exact
            path={Routes.PUBLIC.RESET_PASSWORD}
            component={ResetPasswordPage}
          />
          <Redirect from={'*'} to={Routes.PUBLIC.HOME} />
        </Switch>
      </App>
    </Router>
  );
};

export default Routing;
