import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Api, Actions } from '../constants';
import { createAction } from './utils';
import { getFromServer } from '../api';
import News from '../model/news';

export const getNews = (token, enqueueSnackbar) => (dispatch) => {
  return getFromServer(Api.ADMIN_API.NEWS, token)
    .then((response) => {
      if (response.ok) return response.json();
      else throw response;
    })
    .then(({ data }) =>
      dispatch(
        createAction(
          Actions.ADMIN.NEWS.ADD_NEWS,
          data.map((element) => new News(element))
        )
      )
    )
    .catch((resp = {}) => {
      if (resp.json)
        return resp.json().then(({ errorCode }) =>
          enqueueSnackbar(
            <FormattedMessage
              id="NEWS.COULD_NOT_GET"
              values={{
                errorCode,
              }}
            />,
            { variant: 'error' }
          )
        );
      else
        return enqueueSnackbar(
          <FormattedMessage
            id="NEWS.COULD_NOT_GET"
            values={{
              errorCode: 'GENERAL_ERROR',
            }}
          />,
          { variant: 'error' }
        );
    });
};

export const setNews = (token, enqueueSnackbar) => (dispatch) => {
  return getFromServer(Api.ADMIN_API.NEWS, token)
    .then((response) => {
      if (response.ok) return response.json();
      else throw response;
    })
    .then(({ data }) =>
      dispatch(
        createAction(
          Actions.ADMIN.NEWS.ADD_NEWS,
          data.map((element) => new News(element))
        )
      )
    )
    .catch((resp = {}) => {
      if (resp.json)
        return resp.json().then(({ errorCode }) =>
          enqueueSnackbar(
            <FormattedMessage
              id="NEWS.COULD_NOT_SET"
              values={{
                errorCode,
              }}
            />,
            { variant: 'error' }
          )
        );
      else
        return enqueueSnackbar(
          <FormattedMessage
            id="NEWS.COULD_NOT_SET"
            values={{
              errorCode: 'GENERAL_ERROR',
            }}
          />,
          { variant: 'error' }
        );
    });
};
