import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
    '&:hover': {
      color: theme.palette.primary.slateGray,
      background: 'none',
    },
    transition: 'color 200ms ease-in-out',
    color: theme.palette.primary.blueZodiac,
  },
  label: {
    textTransform: 'none',
    display: 'flex',
  },
  img: {
    display: 'flex',
    paddingRight: '0.5rem',
  },
  text: {
    fontSize: '1rem',
  },
  disabled: {
    '& path': {
      fill: theme.palette.action.disabled,
    },
  },
  link: {
    height: '25px',
    width: '25px',
    backgroundColor: '#bbb',
    borderRadius: '50%',
    position: 'absolute',
  },
}));

export function MenuButton({
  text,
  onClick,
  img,
  classes,
  disabled,
  id,
  component,
  paddingLeft,
  minWidth,
}) {
  const defaultClasses = useStyles();

  const paddingStyle = paddingLeft ? paddingLeft : '0em';

  return (
    <Button
      id={id}
      component={component}
      className={`${defaultClasses.button}${
        classes.button ? ' ' + classes.button : ''
      }`}
      classes={{
        label: `${defaultClasses.label}${
          classes.label ? ' ' + classes.label : ''
        }`,
        disabled: defaultClasses.disabled,
      }}
      style={{
        paddingLeft: paddingStyle,
        minWidth: minWidth ? minWidth : null,
      }}
      color={'default'}
      onClick={onClick}
      disabled={disabled}
      disableFocusRipple
      disableRipple
    >
      <div
        className={`${defaultClasses.img}${
          classes.img ? ' ' + classes.img : ''
        }`}
      >
        {img}
      </div>
      <div
        className={`${defaultClasses.text}${
          classes.text ? ' ' + classes.text : ''
        }`}
      >
        {text}
      </div>
    </Button>
  );
}

MenuButton.defaultProps = {
  isTableButton: false,
  classes: {},
};

MenuButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  img: PropTypes.node,
  classes: PropTypes.shape({
    button: PropTypes.string,
    label: PropTypes.string,
    img: PropTypes.string,
    text: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

export default MenuButton;
