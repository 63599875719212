import React from 'react';
import ReactDOM from 'react-dom';

import Root from './containers/root';
import configureStore from './store';
import 'typeface-open-sans';

const polyfill = [];

if (!window.fetch) {
  polyfill.push(
    import(/* webpackChunkName: "polyfill-fetch" */ 'whatwg-fetch')
  );
}

if (!window.IntersectionObserver) {
  polyfill.push(
    import(
      /* webpackChunkName: "polyfill-intersection-observer" */ 'intersection-observer'
    )
  );
}

// https://gist.github.com/bob-lee/e7520bfcdac266e5490f40c2759cc955
if ('NodeList' in window && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

const store = configureStore();

Promise.all(polyfill)
  .then(() => {
    ReactDOM.render(<Root store={store} />, document.getElementById('root'));
  })
  .catch(() => console.warn('error loading polyfills'));
