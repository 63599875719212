import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField, makeStyles } from '@material-ui/core';

import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  line: {
    backgroundColor: theme.palette.primary.linkWater25,
    height: '4px',
    display: 'flex',
    width: '100%',
    marginTop: '2em',
    marginBottom: '2em',
  },
}));

export default function UnchangeableProfileInformation({
  userUnchangeableInforations,
}) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container spacing={4} justify="center">
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              disabled={true}
              id="standard-email"
              label={<FormattedMessage id="BASE.EMAIL" />}
              type="email"
              margin="normal"
              value={userUnchangeableInforations.email || ''}
              onChange={() => {}}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              id="comapny-name"
              disabled={true}
              label={<FormattedMessage id="BASE.COMPANY_NAME" />}
              margin="normal"
              value={userUnchangeableInforations.company.name || ''}
              onChange={() => {}}
            />
          </FormControl>
        </Grid>
      </Grid>
      <div className={classes.line} />
    </React.Fragment>
  );
}

UnchangeableProfileInformation.propTypes = {
  userUnchangeableInforations: PropTypes.shape({
    email: PropTypes.string.isRequired,
    company: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }).isRequired,
  }),
};
