import { getFromServer, deleteFromServer, postToServer } from '../api';
import { Actions, Api } from '../constants';
import { createAction } from './utils';
import AdministratedUser from '../model/administratedUser';

const isUnauthorized = (status) => {
  return status === 401 || status === 403;
};

export const getUsersAsAdmin = (token) => (dispatch) => {
  return getFromServer(Api.ADMIN_API.USERS, token).then((resp) => {
    if (resp.ok) {
      resp.json().then((value) =>
        dispatch(
          createAction(
            Actions.ADMIN.ADD_USERS,
            value.map((user) => new AdministratedUser(user))
          )
        )
      );
    } else if (isUnauthorized(resp.status)) {
      // this is to be expected, if the page mounts as a normal user
    } else {
      // this is an actual problem (api is not up, etc)
      throw resp;
    }
  });
};

export const getRolesAsAdmin = (token) => (dispatch) => {
  return getFromServer(Api.ADMIN_API.USERS + '/roles', token).then((resp) => {
    if (resp.ok) {
      resp
        .json()
        .then((value) =>
          dispatch(createAction(Actions.ADMIN.ADD_ROLES, value))
        );
    } else if (isUnauthorized(resp.status)) {
      // this is to be expected, if the page mounts as a normal user
    } else {
      // this is an actual problem (api is not up, etc)
      throw resp;
    }
  });
};

export const enableUser = (token, keycloakId) => {
  return enableDisableUser(token, keycloakId, 'enable');
};

export const disableUser = (token, keycloakId) => {
  return enableDisableUser(token, keycloakId, 'disable');
};

export const setUserRole = (token, keycloakId, role) => {
  return postToServer(
    Api.ADMIN_API.USERS + '/' + keycloakId + '/addRole',
    role,
    token
  ).then((resp) => {
    if (resp.ok) {
      return true;
    } else {
      throw resp;
    }
  });
};

const enableDisableUser = (token, keycloakId, action) => {
  return getFromServer(
    Api.ADMIN_API.USERS + '/' + keycloakId + '/' + action,
    token
  ).then((resp) => {
    if (resp.ok) {
      return true;
    } else {
      throw resp;
    }
  });
};

export const deleteUser = (keycloakId, token) => {
  const payload = { keycloakId };
  return deleteFromServer(Api.ADMIN_API.USERS, payload, token).then((resp) => {
    if (resp.ok) {
      return true;
    } else {
      throw resp;
    }
  });
};
