import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { withStyles, Link } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';

import BackIcon from '../icons/back';
import MenuButton from '../components/menuButton';
import HeadlineBox from '../components/headlineBox';

import { Routes } from '../constants';

const styles = (theme) => ({
  title: {
    color: theme.palette.primary.dark,
    fontSize: '2.25rem',
    marginTop: '1.5em',
    marginBottom: '0.7em',
  },
  textStyle: {
    fontSize: '1.125rem',
    color: 'black',
    fontWeight: 'normal',
  },
  subtitle: {
    color: theme.palette.primary.dark,
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
});

class TermsOfUse extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <HeadlineBox headLineText="FOOTER.LEGAL_INFORMATION" />
        <div style={{ marginTop: '1em' }} />
        <MenuButton
          text={<FormattedMessage id="BASE.BACK" />}
          onClick={() => this.props.history.push(Routes.APP.INDEX)}
          img={<BackIcon />}
        />
        <div className={classes.title}>
          <FormattedMessage id="FOOTER.TERMS_OF_USE" />
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage
            id="FOOTER.TERMS_OF_USE_TEXT"
            values={{
              link: (
                <FormattedMessage id="FOOTER.TERMS_OF_USE_SKYPOOLING_LINK">
                  {(link) => <Link href={link}>{link}</Link>}
                </FormattedMessage>
              ),
            }}
          />
        </div>
        <br />
        <div className={classes.listDefinition}>
          <ol>
            <li className={classes.subtitle}>
              <div className={classes.textStyle}>
                <ListItemText
                  classes={{ primary: classes.subtitle }}
                  primary={<FormattedMessage id="FOOTER.TERM_OF_USE_GENERAL" />}
                />
              </div>
              <br />
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERM_OF_USE_GENERAL_TEXT_1" />
              </div>
              <br />
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERM_OF_USE_GENERAL_TEXT_1_2" />
              </div>
              <br />
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERMS_OF_USE_AGREE" />
              </div>
              <ol>
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_AGREE_1" />
                </li>
                <br />
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_AGREE_2" />
                </li>
                <br />
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_AGREE_3" />
                </li>
                <br />
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_AGREE_4" />
                </li>
                <br />
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_AGREE_5" />
                </li>
                <br />
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_AGREE_6" />
                </li>
                <br />
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_AGREE_7" />
                </li>
                <br />
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_AGREE_8" />
                </li>
              </ol>
            </li>
            <br />
            <li className={classes.subtitle}>
              <div className={classes.textStyle}>
                <ListItemText
                  classes={{ primary: classes.subtitle }}
                  primary={
                    <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION" />
                  }
                />
              </div>
              <br />
              <ol type="1">
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_1" />
                  <div style={{ minHeight: '1rem' }} />
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_1_1" />
                  <div style={{ minHeight: '1rem' }} />
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_1_2" />
                </li>
                <br />
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_2" />
                  <div style={{ minHeight: '1rem' }} />
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_2_1" />
                  <div style={{ minHeight: '1rem' }} />
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_2_2" />
                </li>
                <br />
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_3" />
                  <div style={{ minHeight: '1rem' }} />
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_3_1" />
                  <div style={{ minHeight: '1rem' }} />
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_3_2" />
                  <div style={{ minHeight: '1rem' }} />
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_3_3" />
                </li>
                <br />
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_4" />
                  <div style={{ minHeight: '1rem' }} />
                  <FormattedMessage
                    id="FOOTER.TERMS_OF_USE_REGISTRATION_2_4_1"
                    values={{
                      link: (
                        <Link href="https://skypooling.com/dataPrivacy ">
                          <FormattedMessage id="FOOTER.DATA_PRIVACY_TITLE" />
                        </Link>
                      ),
                    }}
                  />
                </li>
                <br />
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_5" />
                  <div style={{ minHeight: '1rem' }} />
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_5_1" />
                  <div style={{ minHeight: '1rem' }} />
                  <FormattedMessage
                    id="FOOTER.TERMS_OF_USE_REGISTRATION_2_5_2"
                    values={{
                      email: (
                        <FormattedMessage id="FOOTER.TERMS_OF_USE_EMAIL_INFO_SKYPOOLING">
                          {(mail) => (
                            <Link href={`mailto:${mail}`}>{mail}</Link>
                          )}
                        </FormattedMessage>
                      ),
                    }}
                  />
                  <div style={{ minHeight: '1rem' }} />
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_5_3" />
                </li>
                <br />
                <li className={classes.textStyle}>
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_6" />
                  <div style={{ minHeight: '1rem' }} />
                  <FormattedMessage id="FOOTER.TERMS_OF_USE_REGISTRATION_2_6_1" />
                </li>
              </ol>
            </li>
            <br />
            <li className={classes.subtitle}>
              <div className={classes.textStyle}>
                <ListItemText
                  classes={{ primary: classes.subtitle }}
                  primary={
                    <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES" />
                  }
                />
                <br />
                <ol>
                  <li className={classes.textStyle}>
                    <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3" />
                    <div classes={classes.textStyle}>
                      <br />
                      <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_1" />
                      <ol type="a">
                        <li>
                          <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_1_a" />
                        </li>
                        <li>
                          <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_1_b" />
                        </li>
                        <li>
                          <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_1_c" />
                        </li>
                      </ol>
                    </div>
                  </li>
                  <br />
                  <li className={classes.textStyle}>
                    <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_2" />
                    <div classes={classes.textStyle}>
                      <br />
                      <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_2_1" />
                    </div>
                  </li>
                  <br />
                  <li className={classes.textStyle}>
                    <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_3" />
                    <div classes={classes.textStyle}>
                      <br />
                      <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_3_1" />
                      <ol>
                        <br />
                        <li>
                          <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_3_POINT" />
                        </li>
                        <li>
                          <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_3_POINT_1" />
                        </li>
                      </ol>
                      <br />
                      <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_3_2" />
                    </div>
                  </li>
                  <br />
                  <li className={classes.textStyle}>
                    <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_4" />
                    <div classes={classes.textStyle}>
                      <br />
                      <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_4_1" />
                      <ol>
                        <br />
                        <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_4_2" />
                      </ol>
                      <br />
                      <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_4_3" />
                    </div>
                  </li>
                  <br />
                  <li className={classes.textStyle}>
                    <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_5" />
                    <div classes={classes.textStyle}>
                      <br />
                      <FormattedMessage id="FOOTER.TERMS_OF_USE_SERVICES_3_5_1" />
                    </div>
                  </li>
                </ol>
              </div>
            </li>
            <br />
            <li className={classes.subtitle}>
              <div className={classes.textStyle}>
                <ListItemText
                  classes={{ primary: classes.subtitle }}
                  primary={
                    <FormattedMessage id="FOOTER.TERMS_OF_USE_APPLICABLE" />
                  }
                />
              </div>
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERMS_OF_USE_APPLICABLE_1" />
              </div>
            </li>
            <br />
            <li className={classes.subtitle}>
              <div className={classes.textStyle}>
                <ListItemText
                  classes={{ primary: classes.subtitle }}
                  primary={
                    <FormattedMessage id="FOOTER.TERMS_OF_USE_PROPRIETARY" />
                  }
                />
              </div>
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERMS_OF_USE_PROPRIETARY_1" />
              </div>
              <br />
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERMS_OF_USE_PROPRIETARY_2" />
              </div>
            </li>
            <br />
            <li className={classes.subtitle}>
              <div className={classes.textStyle}>
                <ListItemText
                  classes={{ primary: classes.subtitle }}
                  primary={<FormattedMessage id="FOOTER.TERMS_OF_USE_CHANGE" />}
                />
              </div>
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERMS_OF_USE_CHANGE_1" />
              </div>
            </li>
            <br />
            <li className={classes.subtitle}>
              <div className={classes.textStyle}>
                <ListItemText
                  classes={{ primary: classes.subtitle }}
                  primary={<FormattedMessage id="FOOTER.TERMS_OF_USE_LINKS" />}
                />
              </div>
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERMS_OF_USE_LINKS_1" />
              </div>
              <br />
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERMS_OF_USE_LINKS_2" />
              </div>
            </li>
            <br />
            <li className={classes.subtitle}>
              <div className={classes.textStyle}>
                <ListItemText
                  classes={{ primary: classes.subtitle }}
                  primary={<FormattedMessage id="FOOTER.TERMS_OF_USE_LEGAL" />}
                />
              </div>
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERMS_OF_USE_LEGAL_1" />
              </div>
              <br />
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERMS_OF_USE_LEGAL_2" />
              </div>
              <br />
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERMS_OF_USE_LEGAL_3" />
              </div>
            </li>
            <br />
            <li className={classes.subtitle}>
              <div className={classes.textStyle}>
                <ListItemText
                  classes={{ primary: classes.subtitle }}
                  primary={
                    <FormattedMessage id="FOOTER.TERMS_OF_USE_STORAGE" />
                  }
                />
              </div>
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERMS_OF_USE_STORAGE_1" />
              </div>
            </li>
            <br />
            <li className={classes.subtitle}>
              <div className={classes.textStyle}>
                <ListItemText
                  classes={{ primary: classes.subtitle }}
                  primary={
                    <FormattedMessage id="FOOTER.TERMS_OF_USE_OTHER_TERMS" />
                  }
                />
              </div>
              <div className={classes.textStyle}>
                <FormattedMessage id="FOOTER.TERMS_OF_USE_OTHER_TERMS_1" />
              </div>
            </li>
          </ol>
        </div>
      </Fragment>
    );
  }
}

TermsOfUse.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(withStyles(styles)(TermsOfUse));
