import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import LostAndFoundContact from '../components/lostAndFound/lostAndFoundContact';
import LostAndFoundDetailInformations from '../components/lostAndFound/lostAndFoundDetailInformations';
import LostAndFoundChat from '../components/lostAndFound/lostAndFoundChat';
import MenuButton from '../components/menuButton';
import BackIcon from '../icons/back';
import HeadlineBox from '../components/headlineBox';
import { getLostAndFoundEntry, putMessage } from '../actions/lostAndFound';
import LostAndFoundEntry from '../model/lostAndFoundEntry';
import LoadingComponent from '../components/loadingComponent';
import { Routes } from '../constants';

const styles = (theme) => ({
  line: {
    backgroundColor: theme.palette.primary.linkWater25,
    height: '4px',
    display: 'flex',
    width: '100%',
    marginTop: '2em',
    marginBottom: '2em',
  },
  detailsHeadline: {
    fontSize: '1.5rem',
    fontWeight: '700',
    marginBottom: '2em',
    marginTop: '1.5em',
    padding: '0',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'left',
    textTransform: 'none',
    color: theme.palette.primary.chambray,
  },
});

export function LostAndFoundDetails({
  token,
  classes,
  history,
  match,
  getLostAndFoundEntry,
  lostAndFoundEntry,
  enqueueSnackbar,
  putMessage,
}) {
  const commentRef = React.createRef();

  const onError = () => {
    history.push(Routes.APP.LOST_AND_FOUND.INDEX);
    enqueueSnackbar(
      <FormattedMessage id="ADD_NEW_LOST_AND_FOUND.COULD_NOT_BE_LOADED" />,
      {
        variant: 'error',
      }
    );
  };

  useEffect(() => {
    getLostAndFoundEntry(match.params.id, token).catch(() => onError());
  }, []);

  const sendChatMessage = (message) => {
    const validMessage = message ? message : undefined;

    if (validMessage) {
      if (validMessage.length > 512) {
        enqueueSnackbar(
          <FormattedMessage id="GLOBAL_CHAT.ERROR_MESSAGE_LENGTH" />,
          { variant: 'warning' }
        );
      } else {
        putMessage(lostAndFoundEntry.id, message, token);
      }
    } else {
      enqueueSnackbar(<FormattedMessage id="GLOBAL_CHAT.ERROR_MESSAGE" />, {
        variant: 'warning',
      });
    }
  };

  const scrollToComments = () => {
    commentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };
  if (lostAndFoundEntry !== undefined) {
    return (
      <React.Fragment>
        <HeadlineBox
          textTransform="initial"
          headLineText="TAB_NAVBAR.LOST_AND_FOUND"
        />

        <div style={{ marginTop: '1em' }}>
          <MenuButton
            text={<FormattedMessage id="BASE.BACK" />}
            onClick={() => history.goBack()}
            img={<BackIcon />}
          />
        </div>

        <div className={`MuiTab-root ${classes.detailsHeadline}`}>
          <FormattedMessage id="STOCKDETAILS.HEADLINE" />
        </div>

        <LostAndFoundContact
          contactPerson={lostAndFoundEntry.userName}
          phone={lostAndFoundEntry.phone}
          scrollToComments={scrollToComments}
          companyName={lostAndFoundEntry.companyName}
        />

        <div className={classes.line} />

        <LostAndFoundDetailInformations
          report={lostAndFoundEntry.report}
          stockIds={lostAndFoundEntry.uldIds}
          comment={lostAndFoundEntry.comment}
          iataCode={lostAndFoundEntry.iataCode}
        />

        <div className={classes.line} />

        <LostAndFoundChat
          chatContainerRef={commentRef}
          lostAndFoundMessages={lostAndFoundEntry.messages}
          sendChatMessage={sendChatMessage}
        />

        <div className={classes.line} />
      </React.Fragment>
    );
  } else {
    return <LoadingComponent formattedMessageId="BASE.LOADING" />;
  }
}

LostAndFoundDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.object,
  token: PropTypes.string,
  getLostAndFoundEntry: PropTypes.func.isRequired,
  lostAndFoundEntry: PropTypes.instanceOf(LostAndFoundEntry),
  enqueueSnackbar: PropTypes.func.isRequired,
  putMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  lostAndFoundEntry: state.lostAndFound.lostAndFoundEntry,
});

const mapDispatchToProps = (dispatch) => ({
  getLostAndFoundEntry: (id, token) =>
    dispatch(getLostAndFoundEntry(id, token)),
  putMessage: (id, message, token) => dispatch(putMessage(id, message, token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(withStyles(styles)(LostAndFoundDetails))));
