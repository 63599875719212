import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';

import Table from '../components/table';
import { Routes } from '../constants';
import { getMyInventoryStocks, importExcel } from '../actions/stock';
import { createAction } from '../actions/utils';
import Actions from '../constants/actions';
import SearchFieldBox from '../components/searchFieldBox';
import { getAdMyInventory } from '../actions/pathsForImages';
import { HeadlineBoxWithSearchAndAddStock } from '../components/headlineBox';
import { withSnackbar } from 'notistack';
import AdLandscape from '../components/adLandscape';

const headRowsForTable = (formatMessage) => [
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TILE_EMERGENCY' }),
  }, // "Mark-as-important"-Row
  {
    id: 'companyCode',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.COMPANY_CODE' }),
  },
  {
    id: 'contact',
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.CONTACT' }),
  },
  {
    id: 'amount',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.AMOUNT' }),
  },
  {
    id: 'uldType',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.ULD_TYPE' }),
  },
  {
    id: 'iataCode',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.STATION' }),
  },
  {
    id: 'availableFrom',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.AVAILABLE_FROM' }),
  },
  {
    id: 'validUntil',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.VALID_UNTIL' }),
  },
  {
    id: 'requests',
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.REQUESTS' }),
  },
  { id: 'deleteEdit', numeric: true, disablePadding: false, label: '' }, // "Delete"-Row
];

const headRowsForTableWithoutEmergency = (formatMessage) => [
  {
    id: 'companyCode',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.COMPANY_CODE' }),
  },
  {
    id: 'contact',
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.CONTACT' }),
  },
  {
    id: 'amount',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.AMOUNT' }),
  },
  {
    id: 'uldType',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.ULD_TYPE' }),
  },
  {
    id: 'iataCode',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.STATION' }),
  },
  {
    id: 'availableFrom',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.AVAILABLE_FROM' }),
  },
  {
    id: 'validUntil',
    sortable: true,
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.VALID_UNTIL' }),
  },
  {
    id: 'requests',
    numeric: true,
    disablePadding: false,
    label: formatMessage({ id: 'TABLE_HEADER.REQUESTS' }),
  },
  { id: 'deleteEdit', numeric: true, disablePadding: false, label: '' }, // "Delete"-Row
];

const styles = (theme) => ({
  menuButtonContrastText: {
    color: theme.palette.primary.cornflowerBlue,
  },
  spanStyles: {
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: '0.85rem',
    textAlign: 'center',
  },
  newStockButton: {
    justifyContent: 'center',
  },
  searchButton: {
    minWidth: 'unset',
    padding: '6px 0 8px 6px',
  },
  gridItemHeadline: {
    color: theme.palette.primary.chambray,
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontWeight: '600',
  },
  sponsorsText: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    padding: '0',
    fontSize: '1rem',
    fontWeight: '600',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'left',
    marginBottom: '15px',
    marginTop: 'inherit',
  },
  linkStyle: {
    textDecorationColor: theme.palette.primary.blueZodiac,
  },
});

export class MyInventory extends Component {
  componentDidMount = () => {
    this.props.getStocks(this.props.token);
    this.props.getAdSlider();
  };

  state = {
    filterString: '',
    showField: false,
  };

  handleChange = (newValue) => {
    this.setState({
      filterString: newValue,
    });
  };

  showSearchInputField = (newValue) => {
    this.setState({
      showField: newValue,
    });
  };

  editFunc = (element) => {
    this.props.editStock(element);
    this.props.history.push(Routes.APP.MY_INVENTORY.EDIT_STOCK);
  };

  detailsFunc = (stock) => {
    this.props.dispatchStockDetails(stock);
    this.props.history.push(`${Routes.APP.MY_INVENTORY.DETAILS}/${stock.id}`);
  };

  filterData = (stocks) => {
    return stocks.filter((row) =>
      Object.values([row.iataCode, row.uldType, row.amount]).find((values) =>
        String(values)
          .toLocaleLowerCase()
          .includes(this.state.filterString.toLowerCase())
      )
    );
  };

  addNewStockFunc = () => {
    this.props.history.push(Routes.APP.MY_INVENTORY.ADD_STOCK);
  };

  importExcelFunction = (event) => {
    const { token, enqueueSnackbar, closeSnackbar } = this.props;
    this.props.importExcel(
      event.target.files[0],
      token,
      enqueueSnackbar,
      closeSnackbar
    );
    event.target.value = '';
  };

  goToBooking = () => {
    this.props.history.push(`${Routes.APP.BOOKINGS.INCOMING}`);
  };

  render() {
    const { myInventory, intl, dispatch, getStocks, token, adImages, roles } =
      this.props;

    return (
      <React.Fragment>
        <HeadlineBoxWithSearchAndAddStock
          height="180px"
          headLineText="MY_INVENTORY.HEADLINE"
          addNewStockFunc={this.addNewStockFunc}
          handleChange={this.handleChange}
          importExcelFunction={this.importExcelFunction}
          disableExcelUpload={roles.includes('ULDLeaser')}
        />
        <SearchFieldBox handleChange={this.handleChange} />
        <Table
          title={<FormattedMessage id="MY_INVENTORY.INVENTORY_OVERVIEW" />}
          renderAdditionalButtons={true}
          headRows={
            roles.includes('ULDLeaser')
              ? headRowsForTableWithoutEmergency(intl.formatMessage)
              : headRowsForTable(intl.formatMessage)
          }
          data={this.filterData(myInventory)}
          defaultOrder={'companyCode'}
          editFunc={this.editFunc}
          detailsFunc={this.detailsFunc}
          getStocksFunction={getStocks}
          token={token}
          dispatch={dispatch}
          goToBooking={this.goToBooking}
        />
        {adImages.length !== 0 && <AdLandscape adSlider={adImages} />}
      </React.Fragment>
    );
  }
}

MyInventory.defaultProps = {
  myInventory: [],
};

MyInventory.propTypes = {
  myInventory: PropTypes.array,
  getStocks: PropTypes.func.isRequired,
  editStock: PropTypes.func.isRequired,
  importExcel: PropTypes.func.isRequired,
  adImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  getAdSlider: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  intl: intlShape,
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  dispatchStockDetails: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  token: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  myInventory: state.stocks.myInventory,
  token: state.auth.token,
  adImages: state.getPathForImages.adSlider.myInventory,
  roles: state.user.roles,
});

const mapDispatchToProps = (dispatch) => ({
  getStocks: (token) => dispatch(getMyInventoryStocks(token)),
  editStock: (element) =>
    dispatch(createAction(Actions.STOCK.SPECIAL_STOCK, element)),
  importExcel: (files, token, enqueueSnackbar, closeSnackbar) =>
    dispatch(importExcel(files, token, enqueueSnackbar, closeSnackbar)),
  dispatchStockDetails: (element) =>
    dispatch(createAction(Actions.STOCK.SPECIAL_STOCK, element)),
  getAdSlider: () => dispatch(getAdMyInventory()),
  dispatch,
});

export default injectIntl(
  withSnackbar(
    withStyles(styles)(
      connect(mapStateToProps, mapDispatchToProps)(MyInventory)
    )
  )
);
