import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { gridItemHeadline, grid, checked, grid_margin } from './cssStyles';
import LEASINGTYPES from '../../constants/leasingTypes';

const useStyles = makeStyles((theme) => ({
  gridItemHeadline: gridItemHeadline(theme),
  grid: grid,
  checked: checked(theme),
  grid_margin: grid_margin,
}));

export function AddNewStockLeasingSection({
  leasingType,
  setLeasingType,
  paddingRight,
  commercialInformation,
  setCommercialInformation,
  intl,
  disableAllInputfields,
}) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
          <FormattedMessage id="ADD_NEW_STOCK.COMMERCIAL_INFORMATION" />
        </div>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container>
          <Grid item xs={12} md={12} className={classes.grid_margin}>
            <RadioGroup
              aria-label={
                <FormattedMessage id="ADD_NEW_STOCK.COMMERCIAL_INFORMATION" />
              }
              disabled={disableAllInputfields}
              name="LeasingType"
              onChange={(event) => setLeasingType(event.target.value)}
              value={leasingType}
              style={{ width: '100%' }}
            >
              {(leasingType === LEASINGTYPES.OFFER ||
                !disableAllInputfields) && (
                <FormControlLabel
                  classes={{ root: classes.root }}
                  value={LEASINGTYPES.OFFER}
                  style={{ marginRight: '15%' }}
                  disabled={disableAllInputfields}
                  control={
                    <Radio
                      classes={{ checked: classes.checked }}
                      required
                      color="default"
                    />
                  }
                  label={
                    <FormattedMessage id="ADD_NEW_STOCK.LEASING_FEE_APPLICABLE" />
                  }
                />
              )}
              {(leasingType === LEASINGTYPES.SPECIAL_OFFER ||
                !disableAllInputfields) && (
                <FormControlLabel
                  classes={{ root: classes.root }}
                  value={LEASINGTYPES.SPECIAL_OFFER}
                  style={{ marginRight: '15%' }}
                  disabled={disableAllInputfields}
                  control={
                    <Radio
                      classes={{ checked: classes.checked }}
                      required
                      color="default"
                    />
                  }
                  label={
                    <FormattedMessage id="ADD_NEW_STOCK.LEASING_FEE_APPLICABLE_DISCOUNT" />
                  }
                />
              )}
              {(leasingType === LEASINGTYPES.FREE ||
                !disableAllInputfields) && (
                <FormControlLabel
                  classes={{ root: classes.root }}
                  value={LEASINGTYPES.FREE}
                  style={{ marginRight: '15%' }}
                  disabled={disableAllInputfields}
                  control={
                    <Radio
                      classes={{ checked: classes.checked }}
                      required
                      color="default"
                    />
                  }
                  label={
                    <FormattedMessage id="ADD_NEW_STOCK.OFFER_FREE_OF_CHARGE" />
                  }
                />
              )}
            </RadioGroup>
          </Grid>
          <Grid item xs={12} md={12} className={classes.grid_margin}>
            <TextField
              fullWidth
              id="commercial information"
              label={
                <FormattedMessage id="ADD_NEW_STOCK.COMMERCIAL_INFORMATION" />
              }
              placeholder={intl.formatMessage({
                id: 'ADD_NEW_STOCK.LEASING_FEE_ULD_PER_DAY',
              })}
              type="text"
              value={commercialInformation}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disableAllInputfields}
              onChange={(event) => setCommercialInformation(event.target.value)}
            />
            <div
              className={classes.push}
              style={{ paddingRight: paddingRight }}
            />
          </Grid>

          <Grid item xs={12} md={12} className={classes.grid_margin}>
            <Typography>
              <FormattedMessage id="ADD_LEASING.SKYPOOLING_DISCLAIMER" />
            </Typography>
          </Grid>

          {!disableAllInputfields && (
            <Grid item xs={12} md={12} className={classes.grid_margin}>
              <FormattedMessage id="ADD_LEASING.PREMIUM_FUNCTION" />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

AddNewStockLeasingSection.defaultProps = {
  defaultValues: {},
};

AddNewStockLeasingSection.propTypes = {
  leasingType: PropTypes.string.isRequired,
  setLeasingType: PropTypes.func,
  commercialInformation: PropTypes.string.isRequired,
  setCommercialInformation: PropTypes.func,
  paddingRight: PropTypes.string,
  disableAllInputfields: PropTypes.bool,
  intl: intlShape,
};

export default injectIntl(AddNewStockLeasingSection);
