import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Home from '../containers/home';
import MyInventory from '../containers/myInventory';
import AirportStocks from '../containers/airportStocks';
import MyCompany from '../containers/myCompany';
import AddNewStock from '../containers/addNewStock';
import EditStock from '../containers/editStock';
import StockDetails from '../containers/stockDetails';
import { Routes } from '../constants';
import EditProfile from '../containers/editProfile';
import GlobalChat from '../containers/globalChat';
import BookStock from '../containers/bookings/form/bookStock';
import AdminPanel from '../containers/admin';
import Bookings from '../containers/bookings/overview/bookings';
import ErrorBoundary from '../containers/errorBoundary';
import BookLeasingStock from '../containers/bookings/form/bookLeasingStock';
import NotificationCenter from '../containers/notificationCenter';
import LostAndFound from '../containers/lostAndFound';
import AddNewLostAndFound from '../containers/addNewLostAndFound';
import LostAndFoundDetails from '../containers/lostAndFoundDetails';
import BookingDetails from '../containers/bookings/details';

const TabNavbarRoutes = () => {
  const isAdmin = useSelector((state) => state.user.roles?.includes('Admin'));

  return (
    <ErrorBoundary>
      <Switch>
        <Route
          exact
          path={Routes.APP.EDIT_PROFILE.INDEX}
          component={EditProfile}
        />
        <Route
          exact
          path={Routes.APP.GLOBAL_CHAT.INDEX}
          component={GlobalChat}
        />
        <Route
          exact
          path={Routes.APP.NOTIFICATIONCENTER.INDEX}
          component={NotificationCenter}
        />
        <Route exact path={Routes.APP.HOME.INDEX} component={Home} />
        <Route exact path={Routes.APP.INDEX} component={Home} />
        <Route
          exact
          path={Routes.APP.MY_INVENTORY.ADD_STOCK}
          component={AddNewStock}
        />
        <Route
          exact
          path={Routes.APP.MY_INVENTORY.INDEX}
          component={MyInventory}
        />
        <Route
          exact
          path={Routes.APP.AIRPORT_STOCKS.INDEX}
          component={AirportStocks}
        />
        <Route
          exact
          path={Routes.APP.MY_INVENTORY.EDIT_STOCK}
          component={() => (
            <EditStock redirectTo={Routes.APP.MY_INVENTORY.INDEX} />
          )}
        />
        <Route exact path={Routes.APP.MY_COMPANY.INDEX} component={MyCompany} />
        <Route
          exact
          path={Routes.APP.MY_COMPANY.EDIT_STOCK}
          component={() => (
            <EditStock redirectTo={Routes.APP.MY_COMPANY.INDEX} />
          )}
        />
        <Route
          exact
          path={Routes.APP.AIRPORT_STOCKS.DETAILS + '/:id'}
          component={StockDetails}
        />
        <Route
          exact
          path={Routes.APP.AIRPORT_STOCKS.DETAILS + '/:id' + '/:chatId'}
          component={StockDetails}
        />
        <Redirect
          exact
          from={Routes.APP.AIRPORT_STOCKS.DETAILS}
          to={Routes.APP.AIRPORT_STOCKS.INDEX}
        />
        <Route
          exact
          path={Routes.APP.MY_INVENTORY.DETAILS + '/:id'}
          component={StockDetails}
        />
        <Redirect
          exact
          from={Routes.APP.MY_INVENTORY.DETAILS}
          to={Routes.APP.MY_INVENTORY.INDEX}
        />
        <Route
          exact
          path={Routes.APP.MY_COMPANY.DETAILS + '/:id'}
          component={StockDetails}
        />
        <Redirect
          exact
          from={Routes.APP.MY_COMPANY.DETAILS}
          to={Routes.APP.MY_COMPANY.INDEX}
        />
        <Route
          exact
          path={Routes.APP.BOOKINGS.INDEX + '/:id'}
          component={BookingDetails}
        />
        <Route
          exact
          path={Routes.APP.BOOKINGS.DETAILS.INDEX + '/:id'}
          component={BookingDetails}
        />
        <Route path={Routes.APP.BOOKINGS.INDEX} component={Bookings} />
        <Route
          exact
          path={Routes.APP.AIRPORT_STOCKS.BOOK_NOW + '/:id'}
          component={BookStock}
        />
        <Route
          exact
          path={Routes.APP.AIRPORT_STOCKS.LEASING.BOOK_NOW + '/:id'}
          component={BookLeasingStock}
        />
        {isAdmin && (
          <Route path={Routes.APP.ADMIN.INDEX} component={AdminPanel} />
        )}
        <Route
          exact
          path={Routes.APP.LOST_AND_FOUND.ADD_ENTRY}
          component={AddNewLostAndFound}
        />
        <Route
          exact
          path={Routes.APP.LOST_AND_FOUND.DETAILS + '/:id'}
          component={LostAndFoundDetails}
        />
        <Route
          path={Routes.APP.LOST_AND_FOUND.INDEX}
          component={LostAndFound}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default TabNavbarRoutes;
