import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { FormattedMessage } from 'react-intl';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Select, FormControl, InputLabel } from '@material-ui/core';

import { useMedia } from '../../utils/customHooks';

const useToolbarStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 300,
  },
  toolbar: {
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  selectBox: {
    marginBottom: '16px',
    width: '75px',
  },
  title: {
    flex: '0 0 auto',
    color: theme.palette.primary.dark,
  },
}));

export default function EnhancedTableToolbar({
  onRequestSort,
  headRows,
  title,
}) {
  const { isMobile, fontSize } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        fontSize: '2rem',
        isMobile: false,
      },
    ],
    {
      fontSize: '1.5rem',
      isMobile: true,
    }
  );

  const classes = useToolbarStyles();
  const [menuItem, setMenuItem] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const usedHeadRows = headRows.filter(
    (row) => row.id !== 'companyCode' && row.id !== 'deleteEdit'
  );
  function createSortHandler(event, property) {
    onRequestSort(event, property);
  }

  function handleChange(event) {
    createSortHandler(event, event.target.value);
    setMenuItem(event.target.value);
  }

  function handleClose() {
    setMenuItem(null);
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  return (
    <div className={classes.toolbar}>
      <div className={classes.title}>
        <Typography style={{ fontSize: fontSize }} variant="h6" id="tableTitle">
          {title}
        </Typography>
      </div>
      {isMobile ? (
        <FormControl className={classes.formControl}>
          <InputLabel>
            <FormattedMessage id={'TILE_TOOLBAR.SORT_BY'} />
          </InputLabel>
          <Select
            className={classes.selectBox}
            open={open}
            onChange={handleChange}
            onClose={handleClose}
            onOpen={handleOpen}
            value={menuItem}
            disableUnderline
          >
            {usedHeadRows.map((row, index) => {
              return (
                <option
                  className={'MuiTab-root'}
                  style={{ textAlign: 'left' }}
                  key={index}
                  value={row.id}
                >
                  {' '}
                  {row.label}{' '}
                </option>
              );
            })}
          </Select>
        </FormControl>
      ) : null}
    </div>
  );
}

EnhancedTableToolbar.propTypes = {
  onRequestSort: PropTypes.func,
  name: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.string),
  selectedValue: PropTypes.string,
  setValue: PropTypes.func,
  menuItemsFunction: PropTypes.func,
  headRows: PropTypes.array.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};
