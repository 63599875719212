import * as React from 'react';

function IconUserGuideSkypooling(props) {
  return (
    <svg
      id="prefix__Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 1000 1000"
      xmlSpace="preserve"
      {...props}
    >
      <style>{'.prefix__st1{fill:#fff}'}</style>
      <circle cx={496} cy={501.8} r={485} fill="#3e628b" />
      <path
        className="prefix__st1"
        d="M784.1 317.5c-9.9-.5-20.1-.8-30.5-.7v360.8c0 13.1-10.2 23.8-23.2 24.5-26.6 1.4-108.9 8.6-184.7 43 94.2-17.4 199.6-15.1 236.1-13.5 7.3.3 13.3-5.8 13.3-13.3V329.5c-.1-6.4-4.9-11.7-11-12z"
      />
      <path
        className="prefix__st1"
        d="M736.8 672.4V277.8c0-11.9-9.6-21.5-21.4-21.5-120.6.2-208.4 84.6-208.4 84.6v406.4c81.9-51.7 185.5-61.4 218.9-63.2 6.1-.4 10.9-5.5 10.9-11.7zM238.5 677.6V316.8c-10.4 0-20.7.2-30.5.7-6.1.3-11 5.6-11 12v388.7c0 7.6 6 13.6 13.3 13.3 36.5-1.5 141.9-3.9 236.1 13.5-75.7-34.4-158.1-41.5-184.7-43-13-.6-23.2-11.4-23.2-24.4z"
      />
      <path
        className="prefix__st1"
        d="M255.2 277.8v394.6c0 6.2 4.8 11.3 10.9 11.6 33.4 1.8 137.1 11.6 218.9 63.2V340.9s-87.8-84.3-208.4-84.6c-11.8 0-21.4 9.6-21.4 21.5z"
      />
    </svg>
  );
}

export default IconUserGuideSkypooling;
