import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    paddingLeft: '1em',
    height: (props) => props?.height ?? '2.5em',
    width: (props) => `calc(100% - (${props?.height ?? '2.5em'} * 0.3))`,
    backgroundColor: (props) =>
      props?.backgroundColor ?? theme.palette.secondary.main,
    border: 'none',
    padding: 0,
    alignItems: 'center',
    fontSize: 'unset',
    '&:hover': {
      backgroundColor: (props) =>
        props?.hoverColor ?? theme.palette.secondary.fulvous,
      '&:before': {
        backgroundColor: (props) =>
          props?.hoverColor ?? theme.palette.secondary.fulvous,
      },
      '&:after': {
        borderTopColor: (props) =>
          props?.hoverColor ?? theme.palette.secondary.fulvous,
        boxShadow: (props) =>
          `0 -3px 0px 0px ${
            props?.hoverColor ?? theme.palette.secondary.fulvous
          }`,
      },
    },
    '&:before': {
      width: (props) => `calc(${props?.height ?? '2.5em'}*0.3)`,
      height: (props) => `calc(${props?.height ?? '2.5em'}*0.7)`,
      backgroundColor: (props) =>
        props?.backgroundColor ?? theme.palette.secondary.main,
      content: "' '",
      position: 'absolute',
      top: 0,
      left: (props) => `calc(100% - (${props?.height ?? '2.5em'} * 0.3))`,
    },
    '&:after': {
      boxShadow: (props) =>
        `0 -3px 0px 0px ${
          props?.backgroundColor ?? theme.palette.secondary.main
        }`,
      width: '0px',
      borderRight: (props) =>
        `calc(${props?.height ?? '2.5em'}*0.3) solid transparent`,
      borderTop: (props) =>
        `calc(${props?.height ?? '2.5em'}*0.3) solid ${
          props?.backgroundColor ?? theme.palette.secondary.main
        }`,
      height: 0,
      content: "' '",
      position: 'absolute',
      left: (props) => `calc(100% - (${props?.height ?? '2.5em'} * 0.3))`,
      bottom: 0,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  spanStyle: {
    color: theme.palette.primary.linkWater25,
    fontSize: '1em',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
  },
}));

export function UldShapedButton({
  label,
  onClick,
  img,
  buttonType,
  buttonWidth,
  paddingLeft,
  fontSize,
  color,
  backgroundColor,
  hoverColor,
  buttonHeight,
  whiteSpace = 'nowrap',
}) {
  const justifyContent = img ? 'space-evenly' : 'space-between';

  const classes = styles({ backgroundColor, hoverColor, height: buttonHeight });
  const mobileButtonWidth = buttonWidth ? buttonWidth : '12%';
  const mobilePaddingLeft = paddingLeft ? paddingLeft : '0px';
  const mobileFontSize = fontSize ? fontSize : 'unset';
  const colorStyle = color ? color : '#ffffff';

  return (
    <React.Fragment>
      <div
        style={{
          position: 'relative',
          minWidth: mobileButtonWidth,
          paddingLeft: mobilePaddingLeft,
        }}
      >
        <button
          className={classes.buttonWrapper}
          type={buttonType}
          onClick={onClick}
        >
          <span
            className={classes.spanStyle}
            style={{
              justifyContent: justifyContent,
              fontSize: mobileFontSize,
              color: colorStyle,
              textTransform: 'uppercase',
              whiteSpace: whiteSpace,
            }}
          >
            {img && img}
            {label}
          </span>
        </button>
      </div>
    </React.Fragment>
  );
}

UldShapedButton.defaultProps = {
  onClick: () => {},
  buttonType: 'button',
};

UldShapedButton.propTypes = {
  buttonType: PropTypes.oneOf(['button', 'submit', 'reset']).isRequired,
  classes: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func.isRequired,
  withArrow: PropTypes.bool,
  img: PropTypes.node,
};

export default UldShapedButton;
