export default {
  BOOKER_API: {
    BASE: 'booker/v1/stocks',
    MATCHES: 'booker/v1/stocks/matches',
    HASMATCHES: 'booker/v1/stocks/hasmatches',
    MARK_AS_IMPORTANT: 'booker/v1/stocks/important',
    EXCEL: {
      IMPORT: 'booker/v1/excel/import',
    },
    LEASING_STOCK: {
      BASE: 'booker/v1/leasing/stocks',
    },
    BOOKING: {
      INCOMING: {
        GET_OPEN_BOOKINGS: 'booker/v1/booking/incoming/open',
        GET_CONFIRMED_BOOKINGS: 'booker/v1/booking/incoming/confirmed',
        GET_DENIED_BOOKINGS: 'booker/v1/booking/incoming/denied',
      },
      OUTGOING: {
        GET_OPEN_BOOKINGS: 'booker/v1/booking/outgoing/open',
        GET_CONFIRMED_BOOKINGS: 'booker/v1/booking/outgoing/confirmed',
        GET_DENIED_BOOKINGS: 'booker/v1/booking/outgoing/denied',
      },
      GET_ALL_BOOKINGS: 'booker/v1/booking/all',
      GET_SPECIFIC_BOOKING: 'booker/v1/booking/',
      BOOK_STOCK: 'booker/v1/booking/bookStock',
      ACCEPT_REQUEST: (id) => `booker/v1/booking/${id}/accept`,
      DENY_REQUEST: (id) => `booker/v1/booking/${id}/deny`,
      SAVE_ADDITIONAL: (id) =>
        `booker/v1/booking/${id}/saveAdditionalInformation`,
      ACCEPT_LEASING_REQUEST: (id) => `booker/v1/leasing/booking/${id}/accept`,
      DENY_LEASING_REQUEST: (id) => `booker/v1/leasing/booking/${id}/deny`,
      LEASING: {
        BOOK_STOCK: 'booker/v1/leasing/booking/bookStock',
      },
    },
    STOCK_CHAT: {
      BASE: 'booker/v1/stockchat',
    },
    LOST_AND_FOUND_API: {
      BASE: 'booker/v1/lostandfound',
      LOST: 'booker/v1/lostandfound/lost',
      FOUND: 'booker/v1/lostandfound/found',
      GET_LOST_AND_FOUND_ENTRY: 'booker/v1/lostandfound/details',
      ADD_MESSAGE_FOR_LOST_AND_FOUND_ENTRY: 'booker/v1/lostandfound/comments',
    },
  },
  PUBLIC_API: {
    ULD_CONTROLLER: 'public/v1/uldtypes',
    AIRPORTS_CONTROLLER: 'public/v1/airports',
    USER: {
      USER: 'public/v1/user',
      LAST_READ: 'public/v1/user/lastRead',
      REGISTER: 'public/v1/user/register',
      UPDATE_LAST_LOGIN: 'public/v1/user/updateLastLogin',
    },
    COMPANIES: 'public/v1/companies',
    CONTACT: 'public/v1/contact',
    NEWS: 'public/v1/news',
    GET_PATH_FOR_IMAGES: {
      INFO_SLIDER: 'public/v1/getpathforimages/infoSlider',
      BENEFIT_SLIDER: 'public/v1/getpathforimages/benefitsSlider',
      COMPANIES_IMAGES: 'public/v1/getpathforimages/companiesImages',
      AD_LANDSCAPE: 'public/v1/getpathforimages/',
    },
    SET_ACCEPT_TERMS_AND_POLICY_DATE:
      'public/v1/user/acceptedTermsAndPolicyDate',
  },
  USERS_API: {
    SEND_MAIL_ALL_USERS: 'users/v1/mail/allUsers',
    SEND_MAIL_TO_SKYPOOLING: 'users/v1/mail/skypoolingInfo',
    GET_GLOBAL_CHAT: 'users/v1/global_chat',
    GET_NOTIFICATIONS: 'users/v1/notifications',
    NOTIFICATION_READ: (id) =>
      `users/v1/notifications/marknotificationasread/${id}`,
  },
  ADMIN_API: {
    USERS: 'admin/v1/users',
    AIRPORT: 'admin/v1/airports',
    AUTHORIZED: 'admin/v1/authorized',
    NEWS: 'admin/v1/news',
    REMINDER: {
      INDEX: 'admin/v1/reminder',
      AUDIENCE: 'admin/v1/reminder/audience',
      INTERVAL: 'admin/v1/reminder/interval',
    },
  },
  IMAGES: 'images',
};
