import { Actions } from '../constants';
import Airport from '../model/airport';

const initialState = [];

export default function airports(state = initialState, action) {
  switch (action.type) {
    case Actions.AIRPORTS.ADD_ALL_AIRPORTS:
      return [...action.payload.map((airport) => new Airport(airport))];
    default: {
      return state;
    }
  }
}
