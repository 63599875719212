import React, { Component } from 'react';

import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Search from '../icons/search';

const styles = (theme) => ({
  gridLayout: {
    marginLeft: '25px',
    marginTop: '45px',
  },
  textFieldStyle: {
    width: '200%',
  },
});

export class SearchTextField extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item className={classes.gridLayout}>
            <Search />
          </Grid>
          <Grid item>
            <TextField
              className={classes.textFieldStyle}
              id="input-with-icon-grid"
              label="Search"
              type="text"
              onChange={(event) => this.props.onChange(event.target.value)}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(SearchTextField);
