import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

import { withStyles } from '@material-ui/core/styles';
import { Typography, FormControlLabel } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

import { getAllBookings } from '../../../actions/booking';
import Table from '../../../components/booking/overviewTable';
import MobileTable from '../../../components/booking/overviewTableMobile';
import { Routes } from '../../../constants';

import BOOKINGSTATE from '../../../constants/bookingState';

const styles = (theme) => ({
  titleContainer: {
    flex: '0 0 auto',
    color: theme.palette.primary.chambray,
  },
  titleText: {
    fontSize: '2rem',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'left',
    textTransform: 'none',
    color: theme.palette.primary.dark,
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  gridMargin: {
    marginTop: '1.5rem',
  },
  formControlLabel: {
    color: theme.palette.primary.dark,
  },
});

export class BookingOverview extends Component {
  state = {
    checked:
      this.props.match.params.withCompanyBookings === 'withCompanyBookings' ||
      false,
  };

  componentDidMount = () => {
    this.props.getAllBookings(
      this.props.token,
      this.props.enqueueSnackbar,
      !this.state.checked
    );
  };

  createDataFromBookings = (bookings) => {
    return bookings.map((booking) => {
      const isMovingToPartner =
        (booking.bookedStock.amount > 0 &&
          this.props.ownCompany.code ===
            booking.stockCreatorUser.company.code) ||
        (booking.bookedStock.amount < 0 &&
          this.props.ownCompany.code !== booking.stockCreatorUser.company.code);

      const partnerCompanyCode =
        booking.bookingUser.company.code === this.props.ownCompany.code
          ? booking.bookedStock.companyCode
          : booking.bookingUser.company.code;

      return {
        id: booking.id,
        ownCompanyCode: this.props.ownCompany.code,
        partnerCompanyCode,
        isMovingToPartner,
        amount: booking.amountBooked,
        type: booking.bookedStock.uldType,
        stationCode: booking.bookedStock.iataCode,
        returnStationCode:
          booking.returnAirport === undefined
            ? ''
            : booking.returnAirport.iataCode,
        bookingDate: booking.creationDate,
        state: this.getBookingStateForBooking(booking),
        leasing: booking.bookedStock.leasing,
      };
    });
  };

  filterData = (rows) => {
    if (this.props.filterString === '') {
      return rows;
    }
    return rows.filter((row) =>
      Object.values([
        row.ownCompanyCode,
        row.partnerCompanyCode,
        row.amount,
        row.type,
        row.stationCode,
        row.returnStationCode,
      ]).find((values) =>
        String(values)
          .toLocaleLowerCase()
          .includes(this.props.filterString.toLocaleLowerCase())
      )
    );
  };

  getBookingStateForBooking = (booking) => {
    if (booking.incoming) {
      if (booking.open) {
        return BOOKINGSTATE.INCOMING.OPEN;
      } else if (!booking.open && !booking.denied) {
        return BOOKINGSTATE.CONFIRMED;
      } else if (!booking.open && booking.denied) {
        return BOOKINGSTATE.DENIED;
      } else {
        return '';
      }
    } else {
      if (booking.open) {
        return BOOKINGSTATE.OUTGOING.OPEN;
      } else if (!booking.open && !booking.denied) {
        return BOOKINGSTATE.CONFIRMED;
      } else if (!booking.open && booking.denied) {
        return BOOKINGSTATE.DENIED;
      } else {
        return '';
      }
    }
  };

  goToBookingDetails = (id) => {
    this.props.history.push(`${Routes.APP.BOOKINGS.DETAILS.INDEX}/${id}`);
  };

  handleChange = (name) => (event) => {
    const checked = event.target.checked;
    this.setState({ ...this.state, [name]: checked }, () => {
      this.props.getAllBookings(
        this.props.token,
        this.props.enqueueSnackbar,
        !this.state.checked
      );
    });
  };

  render() {
    const { intl, allBookings, classes, isMobile } = this.props;

    return (
      <Fragment>
        <Grid container className={classes.gridMargin}>
          <Grid item xs={12} md={8}>
            <div className={classes.titleContainer}>
              <Typography
                className={classes.titleText}
                variant="h6"
                id="tableTitle"
              >
                <FormattedMessage id="BOOKINGS.LIST.HEADLINE" />
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4} className={classes.switchContainer}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Switch
                  checked={this.state.checked}
                  onChange={this.handleChange('checked')}
                  value="checked"
                />
              }
              label={<FormattedMessage id="BOOKINGS.SWITCH" />}
            />
          </Grid>
          <Grid item xs={12}>
            {isMobile ? (
              <MobileTable
                data={this.filterData(this.createDataFromBookings(allBookings))}
                onRowClick={this.goToBookingDetails}
                intl={intl}
              />
            ) : (
              <Table
                data={this.filterData(this.createDataFromBookings(allBookings))}
                onRowClick={this.goToBookingDetails}
                intl={intl}
              />
            )}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

BookingOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  allBookings: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  intl: intlShape,
  match: PropTypes.shape({
    params: PropTypes.shape({
      withCompanyBookings: PropTypes.string,
    }).isRequired,
  }).isRequired,
  getAllBookings: PropTypes.func.isRequired,
  filterString: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  allBookings: state.bookings.allBookings,
  ownCompany: state.user.company,
});

const mapDispatchToProps = (dispatch) => ({
  getAllBookings: (token, enqueueSnackbar, withCompanyBookings) => {
    dispatch(getAllBookings(token, enqueueSnackbar, withCompanyBookings));
  },
  dispatch,
});

export default withRouter(
  injectIntl(
    withSnackbar(
      withStyles(styles)(
        connect(mapStateToProps, mapDispatchToProps)(BookingOverview)
      )
    )
  )
);
