import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import UldShapedBox from '../uldShapedBox';
import UldShapedButton from '../uldShapedButton';
import { useMedia } from '../../utils/customHooks';

const useStyles = makeStyles((theme) => ({
  buttonNormal: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.linkWater50,
    color: theme.palette.primary.blueZodiac,
    '&:hover': {
      background: theme.palette.primary.cornflowerBlue,
    },
  },
  buttonAction: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.orangePeel,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      background: theme.palette.secondary.tangerine,
    },
  },
  uldShapedBoxWrapper: {
    width: '100%',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  uldShapedBoxWrapperMobile: {
    width: '100%',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 1rem 1rem 0rem',
  },
  formPadding: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
}));

export default function MessageInputContainer({ sendFunc }) {
  const [messageText, setMessageText] = React.useState('');

  const classes = useStyles();

  const { isMobile } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: false,
      },
    ],
    {
      isMobile: true,
    }
  );

  return (
    <div
      className={
        isMobile
          ? classes.uldShapedBoxWrapperMobile
          : classes.uldShapedBoxWrapper
      }
    >
      <UldShapedBox>
        <form
          className={classes.formPadding}
          onSubmit={(event) => sendFunc(event, messageText, setMessageText)}
        >
          <TextField
            id="textInput"
            fullWidth
            label={<FormattedMessage id={'STOCK_CHAT.MESSAGE_INPUT'} />}
            multiline
            value={messageText}
            onChange={(event) => setMessageText(event.target.value)}
            margin="normal"
          />
          <div className={classes.buttonWrapper}>
            <UldShapedButton
              className={classes.buttonAction}
              buttonType="submit"
              id="submit-button"
              label={<FormattedMessage id="BUTTON.SUBMIT" />}
              buttonWidth={isMobile ? '50%' : '25%'}
            />
          </div>
        </form>
      </UldShapedBox>
    </div>
  );
}

MessageInputContainer.propTypes = {
  sendFunc: PropTypes.func.isRequired,
};
