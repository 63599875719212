import React from 'react';
import { getFromServer } from '../api';
import { Actions, Api } from '../constants';
import { createAction } from './utils';
import StockChat from '../model/stockChat';
import { putToServerPlaintext } from '../api';
import { deleteFromServer } from '../api';
import { FormattedMessage } from 'react-intl';
import { patchFromServer } from '../api';
import { getNotifications } from '../actions/notifications';

export const createNewStockChat =
  (stockId, enqueueSnackbar, token) => (dispatch) => {
    return putToServerPlaintext(
      `${Api.BOOKER_API.STOCK_CHAT.BASE}/${stockId}`,
      undefined,
      token
    )
      .then((response) => {
        if (response.ok) {
          response
            .json()
            .then(({ data }) =>
              dispatch(
                createAction(Actions.STOCK_CHAT.GET_STOCK_CHAT, [
                  new StockChat(data),
                ])
              )
            );
        } else {
          throw response;
        }
      })
      .catch((resp = {}) => {
        if (resp.json)
          return resp
            .json()
            .then(({ code }) =>
              enqueueSnackbar(
                <FormattedMessage
                  id="COULD_NOT_CREATE_STOCKCHAT"
                  values={{ code }}
                />,
                { variant: 'error' }
              )
            );
        else
          enqueueSnackbar(
            <FormattedMessage
              id="COULD_NOT_CREATE_STOCKCHAT"
              values={{ code: 'GENERAL_ERROR' }}
            />,
            { variant: 'error' }
          );
      });
  };

export const putNewStockChatMessage = (message, stockChatId, token) => {
  return putToServerPlaintext(
    Api.BOOKER_API.STOCK_CHAT.BASE + '/' + stockChatId + '/message',
    JSON.stringify(message),
    token,
    'text/plain;charset=UTF-8'
  ).then((response) => {
    if (!response.ok) {
      throw response;
    }
  });
};

export const getStockChat = (stockId, token) => (dispatch) => {
  getFromServer(Api.BOOKER_API.STOCK_CHAT.BASE + '/' + stockId, token).then(
    (response) => {
      if (response.ok) {
        response.json().then((json) => {
          dispatch(createAction(Actions.STOCK_CHAT.GET_STOCK_CHAT, json.data));
        });
      }
    }
  );
};

export const markMessageAndNotificationAsRead =
  (messageId, token) => (dispatch) => {
    return patchFromServer(
      Api.BOOKER_API.STOCK_CHAT.BASE +
        '/' +
        messageId +
        '/markmessageandnotificationasread',
      '',
      token
    ).then((response) => {
      if (response.ok) {
        dispatch(getNotifications(token));
        return;
      } else {
        throw response;
      }
    });
  };

export const resetStockChatMessages = () => (dispatch) => {
  dispatch(createAction(Actions.STOCK_CHAT.RESET_STOCK_CHAT_MESSAGES));
};
