import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Grid, FormControl, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import UldShapedFooterWithButtons from '../../components/uldShapedFooterWithButtons';
import { setNews } from '../../actions/news';
import { withSnackbar } from 'notistack';
import { putToServerPlaintext } from '../../api';
import { Api } from '../../constants';

const styles = (theme) => ({
  titleText: {
    fontSize: '1.5rem',
    fontWeight: '700',
    marginBottom: '2em',
    marginTop: '1.5em',
    padding: '0',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'left',
    textTransform: 'none',
    color: theme.palette.primary.chambray,
  },
  textareaStyle: {
    width: '100%',
    outline: 'none',
  },
  subtitle: {
    color: theme.palette.primary.dark,
    textTransform: 'none',
    fontSize: '1.125em',
    marginBottom: '2em',
    marginTop: '2.5em',
    padding: '0',
    textAlign: 'left',
    minWidth: 'unset',
    maxWidth: 'unset',
  },
});

export class News extends Component {
  state = {
    title: '',
    message: '',
  };

  changeTitle = (newValue) => this.setState({ title: newValue });
  changeMessage = (newValue) => this.setState({ message: newValue });

  clearAllFields = () =>
    this.setState({
      title: '',
      message: '',
    });

  handleSubmit = (event) => {
    event.preventDefault();
    const { token, enqueueSnackbar } = this.props;
    const { title, message } = this.state;

    // post zum server für neue news
    putToServerPlaintext(
      Api.ADMIN_API.NEWS,
      JSON.stringify({ title, message }),
      token
    )
      .then((resp) => {
        if (resp.ok)
          return enqueueSnackbar(<FormattedMessage id="NEWS.CREATED_NEWS" />, {
            variant: 'success',
          });
        else throw resp;
      })
      .catch((resp) => {
        if (resp.json)
          return resp
            .json()
            .then(({ code }) =>
              enqueueSnackbar(
                <FormattedMessage
                  id="NEWS.ERROR_CREATING_NEWS"
                  values={{ code }}
                />,
                { variant: 'error' }
              )
            );
        return enqueueSnackbar(
          <FormattedMessage
            id="NEWS.ERROR_CREATING_NEWS"
            values={{ code: 'GENERAL_ERROR' }}
          />,
          { variant: 'error' }
        );
      });

    this.clearAllFields();
  };

  render() {
    const { classes, intl } = this.props;

    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <Grid container justify="center">
            <Grid item xs={12} md={4}>
              <div className={`MuiTab-root ${classes.titleText}`}>
                <FormattedMessage id="ADMIN_TAB.POPUP" />
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControl fullWidth>
                <TextField
                  required
                  id="input-title"
                  label={<FormattedMessage id="ADMIN_TAB.NEWS_TITLE" />}
                  type="text"
                  margin="normal"
                  value={this.state.title}
                  onChange={(event) => this.changeTitle(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={false} md={4} />
            <Grid item xs={12} md={8}>
              <TextareaAutosize
                aria-label="minimum height"
                rows={15}
                placeholder={intl.formatMessage({
                  id: 'ADMIN_TAB.NEWS_PLACEHOLDER',
                })}
                className={classes.textareaStyle}
                value={this.state.message}
                onChange={(event) => this.changeMessage(event.target.value)}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: '3em' }}>
            <UldShapedFooterWithButtons
              confirmButtonLabel={
                <FormattedMessage id="ADMIN_TAB.NEWS_BUTTON_LABEL" />
              }
              confirmButtonType="submit"
              cancelButtonOnClick={this.props.history.goBack}
            />
          </div>
        </form>
      </Fragment>
    );
  }
} //     /<(h[1-6]{1}|b|i|ul|ol|li|p|a href=".*"|u){1}>/gm   (formatierter Text)

News.propTypes = {
  classes: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  setNews: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  intl: intlShape,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  setNews: (token, enqueueSnackbar) =>
    dispatch(setNews(token, enqueueSnackbar)),
});

export default withSnackbar(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(News))
  )
);
