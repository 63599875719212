export function getSuggestions(
  element,
  data,
  maxItems = 5,
  { showEmpty = false } = {}
) {
  const inputValue = element ? element.trim().toLowerCase() : '';
  const inputLength = inputValue.length;

  if (inputLength === 0 && !showEmpty) return [];

  const filteredByValue = data.filter(
    ({ value }) => !!value.toLowerCase().match(inputValue)
  );
  if (filteredByValue.length >= maxItems)
    return filteredByValue.slice(0, maxItems);
  else {
    const valuesAlreadyFound = filteredByValue.map(({ value }) => value);
    const filteredByLabel = data.filter(({ value, label }) => {
      if (valuesAlreadyFound.indexOf(value) < 0) {
        return !!label.toLowerCase().match(inputValue);
      } else {
        return false;
      }
    });
    const result = [...filteredByValue, ...filteredByLabel].slice(0, maxItems);
    return result;
  }
}
