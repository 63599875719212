export default class StockBookingQuery {
  constructor({
    stockId,
    fromWhen,
    tillWhen,
    amountToBook,
    returnAirport,
    requestComment,
    replyComment,
  }) {
    this.stockId = stockId;
    this.fromWhen = fromWhen;
    this.tillWhen = tillWhen;
    this.amountToBook = amountToBook;
    this.returnAirport = returnAirport;
    this.requestComment = requestComment;
    this.replyComment = replyComment;
  }
}
