import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { withStyles, Link } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import HeadlineBox from '../components/headlineBox';
import MenuButton from '../components/menuButton';
import BackIcon from '../icons/back';

import { Routes } from '../constants';

const styles = (theme) => ({
  title: {
    color: theme.palette.primary.dark,
    fontSize: '2.25rem',
    marginTop: '1.5em',
    marginBottom: '0.7em',
  },
  textStyle: {
    fontSize: '1.125rem',
    color: 'black',
  },
  question: {
    color: theme.palette.primary.dark,
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  cookie: {
    color: theme.palette.primary.dark,
    fontSize: '1.125rem',
    fontStyle: 'italic',
  },
  listStyleDisc: {
    listStyleType: 'disc',
  },
});

class DataPrivacy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <HeadlineBox headLineText="FOOTER.LEGAL_INFORMATION" />
        <div style={{ marginTop: '1em' }} />
        <MenuButton
          text={<FormattedMessage id="BASE.BACK" />}
          onClick={() => this.props.history.push(Routes.APP.INDEX)}
          img={<BackIcon />}
        />
        <div className={classes.title}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_TITLE" />
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage
            id="FOOTER.DATA_PRIVACY_SUB_TEXT"
            values={{
              gdpr: (
                <b>
                  <FormattedMessage id="BASE.GDPR" />
                </b>
              ),
              bdsg: (
                <b>
                  <FormattedMessage id="BASE.BDSG" />
                </b>
              ),
            }}
          />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage
            id="FOOTER.DATA_PRIVACY_SUB_TEXT_1"
            values={{
              skypoolingWebsite: (
                <b>
                  <FormattedMessage id="BASE.SKYPOOLING_WEBSITE" />
                </b>
              ),
              website: (
                <b>
                  <FormattedMessage id="BASE.WEBSITE" />
                </b>
              ),
            }}
          />
        </div>
        <div>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.question }}
              primary={<FormattedMessage id="FOOTER.DATA_PRIVACY_QUESTION_1" />}
            />
          </ListItem>
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_1" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage
            id="FOOTER.DATA_PRIVACY_ANSWER_1_2"
            values={{
              email: (
                <FormattedMessage id="FOOTER.DATA_PRIVACY.EMAIL_DLH">
                  {(mail) => <Link href={`mailto:${mail}`}>{mail}</Link>}
                </FormattedMessage>
              ),
            }}
          />
        </div>
        <br />
        <div className={classes.textStyle}>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.question }}
              primary={<FormattedMessage id="FOOTER.DATA_PRIVACY_QUESTION_2" />}
            />
          </ListItem>
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_2" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_2_2" />
        </div>
        <div className={classes.textStyle}>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.question }}
              primary={<FormattedMessage id="FOOTER.DATA_PRIVACY_QUESTION_3" />}
            />
          </ListItem>
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_3" />
        </div>
        <br />
        <ol type="1">
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_3_1" />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_3_2" />
            <br />
            <br />
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_3_2_1" />
            <ul className={classes.listStyleDisc}>
              <li>
                <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_3_2_2" />
              </li>
              <li>
                <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_3_2_3" />
              </li>
            </ul>
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage
              id="FOOTER.DATA_PRIVACY_ANSWER_3_3"
              values={{
                link: (
                  <FormattedMessage id="FOOTER.DATA_PRIVACY.GOOGLE_PRIVACY_LINK">
                    {(link) => <Link href={link}>{link}</Link>}
                  </FormattedMessage>
                ),
              }}
            />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage
              id="FOOTER.DATA_PRIVACY_ANSWER_3_4"
              values={{
                link: (
                  <FormattedMessage id="FOOTER.DATA_PRIVACY.TWITTER_PRIVACY_LINK">
                    {(link) => <Link href={link}>{link}</Link>}
                  </FormattedMessage>
                ),
              }}
            />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_3_5" />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_3_6" />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage
              id="FOOTER.DATA_PRIVACY_ANSWER_3_7"
              values={{
                contactPerson: (
                  <b>
                    <FormattedMessage id="FOOTER.DATA_PRIVACY.CONTACT_PERSON" />
                  </b>
                ),
                businessPartner: (
                  <b>
                    <FormattedMessage id="FOOTER.DATA_PRIVACY.BUSINESS_PARTNER" />
                  </b>
                ),
              }}
            />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_3_8" />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage
              id="FOOTER.DATA_PRIVACY_ANSWER_3_9"
              values={{
                breakLine: <br />,
                matomolink: (
                  <a href={'https://matomo.org/privacy'}>
                    https://matomo.org/privacy
                  </a>
                ),
              }}
            />
          </li>
        </ol>
        <div className={classes.textStyle}>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.question }}
              primary={<FormattedMessage id="FOOTER.DATA_PRIVACY_QUESTION_4" />}
            />
          </ListItem>
        </div>
        <ol type="1">
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_4_1" />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_4_2" />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_4_3" />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage
              id="FOOTER.DATA_PRIVACY_ANSWER_4_4"
              values={{
                link: (
                  <Link href="https://skypooling.com/termsOfuse ">
                    <FormattedMessage id="FOOTER.TERMS_OF_USE" />
                  </Link>
                ),
              }}
            />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_4_5" />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_4_6" />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_4_7" />
          </li>
        </ol>
        <ol type="1">
          <ol>
            <li className={classes.textStyle}>
              <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_4_7_1" />
            </li>
            <br />
            <li className={classes.textStyle}>
              <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_4_7_2" />
            </li>
            <br />
            <li className={classes.textStyle}>
              <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_4_7_3" />
            </li>
          </ol>
        </ol>
        <div className={classes.textStyle}>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.question }}
              primary={<FormattedMessage id="FOOTER.DATA_PRIVACY_QUESTION_5" />}
            />
          </ListItem>
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_5" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_5_1" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.question }}
              primary={<FormattedMessage id="FOOTER.DATA_PRIVACY_QUESTION_6" />}
            />
          </ListItem>
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_6" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_6_1" />
        </div>
        <br />
        <ul type="1">
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_6_POINT_ONE" />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_6_POINT_TWO" />
          </li>
          <br />
          <li className={classes.textStyle}>
            <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_6_POINT_THREE" />
          </li>
        </ul>
        <div className={classes.textStyle}>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.question }}
              primary={<FormattedMessage id="FOOTER.DATA_PRIVACY_QUESTION_7" />}
            />
          </ListItem>
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_7" />
        </div>
        <div className={classes.textStyle}>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.question }}
              primary={<FormattedMessage id="FOOTER.DATA_PRIVACY_QUESTION_8" />}
            />
          </ListItem>
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_8" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_8_1" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_8_2" />
        </div>
        <div className={classes.textStyle}>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.question }}
              primary={<FormattedMessage id="FOOTER.DATA_PRIVACY_QUESTION_9" />}
            />
          </ListItem>
        </div>
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_9" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_9_1" />
        </div>
        <br />
        <div className={classes.textStyle}>
          <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_9_2" />
        </div>
        <div className={classes.textStyle}>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.question }}
              primary={
                <FormattedMessage id="FOOTER.DATA_PRIVACY_QUESTION_10" />
              }
            />
          </ListItem>
        </div>
        <ol type="a">
          <li className={classes.textStyle}>
            <div className={classes.textStyle}>
              <ListItem>
                <ListItemText
                  classes={{ primary: classes.textStyle }}
                  primary={
                    <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_10_1" />
                  }
                />
              </ListItem>
            </div>
            <br />
            <div className={`${classes.textStyle} `}>
              <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_10_1_TEXT" />
            </div>
            <br />
            <div className={`${classes.textStyle} `}>
              <FormattedMessage id="FOOTER.DATA_PRIVACY_ANSWER_10_1_TEXT1" />
            </div>
          </li>
          <br />
          <li className={classes.textStyle}>
            <div className={classes.textStyle}>
              <ListItem>
                <ListItemText
                  classes={{ primary: classes.textStyle }}
                  primary={
                    <FormattedMessage id="FOOTER.DATA_PRIVACY_ADDITIONAL_RIGHTS" />
                  }
                />
              </ListItem>
            </div>

            <br />
            <div className={classes.textStyle}>
              <FormattedMessage id="FOOTER.DATA_PRIVACY_ADDITIONAL_RIGHTS_TEXT" />
            </div>
            <br />
            <ul className={classes.listStyleDisc}>
              <li className={classes.textStyle}>
                <FormattedMessage id="FOOTER.DATA_PRIVACY_ADDITIONAL_RIGHTS_POINT_1" />
              </li>
              <li className={classes.textStyle}>
                <FormattedMessage id="FOOTER.DATA_PRIVACY_ADDITIONAL_RIGHTS_POINT_2" />
              </li>
              <li className={classes.textStyle}>
                <FormattedMessage id="FOOTER.DATA_PRIVACY_ADDITIONAL_RIGHTS_POINT_3" />
              </li>
              <li className={classes.textStyle}>
                <FormattedMessage id="FOOTER.DATA_PRIVACY_ADDITIONAL_RIGHTS_POINT_4" />
              </li>
              <li className={classes.textStyle}>
                <FormattedMessage id="FOOTER.DATA_PRIVACY_ADDITIONAL_RIGHTS_POINT_5" />
              </li>
              <li className={classes.textStyle}>
                <FormattedMessage id="FOOTER.DATA_PRIVACY_ADDITIONAL_RIGHTS_POINT_6" />
              </li>
            </ul>
            <div className={classes.textStyle}>
              <FormattedMessage
                id="FOOTER.DATA_PRIVACY_ADDITIONAL_RIGHTS_TEXT2"
                values={{
                  email: (
                    <FormattedMessage id="FOOTER.DATA_PRIVACY.EMAIL_JT_DATAPROTECTION">
                      {(mail) => <Link href={`mailto:${mail}`}>{mail}</Link>}
                    </FormattedMessage>
                  ),
                }}
              />
            </div>
            <br />
            <div className={classes.textStyle}>
              <FormattedMessage id="FOOTER.DATA_PRIVACY_ADDITIONAL_RIGHTS_TEXT3" />
            </div>
            <br />
            <div className={classes.textStyle}>
              <FormattedMessage id="FOOTER.DATA_PRIVACY_ADDITIONAL_RIGHTS_TEXT4" />
            </div>
          </li>
        </ol>
      </Fragment>
    );
  }
}

DataPrivacy.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(withStyles(styles)(DataPrivacy));
