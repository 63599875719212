import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, TextField, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { gridItemHeadline, grid } from '../../addNewStock/cssStyles';

const useStyles = makeStyles((theme) => ({
  gridItemHeadline: gridItemHeadline(theme),
  grid,
}));

const BookingContactForm = ({ contactPerson, company }) => {
  const classes = useStyles();

  return (
    <span>
      <Grid container justify="center">
        <Grid item xs={12} md={4}>
          <div className={`MuiTab-root ${classes.gridItemHeadline}`}>
            <FormattedMessage id="BASE.CONTACT" />
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} className={classes.grid}>
              <FormControl fullWidth>
                <TextField
                  id="standard-name"
                  label={<FormattedMessage id="BASE.NAME" />}
                  type="text"
                  margin="normal"
                  value={contactPerson}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} className={classes.grid}>
              <FormControl fullWidth>
                <TextField
                  id="standard-company"
                  label={<FormattedMessage id="BASE.COMPANY" />}
                  type="text"
                  margin="normal"
                  value={company}
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </span>
  );
};

BookingContactForm.propTypes = {
  contactPerson: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
};

export default BookingContactForm;
