import React from 'react';
import { FormattedMessage } from 'react-intl';
import YoutubeIcon from '../../icons/youtube';
import linkedInIcon from '../../assets/linkedIn.png';
import twitterIcon from '../../assets/twitter.png';
import IconUserGuideSkypooling from '../../icons/iconUserGuideSkypooling';

import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textWrapper: {
    color: theme.palette.primary.dark,
  },
  iconStyle: {
    width: '50px',
    height: '50px',
    margin: '4%',
  },
  gridContainer: {
    marginTop: '1rem',
  },
  iconTextWrapper: {},
  welcomeToSkypooling: {
    fontSize: '1.8rem',
    marginBottom: '1rem',
  },
  text: {
    fontSize: '1.625rem',
    color: theme.palette.primary.light,
  },
}));

export function SocialBlockIcons() {
  const classes = useStyles();
  return (
    <div className={classes.textWrapper}>
      <Grid container className={classes.welcomeToSkypooling}>
        <Grid item xs={12} md={12}>
          <FormattedMessage id="HOME.SOCIAL_BLOCK_WELCOME_TO_SKYPOOLING" />
        </Grid>
      </Grid>
      <Grid alignItems="center" container className={classes.iconNames}>
        <Grid className={classes.text} item xs={6} md={6}>
          <FormattedMessage id="HOME.SOCIAL_BLOCK_NEWS" />
        </Grid>
        <Grid item xs={6} md={6}>
          <a href="https://twitter.com/skypooling">
            <img className={classes.iconStyle} src={twitterIcon} alt=""></img>
          </a>
          <a href="https://ca.linkedin.com/company/skypooling">
            <img className={classes.iconStyle} src={linkedInIcon} alt=""></img>
          </a>
        </Grid>
      </Grid>
      <Grid alignItems="center" container className={classes.iconNames}>
        <Grid className={classes.text} item xs={6} md={6}>
          <FormattedMessage
            className={classes.text}
            id="HOME.SOCIAL_BLOCK_USER_GUIDE"
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <a href={`/skypooling_user_guide.pdf`} download>
            <IconUserGuideSkypooling className={classes.iconStyle} />
          </a>
        </Grid>
      </Grid>
      <Grid alignItems="center" container className={classes.iconNames}>
        <Grid className={classes.text} item xs={6} md={6}>
          <FormattedMessage
            className={classes.text}
            id="HOME.SOCIAL_BLOCK_VIDEO_GUIDES"
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <a href="https://www.youtube.com/channel/UCMbVVDV8X81-pxIK33gaRTA/featured">
            <YoutubeIcon className={classes.iconStyle} />
          </a>
        </Grid>
      </Grid>
    </div>
  );
}

export default SocialBlockIcons;

SocialBlockIcons.propTypes = {};
