import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import UldShapedFooterWithButtons from '../components/uldShapedFooterWithButtons';
import MultipleSelect from './searchSelect/multipleSelect';
import ClearButton from './clearButton';
import { useKeycloak } from '@react-keycloak/web';
import { createAction } from '../actions/utils';
import { Actions } from '../constants';
import { useDispatch } from 'react-redux';
import UldShapedButton from './uldShapedButton';
import { useTheme } from '@material-ui/core/styles';
import { useMedia } from '../utils/customHooks';

export function EditProfileForm({
  handleSubmit,
  cancelButtonFunction,
  user,
  airports,
  intl,
}) {
  const [firstName, setFirstName] = React.useState(
    user.firstName ? user.firstName : ''
  );
  const [lastName, setLastName] = React.useState(
    user.lastName ? user.lastName : ''
  );
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState(
    user.phoneNumber ? user.phoneNumber : ''
  );
  const [place, setPlace] = React.useState(user.place ? user.place : '');
  const [reachability, setReachability] = React.useState(
    user.reachability ? user.reachability : ''
  );
  const [position, setPosition] = React.useState(
    user.position ? user.position : ''
  );
  const [groupMail, setGroupMail] = React.useState(
    user.groupMail ? user.groupMail : ''
  );
  const [iataCode, setIataCode] = React.useState(
    user.iataCode ? user.iataCode : []
  );
  const [notificationStockMatch, setNotificationStockMatch] = React.useState(
    user.notificationStockMatch
  );
  const [notificationScheduledReminder, setNotificationScheduledReminder] =
    React.useState(user.notificationScheduledReminder);
  const [notificationRest, setNotificationRest] = React.useState(
    user.notificationRest
  );

  const { width } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        width: '25%',
      },
    ],
    {
      width: '50%',
    }
  );

  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const theme = useTheme();
  const submitForm = (event) => {
    event.preventDefault();

    handleSubmit({
      firstName,
      lastName,
      phoneNumber,
      place,
      reachability,
      groupMail,
      position,
      iataCode,
      password,
      confirmPassword,
      notificationRest,
      notificationStockMatch,
      notificationScheduledReminder,
    });
  };

  return (
    <form onSubmit={submitForm}>
      <Grid container spacing={4} justify="center">
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="standard-firstName"
              label={<FormattedMessage id="BASE.FIRSTNAME" />}
              type="text"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setFirstName('')} />
                  </InputAdornment>
                ),
              }}
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="standard-lastName"
              label={<FormattedMessage id="BASE.LASTNAME" />}
              type="text"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setLastName('')} />
                  </InputAdornment>
                ),
              }}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
      {/* 2 Zeile */}
      {/* 3 Zeile */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              id="standard-phoneNumber"
              label={<FormattedMessage id="BASE.PHONE" />}
              type="tel"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setPhoneNumber('')} />
                  </InputAdornment>
                ),
              }}
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              id="standard-place"
              label={<FormattedMessage id="BASE.USER_PLACE" />}
              type="test"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setPlace('')} />
                  </InputAdornment>
                ),
              }}
              value={place}
              onChange={(event) => setPlace(event.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              id="standard-reachability"
              label={<FormattedMessage id="BASE.USER_REACHABILITY" />}
              type="text"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setReachability('')} />
                  </InputAdornment>
                ),
              }}
              value={reachability}
              placeholder={intl.formatMessage({
                id: 'BASE.USER_GROUP_MAIL_PLACEHOLDER',
              })}
              onChange={(event) => setReachability(event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              id="standard-groupMail"
              label={<FormattedMessage id="BASE.USER_GROUP_MAIL" />}
              type="email"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setGroupMail('')} />
                  </InputAdornment>
                ),
              }}
              value={groupMail}
              onChange={(event) => setGroupMail(event.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              id="standard-position"
              label={<FormattedMessage id="BASE.USER_POSITION" />}
              type="text"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClearButton onClick={() => setPosition('')} />
                  </InputAdornment>
                ),
              }}
              value={position}
              onChange={(event) => setPosition(event.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ marginTop: '16px' }}>
            <FormControl fullWidth>
              <MultipleSelect
                id="airports"
                margin="0 2px"
                data={airports.map(({ iataCode, name }) => ({
                  value: iataCode,
                  label: `${iataCode} - ${name}`,
                }))}
                placeholder={intl.formatMessage({
                  id: 'ADD_NEW_STOCK.ANY_AIRPORT',
                })}
                label={<FormattedMessage id="BASE.USER_AIRPORTS" />}
                limit={5}
                selected={iataCode.map((value) => ({ value }))}
                setSelected={setIataCode}
              />
            </FormControl>
          </div>
        </Grid>

        <Grid item xs={12} md={12}>
          <div>
            <p
              style={{
                marginBottom: '4px',
                marginLeft: '0px',
                marginTop: '0px',
              }}
            >
              {<FormattedMessage id="BASE.NOTIFICATION_SUBSCRIPTION" />}
            </p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={notificationStockMatch}
                  onChange={(event) =>
                    setNotificationStockMatch(event.target.checked)
                  }
                />
              }
              label={<FormattedMessage id="BASE.NOTIFICATION_STOCK_MATCH" />}
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={true}
                  checked={notificationScheduledReminder}
                  onChange={(event) =>
                    setNotificationScheduledReminder(event.target.checked)
                  }
                />
              }
              label={
                <FormattedMessage id="BASE.NOTIFICATION_SCHEDULED_REMINDER" />
              }
            />
          </div>
        </Grid>
      </Grid>

      <p style={{ width: width }}>
        <UldShapedButton
          onClick={() => {
            keycloak.logout({
              redirectUri: process?.env?.REACT_APP_KEYCLOAK_URL?.concat(
                '/realms/'
              )
                .concat(process.env.REACT_APP_KEYCLOAK_REALM)
                .concat(
                  '/login-actions/reset-credentials?client_id=react-frontend'
                ),
            });
            dispatch(createAction(Actions.AUTH.TOKEN.REMOVE));
          }}
          backgroundColor={theme.palette.primary.dark}
          buttonWidth={'25%'}
          label={'reset password'}
        />
      </p>
      <div style={{ marginTop: '3em' }}>
        <UldShapedFooterWithButtons
          confirmButtonLabel={<FormattedMessage id="BASE.SUBMIT" />}
          confirmButtonType={'submit'}
          cancelButtonOnClick={cancelButtonFunction}
        />
      </div>
    </form>
  );
}

EditProfileForm.defaultProps = {
  user: {},
};

EditProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancelButtonFunction: PropTypes.func.isRequired,
  user: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    phoneNumber: PropTypes.string,
    place: PropTypes.string,
    reachability: PropTypes.string,
    position: PropTypes.string,
    groupMail: PropTypes.string,
    iataCode: PropTypes.arrayOf(PropTypes.string),
    notificationStockMatch: PropTypes.bool,
    notificationScheduledReminder: PropTypes.bool,
    notificationRest: PropTypes.bool,
  }),
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      iataCode: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  keycloak: PropTypes.shape({
    logout: PropTypes.func.isRequired,
  }).isRequired,
  intl: intlShape,
};

export default injectIntl(EditProfileForm);
