import React, { Fragment, Component } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';
import { withStyles, Link, makeStyles } from '@material-ui/core';

const styles = () => ({
  widthAndHeight: {
    width: '100%',
  },
  sliderIe11Compat: {
    display: 'flex',
  },
});

export class AdLandscape extends Component {
  renderImages = () => {
    const { adSlider } = this.props;
    const classes = this.props.classes;
    return adSlider.map(({ path, href, alt }) => (
      <div className={classes.widthAndHeight} key={path}>
        {href && href !== '' ? (
          <Link href={href} rel="noopener" target="_blank">
            <img
              className={classes.widthAndHeight}
              style={{ display: 'block', pointerEvents: 'auto' }}
              alt={alt}
              src={path}
            />
          </Link>
        ) : (
          <img
            className={classes.widthAndHeight}
            style={{ display: 'block', pointerEvents: 'auto' }}
            alt={alt}
            src={path}
          />
        )}
      </div>
    ));
  };

  render() {
    const { adSlider, classes } = this.props;
    return (
      <Carousel
        className={classes.sliderIe11Compat}
        autoPlay
        interval={5000}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        infiniteLoop={true}
      >
        {adSlider.length === 0 ? null : this.renderImages()}
      </Carousel>
    );
  }
}

AdLandscape.propTypes = {
  adSlider: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      alt: PropTypes.string,
      href: PropTypes.string,
    })
  ),
};

export default withStyles(styles)(AdLandscape);
