import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withSnackbar } from 'notistack';

import { withStyles } from '@material-ui/core/styles';

import {
  enableUser,
  disableUser,
  getUsersAsAdmin,
  setUserRole,
  deleteUser,
} from '../../actions/admin';
import UserComponent from '../../components/userComponent';

const styles = (theme) => ({
  titleText: {
    fontSize: '1.5rem',
    fontWeight: '700',
    marginBottom: '2em',
    marginTop: '1.5em',
    padding: '0',
    minWidth: 'unset',
    maxWidth: 'unset',
    textAlign: 'left',
    textTransform: 'none',
    color: theme.palette.primary.chambray,
  },
});
export class UserList extends Component {
  enableUserFunction = (keycloakId, companyCode, token) => () => {
    if (companyCode !== 'notListed') {
      return enableUser(token, keycloakId)
        .then(() =>
          this.props.enqueueSnackbar(
            <FormattedMessage id="ADMIN.USER_LIST.ENABLE_SUCCESS" />,
            { variant: 'success' }
          )
        )
        .then(() => this.props.getUsersAsAdmin(token))
        .catch(() =>
          this.props.enqueueSnackbar(
            <FormattedMessage id="ADMIN.USER_LIST.ENABLE_ERROR" />,
            { variant: 'warning' }
          )
        );
    } else {
      this.props.enqueueSnackbar(
        <FormattedMessage id="ADMIN.USER_LIST.ENABLE_ERROR_COMPANY_CODE" />,
        { variant: 'warning' }
      );
    }
  };

  disableUserFunction = (keycloakId, token) => () => {
    return disableUser(token, keycloakId)
      .then(() =>
        this.props.enqueueSnackbar(
          <FormattedMessage id="ADMIN.USER_LIST.DISABLE_SUCCESS" />,
          { variant: 'success' }
        )
      )
      .then(() => this.props.getUsersAsAdmin(token))
      .catch(() =>
        this.props.enqueueSnackbar(
          <FormattedMessage id="ADMIN.USER_LIST.DISABLE_ERROR" />,
          { variant: 'warning' }
        )
      );
  };

  setRoleFunction = (keycloakId, companyCode, token) => (role) => {
    if (companyCode !== 'notListed') {
      return setUserRole(token, keycloakId, role)
        .then(() =>
          this.props.enqueueSnackbar(
            <FormattedMessage id="ADMIN.USER_LIST.SET_ROLE_SUCCESS" />,
            { variant: 'success' }
          )
        )
        .then(() => this.props.getUsersAsAdmin(token))
        .catch(() =>
          this.props.enqueueSnackbar(
            <FormattedMessage id="ADMIN.USER_LIST.SET_ROLE_ERROR" />,
            { variant: 'warning' }
          )
        );
    } else {
      this.props.enqueueSnackbar(
        <FormattedMessage id="ADMIN.USER_LIST.ENABLE_ERROR_COMPANY_CODE" />,
        { variant: 'warning' }
      );
    }
  };

  deleteUser = (keycloakId, token) => {
    return deleteUser(keycloakId, token)
      .then(() =>
        this.props.enqueueSnackbar(
          <FormattedMessage id="ADMIN.USER_LIST.DELETE_USER_SUCCESS" />,
          { variant: 'success' }
        )
      )
      .then(() => this.props.getUsersAsAdmin(token))
      .catch(() =>
        this.props.enqueueSnackbar(
          <FormattedMessage id="ADMIN.USER_LIST.DELETE_USER_ERROR" />,
          { variant: 'warning' }
        )
      );
  };

  render() {
    const { users, classes } = this.props;

    return (
      <Fragment>
        <div className={classes.titleText}>
          <FormattedMessage id="ADMIN_TAB.USERS_USERS_OVERVIEW" />
        </div>
        {users.map((user) => (
          <UserComponent
            user={user}
            key={user.keycloakId}
            enableUserFunction={this.enableUserFunction(
              user.keycloakId,
              user.company.code,
              this.props.token
            )}
            deleteUser={() =>
              this.deleteUser(user.keycloakId, this.props.token)
            }
            disableUserFunction={this.disableUserFunction(
              user.keycloakId,
              this.props.token
            )}
            roles={this.props.roles}
            roleFromBackend={user.role}
            setRole={this.setRoleFunction(
              user.keycloakId,
              user.company.code,
              this.props.token
            )}
          />
        ))}
      </Fragment>
    );
  }
}

UserList.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  getUsersAsAdmin: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  token: PropTypes.string,
};

const mapStateToProps = (state) => ({
  users: state.admin.users,
  roles: state.admin.roles,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  getUsersAsAdmin: (token) => dispatch(getUsersAsAdmin(token)),
});

export default withStyles(styles)(
  withSnackbar(connect(mapStateToProps, mapDispatchToProps)(UserList))
);
