export default class LostUlds {
  constructor({ id, report, uldIds, comment, user, creationDate }) {
    this.id = id;
    this.report = report;
    this.uldIds = uldIds;
    this.comment = comment;
    this.companyName = user.company.name;
    this.companyCode = user.company.code;
    this.creationDate = creationDate;
  }
}
