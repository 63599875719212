import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '50%',
  },
  success: {
    backgroundColor: theme.palette.primary.blueZodiac,
    color: theme.palette.primary.contrastText,
  },
  warning: {
    flexWrap: 'unset',
    backgroundColor: theme.palette.secondary.orangePeel,
    color: theme.palette.secondary.contrastText,
  },
}));

const SnackbarProviderWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      maxSnack={5}
      classes={{
        root: classes.root,
        variantSuccess: classes.success,
        variantWarning: classes.warning,
      }}
      hideIconVariant={true}
    >
      {children}
    </SnackbarProvider>
  );
};

SnackbarProviderWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SnackbarProviderWrapper;
