import { Actions } from '../constants';

const initialState = {
  idToken: undefined,
  refreshToken: undefined,
  token: undefined,
  error: false,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case Actions.AUTH.TOKEN.ADD:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.AUTH.TOKEN.REMOVE:
      return {
        ...initialState,
      };
    case Actions.AUTH.TOKEN.ADD_ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
}
