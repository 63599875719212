import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AdminTabRoutes from '../../routes/adminTabRoutes';
import { Routes } from '../../constants';
import { useMedia } from '../../utils/customHooks';
import UsersIcon from '../../icons/users';
import CompanyIcon from '../../icons/company';
import EmailIcon from '../../icons/email';
import RocketIcon from '../../icons/rocket';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabsIndicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  labelIcon: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.primary.dark,
    '& > svg': {
      marginRight: '1em',
    },
  },
}));

const getAdminTabPathname = (pathname) => {
  const tabArray = {
    [Routes.APP.INDEX]: Routes.APP.INDEX,
    [Routes.APP.ADMIN.USERS]: Routes.APP.ADMIN.USERS,
    [Routes.APP.ADMIN.COMPANIES]: Routes.APP.ADMIN.COMPANIES,
    [Routes.APP.ADMIN.NEWS]: Routes.APP.ADMIN.NEWS,
    [Routes.APP.ADMIN.REMINDER]: Routes.APP.ADMIN.REMINDER,
  };

  // if path has an id or similar
  if (Object.keys(tabArray).indexOf(pathname) < 0) {
    let pathWithoutLastSlash = pathname.substr(0, pathname.lastIndexOf('/'));
    return tabArray[pathWithoutLastSlash];
  }

  return tabArray[pathname];
};

export function AdminTab(props) {
  const classes = useStyles();

  const { variant, scrollButtons } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        variant: 'standard',
        scrollButtons: 'auto',
      },
    ],
    {
      variant: 'scrollable',
      scrollButtons: 'on',
    }
  );

  const tabStyle = {
    root: classes.tabRoot,
    selected: classes.tabSelected,
    wrapper: classes.labelIcon,
  };

  return (
    <div>
      <Tabs
        className={classes.tabs}
        value={getAdminTabPathname(props.location.pathname)}
        classes={{
          root: classes.root,
          indicator: classes.tabsIndicator,
          scrollButtons: classes.scrollButtons,
        }}
        variant={variant}
        scrollButtons={scrollButtons}
      >
        <Tab
          classes={tabStyle}
          label={<FormattedMessage id="BASE.USER" />}
          icon={<UsersIcon />}
          to={Routes.APP.ADMIN.USERS}
          value={Routes.APP.ADMIN.USERS}
          component={Link}
        />
        <Tab
          classes={tabStyle}
          label={<FormattedMessage id="BASE.COMPANIES" />}
          icon={<CompanyIcon />}
          to={Routes.APP.ADMIN.COMPANIES}
          value={Routes.APP.ADMIN.COMPANIES}
          component={Link}
        />
        <Tab
          classes={tabStyle}
          label={<FormattedMessage id="BASE.REMINDER" />}
          icon={<EmailIcon />}
          to={Routes.APP.ADMIN.REMINDER}
          value={Routes.APP.ADMIN.REMINDER}
          component={Link}
        />
        <Tab
          classes={tabStyle}
          label={<FormattedMessage id="BASE.NEWS" />}
          icon={<RocketIcon />}
          to={Routes.APP.ADMIN.NEWS}
          value={Routes.APP.ADMIN.NEWS}
          component={Link}
        />
      </Tabs>
      <AdminTabRoutes />
    </div>
  );
}

AdminTab.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(AdminTab);
