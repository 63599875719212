import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { Routes, Api } from '../../constants';
import { postToServer } from '../../api/index';

import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core';
import PaperPlane from '../../icons/paperplane';
import Bullhorn from '../../icons/bullhorn';
import Bulb from '../../icons/bulb';
import Pencil5 from '../../icons/pencil5';
import { UldShapedButton } from '../uldShapedButton.jsx';
import ModalWithContactForm from '../modalWithContactForm';
import TextSlideshow from './textSlideshow';

import { useMedia } from '../../utils/customHooks';
import { withSnackbar } from 'notistack';

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  text: {
    fontSize: '1.125rem',
    color: theme.palette.primary.dark,
    margin: '10px 0',
  },
  gridRootItem: {
    display: 'flex',
  },
}));

export function InfoContainer() {
  const classes = styles();

  return (
    <div className={classes.root}>
      <Grid item xs={12} md={12} className={classes.gridRootItem}>
        <TextSlideshow />
      </Grid>
    </div>
  );
}

InfoContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(InfoContainer);
