import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withStyles, List, ListItem } from '@material-ui/core';

import { Routes } from '../constants';
import FAQs from '../components/faqs/faqs';
import InfoSlideshow from '../components/homePage/infoSlideshow';
import AboutTextContainer from '../components/homePage/aboutTextContainer';
import InfoContainer from '../components/homePage/infoContainer';
import InfoSlideshowContainer from '../components/homePage/infoSlideshowContainer';
import VideoContainer from '../components/homePage/videoContainer';
import SocialBlock from '../components/socialBlock/socialBlock';
import {
  InfoBoxSkypoolingBenefitsFreeToUse,
  InfoBoxSkypoolingBenefitsSharingEconomy,
} from '../components/homePage/infoBoxSkypoolingBenefits';
import BenefitsContainer from '../components/homePage/benefitsContainer';
import AdLandscape from '../components/adLandscape';
import PressRelease from '../components/homePage/pressRelease';
import UldShapedBox from '../components/uldShapedBox';

import { getUser } from '../actions/user';

import {
  getInfoSlider,
  getCompaniesImages,
  getBenefitsSlide,
  getAdLandingPageBottom,
  getAdLandingPageTop,
} from '../actions/pathsForImages';

const styles = (theme) => ({
  outerDiv: {
    display: 'flex',
    padding: '10px',
    flexFlow: 'column',
  },
  headLineDiv: {
    margin: '10px 0',
    fontSize: '1.625rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: '1.5rem',
  },
  innerTextDiv: {
    margin: '10px 0',
    fontSize: '1.125rem',
    fontWeight: '500',
    color: theme.palette.primary.dark,
  },
  sponsorsListStyle: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    margin: '2rem 0',
    fontWeight: 'bold',
  },
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.myRefTwo = React.createRef();
    this.myRefThree = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.token && this.props.location.pathname === Routes.PUBLIC.HOME)
      this.props.history.push(Routes.APP.HOME.INDEX);
  }

  componentDidMount() {
    this.props.getInfoSlider();
    this.props.getBenefitsSlide();
    this.props.getCompaniesImages();
    this.props.getAdSlider();
    getUser(this.props.token);
  }

  render() {
    const { classes, infoSlider, adLandingPageTop, adLandingPageBottom } =
      this.props;

    return (
      <Fragment>
        <List>
          <ListItem>
            {infoSlider && infoSlider.length !== 0 && (
              <InfoSlideshow infoSlider={infoSlider} />
            )}
          </ListItem>
          <ListItem>
            <SocialBlock
              onClick={this.scrollToMyRef}
              history={this.props.history}
            />
          </ListItem>
          <ListItem>
            <AboutTextContainer ref={this.myRefThree} />
          </ListItem>
          <ListItem>
            <BenefitsContainer />
          </ListItem>

          <ListItem>
            <VideoContainer />
          </ListItem>
          <ListItem>
            <InfoBoxSkypoolingBenefitsSharingEconomy />
          </ListItem>
          <ListItem>
            <InfoSlideshowContainer />
          </ListItem>
          <ListItem>
            <InfoBoxSkypoolingBenefitsFreeToUse />
          </ListItem>
          <ListItem>
            <InfoContainer />
          </ListItem>
          <ListItem>
            <FAQs ref={this.myRef} />
          </ListItem>
          <ListItem>
            <UldShapedBox margin={'50px 0 10px'}>
              <PressRelease />
            </UldShapedBox>
          </ListItem>
          <ListItem>
            {adLandingPageBottom && adLandingPageBottom.length !== 0 && (
              <AdLandscape adSlider={adLandingPageBottom} />
            )}
          </ListItem>
        </List>
      </Fragment>
    );
  }
}

Home.propTypes = {
  infoSlider: PropTypes.arrayOf(PropTypes.string).isRequired,
  companiesImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  adSlider: PropTypes.arrayOf(PropTypes.string).isRequired,
  adLandingPageBottom: PropTypes.arrayOf(PropTypes.string).isRequired,
  adLandingPageTop: PropTypes.arrayOf(PropTypes.string).isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  getInfoSlider: PropTypes.func.isRequired,
  getCompaniesImages: PropTypes.func.isRequired,
  getBenefitsSlide: PropTypes.func.isRequired,
  getAdSlider: PropTypes.func.isRequired,
  token: PropTypes.string,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  infoSlider: state.getPathForImages.infoSlider,
  companiesImages: state.getPathForImages.companiesImages,
  user: state.user,
  adLandingPageBottom: state.getPathForImages.adSlider.landingPageBottom,
  adLandingPageTop: state.getPathForImages.adSlider.landingPageTop,
});

const mapDispatchToProps = (dispatch) => ({
  getInfoSlider: () => dispatch(getInfoSlider()),
  getCompaniesImages: () => dispatch(getCompaniesImages()),
  getBenefitsSlide: () => dispatch(getBenefitsSlide()),
  getAdSlider: () => {
    dispatch(getAdLandingPageTop());
    dispatch(getAdLandingPageBottom());
  },
  dispatch,
});

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Home))
);
