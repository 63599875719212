export default {
  INDEX: '/',
  PUBLIC: {
    HOME: '/home',
    AUTH: '/auth',
    LOGIN: '/login',
    REGISTRATION: '/registration',
    IMPRINT: '/imprint',
    DATA_PRIVACY: '/dataPrivacy',
    TERMS_OF_USE: '/termsOfUse',
    EVENTS_CALENDER: '/eventsCalender',
    RESET_PASSWORD: '/passwordReset',
  },
  APP: {
    INDEX: '/app',
    HOME: {
      INDEX: '/app/home',
    },
    GLOBAL_CHAT: {
      INDEX: '/app/globalChat',
    },
    NOTIFICATIONCENTER: {
      INDEX: '/app/notificationCenter',
    },
    EDIT_PROFILE: {
      INDEX: '/app/editProfile',
    },
    MY_INVENTORY: {
      INDEX: '/app/myInventory',
      ADD_STOCK: '/app/myInventory/addNewStock',
      EDIT_STOCK: '/app/myInventory/editStock',
      DETAILS: '/app/myInventory/details',
    },
    BOOKINGS: {
      INDEX: '/app/bookings',
      DETAILS: {
        INDEX: '/app/bookings/details',
        INCOMING: '/app/bookings/details/incoming',
        OUTGOING: '/app/bookings/details/outgoing',
      },
      INCOMING: '/app/bookings/incoming',
      OUTGOING: '/app/bookings/outgoing',
    },
    AIRPORT_STOCKS: {
      INDEX: '/app/airportStocks',
      DETAILS: '/app/airportStocks/details',
      BOOK_NOW: '/app/airportStocks/bookNow',
      LEASING: {
        BOOK_NOW: '/app/airportStocks/leasing/bookNow',
      },
    },
    MY_COMPANY: {
      INDEX: '/app/myCompany',
      EDIT_STOCK: '/app/myCompany/editStock',
      DETAILS: '/app/myCompany/details',
    },
    ADMIN: {
      INDEX: '/app/admin',
      USERS: '/app/admin/users',
      COMPANIES: '/app/admin/companies',
      NEWS: '/app/admin/news',
      REMINDER: '/app/admin/reminder',
    },
    LOST_AND_FOUND: {
      INDEX: '/app/lostAndFound',
      ADD_ENTRY: '/app/lostAndFound/addNewLostAndFoundEntry',
      DETAILS: '/app/lostAndFound/details',
      LOST: '/app/lostAndFound/lost',
      FOUND: '/app/lostAndFound/found',
    },
  },
};
