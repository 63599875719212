import { Actions } from '../constants';

const initialState = [];

export default function uldTypes(state = initialState, action) {
  switch (action.type) {
    case Actions.ULD_TYPES.ADD_ALL_ULD_TYPES:
      return [...action.payload.map(({ uldType }) => uldType)];
    default: {
      return state;
    }
  }
}
