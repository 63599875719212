import React from 'react';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Cross from '../icons/cross';
import { useMedia } from '../utils/customHooks';

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'initial',
    boxShadow: 'none',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: '-20px',
      right: 'calc(50% + 10px)',
      left: 'calc(50% - 10px)',
      borderBottom: `10px solid ${theme.palette.primary.cornflowerBlue}`,
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
      borderTop: '10px solid transparent',
    },
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.primary.cornflowerBlue}`,
    borderRight: `1px solid ${theme.palette.primary.cornflowerBlue}`,
    borderLeft: `1px solid ${theme.palette.primary.cornflowerBlue}`,
    borderTop: `1px solid ${theme.palette.primary.cornflowerBlue}`,
    borderRadius: '0px',
    maxWidth: 300,
    minWidth: 300,
    padding: 10,
  },
  mainText: {
    textColor: theme.palette.primary.blueZodiac,
    marginTop: '1rem',
    fontSize: '1rem',
  },
  headerText: {
    color: theme.palette.primary.chambray,
    fontSize: '1.3em',
    fontWeight: 600,
  },
  closeIcon: {
    textAlign: 'right',
    color: theme.palette.primary.chambray,
  },
  icon: {
    cursor: 'pointer',
  },
}));

export default function HoverInfo({
  titleText,
  mainText,
  elementRef,
  open,
  handleCloseDialog,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(elementRef);

  const { isMobile } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: false,
      },
    ],
    {
      isMobile: true,
    }
  );

  const openPopup = isMobile ? false : open;

  return (
    <Popper
      id="popover"
      className={classes.paper}
      open={openPopup}
      anchorEl={anchorEl.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      disableRestoreFocus
    >
      <span>
        <Grid container spacing={0}>
          <Grid item xs="10" className={classes.headerText}>
            <FormattedMessage id={titleText} />
          </Grid>
          <Grid item xs="2" className={classes.closeIcon}>
            <span onClick={handleCloseDialog} className={classes.icon}>
              <Cross />
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs="12" className={classes.mainText}>
            <FormattedMessage id={mainText} values={{ breakLine: <br /> }} />
          </Grid>
        </Grid>
      </span>
    </Popper>
  );
}

HoverInfo.propTypes = {
  titleText: PropTypes.string.isRequired,
  mainText: PropTypes.string.isRequired,
  elementRef: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};
