import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Routes } from '../constants';
import { withSnackbar } from 'notistack';

export class ResetPasswordPage extends Component {
  componentDidMount() {
    this.props.enqueueSnackbar(
      <FormattedMessage id="RESETPASSWORD_SUCCESS" />,
      { variant: 'success' }
    );
    this.props.history.push(Routes.PUBLIC.HOME);
  }

  render() {
    return null;
  }
}

ResetPasswordPage.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(withSnackbar(ResetPasswordPage));
