import React from 'react';
import { Typography, Grid } from '@material-ui/core';

export default function InformationItem({ headline, color, message }) {
  color = color ? color : 'unset';

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography component="span" variant="body2" color="textPrimary">
          {headline}
        </Typography>
      </Grid>
      <Grid style={{ color: color }} item xs={6}>
        {message}
      </Grid>
    </Grid>
  );
}
