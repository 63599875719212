import { Actions } from '../constants';

const initialState = [];

export default function companies(state = initialState, action) {
  switch (action.type) {
    case Actions.COMPANY.ADD_ALL_COMPANIES:
      return [...action.payload];
    default: {
      return state;
    }
  }
}
