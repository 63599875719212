import React, { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({});

export class Companies extends Component {
  render() {
    return <Fragment></Fragment>;
  }
}

export default withStyles(styles)(Companies);
