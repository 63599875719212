import React from 'react';
import { FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.primary.linkWater25,
    padding: '3rem',
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2.5rem',
    color: theme.palette.primary.dark,
  },
}));

export function BlueInfoContainer() {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <FormattedMessage id="BASE.TABLE_NO_INFO" />
    </div>
  );
}

export default BlueInfoContainer;
