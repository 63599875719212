import React from 'react';

export default function YoutubeIcon(props) {
  return (
    <svg viewBox="0 0 512 512" fill="#376092" {...props}>
      <path d="M147.1 277.4h17.7v92.2h18.6v-92.2H202v-15.8h-54.9zM256.9 201.1c2.8 0 4.7-.9 5.6-1.9 1.9-.9 1.9-2.8 1.9-5.6v-47.5c0-1.9-.9-3.7-1.9-4.7-1.9-.9-3.7-1.9-5.6-1.9-1.9 0-3.7.9-5.6 1.9-.9.9-1.9 2.8-1.9 4.7v47.5c0 2.8.9 4.7 1.9 5.6 1 .9 2.8 1.9 5.6 1.9zM296 289.5c-2.8 0-4.7.9-7.4 1.9-2.8.9-4.7 2.8-6.5 5.6v-34.4h-15.8v108h15.8V364c1.9 2.8 4.7 4.7 6.5 5.6 2.8.9 5.6 1.9 8.4 1.9 4.7 0 8.4-1.9 11.2-4.7 2.8-2.8 3.7-7.4 3.7-13V310c0-6.5-.9-11.2-3.7-14.9-2.9-4.7-6.6-5.6-12.2-5.6zm-.9 61.5c0 2.8-.9 4.7-.9 5.6-.9.9-2.8 1.9-4.7 1.9-.9 0-2.8 0-3.7-.9-.9-.9-2.8-1.9-3.7-2.8v-49.3c.9-.9 1.9-1.9 3.7-2.8.9-.9 1.9-.9 3.7-.9 1.9 0 3.7.9 4.7 1.9.9 1.9 1.9 3.7 1.9 6.5v41h-1zM238.3 351l-4.7 4.7c-1.9.9-3.7 1.9-4.7 1.9-1.9 0-2.8 0-3.7-.9-.9-.9-.9-1.9-.9-3.7v-61.4h-15.8v66.1c0 4.7.9 8.4 2.8 10.2 1.9 2.8 4.7 3.7 8.4 3.7 2.8 0 6.5-.9 9.3-2.8 2.8-1.9 6.5-3.7 9.3-7.4v8.4h15.8v-79.1h-15.8V351z" />
      <path d="M256 0C114.5 0 0 114.5 0 256s114.5 256 256 256 256-114.5 256-256S397.5 0 256 0zm41 126.6h17.7v67c0 1.9 0 3.7.9 4.7.9.9 1.9 1.9 3.7 1.9.9 0 2.8-.9 4.7-1.9 1.9-.9 3.7-2.8 5.6-4.7v-66.1h17.7V215h-16.8v-9.3c-3.7 3.7-6.5 6.5-10.2 8.4-3.7 1.9-7.4 2.8-10.2 2.8-3.7 0-7.4-.9-9.3-3.7-1.9-2.8-2.8-6.5-2.8-12.1v-74.5h-1zm-66.1 19.6c0-6.5 2.8-12.1 7.4-15.8s11.2-5.6 19.5-5.6c7.4 0 13 1.9 18.6 6.5 4.7 4.7 7.4 9.3 7.4 16.8v45.6c0 7.4-1.9 13-6.5 17.7s-11.2 6.5-19.5 6.5c-7.4 0-14-1.9-18.6-6.5s-7.4-10.2-7.4-17.7l-.9-47.5zM182.5 95l13 47.5h.9L208.5 95H229l-23.3 69.8v49.3h-20.5v-47.5L161 95h21.5zm233.6 250.4c0 31.7-26.1 57.7-57.7 57.7H160.1c-31.7 0-57.7-26.1-57.7-57.7v-46.5c0-31.7 26.1-57.7 57.7-57.7h199.2c31.7 0 57.7 26.1 57.7 57.7v46.5h-.9z" />
      <path d="M344.4 288.6c-7.4 0-13 1.9-17.7 6.5-4.7 4.7-6.5 10.2-6.5 16.8v35.4c0 7.4 1.9 14 6.5 17.7 3.7 4.7 9.3 6.5 16.8 6.5 8.4 0 14-1.9 17.7-6.5 3.7-3.7 5.6-10.2 5.6-18.6v-3.7H350v3.7c0 4.7-.9 7.4-1.9 9.3-.9.9-2.8 1.9-5.6 1.9s-4.7-.9-5.6-2.8c-.9-1.9-1.9-4.7-1.9-8.4v-14.9h30.7V312c0-7.4-1.9-13-5.6-17.7-2.6-3.9-8.2-5.7-15.7-5.7zm5.6 30.7h-14v-7.4c0-3.7.9-5.6 1.9-7.4.9-1.9 2.8-1.9 5.6-1.9s4.7.9 5.6 1.9c.9.9 1.9 3.7 1.9 7.4l-1 7.4z" />
    </svg>
  );
}
