import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import HeadlineBox from '../../../components/headlineBox';
import BookingOverview from './bookingOverview';
import { useMedia } from '../../../utils/customHooks';
import SearchFieldBox from '../../../components/searchFieldBox';
import SearchButton from '../../../components/headlineBox/searchButton';

const useStyle = makeStyles((theme) => ({
  span: {
    display: 'flex',
  },
}));

export default function Bookings() {
  const classes = useStyle();

  const [filterString, setFilterString] = React.useState('');

  const handleFilterChange = (string) => {
    setFilterString(string);
  };

  const { isMobile } = useMedia(
    ['(min-width: 960px)'],
    [
      {
        isMobile: false,
      },
    ],
    {
      isMobile: true,
    }
  );

  return (
    <Fragment>
      <HeadlineBox headLineText="BOOKINGS.HEADLINE">
        <span className={classes.span}>
          {isMobile && <SearchButton handleChange={handleFilterChange} />}
        </span>
      </HeadlineBox>
      {!isMobile && <SearchFieldBox handleChange={handleFilterChange} />}
      {/* <BookingTab>{children}</BookingTab> */}
      <BookingOverview filterString={filterString} isMobile={isMobile} />
    </Fragment>
  );
}

Bookings.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
