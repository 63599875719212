import { combineReducers } from 'redux';
import stockReducer from './stock';
import languageReducer from './language';
import authReducer from './auth_reducer';
import airportReducer from './airports';
import uldReducer from './uldTypes';
import userReducer from './user';
import companies from './companies';
import adminReducer from './admin';
import getPathForImages from './get_path_for_images';
import stockChatReducer from './stockChat';
import globalChatReducer from './globalChatReducer';
import bookingReducer from './bookings';
import notificationReducer from './notifications';
import lostAndFoundReducer from './lostAndFound';

export default combineReducers({
  stocks: stockReducer,
  language: languageReducer,
  auth: authReducer,
  airports: airportReducer,
  uldTypes: uldReducer,
  user: userReducer,
  companies: companies,
  admin: adminReducer,
  stockChat: stockChatReducer,
  globalChat: globalChatReducer,
  bookings: bookingReducer,
  notifications: notificationReducer,
  getPathForImages,
  lostAndFound: lostAndFoundReducer,
});
