import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import cross from '../assets/cross.svg';

const withStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    '&:hover': {
      background: 'none',
    },
  },
}));

export default function ClearButton({ onClick }) {
  const classes = withStyles();

  return (
    <Button
      style={{ minWidth: 'unset' }}
      className={classes.button}
      onClick={onClick}
      tabIndex="-1"
    >
      <img src={cross} alt="Cross" />
    </Button>
  );
}

ClearButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
