import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import MenuButton from '../components/menuButton';
import BackIcon from '../icons/back';
import HeadlineBox from '../components/headlineBox';
import { updateLastRead } from '../actions/user';
import GlobalChatForm from '../components/globalChatForm';
import { getGlobalChat, postGlobalChat } from '../actions/globalChat';
import AdLandscape from '../components/adLandscape';
import { getAdMessages } from '../actions/pathsForImages';
import GlobalChatMessage from '../model/globalChatMessage';

let timer = null;
let interval = 120000;
export class GlobalChat extends Component {
  state = {
    globalChat: [],
  };

  componentDidMount() {
    this.props.getGlobalChat(this.props.token);
    this.props.updateLastRead(this.props.token);
    this.props.getAdSlider();
    timer = setInterval(
      () => this.props.getGlobalChat(this.props.token),
      interval
    );
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  sendChatMessage = (message) => {
    const validMessage = message ? message : undefined;

    if (validMessage) {
      if (validMessage.length > 512) {
        this.props.enqueueSnackbar(
          <FormattedMessage id="GLOBAL_CHAT.ERROR_MESSAGE_LENGTH" />,
          { variant: 'warning' }
        );
      } else {
        this.props.postGlobalChat(message, this.props.token);
      }
    } else {
      this.props.enqueueSnackbar(
        <FormattedMessage id="GLOBAL_CHAT.ERROR_MESSAGE" />,
        { variant: 'warning' }
      );
    }
  };

  render() {
    const { globalChat, adImages } = this.props;

    return (
      <React.Fragment>
        <HeadlineBox
          textTransform="initial"
          headLineText="GLOBAL_CHAT.HEADLINE"
        />
        <div style={{ marginTop: '1em' }}>
          <MenuButton
            text={<FormattedMessage id="BASE.BACK" />}
            onClick={() => this.props.history.goBack()}
            img={<BackIcon />}
          />
          <div>
            <GlobalChatForm
              sendChatMessage={this.sendChatMessage}
              globalChat={globalChat}
            />
          </div>
          {adImages && adImages.length > 0 && (
            <AdLandscape adSlider={adImages} />
          )}
        </div>
      </React.Fragment>
    );
  }
}

GlobalChat.defaultProps = {
  globalChat: [],
};

GlobalChat.propTypes = {
  adImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }),
  getAdSlider: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  postGlobalChat: PropTypes.func.isRequired,
  getGlobalChat: PropTypes.func.isRequired,
  updateLastRead: PropTypes.func.isRequired,
  globalChat: PropTypes.arrayOf(PropTypes.instanceOf(GlobalChatMessage))
    .isRequired,
  token: PropTypes.string,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  globalChat: state.globalChat,
  user: {
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    phoneNumber: state.user.phone,
    place: state.user.place,
    reachability: state.user.reachability,
    position: state.user.position,
    groupMail: state.user.groupMail,
    iataCode: state.user.iataCode,
  },
  adImages: state.getPathForImages.adSlider.messages,
});

const mapDispatchToProps = (dispatch) => ({
  getGlobalChat: (token) => {
    dispatch(getGlobalChat(token));
  },
  updateLastRead: (token) => {
    dispatch(updateLastRead(token));
  },
  postGlobalChat: (message, token) => dispatch(postGlobalChat(message, token)),
  getAdSlider: () => dispatch(getAdMessages()),
});

export default withSnackbar(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalChat))
);
