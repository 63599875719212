import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Defaults, Routes } from '../constants';
import { FormattedMessage } from 'react-intl';

const styles = {
  root: {
    left: 0,
    top: 0,
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export class AuthPage extends PureComponent {
  componentDidMount() {
    if (this.props.token) {
      const route = localStorage.getItem(Defaults.UNAUTHORIZED_ROUTE_KEY);
      if (route) this.props.history.push(route);
      else this.props.history.push(Routes.APP.HOME.INDEX);
    }
  }

  componentDidUpdate() {
    if (this.props.token) {
      const route = localStorage.getItem(Defaults.UNAUTHORIZED_ROUTE_KEY);
      if (route) this.props.history.push(route);
      else this.props.history.push(Routes.APP.HOME.INDEX);
    } else if (this.props.error) {
      this.props.history.push(Routes.APP.HOME.INDEX);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.loadingInfo}>
          <CircularProgress />
          <FormattedMessage id="AUTH.AUTHENTICATION_IN_PROGRESS" />
        </div>
      </div>
    );
  }
}

AuthPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  token: PropTypes.string,
  error: PropTypes.any,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  error: state.auth.error,
});

export default withStyles(styles)(connect(mapStateToProps)(AuthPage));
