export const gridItemHeadline = (theme) => ({
  fontWeight: '700',
  padding: '9px 0',
  fontSize: '1rem',
  width: 'fit-content',
  minWidth: 'unset',
  minHeight: 'unset',
  maxHeight: 'unset',
  maxWidth: 'unset',
  textAlign: 'left',
  textTransform: 'uppercase',
  color: theme.palette.primary.chambray,
});

export const grid = {
  display: 'flex',
  alignItems: 'center',
};

export const checked = (theme) => ({
  color: `${theme.palette.primary.blueZodiac}`,
  '&:hover': {
    backgroundColor: theme.palette.primary.lightBlue,
  },
});

export const grid_margin = {
  display: 'flex',
  alignItems: 'flex-end',
  marginBottom: '25px',
};

export const radio = (theme) => ({
  color: theme.palette.primary.dark,
});
