import React from 'react';

export default () => (
  <svg
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="icons/uld-out"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M14.999,1.48687519 L14.999,2.48687519 L2.00075,2.60437481 L2.00075,23.5833748 L4.0785,23.6031248 L14.92475,23.6023748 L24.00075,13.8943748 L24,11.3958752 L25,11.3968752 L25,14.2630323 L15.387128,24.6031248 L4.07394328,24.6031248 L1,24.5750887 L1,1.61432974 L14.999,1.48687519 Z M19.674058,1.51051549 C19.8255784,1.35899509 20.0718962,1.35899509 20.2234166,1.51051549 L20.2234166,1.51051549 L24.8855827,6.17268155 C25.0378801,6.32342492 25.0378801,6.56974269 24.8863597,6.72126309 L24.8863597,6.72126309 L20.2241936,11.3834291 C20.1480449,11.4588008 20.0485854,11.4968752 19.9491258,11.4968752 C19.8496663,11.4968752 19.7502068,11.4595779 19.674058,11.3834291 C19.5225376,11.2319088 19.5225376,10.985591 19.674058,10.8340706 L19.674058,10.8340706 L23.6726425,6.83548616 L11.0125305,6.83548616 C10.7980709,6.83548616 10.6240167,6.66143196 10.6240167,6.44697232 C10.6240167,6.23251268 10.7980709,6.05845848 11.0125305,6.05845848 L11.0125305,6.05845848 L23.6726425,6.05845848 L19.674058,2.05987406 C19.5225376,1.90835366 19.5225376,1.66203589 19.674058,1.51051549 Z"
        id="Combined-Shape"
        fill="#122236"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);
