export default {
  SKYPOOLING_NEWS: 'SKYPOOLING NEWS',
  STOCK_CHAT: 'MESSAGE',
  BOOKING: {
    REQUEST: 'BOOKING REQUEST',
    ANSWER: {
      CONFIRM: 'BOOKING REQUEST CONFIRMED',
      DENY: 'BOOKING REQUEST DENY',
    },
  },
  LOST_AND_FOUND: 'LOST AND FOUND',
};
