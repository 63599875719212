import { Actions } from '../constants';
import jwt_decode from 'jwt-decode';
import { getCookie, COOKIE_KEYS, setCookie } from '../utils/cookieUtils';

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  place: '',
  position: '',
  reachability: '',
  groupMail: '',
  iataCode: [],
  company: {},
  roles: [],
  consent: getCookie(COOKIE_KEYS.KEY),
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case Actions.USER.GET_USER:
      return {
        ...state,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        phone: action.payload.phone,
        place: action.payload.place,
        position: action.payload.position,
        reachability: action.payload.reachability,
        groupMail: action.payload.groupMail,
        iataCode: action.payload.iataCode,
        lastRead: action.payload.lastRead,
        company: { ...action.payload.company },
        hasSeenNews: action.payload.hasAlreadySeenNews,
        notificationStockMatch: action.payload.notificationStockMatch,
        notificationScheduledReminder:
          action.payload.notificationScheduledReminder,
        notificationRest: action.payload.notificationRest,
      };
    case Actions.USER.GET_UNREAD:
      return {
        ...state,
        unread: action.payload,
      };
    case Actions.AUTH.TOKEN.ADD: {
      let encoded = jwt_decode(action.payload.token);
      return {
        ...state,
        roles: encoded.realm_access.roles,
      };
    }
    case Actions.COOKIE.SET_COOKIE: {
      const { lifetime, value } = action.payload;
      setCookie(COOKIE_KEYS.KEY, value, lifetime);
      return {
        ...state,
        consent: value,
      };
    }
    default: {
      return state;
    }
  }
}
